import React from "react";
import { SketchPicker } from "react-color";
import "./others/style.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const SketchColorShape = ({
  shape,
  display,
  setDisplay,
  color,
  setHex,
  direction,
  percent,
  tooltip = null,
  // , setAlpha, setColor
}) => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row-reverse",
      }}
    >
      <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        <div onClick={() => setDisplay(!display)} background={color}>
          {shape}
        </div>
      </OverlayTrigger>
      {display && (
        <div
          style={{
            top: "110%",
            position: "absolute",
            [direction]: percent,
            zIndex: 3,
            paddingBottom: "30px",
          }}
        >
          <SketchPicker
            disableAlpha={true}
            style={{ width: "200px", border: "1px solid black", zIndex: 3 }}
            color={color}
            onChange={(c) => {
              setHex(
                "#" +
                  ((1 << 24) + (c.rgb.r << 16) + (c.rgb.g << 8) + c.rgb.b)
                    .toString(16)
                    .slice(1)
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SketchColorShape;
