export const patterns = {
  // first:
  // "[הקדמה1]\nפרטי האירוע ואישור הגעתכם בקישור:\n[הזמנה]\nבמידה וחל שינוי ניתן להיכנס לקישור ולהזין מחדש את תשובתכם.",
  first:
    "[הקדמה1]\nפרטי האירוע ואישור הגעתכם בקישור:\n[הזמנה]\nבמידה וחל שינוי ניתן להיכנס לקישור ולהזין מחדש את תשובתכם.\nבמידה והקישור אינו נפתח יש להגיב להודעה זו את מספר המגיעים.",
  second:
    "טרם אישרתם הגעתכם [הקדמה2].\nתשובתכם תסייע לזוג להערך בהתאם:\n[הזמנה]",
  third:
    "אוטוטו זה קורה - [הקדמה3]\nאנא השיבו סטטוס הגעתכם כאן או בקישור:\n[הזמנה]",
};
