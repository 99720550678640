import React, { useEffect, useMemo, useState } from "react";
import DropDown from "../common/others/DropDownG";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import CsvDownloader from "react-csv-downloader";
import {
  GenericButton,
  GradientText,
  InputBox,
  MainDiv,
  RTextArea,
  FlexRowBetween,
  GenericLabel,
  GenericDiv,
  RespFont,
} from "../common/Style";
import {
  print,
  sms,
  postCsv,
  cleanAllData,
} from "../../store/reducers/dataReducer";
import OpacityLoading from "../common/loader/OpacityLoading";
import { useHistory } from "react-router-dom";
import GenericModal from "../common/modals/GenericModal";
import LogOutAll from "../common/LogOut/LogOutAll";
import { useToasts } from "react-toast-notifications";
import errors from "../../assets/print/errors1.svg";
import import1 from "../../assets/print/import1.svg";
import notes1 from "../../assets/print/notes.svg";
import download1 from "../../assets/print/download4.svg";
import bus from "../../assets/print/bus3.svg";
import gifts from "../../assets/print/gifts2.svg";
import expenses from "../../assets/client/expenses.svg";
import brain from "../../assets/client/brain.svg";
import logout1 from "../../assets/client/logout.svg";
import editInv from "../../assets/client/editInv.svg";
import todoList from "../../assets/client/todoList.svg";
import creditCard from "../../assets/client/creditCard.svg";
import tables from "../../assets/client/editTables.svg";
import video from "../../assets/client/video2.svg";
import guests1 from "../../assets/client/guests.svg";
import excel from "../../assets/print/excel1.svg";
import upload from "../../assets/print/upload.svg";
import { GetToast, MenuCss } from "../common/modals/Toast";
import readXlsxFile from "read-excel-file";
import Switcher from "../common/switcher/Switcher";
import { useExcelDownloder } from "react-xls";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getUpdatedSms } from "../../store/reducers/authReducer";
import SelfInvitationLink from "../common/modals/SelfInvitationLink";
import Options from "./brain/Options";
import { slide as Menu } from "react-burger-menu";
import ClientRoutes from "../../navigation/Routes";
import { patterns } from "./brain/patterns";
import { getStatusByCallNumber } from "../../store/reducers/dataReducer";
let dict = {
  קירבה: "closeness",
  שם: "name",
  שולחן: "table",
  טלפון: "phone",
  הוזמנו: "sum",
  אישרו: "accept",
  הגיעו: "arrived",
  מתנה: "gift",
  תשובה: "ans",
  הערות: "notes",
};

const Print = () => {
  const { ExcelDownloder, Type } = useExcelDownloder();
  const { user } = useSelector((state) => state.authReducer);
  let history = useHistory();
  const { encode } = require("url-encode-decode");
  const [link, setLink] = useState(true);
  const [title, setTitle] = useState(false);
  const [table, setTable] = useState(false);
  const [report, setReport] = useState("הכל");
  const [preSMS, setPreSMS] = useState(true);
  const [selfInv, setSelfInv] = useState(false);
  const [sender, setSender] = useState("BeSimple");
  const defaultPreSms1 = `${user.brideFN + " " + user.brideLN} ו${
    user.groomFN + " " + user.groomLN
  } שמחים להזמינכם לחתונתם!`;
  const defaultPreSms2 = `לחתונה של ${user.brideFN + " " + user.brideLN} ו${
    user.groomFN + " " + user.groomLN
  }`;
  const searchRegExp = /[a-zA-Zא-ת]/gi;
  const defaultPreSms3 = `${user.brideFN + " " + user.brideLN} ו${
    user.groomFN + " " + user.groomLN
  } מתחתנים!`;
  const [customMsg, setCustomMsg] = useState(``);
  // const [msg1, // setMsg1] = useState(`פרטי האירוע ואישור הגעתכם בקישור:\n`);
  // const [msg2, // setMsg2] = useState("\nבמידה וחל שינוי ניתן להיכנס לקישור בכל עת ולעדכן את מספר המגיעים.\nבמידה והקישור אינו נפתח יש להגיב להודעה זו");
  const { addToast } = useToasts();
  const { data, dataLoading, dataError, changeData } = useSelector(
    (state) => state.dataReducer
  );
  const dispatch = useDispatch();
  const [accept, setAccept] = useState("אין מענה");
  const [pattern, setPattern] = useState("הודעה ראשונה");
  const [notes, setNotes] = useState("הכל");
  const [phone, setPhone] = useState("עם מספר");
  const [sort, setSort] = useState("הערות");
  const [tables1, setTables] = useState(false);
  const [specialDishes, setSpecialDishes] = useState(false);
  const [lowerThan, setLowerThan] = useState(0);
  let selfInvitationLink = encodeURI(
    `https://b-s.fun/s?n=` + `${window.btoa(user._id)}`
  );

  const [isOpen, setIsOpen] = useState(false);
  const [logout, setLogout] = useState(false);
  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  useEffect(() => {
    return () => {
      dispatch(cleanAllData());
    };
  }, []);

  const fileSelected = (event) => {
    readXlsxFile(event.target.files[0]).then((rows) => {
      dispatch(postCsv({ rows }));
      // .then(() => {
      //     if (dataError === null)
      //         GetToast(addToast, "success", "הפעולה בוצעה בהצלחה")
      //     else GetToast(addToast, "error", "הפעולה לא הושלמה, יש להוריד דוח שגיאות")
      // });
    });
  };

  const getStatusByCallNumberFunc = (call) => {
    dispatch(getStatusByCallNumber({ call })).then((res) => {
      setTables(false);
      setSpecialDishes(false);
      if (!res.payload?.error) {
        setReport("שיחות");
      }
    });
  };

  const getData = () => {
    dispatch(
      print({
        accept,
        notes: notes === "הכל" ? false : true,
        phone: phone === "עם מספר" ? true : false,
        sort: dict[sort],
        lowerThan,
        specialDishes,
      })
    ).then((res) => {
      setTables(false);
      setSpecialDishes(false);
      if (!res.payload?.error) {
        setReport("הכל");
      }
    });
  };

  // const sendSMS = () => {
  //     let arr = []
  //     for (let i = 0; i < exportedGuests.length; i++) {
  //         let msg = "";
  //         msg += preSMS ? (exportedGuests[i]["הקדמה"] !== undefined && exportedGuests[i]["הקדמה"] !== null && exportedGuests[i]["הקדמה"] !== "")
  //             ? `${exportedGuests[i]["הקדמה"]}\n` : defaultPreSms : "";
  //         msg += `${msg1}\n`;
  //         msg += link ? `${exportedGuests[i]["קישור"]}\n` : "";
  //         msg += msg2;
  //         if (table)
  //             msg += exportedGuests[i]["שולחן"]
  //         arr.push({ phone: exportedGuests[i]["טלפון"], msg })
  //     }
  //     dispatch(sms({ arr, title })).then(data => {
  //         dispatch(getUpdatedSms())
  //     })
  // }

  const commonSetters = () => {
    setTable(false);
    setTables(false);
    setSpecialDishes(false);
    setPreSMS(true);
    setLink(true);
    setPhone("עם מספר");
    setAccept("אין מענה");
    setNotes("הכל");
    if (user.smsPhone) setTitle(false);
  };

  const prepareThankMsg = () => {
    setCustomMsg(
      `תודה שלקחתם חלק ביום המאושר שלנו 🤗\nמקווים שנהנתם, נתראה בשמחות 🙏\nאוהבים ${user.brideFN} ו${user.groomFN} ❤`
    );
    // setMsg1(`תודה שלקחתם חלק ביום המאושר שלנו 🤗\nמקווים שנהנתם, נתראה בשמחות 🙏\n אוהבים ${user.brideFN} ו${user.groomFN} ❤`);
    // setMsg2("");
    setTable(false);
    setAccept("מגיעים");
    setPhone("עם מספר");
    setPreSMS(false);
    setLink(false);
    setTitle(true);
    setLowerThan(5);
  };

  const prepareReminderMsg = () => {
    setCustomMsg(
      `היום זה קורה - החתונה של ${user.brideFN + " " + user.brideLN} ו${
        user.groomFN + " " + user.groomLN
      } 🎊🎉🍻\nמתנה באשראי - ${user.paybox}\nניווט לאירוע - ${
        user.waze
      }\nמתרגשים ומצפים לראותכם 🙏`
    );
    // setMsg1(`היום זה קורה - החתונה של ${user.brideFN + " " + user.brideLN} ו${user.groomFN + " " + user.groomLN} 🎊🎉🍻\nמתנה באשראי - ${user.paybox}\nניווט לאולם - ${user.waze}\nמתרגשים ומצפים לראותכם 🙏`);
    // setMsg2(`מספר שולחן: `);
    setTable(true);
    setPreSMS(false);
    setAccept("כולם");
    setPhone("עם מספר");
    setLink(false);
    setTitle(true);
    setLowerThan(4);
  };

  const prepareTableReminderMsg = () => {
    setCustomMsg(
      `אורחים יקרים, תזכורת\nמספר השולחן שלכם לחתונה של ${
        user.brideFN + " " + user.brideLN
      } ו${
        user.groomFN + " " + user.groomLN
      } הינו - [שולחן]\nאנא שמרו על הסדר 🙏`
    );
    // setMsg1(`היום זה קורה - החתונה של ${user.brideFN + " " + user.brideLN} ו${user.groomFN + " " + user.groomLN} 🎊🎉🍻\nמתנה באשראי - ${user.paybox}\nניווט לאולם - ${user.waze}\nמתרגשים ומצפים לראותכם 🙏`);
    // setMsg2(`מספר שולחן: `);
    setTable(true);
    setPreSMS(false);
    setAccept("כולם");
    setPhone("עם מספר");
    setLink(false);
    setTitle(true);
    setLowerThan(4);
  };

  const prepareThirdMsg = () => {
    setCustomMsg(patterns.third);
    // setMsg1(`${user.brideFN + " " + user.brideLN} ו${user.groomFN + " " + user.groomLN} עדיין ממתינים לתשבותכם,\nכתבו לנו את מספר המגיעים כאן או בקישור:\n`);
    // setMsg2("");
    commonSetters();
    setLowerThan(3);
  };

  const prepareAutoMsg = () => {
    commonSetters();
    setLowerThan(10);
  };

  const prepareSecondMsg = () => {
    setCustomMsg(patterns.second);
    // setMsg1(`טרם אישרתם הגעתכם לחתונה של ${user.brideFN + " " + user.brideLN} ו${user.groomFN + " " + user.groomLN},\nתשובתכם תסייע לזוג להערך בהתאם:\n`);
    // setMsg2("");
    commonSetters();
    setLowerThan(2);
  };

  const prepareFirstMsg = () => {
    setCustomMsg(patterns.first);
    // setMsg1(`פרטי האירוע ואישור הגעתכם בקישור:\n`);
    // setMsg2("\nבמידה וחל שינוי ניתן להיכנס לקישור בכל עת ולעדכן את מספר המגיעים.\nבמידה והקישור אינו נפתח יש להגיב להודעה זו");
    commonSetters();
    setLowerThan(1);
  };

  const dic = {
    0: "יום ראשון הקרוב",
    1: "יום שני הקרוב",
    2: "יום שלישי הקרוב",
    3: "יום רביעי הקרוב",
    4: "יום חמישי הקרוב",
    5: "יום שישי הקרוב",
  };
  const prepareKdamMsg = () => {
    setCustomMsg(
      `${dic[new Date(user.date).getDay()]} זה קורה - החתונה של ${
        user.brideFN + " " + user.brideLN
      } ו${
        user.groomFN + " " + user.groomLN
      } 🎊🎉🍻\nלנוחיותכם, לינק למתנה באשראי - ${user.paybox}\nניווט לאירוע - ${
        user.waze
      }\nנתראה 🙂`
    );
    // setMsg1(`פרטי האירוע ואישור הגעתכם בקישור:\n`);
    // setMsg2("\nבמידה וחל שינוי ניתן להיכנס לקישור בכל עת ולעדכן את מספר המגיעים.\nבמידה והקישור אינו נפתח יש להגיב להודעה זו");
    commonSetters();
    setLowerThan(9);
    setAccept("כולם");
    setTitle(true);
  };

  let msgOptions = [
    <MenuItem value={"הודעה ראשונה"} onClick={prepareFirstMsg}>
      הודעה ראשונה
    </MenuItem>,
    <MenuItem value={"הודעה שניה"} onClick={prepareSecondMsg}>
      הודעה שניה
    </MenuItem>,
    <MenuItem value={"הודעה שלישית"} onClick={prepareThirdMsg}>
      הודעה שלישית
    </MenuItem>,
    <MenuItem value={"אוטומט"} onClick={prepareAutoMsg}>
      אוטומט
    </MenuItem>,
    <MenuItem value={"קדם תזכורת"} onClick={prepareKdamMsg}>
      קדם תזכורת
    </MenuItem>,
    <MenuItem value={"תזכורת בוקר האירוע"} onClick={prepareReminderMsg}>
      תזכורת בוקר האירוע
    </MenuItem>,
    <MenuItem value={"הודעת תודה למחורת"} onClick={prepareThankMsg}>
      הודעת תודה למחורת
    </MenuItem>,
  ];

  let acceptOptions = [
    <MenuItem value={"אין מענה"}>אין מענה</MenuItem>,
    <MenuItem value={"מגיעים"}>מגיעים</MenuItem>,
    <MenuItem value={"מגיעים + לא ענו"}>מגיעים + לא ענו</MenuItem>,
    <MenuItem value={"כולם"}>כולם</MenuItem>,
  ];
  let notesOptions = [
    <MenuItem value={"עם הערה"}>עם הערה</MenuItem>,
    <MenuItem value={"הכל"}>הכל</MenuItem>,
  ];
  let phoneOptions = [
    <MenuItem value={"עם מספר"}>עם מספר</MenuItem>,
    <MenuItem value={"הכל"}>הכל</MenuItem>,
  ];
  let sortOptions = [];
  for (const [key, value] of Object.entries(dict)) {
    sortOptions.push(
      <MenuItem key={key} value={key}>
        {key}
      </MenuItem>
    );
  }

  let exportedGuests = useMemo(() => {
    let arr = [];
    let sum = 0;
    let preSms = false;
    let accept = 0;
    let arrived = 0;
    let transportation = 0;
    let gift = 0;
    if (data.length > 0)
      switch (report) {
        case "שיחות":
          let posC = 0;
          let negC = 0;
          arr = data.map((item, index) => {
            if (item.ans.includes("לא")) negC++;
            else posC++;
            return {
              שם: item.name,
              טלפון: item.phone,
              קירבה: item.closeness,
              מוזמנים: item.sum,
              אישרו: item.accept,
              תשובה: item.ans,
              הערות: item.notes,
            };
          });
          arr.push({
            שם: "",
            טלפון: "",
            קירבה: "",
            מוזמנים: "",
            אישרו: "",
            תשובה: "",
            הערות: "",
            "סך הכל ענו": posC,
            "סך הכל לא ענו": negC,
          });
          break;
        case "סידור":
          arr = data.map((item, index) => {
            return {
              שם: item.name,
              אישרו: item.accept,
              שולחן:
                item.table > 0
                  ? user.array[item.table - 1].side.split(" ")[0] === "או"
                    ? item.table +
                      ", " +
                      user.array[item.table - 1].side
                        .substring(3)
                        .replaceAll(searchRegExp, "")
                    : item.table
                  : "",
            };
          });
          break;
        case "מנות מיוחדות":
          arr = data.map((item, index) => {
            return {
              שם: item.name,
              ["ללא גלוטן"]: item.glt,
              ["צמחוני"]: item.tsim,
              ["טבעוני"]: item.tiv,
              ["גלאט"]: item.glat,
            };
          });
          break;
        case "הסעה":
          for (let i = 0; i < data.length; i++)
            if (data[i].transportation > 0) {
              sum += data[i].sum;
              accept += data[i].accept;
              transportation += data[i].transportation;
              arr.push({
                קירבה: data[i].closeness,
                שם: data[i].name,
                טלפון: data[i].phone,
                הוזמנו: data[i].sum,
                אישרו: data[i].accept,
                הסעה: data[i].transportation,
                הערות: data[i].notes,
              });
            }
          arr.push({
            קירבה: null,
            שם: null,
            טלפון: null,
            הוזמנו: "סה״כ הוזמנו",
            אישרו: "סה״כ אישרו",
            הסעה: "סה״כ הסעה",
          });
          arr.push({
            קירבה: null,
            שם: null,
            טלפון: null,
            הוזמנו: sum,
            אישרו: accept > 0 && accept,
            הסעה: transportation > 0 && transportation,
          });
          break;
        case "מתנות":
          arr = data.map((item, index) => {
            sum += item.sum;
            accept += item.accept;
            arrived += item.arrived > 0 ? item.arrived : 0;
            gift += item.gift > 0 ? item.gift : 0;
            return {
              קירבה: item.closeness,
              שם: item.name,
              טלפון: item.phone,
              הוזמנו: item.sum,
              אישרו: item.accept,
              הגיעו: item.arrived,
              מתנה: item.gift,
            };
          });
          arr.push({
            קירבה: null,
            שם: null,
            טלפון: null,
            הוזמנו: "סה״כ הוזמנו",
            אישרו: "סה״כ אישרו",
            הגיעו: "סה״כ הגיעו",
            מתנה: "סה״כ מתנות",
          });
          // arr.push({
          //     קירבה: null,
          //     שם: null,
          //     טלפון: null,
          //     הוזמנו: `=SUM(D2:D${data.length + 1})`,
          //     אישרו: accept > 0 && `=SUM(E2:E${data.length + 1})`,
          //     הגיעו: arrived > 0 && `=SUM(F2:F${data.length + 1})`,
          //     מתנה: gift > 0 && `=SUM(G2:G${data.length + 1})`,
          // })
          arr.push({
            קירבה: null,
            שם: null,
            טלפון: null,
            הוזמנו: sum,
            אישרו: accept > 0 && accept,
            הגיעו: arrived > 0 && arrived,
            מתנה: gift > 0 && gift,
          });
          break;
        case "שיחות":
          for (let i = 0; i < data.length; i++)
            if (data[i].ans !== "M")
              arr.push({
                קירבה: data[i].closeness,
                שם: data[i].name,
                טלפון: data[i].phone,
                אישרו: data[i].accept,
                תשובה: data[i].ans,
              });

          break;
        case "הערות":
          arr = data.map((item, index) => {
            return {
              קירבה: item.closeness,
              שם: item.name,
              טלפון: item.phone,
              הוזמנו: item.sum,
              אישרו: item.accept,
              הערות: item.notes,
            };
          });

          break;
        case "אייפלאן":
          arr = data.map((item, index) => {
            return {
              "הזמנה לכבוד": item.name,
              "מס' אורחים שהוזמנו": item.accept > 0 ? item.accept : item.sum,
              צד: item.accept > 0 ? "כלה" : "חתן",
              קבוצה: item.closeness,
              סלולרי: item.phone,
            };
          });

          break;
        default:
          arr = data.map((item, index) => {
            preSms =
              item.inviting !== undefined &&
              item.inviting !== null &&
              item.inviting !== "" &&
              item.owc !== undefined &&
              item.owc !== null &&
              item.owc !== "";
            return {
              id: item._id,
              שם: item.name,
              קירבה: item.closeness,
              שולחן:
                item.table > 0
                  ? user.array[item.table - 1].side.split(" ")[0] === "או"
                    ? "הנכם יושבים בשולחנות: " +
                      item.table +
                      ", " +
                      user.array[item.table - 1].side
                        .substring(3)
                        .replaceAll(searchRegExp, "")
                    : "הנכם יושבים בשולחן: " + item.table
                  : "",
              טלפון: item.phone,
              הוזמנו: item.sum,
              אישרו: item.accept,
              הסעה: item.transportation,
              הגיעו: item.arrived,
              מתנה: item.gift,
              תשובה: item.ans,
              נשלחו: item.sent,
              הערות: item.notes,
              // הקדמה1: (item.preSMS1 !== undefined && item.preSMS1 !== null && item.preSMS1 !== "") ? item.preSMS1 : defaultPreSms1,
              הקדמה1: preSms
                ? item.inviting +
                  (item.owc[item.owc.length - 1] === "ם"
                    ? " שמחים"
                    : item.owc[item.owc.length - 1] === "ו"
                    ? " שמח"
                    : " שמחה") +
                  " להזמינכם לחתונת " +
                  item.owc +
                  (item.owc.includes("ת")
                    ? ` ${user.brideFN}.`
                    : ` ${user.groomFN}.`)
                : defaultPreSms1,
              // הקדמה2: (item.preSMS2 !== undefined && item.preSMS2 !== null && item.preSMS2 !== "") ? item.preSMS2 : defaultPreSms2,
              הקדמה2: preSms
                ? "לחתונת " + item.owc + " של " + item.inviting
                : defaultPreSms2,
              // הקדמה3: (item.preSMS3 !== undefined && item.preSMS3 !== null && item.preSMS3 !== "") ? item.preSMS3 : defaultPreSms3,
              הקדמה3: preSms
                ? item.owc +
                  " של " +
                  item.inviting +
                  (item.owc.includes("ת") ? " מתחתנת" : " מתחתן") +
                  "!"
                : defaultPreSms3,
              קישור: `https://b-s.fun/n?n=${window.btoa(
                user._id + " " + item._id + " " + item.sum
              )}`,
            };
          });
      }
    return arr;
  }, [data, changeData, report]);

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  const prepareSpecialDishesStates = () => {
    setReport("מנות מיוחדות");
    setAccept("מגיעים");
    setNotes("הכל");
    setPhone("הכל");
    setSort("קירבה");
    setSpecialDishes(true);
  };

  const prepareTransportationStates = () => {
    setReport("הסעה");
    setAccept("מגיעים");
    setNotes("הכל");
    setPhone("הכל");
    setSort("קירבה");
  };

  const prepareTablesStates = () => {
    setReport("סידור");
    setAccept("כולם");
    setNotes("הכל");
    setPhone("הכל");
    setSort("שם");
    setTables(true);
  };

  const prepareNotesStates = () => {
    setReport("הערות");
    setAccept("כולם");
    setNotes("עם הערה");
    setPhone("הכל");
    setSort("הערות");
  };

  const prepareGiftsStates = () => {
    setReport("מתנות");
    setAccept("כולם");
    setNotes("הכל");
    setPhone("הכל");
    setSort("קירבה");
  };

  const prepareIplanStates = () => {
    setReport("אייפלאן");
    setAccept("כולם");
    setNotes("הכל");
    setPhone("הכל");
    setSort("שם");
  };

  return (
    <MainDiv
      className="animate__animated animate__fadeIn"
      onClick={() => setIsOpen(false)}
      style={{
        overflowX: "hidden",
        height: "100%",
        background: `radial-gradient(circle, ${addAlpha(
          user.color,
          0.25
        )} 0%, #f2f2f2 100%)`,
        minHeight: "100vh",
      }}
    >
      <Menu
        noOverlay
        isOpen={isOpen}
        onStateChange={handleStateChange}
        right
        width={"max-content"}
        styles={MenuCss(user.color)}
      >
        <div
          className="menu-item"
          style={{ marginTop: "0px" }}
          onClick={() => history.push(ClientRoutes.User.guests)}
        >
          <div>מוזמנים</div>
          <img src={guests1} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.tables)}
        >
          <div>מערך שולחנות</div>
          <img src={tables} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.instructions)}
        >
          <div>הדרכות</div>
          <img src={video} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.editDetails)}
        >
          <div>עריכת הזמנה</div>
          <img src={editInv} style={{ width: "23px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.expenses)}
        >
          <div>יומן הוצאות</div>
          <img src={expenses} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.todos)}
        >
          <div>יומן משימות</div>
          <img src={todoList} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          style={{
            position: "absolute",
            bottom: "20px",
            fontWeight: "bold",
            fontSize: "23px",
          }}
          onClick={() => setLogout(true)}
        >
          <div>התנתקות</div>
          <img src={logout1} style={{ width: "25px" }} />
        </div>

        {/* <div className="menu-item" style={{ position: "absolute", right: "30px", bottom: "20px", fontWeight: "bold", fontSize: "20px" }}
                    onClick={() => setLogout(true)}>התנתקות</div> */}
      </Menu>

      <Options
        specialDishes={user.specialDishes}
        transportation={user.transportation}
        prepareSpecialDishesStates={prepareSpecialDishesStates}
        prepareTransportationStates={prepareTransportationStates}
        prepareNotesStates={prepareNotesStates}
        prepareGiftsStates={prepareGiftsStates}
        prepareIplanStates={prepareIplanStates}
        prepareKdamMsg={prepareKdamMsg}
        prepareTablesStates={prepareTablesStates}
        getData={getData}
        setReport={setReport}
        getStatusByCallNumberFunc={getStatusByCallNumberFunc}
        report={report}
        exportedGuests={exportedGuests}
        prepareFirstMsg={prepareFirstMsg}
        prepareAutoMsg={prepareAutoMsg}
        prepareSecondMsg={prepareSecondMsg}
        prepareThirdMsg={prepareThirdMsg}
        prepareReminderMsg={prepareReminderMsg}
        prepareThankMsg={prepareThankMsg}
        color={user.color}
        setAccept={setAccept}
        setNotes={setNotes}
        setPhone={setPhone}
        setSort={setSort}
        accept={accept}
        notes={notes}
        phone={phone}
        sort={sort}
        acceptOptions={acceptOptions}
        notesOptions={notesOptions}
        phoneOptions={phoneOptions}
        sortOptions={sortOptions}
        defaultPreSms1={defaultPreSms1}
        defaultPreSms2={defaultPreSms2}
        defaultPreSms3={defaultPreSms3}
        title={title}
        numbers={user.numbers}
        setLowerThan={setLowerThan}
        lowerThan={lowerThan}
        customMsg={customMsg}
        setCustomMsg={setCustomMsg}
        setSelfInv={setSelfInv}
      />

      {/* <RespFont style={{ margin: "20px auto", fontSize: "20px", fontWeight: "600" }}>ייבוא וייצוא נתונים, פלטור ומיון לבחירה</RespFont>
            <FlexRowBetween style={{ justifyContent: "space-evenly" }}>
                <div><DropDown key={"מענה"} label={"מענה"} data={acceptOptions} value={accept} onChange={setAccept} /></div>
                <div><DropDown key={"הערות"} label={"הערות"} data={notesOptions} value={notes} onChange={setNotes} /></div>
            </FlexRowBetween>

            <FlexRowBetween style={{ justifyContent: "space-evenly" }}>
                <div><DropDown key={"פלאפון"} label={"פלאפון"} data={phoneOptions} value={phone} onChange={setPhone} /></div>
                <div><DropDown key={"מיון לפי"} label={"מיון לפי"} data={sortOptions} value={sort} onChange={setSort} /></div>
            </FlexRowBetween>

            <FlexRowBetween style={{ margin: "20px auto", justifyContent: "space-evenly" }}>
                <GenericButton background={"#f2f2f2"} style={{ color: "#3A3C3F" }} onClick={prepareNotesStates}><img src={notes1} style={{ width: "25px", marginLeft: "10px" }} /><RespFont>הערות</RespFont></GenericButton>
                {user.transportation &&
                    <GenericButton background={"#f2f2f2"} style={{ color: "#3A3C3F" }} onClick={prepareTransportationStates}><img src={bus} style={{ width: "25px", marginLeft: "10px" }} /><RespFont>הסעה</RespFont></GenericButton>
                }
                <GenericButton background={"#f2f2f2"} style={{ color: "#3A3C3F" }} onClick={prepareGiftsStates}><img src={gifts} style={{ width: "25px", marginLeft: "10px" }} /><RespFont>מתנות</RespFont></GenericButton>
                <GenericButton background={"#f2f2f2"} style={{ color: "#3A3C3F" }} onClick={() => {
                    setReport("שיחות");
                    setAccept("כולם")
                    setNotes("הכל")
                    setPhone("עם מספר")
                    setSort("אישרו")
                }}><img src={errors} style={{ width: "25px", marginLeft: "10px" }} /><RespFont>שיחות</RespFont></GenericButton>
                <GenericButton background={"#f2f2f2"} style={{ color: "#3A3C3F" }} onClick={getData}>
                    <img src={import1} style={{ width: "25px", marginLeft: "10px" }} /><RespFont>יבוא</RespFont></GenericButton>
                {
                    exportedGuests.length && <ExcelDownloder
                        data={{ "נתונים": exportedGuests }}
                        filename={`מיון לפי ${sort}`}
                        type={Type.Button} // or type={'button'}
                        style={{ border: "none" }}
                    >
                        <GenericButton className="animate__animated animate__fadeIn" background={"#f2f2f2"} style={{ color: "#3A3C3F" }}>
                            <img src={download1} style={{ width: "25px", marginLeft: "10px" }} /><RespFont>{`הורדה`}</RespFont></GenericButton>
                    </ExcelDownloder>
                }
            </FlexRowBetween>

            <InputBox labelColor={"gray"} color={"#3A3C3F"}>
                <FlexRowBetween style={{ width: "90%", margin: "0 auto", justifyContent: "center", alignItems: "baseline" }}>
                    <div className='relativeBox' style={{ width: "100px", margin: "0px" }}>
                        <input value={sender} type="text" onChange={(e) => {
                            if (/^[a-zA-Z]{1,11}$/.test(e.target.value))
                                setSender(e.target.value)
                            else alert("1-11 אותיות באנגלית בלבד")
                        }} />
                        <label>כותרת</label>
                    </div>
                    <OverlayTrigger placement={'top'} overlay={<Tooltip>
                        {user.smsPhone ? !title ? "ההודעה תישלח מהמספר שלך ובכך יוכל האורח להשיב בהודעה" : "כותרת ההודעה - האורח לא יוכל להשיב להודעה" : "על מנת שהאורח יוכל להשיב להודעה יש לפנות למנהל המערכת כדי להגדיר מספר פלאפון, אחרת בחר כותרת אשר תופיע כשולח ההודעה"}
                    </Tooltip>}>
                        <div style={{ margin: "0 10px" }}>
                            <Switcher disable={!user.smsPhone} checked={title} onChange={setTitle} color={user.color} style={{ margin: "0 10px" }} />
                        </div>
                    </OverlayTrigger>
                    <div style={{ fontSize: "18px" }}>המספר שלי</div>

                </FlexRowBetween>
            </InputBox>
            <FlexRowBetween style={{ margin: "10px auto", textAlign: "center", justifyContent: "space-evenly" }}>
                <div>
                    <div>הקדמה</div>
                    <Switcher checked={preSMS} onChange={setPreSMS} color={user.color} />
                </div>

                <div><DropDown label={"תבנית"} data={msgOptions} value={pattern} onChange={setPattern} /></div>

            </FlexRowBetween>
            <div style={{ position: "relative", width: "80%", margin: "0px auto" }}>
                <RTextArea value={msg1} onChange={(e) => // setMsg1(e.target.value)} color={user.color} placeholder="תוכן ההודעה" rows="2" />
            </div>

            <div>
                <div>קישור</div>
                <Switcher checked={link} onChange={setLink} color={user.color} />
            </div>

            <div style={{ position: "relative", width: "80%", margin: "0px auto" }}>
                <RTextArea value={msg2} onChange={(e) => // setMsg2(e.target.value)} color={user.color} placeholder="תוכן ההודעה" rows="2" />
            </div>
            <div style={{ margin: "0 10px", textAlign: "center" }}>
                <div>מספר שולחן</div>
                <Switcher checked={table} onChange={setTable} color={user.color} style={{ margin: "0 10px" }} />
            </div>
            <FlexRowBetween style={{ margin: "10px auto", justifyContent: "space-evenly" }}>
                {exportedGuests.length > 0 &&
                    <OverlayTrigger placement={'top'} overlay={<Tooltip>{exportedGuests?.length < user.sms ?
                        `יש ברשותך ${user.sms} הודעות, האם לשלוח הודעה זו ל${exportedGuests?.length} אורחים?`
                        :
                        `בחרת לשלוח הודעה זו ל ${exportedGuests?.length} אורחים אבל יש ברשותך רק ${user.sms} הודעות בחבילה, צמצם רשימה או רכוש הודעות נוספות`}</Tooltip>}>
                        <GenericButton background={user.color} onClick={() => {
                            if (exportedGuests?.length > user.sms)
                                alert("אין ברשותך מספיק הודעות כדי להמשיך פעולה זו")
                            else sendSMS()
                        }} >SMS</GenericButton>
                    </OverlayTrigger>
                }
                {dataError?.kind === "sms" &&
                    <ExcelDownloder
                        data={{ "שגיאות": dataError.error }}
                        filename={'שגיאות sms'}
                        type={Type.Button} // or type={'button'}
                        style={{ border: "none" }}
                    >
                        <GenericButton className="animate__animated animate__fadeIn" background={user.color}>שגיאות sms</GenericButton>
                    </ExcelDownloder>

                }
            </FlexRowBetween> */}

      {dataLoading && <OpacityLoading />}
      {logout && (
        <GenericModal init={setLogout} div={<LogOutAll init={setLogout} />} />
      )}

      {selfInv && (
        <GenericModal
          init={setSelfInv}
          div={
            <SelfInvitationLink link={selfInvitationLink} color={user.color} />
          }
        />
      )}
    </MainDiv>
  );
};

export default Print;
