import styled from 'styled-components';
const media = {
    inv: '@media only screen and (max-width:500px)',
    phone: '@media only screen and (max-width:480px)',
    phoneAndTablet: '@media only screen and (max-width:768px)',
    tablet: '@media only screen and (min-width:481px) and (max-width:768px)',
    bigTablet: '@media only screen and (min-width:769px) and (max-width:1024px)',
    comp: '@media only screen and (min-width:1025px)',
}

const constants = {
    backgroundColor: '#f1ae6e',
    fontColor: '#3A3C3F',
}

export const AvivItems = styled.div`
    display:flex;
    flex-direction: row;
    width: 90%;
    margin-right: 5%;

    justify-content: space-evenly;
    align-items: initial;
    margin-top: 30vh;

    ${media.phone}{
        flex-direction: column;
        margin-top: 10vh;
        margin-right: 0;
        width: 100% !important;
    }
    
`;

export const RowColumn = styled.div`
    display:flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: initial;

    ${media.phone} {
        flex-direction: column;
    }
`;

export const Service = styled.div`
    overflow: auto;
    text-align: center;
    cursor: pointer;
    padding: 10px 10px;
    background: rgba(255,255,255,0.7);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: none;
    border-radius: 28px;
    box-shadow: 0 1px 18px ${props => props.today ? props.color : "silver"};
    margin-top: 25px;
    font-size: 15px;
    height: 200px;
    width: 28%;
    margin-left: 0;
    margin-right:4%;
    ${media.bigTablet}{
        width: 30%;
        margin-right: 2.5%;
    }
    ${media.tablet}{
        width:45%;
        margin-right: 3.3%;
    }
    ${media.phone}{
        width:90%;
        margin-right: 5%;
    }
    div:nth-child(1) {
        align-self: center;
        font-size: 20px;
        font-weight: 600;
    }
    div:nth-child(2) {
        font-size: 15px;
    }
    p{
        margin: 0 0;
    }
    

    transition: all 0.2s;
    &:hover{
        transition: all 0.4s;
        border-radius: 2px;
        transform: scale(1.05);
    }
`;

export const Questions = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 10px;
    font-size: 18px;
    height: 70px;
    width:45%;
    background: rgba(255,255,255,0.3);
    margin: 20px 2.5%;
    padding: 0 10px;
    font-family: openSansRegular;
    font-weight: 600;
    cursor: pointer;
    ${media.phoneAndTablet}{
        font-size: 16px;
        width:90%;
        margin: 10px 5%;
    }
    transition: all 0.2s;
    &:hover{
        transition: all 0.4s;
        box-shadow: 0 1px 10px white;
        border-radius: 2px;
        transform: scale(1.05);
    }
`;
export const Service2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    text-align: center;
    border: none;
    border-radius: 28px;
    box-shadow: 0 1px 18px ${props => props.today ? props.color : "silver"};
    font-size: 15px;
    height: 100px;
    margin-left: 0;
    margin-top:25px;
    width:45%;
    margin-right: 3.3%;
    cursor: pointer;
    ${media.comp}{
        width: 15%;
        margin-right: 2.5%;
        /* margin-left: 0%; */
    }
    transition: all 0.2s;
    &:hover{
        transition: all 0.4s;
        border-radius: 2px;
        transform: scale(1.05);
    }
    div{
        padding: 20px 20px;
    }
    img{
        width:60px;
        position: absolute;
        top:-15px;
        left: -5px;
        transform: rotate(-30deg);
        
    }
    #info{
            width:40px;
            transform: rotate(-0deg);
        }
`;

// export const FlipCardInner = styled.div`
//   position: relative;
//   width: 100%;
//   height: 100%;
//   text-align: center;
//   transition: transform 0.6s;
//   transform-style: preserve-3d;
//   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
//   &:hover{
//     transform: rotateY(180deg);
//   }
// `;

// export const FlipCardFront = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   -webkit-backface-visibility: hidden;
//   backface-visibility: hidden;
//   background-color: #bbb;
//   color: black;
// `;

// export const FlipCardBack = styled(FlipCardFront)`
//   background-color: #2980b9;
//   color: white;
//   transform: rotateY(180deg);
// `;

export const AvivLogoHolder = styled.div`
    position: absolute;
    margin-top: -3vh;
    width: 30%;
    left: 35%;
    max-width: 500px;
    text-align: center;
    font-size: 1.5rem;
    div{
        font-family: "aviv";
        color: #222222;
    }
    ${media.phone}{
        width: 50%;
        left: 25%;
        font-size: 0.8rem !important;
    }
`;

export const ArrangementDiv = styled.div`
    width: 33.33%;
    display: flex;

    ${media.phone}{
        margin: 20px auto;
        width: 80%;
        order: 2;
    }
`;

export const InvitationDiv = styled.div`
    width: 66.66%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    ${media.phone}{
        margin: 20px auto;
        width: 80%;
        order: 3;
    }
`;

export const AvivLogo = styled.div`
    width: 33.33%;
    /* display: flex; */

    ${media.phone}{
        width: 80%;
        order: 1;
    }
`;

export const DealPoints = styled.div`
    box-shadow: 1px 2px 4px rgba(216, 216, 216, 0.8), -1px -2px 4px rgba(216, 216, 216, 0.8);
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.5), -1px -2px 8px rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 10px;
`;

export const AvivPrice = styled.div`
    padding-left: 5px;
    flex: 1 !important;
    ${media.phone}{
        flex: 2 !important;
    }
`;

export const AvivPriceDescription = styled.div`
    padding-right: 5px;
    border-right: 1px solid silver;
    flex: 5;
`;

export const AvivPriceHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 12px;
`;