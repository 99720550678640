import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import SelfInvitation from "./SelfInvitation";

import {
  getDetails,
  cleanInvitationDetails,
} from "../../../store/reducers/authReducer";
import { GetToast } from "../modals/Toast";
import Loading from "../modals/Loading";

const SelfInvitationNavigator = (props) => {
  const { user, authLoading, authError } = useSelector(
    (state) => state.authReducer
  );
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { details, detailsError, detailsLoading } = useSelector(
    (state) => state.invitationReducer
  );
  useEffect(() => {
    if (authError) GetToast(addToast, "error", authError);
  }, [authError]);

  const search = useLocation().search;
  const getId = (num) => {
    let name = new URLSearchParams(search).get("n");
    name = window.atob(name);
    name = name.split(" ");
    return name[num];
  };

  useEffect(() => {
    dispatch(getDetails(getId(0)));
    return () => {
      dispatch(cleanInvitationDetails());
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {user !== null && <SelfInvitation uid={getId(0)} />}
      {authLoading && <Loading />}
    </div>
  );
};

export default SelfInvitationNavigator;
