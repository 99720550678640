import React from 'react';
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ColorCheckbox = props => {
  return (
    <div>
      <Checkbox
        {...label}
        onChange={() => props.handleCHange()}
        checked={props.checked}
        sx={{
          color: props.color,
          '&.Mui-checked': {
            color: props.color,
          },
        }}
      />
    </div>
  );
}

export default ColorCheckbox;