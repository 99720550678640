import React, { useState } from "react";
import { addAlpha, MenuCss } from "../../common/modals/Toast";
import {
  DisplayNoneComputer,
  DisplayNonePhone,
  FlexRowBetween,
  MainDiv,
  RespFont,
} from "../../common/Style";
import { slide as Menu } from "react-burger-menu";
import brain from "../../../assets/client/brain.svg";
import logout1 from "../../../assets/client/logout.svg";
import tables from "../../../assets/client/editTables.svg";
import creditCard from "../../../assets/client/creditCard.svg";
import guests1 from "../../../assets/client/guests.svg";

import expenses from "../../../assets/user/tools/expenses.svg";
import introduction from "../../../assets/user/tools/introduction.svg";
import invitation from "../../../assets/user/tools/invitation.png";
import todos from "../../../assets/user/tools/todos.svg";
import { AddGuestsOption } from "../brain/Style";
import { useSelector } from "react-redux";
import ClientRoutes from "../../../navigation/Routes";
import { useHistory } from "react-router-dom";
import GenericModal from "../../common/modals/GenericModal";
import LogOutAll from "../../common/LogOut/LogOutAll";
import { expensesSvg, introductionSvg, todosSvg } from "../ColoredSvgs";

const Tools = (props) => {
  let history = useHistory();
  const { user } = useSelector((state) => state.authReducer);
  const [logout, setLogout] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  return (
    <MainDiv
      onClick={() => setIsOpen(false)}
      className="animate__animated animate__fadeIn"
      style={{
        overflowX: "hidden",
        overflowY: "auto",
        background: `radial-gradient(circle, ${addAlpha(
          user.color,
          0.25
        )} 0%, #f2f2f2 100%)`,
        maxHeight: "100vh",
        height: "100vh",
        paddingBottom: "20px",
        marginBottom: "20px",
      }}
    >
      <Menu
        noOverlay
        isOpen={isOpen}
        onStateChange={handleStateChange}
        right
        width={"max-content"}
        styles={MenuCss(user.color)}
      >
        <div
          className="menu-item"
          style={{ marginTop: "0px" }}
          onClick={() => history.push(ClientRoutes.User.guests)}
        >
          <div>מוזמנים</div>
          <img src={guests1} alt={"guests1"} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.tables)}
        >
          <div>מערך שולחנות</div>
          <img src={tables} alt={"tables"} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.print)}
        >
          <div>מוח המערכת</div>
          <img src={brain} alt={"brain"} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          style={{
            position: "absolute",
            bottom: "20px",
            fontWeight: "bold",
            fontSize: "20px",
          }}
          onClick={() => setLogout(true)}
        >
          <div>התנתקות</div>
          <img src={logout1} alt={"logout1"} style={{ width: "25px" }} />
        </div>
      </Menu>
      <DisplayNonePhone
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <FlexRowBetween style={{ justifyContent: "space-evenly" }}>
            <AddGuestsOption
              cSize={"200px"}
              onClick={() => history.push(ClientRoutes.User.expenses)}
            >
              <RespFont style={{ color: "#3A3C3F" }}>יומן הוצאות</RespFont>
              {expensesSvg(288.58666, 227.63, user.color)}
            </AddGuestsOption>
            <AddGuestsOption
              cSize={"200px"}
              onClick={() => history.push(ClientRoutes.User.instructions)}
            >
              <RespFont style={{ color: "#3A3C3F" }}>הדרכות</RespFont>
              {introductionSvg(288.58666, 227.63, user.color)}
            </AddGuestsOption>
            <AddGuestsOption
              cSize={"200px"}
              onClick={() => history.push(ClientRoutes.User.todos)}
            >
              <RespFont style={{ color: "#3A3C3F" }}>יומן משימות</RespFont>
              {todosSvg(288.58666, 227.63, user.color)}
            </AddGuestsOption>
          </FlexRowBetween>
          <FlexRowBetween style={{ justifyContent: "space-evenly" }}>
            <AddGuestsOption
              cSize={"250px"}
              onClick={() => history.push(ClientRoutes.User.editDetails)}
            >
              <RespFont style={{ color: "#3A3C3F" }}>עריכת הזמנה</RespFont>
              <img src={invitation} alt="invitation" />
            </AddGuestsOption>
          </FlexRowBetween>
        </div>
      </DisplayNonePhone>

      <DisplayNoneComputer
        style={{
          width: "100%",
          height: "100%",
          paddingBottom: "20px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingBottom: "20px",
          }}
        >
          <AddGuestsOption
            onClick={() => history.push(ClientRoutes.User.editDetails)}
            cSize={"250px"}
            pSize={"150px"}
            style={{ padding: "10px", marginTop: "20px" }}
          >
            <RespFont style={{ color: "#3A3C3F" }}>עריכת הזמנה</RespFont>
            <img src={invitation} alt="invitation" />
          </AddGuestsOption>
          <AddGuestsOption
            onClick={() => history.push(ClientRoutes.User.instructions)}
            cSize={"200px"}
            pSize={"100px"}
            style={{ padding: "10px", marginTop: "20px" }}
          >
            <RespFont style={{ color: "#3A3C3F" }}>הדרכות</RespFont>
            {introductionSvg(144.293, 113.815, user.color)}
          </AddGuestsOption>
          <AddGuestsOption
            onClick={() => history.push(ClientRoutes.User.todos)}
            cSize={"200px"}
            pSize={"100px"}
            style={{ padding: "10px", marginTop: "20px" }}
          >
            <RespFont style={{ color: "#3A3C3F" }}>יומן משימות</RespFont>
            {todosSvg(144.293, 113.815, user.color)}
          </AddGuestsOption>
          <AddGuestsOption
            onClick={() => history.push(ClientRoutes.User.expenses)}
            cSize={"200px"}
            pSize={"100px"}
            style={{ padding: "10px", marginTop: "20px" }}
          >
            <RespFont style={{ color: "#3A3C3F" }}>יומן הוצאות</RespFont>
            {expensesSvg(144.293, 113.815, user.color)}
          </AddGuestsOption>
        </div>
      </DisplayNoneComputer>
      {logout && (
        <GenericModal init={setLogout} div={<LogOutAll init={setLogout} />} />
      )}
    </MainDiv>
  );
};
export default Tools;
