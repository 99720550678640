import React, { useEffect } from "react";
import ClientRoutes from "../../navigation/Routes";
import { RespFont } from "../common/Style";
import Page1 from "./roy/Page1";
import Page4 from "./roy/Page4";
import PricePage from "./roy/pricelist/PricePage";
import BurgerMenu from "./roy/BurgerMenu";
import NavBarMenu from "./roy/NavBarMenu";
import whatsapp from "./whatsapp.svg";
import callMain from "./callMain.svg";
const Itay = () => {
  useEffect(() => {
    localStorage["logout"] = ClientRoutes.Producers.itay;
  }, []);
  return (
    <div
      style={{
        overflowX: "hidden",
        marginTop: "-7vh",
        width: "100%",
        height: "100%",
      }}
    >
      <NavBarMenu />
      <BurgerMenu />
      <a
        href={"tel:0547439322"}
        style={{
          position: "fixed",
          bottom: "80px",
          left: "0",
          padding: "10px 10px",
          paddingRight: "15px",
          color: "#3A3C3F",
          zIndex: "99999",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          background: "rgba(255,255,255,0.9)",
          borderRadius: "0 30px 30px 0",
          textDecoration: "none",
        }}
      >
        <img
          alt="callMain"
          src={callMain}
          style={{ width: "24px", marginLeft: "15px" }}
        />
        <RespFont>0547439322</RespFont>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://wa.me/972547439322/?text=${"היי, "}`}
        style={{
          position: "fixed",
          bottom: "20px",
          left: "0",
          padding: "10px 10px",
          color: "#3A3C3F",
          zIndex: "99999",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          background: "rgba(255,255,255,0.9)",
          borderRadius: "0 30px 30px 0",
          textDecoration: "none",
        }}
      >
        <img
          alt="whatsapp"
          src={whatsapp}
          style={{ width: "24px", marginLeft: "15px" }}
        />
        <RespFont>לכל שאלה</RespFont>
      </a>
      <Page1 />
      <PricePage />
      <Page4 />
      <div style={{ background: "#f2f2f2", padding: "10px" }}>
        שטרית איתי רם - פיתוח מערכות
      </div>
    </div>
  );
};
export default Itay;
