const ServerRoutes = {
  Client: {
    getAll: "clients/getClients",
    getAvivClients: "clients/getAvivClients",
    editPD: "clients/editClientPD/", // price and description
    reminder: "clients/reminder/",
    chair: "clients/chair/",
    edit: "clients/editClient/",
    destroyUser: "clients/destroyUser/",
    delete: "clients/deleteClient",
    whatsapp: "clients/sendWhatsapp",
    getInvitationDetails: "clients/getInvitationDetails/",
  },
  Payments: {
    getAll: "payments/getAll/",
    createlink: "payments/createlink/",
    setRecord: "payments/setRecord/",
    editBlessing: "payments/editBlessing/",
  },
  Auth: {
    editPic: "user/editPic",
    editPicPosition: "user/editPicPosition",
    editArray: "user/editArray",
    editMySelf: "user/editMySelf",
    editColorOnly: "user/editColorOnly",
    editTodos: "user/editTodos",
    getUpdatedTables: "user/getUpdatedTables",
    getUpdatedSms: "user/getUpdatedSms",
    logoutAll: "auth/logoutAll",
    logoutSpecific: "auth/logoutSpecific",
    login: "auth/login",
    checkAuth: "auth/checkAuth",
    register: "auth/register",
  },
  Photos: {
    upload: "photos/upload",
    delete: "photos/delete/",
  },
  Todos: {
    delete: "todos/delete",
    add: "todos/add",
    addBasic: "todos/addBasic",
    getAll: "todos/getAll",
    getBetweenDates: "todos/getBetweenDates",
    getDaniel: "todos/getDaniel",
    edit: "todos/edit/",
    editStatus: "todos/editStatus/",
  },
  Sms: {
    delete: "sms/delete",
    getAll: "sms/getAll",
  },
  Couples: {
    delete: "couples/delete",
    add: "couples/add",
    getAll: "couples/getAll",
    getBetweenDates: "couples/getBetweenDates",
    edit: "couples/edit/",
  },
  Potentials: {
    add: "potentials/add",
    addE4U: "potentials/addE4U",
    getAll: "potentials/getAll",
    editPotentialDetails: "potentials/editPotentialDetails/",
    editStatus: "potentials/editStatus/",
    delete: "potentials/delete",
  },
  Expenses: {
    delete: "expenses/delete",
    add: "expenses/add",
    getAll: "expenses/getAll",
    edit: "expenses/edit/",
    editCalculate: "todos/editCalculate/",
  },
  Guests: {
    getAll: "guests/getGuests",
    sms: "guests/sms",
    smsAdmin: "guests/smsAdmin",
    whaAdmin: "guests/whaAdmin",
    postCsv: "guests/postCsv",
    noNumber: "guests/noNumber",
    getNotAnsweredUser: "guests/getNotAnsweredUser",
    getAcceptedAndNotSitUser: "guests/getAcceptedAndNotSitUser",
    getAcceptedAndSitUser: "guests/getAcceptedAndSitUser",
    getDontArriveUser: "guests/getDontArriveUser",
    getGuest: "guests/getGuest",
    add: "dishes/add",
    editGuestSent: "guests/editGuestSent/",
    editGuestAdmin: "guests/editGuestAdmin/",
    resetSent: "guests/resetSent/",
    editById: "guests/editGuest/",
    setGuestsTable: "guests/setGuestsTable",
    setGuestsInvitingAndOwc: "guests/setGuestsInvitingAndOwc",
    setGuestsCloseness: "guests/setGuestsCloseness",
    setGuestsWasInvited: "guests/setGuestsWasInvited",
    getGuestAdmin: "guests/getGuestAdmin",
    deleteGuest: "guests/deleteGuest",
    setGift: "guests/setGift/",
    findDuplicates: "guests/findDuplicates",
    addGuest: "guests/addGuest",
    AddGuestHimself: "guests/AddGuestHimself",
    setAcceptAndTransportation: "guests/setAcceptAndTransportation/",
    setAcceptAndTransportationHimself:
      "guests/setAcceptAndTransportationHimself/",
    setAccept: "guests/setAccept/",
    setDishes: "guests/setDishes/",
    getNotAnswered: "guests/getNotAnswered/",
    getNotAnsweredW: "guests/getNotAnsweredW/",
    getGuestsAdmin: "guests/getGuestsAdmin/",
    getAcceptedAdmin: "guests/getAcceptedAdmin/",
    getTablesGuests: "guests/getTablesGuests/",
    getMoney: "guests/getMoney/",
    getStatusByCallNumber: "guests/getStatusByCallNumber/",
    print: "guests/print/",
    getAccepted: "guests/getAccepted/",
    getAllExcludeAccepted: "guests/getAllExcludeAccepted/",
    deleteMany: "guests/deleteMany/",
    addMany: "guests/addMany",
  },
  Tables: {
    getAll: "guests/getGuestsTables",
    setTables: "guests/getGuestsTables",
  },
  Messages: {
    getMessages: "messages/getMessages",
  },
};

export default ServerRoutes;
