import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { getAll, cleanAll } from "../../store/reducers/tablesReducer";
import Loading from "../common/modals/Loading";
import { GetToast } from "../common/modals/Toast";
import Switcher from "../common/switcher/Switcher";
import { slide as Menu } from "react-burger-menu";
import {
  GenericButton,
  GuestsHolder,
  BaseInput,
  TableCard,
  MainDiv,
  GradientText,
  InputBox,
  PlaceHolderImage,
  FlexRowBetween,
} from "../common/Style";
import GenericModal from "../common/modals/GenericModal";
import { setGuestsTable } from "../../store/reducers/tablesReducer";
import AddGuest from "./AddGuest";
import EditGuest from "./EditGuest";
import clear from "../../assets/client/clear.svg";
import moveTable from "../../assets/client/table2.svg";
import extension from "../../assets/client/extension2.svg";

import save from "../../assets/client/save1.svg";
import delete1 from "../../assets/client/delete1.svg";
import ClientRoutes from "../../navigation/Routes";
import TrTables from "./TrTables";
import TheadTables from "./TheadTables";
import AddTables from "./AddTables";
import DeleteTables from "./DeleteTables";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import EditTables from "./EditTables";
import checklist2 from "../../assets/client/checklist2.svg";
import refresh from "../../assets/client/refresh.svg";
import { useHistory } from "react-router-dom";
import EditDetails from "./EditDetails";
import LogOutAll from "../common/LogOut/LogOutAll";
import { MenuCss } from "../common/modals/Toast";
import brain from "../../assets/client/brain.svg";
import logout1 from "../../assets/client/logout.svg";
import guests1 from "../../assets/client/guests.svg";
import video from "../../assets/client/video2.svg";
import numbers1 from "../../assets/client/numbers1.svg";
import addTable from "../../assets/client/plus.svg";
import creditCard from "../../assets/client/creditCard.svg";
import editInv from "../../assets/client/editInv.svg";
import editTable from "../../assets/client/editWhite.svg";
import todoList from "../../assets/client/todoList.svg";
import expenses from "../../assets/client/expenses.svg";
import msg5 from "../../assets/client/msg5white.svg";
import "./style.css";

let multyChoise = {};
let multyChoiseCounter = 0;
const Tables = () => {
  let history = useHistory();
  const [selected, setSelected] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [reception, setReception] = useState(true);
  const [tableChosenFlag, setTableChosenFlag] = useState(false);
  const [logout, setLogout] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(null);
  const [table, setTable] = useState(null);
  const [editTables, setEditTables] = useState(false);
  const [multiSelect, setMultiSelect] = useState(false);
  const { guests, flag, guestsError } = useSelector(
    (state) => state.tablesReducer
  );
  const { user } = useSelector((state) => state.authReducer);
  const [title, setTitle] = useState(user.producer.includes("itay"));
  const [showAddGuest, setShowAddGuest] = useState(false);
  const [addTables, setAddTables] = useState(false);
  const [sendTableMsg, setSendTableMsg] = useState(false);
  const [deleteTables, setDeleteTables] = useState(false);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [editDetails, setEditDetails] = useState(false);
  const inputRef = useRef();

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  const gg = async () => {
    setLoading(true);
    dispatch(getAll()).then(
      () => {
        setLoading(false);
      },
      (error) => {
        GetToast(addToast, "error", error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    dispatch(getAll()).then(
      () => {
        setLoading(false);
      },
      (error) => {
        GetToast(addToast, "error", error);
        setLoading(false);
      }
    );
    return () => {
      dispatch(cleanAll());
      multyChoise = {};
      multyChoiseCounter = 0;
      setSelected(0);
    };
  }, []);

  useEffect(() => {
    if (guestsError) GetToast(addToast, "error", guestsError);
  }, [guestsError]);

  const updateGuest = async (items, table1, empty = false) => {
    dispatch(setGuestsTable(items, empty ? null : table1)).then(
      (data) => {
        GetToast(addToast, "success", "הפעולה הצליחה");
        setTable();
        setMultiSelect(false);
        multyChoise = {};
        multyChoiseCounter = 0;
        setSelected(0);
      },
      (error) => GetToast(addToast, "error", error)
    );
  };

  const setGuestsTable1 = async (items) => {
    if (Object.keys(items).length > 0) {
      if (user.array[parseInt(table) - 1] !== undefined)
        await updateGuest(items, parseInt(table));
      else if (parseInt(table) === 0) await updateGuest(items, null, true);
      else alert("שולחן לא תקין או לא קיים");
    } else alert("לא נבחרו אורחים");
  };

  const addOrDeleteAllTable = (tableNum) => {
    let guestsT = guests[tableNum] !== undefined ? guests[tableNum] : {};
    for (const [key, value] of Object.entries(guestsT)) addOrDelete(value);
  };

  const addOrDelete = (item) => {
    if (multyChoise[item._id] !== undefined) {
      delete multyChoise[item._id];
      multyChoiseCounter -= item.accept > 0 ? item.accept : item.sum;
    } else {
      multyChoise[item._id] = item;
      multyChoiseCounter += item.accept > 0 ? item.accept : item.sum;
    }
    setSelected(multyChoiseCounter);
    setTableChosenFlag(!tableChosenFlag);
  };

  let guestsArray = useMemo(() => {
    let sT = searchText.toLowerCase();
    let tables = [];
    let array = [];
    let temp = [];
    let count = 0;
    let totalCount = 0;
    let totalArrived = 0;
    let tableArrived = 0;
    let partialSearch;
    if (user.array.length > 0)
      // אם קיימים אורחים
      for (let i = 0; i < user.array.length; i++) {
        // עבור כל שולחן
        if (guests[i + 1] === undefined) {
          // אם לא קיימים אורחים בשולחן זה - הכנסת כותרת
          if (user.array[i].side?.toLowerCase().includes(searchText))
            tables.push(
              <TableCard>
                <TheadTables
                  multy={multiSelect}
                  addOrDeleteAllTable={addOrDeleteAllTable}
                  key={i + 1}
                  table={i + 1}
                  amount={user.array[i].amount}
                  sit={0}
                  closeness={user.array[i].side}
                />
              </TableCard>
            );
        } else {
          for (const [key, value] of Object.entries(guests[i + 1])) {
            parseInt(value.arrived) >= 0
              ? (totalArrived += parseInt(value.arrived))
              : (totalArrived += 0);
            parseInt(value.arrived) >= 0
              ? (count += parseInt(value.arrived))
              : value.accept
              ? (count += parseInt(value.accept))
              : console.log("");
            parseInt(value.arrived) >= 0 &&
              (tableArrived += parseInt(value.arrived));

            // if (value.name?.toLowerCase().includes(searchText) || value.phone?.includes(searchText))
            if (
              searchText
                .toLowerCase()
                .split(" ")
                .filter((i) =>
                  value.name?.toLowerCase().includes(i.toLowerCase())
                ).length === searchText.toLowerCase().split(" ").length ||
              value.phone?.includes(searchText)
            )
              temp.push(
                <TrTables
                  title={title}
                  sendTableMsg={sendTableMsg}
                  checked={multyChoise[key] !== undefined}
                  setSearchText={setSearchText}
                  key={key}
                  index={key}
                  data={value}
                  addOrDelete={addOrDelete}
                  multy={multiSelect}
                />
              );
          }
          totalCount += parseInt(count);
          if (temp.length > 0 || user.array[i].side?.includes(searchText)) {
            array.push(
              <TheadTables
                multy={multiSelect}
                addOrDeleteAllTable={addOrDeleteAllTable}
                key={i + 1}
                table={i + 1}
                amount={user.array[i].amount}
                sit={reception ? count : tableArrived}
                closeness={user.array[i].side}
              />
            );
            array.push(...temp);
            tables.push(<TableCard key={i + 1000}>{array}</TableCard>);
          }
          array = [];
          temp = [];
          count = 0;
          tableArrived = 0;
        }
      }

    return [tables, totalCount, totalArrived];
  }, [
    guests,
    multiSelect,
    user.array,
    searchText,
    flag,
    tableChosenFlag,
    reception,
    sendTableMsg,
    title,
  ]);

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  return (
    <MainDiv
      onClick={() => setIsOpen(false)}
      className="animate__animated animate__fadeIn"
      style={{
        overflow: "hidden",
        background: `radial-gradient(circle, ${addAlpha(
          user.color,
          0.25
        )} 0%, #f2f2f2 100%)`,
        maxHeight: "100vh",
      }}
    >
      <Menu
        noOverlay
        isOpen={isOpen}
        onStateChange={handleStateChange}
        right
        width={"max-content"}
        styles={MenuCss(user.color)}
      >
        <div
          className="menu-item"
          style={{ marginTop: "0px" }}
          onClick={() => history.push(ClientRoutes.User.guests)}
        >
          <div>מוזמנים</div>
          <img src={guests1} alt="guests1" style={{ width: "25px" }} />
        </div>
        <div className="menu-item" onClick={() => setEditTables(!editTables)}>
          <div>עריכת שולחנות</div>
          <img src={editTable} alt="editTable" style={{ width: "25px" }} />
        </div>
        <div className="menu-item" onClick={() => setAddTables(!addTables)}>
          <div>הוספת שולחנות</div>
          <img src={addTable} alt="addTable" style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => setDeleteTables(!deleteTables)}
        >
          <div>מחיקת שולחנות</div>
          <img src={delete1} alt="delete1" style={{ width: "22px" }} />
        </div>
        <div className="menu-item" onClick={() => setReception(!reception)}>
          <div style={{ marginBottom: "-5px" }}>
            {reception ? "מי כאן?" : "קבלת פנים"}
          </div>
          <img
            src={numbers1}
            alt="numbers1"
            style={{ width: "35px", marginBottom: "-5px" }}
          />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.tools)}
        >
          <div>כלים נוספים</div>
          <img src={extension} style={{ width: "28px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.print)}
        >
          <div>מוח המערכת</div>
          <img src={brain} alt="brain" style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => setSendTableMsg(!sendTableMsg)}
        >
          <div>
            {sendTableMsg ? "ביטול הודעת מס׳ שולחן" : "הודעת מס׳ שולחן"}
          </div>
          <img src={msg5} alt="msg5" style={{ width: "20px" }} />
        </div>

        <div
          className="menu-item"
          style={{
            position: "absolute",
            bottom: "20px",
            fontWeight: "bold",
            fontSize: "20px",
          }}
          onClick={() => setLogout(true)}
        >
          <div>התנתקות</div>
          <img src={logout1} alt="logout1" style={{ width: "25px" }} />
        </div>
      </Menu>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "flex-start",
          direction: "ltr",
          marginLeft: "40px",
          alignItems: "flex-end",
        }}
      >
        {multiSelect ? (
          <>
            <BaseInput
              color={user.color}
              style={{
                height: "max-content",
                width: "max-content",
                textAlign: "left",
                paddingRight: "0px",
                padding: `2px 1px`,
              }}
              type="number"
              min="1"
              max="100"
              onChange={(e) => setTable(e.target.value)}
              className="animate__animated animate__backInDown"
            />
            <img
              className="animate__animated animate__backInDown"
              src={moveTable}
              alt="save"
              style={{ cursor: "pointer", width: "25px", marginLeft: "10px" }}
              onClick={() => setGuestsTable1(multyChoise)}
            />
          </>
        ) : (
          <OverlayTrigger
            placement={"bottom"}
            overlay={<Tooltip>{"סנכרון"}</Tooltip>}
          >
            <GenericButton
              style={{
                boxShadow: "0 0 0 white",
                background: user && user.color,
                minWidth: "max-content",
                display: "flex",
                flexDirection: "row",
              }}
              onClick={() => gg()}
            >
              {
                <>
                  <img
                    src={refresh}
                    alt="refresh"
                    style={{ width: `18px`, marginRight: "8px" }}
                  />
                  {guestsArray[2]}
                </>
              }
            </GenericButton>
          </OverlayTrigger>
        )}
        <OverlayTrigger
          placement={"bottom"}
          overlay={<Tooltip>{"בחירה מרובה"}</Tooltip>}
        >
          <img
            onClick={() => {
              multyChoise = {};
              multyChoiseCounter = 0;
              setMultiSelect(!multiSelect);
              setSelected(0);
            }}
            src={checklist2}
            alt="checklist2"
            style={{ width: `30px`, marginLeft: "15px", cursor: "pointer" }}
          />
        </OverlayTrigger>
        <div
          style={{ marginLeft: "15px" }}
          className="animate__animated animate__fadeIn"
        >
          {selected > 0 && selected}
        </div>
        {/* <img src={clear} alt="clear" style={{ cursor: "pointer", width: "20px" }} onClick={() => {
                    setSearchText('');
                    inputRef.current.focus();
                }} /> */}
        <InputBox
          labelColor={"gray"}
          color={"rgba(0,0,0,0.6)"}
          style={{ width: "100px", margin: "0 0", marginLeft: "15px" }}
        >
          <div style={{ position: "relative", width: "100%" }}>
            <PlaceHolderImage
              onClick={() => {
                setSearchText("");
                inputRef.current.focus();
              }}
              fontSize={`28px`}
              top={`32px`}
              src={clear}
              // style={{ cursor: "pointer" }}
              style={{
                cursor: "pointer",
                border: "1px solid silver",
                padding: "4px",
                borderRadius: "2px",
              }}
            />
            <input
              ref={inputRef}
              value={searchText}
              style={{ direction: "rtl" }}
              type="text"
              required={true}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <label>חיפוש</label>
          </div>
        </InputBox>
      </div>

      <GuestsHolder style={{ marginTop: `12px` }}>
        {guestsArray[0]}
      </GuestsHolder>
      {loading && <Loading />}
      {showAddGuest && (
        <GenericModal
          init={setShowAddGuest}
          div={<AddGuest init={setShowAddGuest} />}
        />
      )}
      {edit && (
        <GenericModal
          init={setEdit}
          div={<EditGuest data={edit} init={setEdit} />}
        />
      )}
      {addTables && (
        <GenericModal
          init={setAddTables}
          div={<AddTables data={user.array} init={setAddTables} />}
        />
      )}
      {deleteTables && (
        <GenericModal
          init={setDeleteTables}
          div={<DeleteTables data={user.array} init={setDeleteTables} />}
        />
      )}
      {editTables && (
        <GenericModal
          init={setEditTables}
          div={<EditTables data={user.array} init={setEditTables} />}
        />
      )}

      {editDetails && (
        <GenericModal
          init={setEditDetails}
          div={<EditDetails init={setEditDetails} />}
        />
      )}

      {logout && (
        <GenericModal init={setLogout} div={<LogOutAll init={setLogout} />} />
      )}
    </MainDiv>
  );
};
export default Tables;
