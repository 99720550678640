import styled from 'styled-components';
const media = {
    phone: '@media(max-width:767px)',
    background: '#FF2E00',
}
export const Sticky = styled.div`
    position: absolute;
    bottom: 0 !important;
    width: 20%;
    border-radius: 70px;
    background: ${media.background};
    opacity: 0.8;
    transition: all 1.8s;
    ${media.phone}{
        width:100%;
        border-radius: 0px;
    }
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    a{
        padding: 10px 20px;
        img{
            width: 30px;
        }
    }
`

export const Sticky2 = styled.div`
    position: absolute;
    bottom: 50px !important;
    left: 0px !important;
    border-radius: 70px;
    transition: all 1.8s;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    color: white;
    a{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background:  ${props => props.background ? props.background : "#f1ad6eb4"};
        border-radius: 0px 5px 5px 0px;
        box-shadow: 0px 1px 3px white;
        margin-bottom: 5px;
        img{
            width: 30px;
        }
    }
`