import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import { addGuest } from "../../store/reducers/guestsReducer";
import {
  CenteredForm,
  PlaceHolderImage,
  GenericButton,
  RespFont,
  InputBox,
  RTextArea,
  FlexRowBetween,
  GenericDiv,
  DropOption,
} from "../common/Style";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import exitModalLogo from "../../assets/common/cancel1.svg";
import sum from "../../assets/client/amount1.svg";
import accept from "../../assets/client/accept.svg";
import dropdown1 from "../../assets/client/dropdown1.svg";
import delete1 from "../../assets/client/delete1.svg";
import fullName from "../../assets/client/userIcon.svg";
import phoneLogo from "../../assets/client/phoneLogo.svg";
import { GetToast } from "../common/modals/Toast";
import Switcher from "../common/switcher/Switcher";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import ExitModal from "../common/modals/ExitModal";
const exp = (
  <div>
    <div>קשר לבעל/ת השמחה במילה אחת</div>
    <div>...בנו/ בתה/ בתם/ אחיו ועוד</div>
  </div>
);
const AddGuest = (props) => {
  const [preSmsNumber, setPreSmsNumber] = useState(1);
  const dispatch = useDispatch();
  const [closeness, setCloseness] = useState("");
  const [show, setShow] = useState(true);
  const [limit, setLimit] = useState(true);
  const [owc, setOwc] = useState("קשר");
  const [showDropOwc, setShowDropOwc] = useState(false);
  const { user } = useSelector((state) => state.authReducer);
  const { addToast } = useToasts();
  const { register, handleSubmit } = useForm();
  const handleOnSelect = (item) => {
    setCloseness(item.id);
  };
  const handleOnSearch = (string, results) => {
    setCloseness(string);
  };

  const onSubmit = async (data) => {
    let newOwc = "";
    if (
      (data?.inviting.length && owc === "קשר") ||
      (data?.inviting.length === 0 && owc !== "קשר")
    )
      alert("שדה המזמין והקשר באים ביחד - הכל או כלום");
    // else if (
    //   data?.inviting.includes(user.brideFN) ||
    //   data?.inviting.includes(user.groomFN) ||
    //   data?.inviting.includes("חתן") ||
    //   data?.inviting.includes("כלה")
    // )
    //   alert(
    //     "המזמין אינו יכול להיות חתן או כלה, יש להשאיר ריק את המזמין והקשר עבור מוזמנים ישירים של הזוג"
    //   );
    else if (closeness.length > 1) {
      newOwc = owc;
      dispatch(
        addGuest({
          name: data.name,
          phone: data.phone,
          closeness,
          sum: parseInt(data.sum),
          accept: parseInt(data.accept),
          inviting: data.inviting,
          owc: newOwc,
        })
      ).then((res) => {
        if (!res.payload?.error) {
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
          props.init();
        }
      });
    } else alert("שדה קירבה לא יכול להיות פחות מ 2 תווים");
  };
  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }
  return (
    <CenteredForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ExitModal init={props.init} color={user.color} />
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          textAlign: "center",
          color: "#3A3C3F",
          fontWeight: "bold",
          padding: `30px 0`,
          paddingBottom: `10px`,
        }}
      >
        הוספת אורח
      </RespFont>
      <InputBox labelColor={"gray"} color={"#3A3C3F"}>
        <FlexRowBetween>
          <div style={{ position: "relative", width: "40%", margin: "4px 5%" }}>
            <PlaceHolderImage
              fontSize={`16px`}
              top={`36px`}
              src={fullName}
              alt="fullName"
            />
            <input
              type="text"
              name="name"
              minLength={2}
              maxLength={50}
              required={true}
              ref={register()}
            />
            <label>שם מלא</label>
          </div>
          <div style={{ position: "relative", width: "40%", margin: "4px 5%" }}>
            <PlaceHolderImage
              fontSize={`16px`}
              top={`36px`}
              src={phoneLogo}
              alt="phoneLogo"
            />
            <input
              type="text"
              placeholder="לא חובה"
              name="phone"
              pattern={limit && "[0]{0,1}[5]{1}[0-9]{8}"}
              ref={register()}
            />
            <label>מס' פלאפון</label>
          </div>
        </FlexRowBetween>
        <FlexRowBetween>
          <div style={{ position: "relative", width: "40%", margin: "4px 5%" }}>
            <PlaceHolderImage
              fontSize={`23px`}
              top={`33px`}
              src={sum}
              alt="sum"
            />
            <input
              type="number"
              name="sum"
              min={1}
              max={limit ? 30 : 1000}
              required={true}
              ref={register()}
            />
            <label>כמות</label>
          </div>
          <div style={{ position: "relative", width: "40%", margin: "4px 5%" }}>
            <PlaceHolderImage
              fontSize={`20px`}
              top={`34px`}
              src={accept}
              alt="accept"
            />
            <input
              type="number"
              placeholder="לא חובה"
              name="accept"
              min={0}
              max={limit ? 30 : 1000}
              ref={register()}
            />
            <label>אישרו</label>
          </div>
        </FlexRowBetween>
        <FlexRowBetween>
          <div
            className="relativeBox"
            style={{
              marginTop: "20px",
              zIndex: "1",
              marginRight: "5%",
              // , display: "flex", flexDirection: "row", width: "100%"
            }}
          >
            <ReactSearchAutocomplete
              items={props.categories}
              onSelect={handleOnSelect}
              autoFocus
              onSearch={handleOnSearch}
              fuseOptions={{ minMatchCharLength: 1 }}
              maxResults={show ? 10 : 0}
              showNoResults={false}
              placeholder="קירבה"
              styling={{
                searchIconMargin: "0 5px 0 10px",
                clearIconMargin: "4px 0 0 5px",
                borderRadius: "5px",
                width: "100%",
                height: "30px",
              }}
            />
          </div>
          <OverlayTrigger
            placement={"bottom"}
            overlay={
              <Tooltip>
                {show ? "הסתרת השלמות לחיפוש" : "הצגת השלמות לחיפוש"}
              </Tooltip>
            }
          >
            <GenericDiv
              style={{
                marginLeft: "5%",
                height: "30px",
                padding: "10px 15px",
                marginTop: "10px",
              }}
              background={user.color}
              onClick={() => setShow(!show)}
            >
              {show ? "הסתר" : "הצג"}
            </GenericDiv>
          </OverlayTrigger>
        </FlexRowBetween>
        {user.eventType && (
          <FlexRowBetween style={{ width: "90%", margin: "0 auto" }}>
            <div style={{ position: "relative", width: "70%" }}>
              <input
                type="text"
                name="inviting"
                minLength={2}
                maxLength={50}
                ref={register()}
              />
              <label>המזמין</label>
            </div>
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip>{"הקשר של בעל/ת השמחה למזמין"}</Tooltip>}
            >
              <GenericDiv
                background={user.color}
                style={{
                  position: "relative",
                  width: "20%",
                  marginBottom: "-10px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropOwc(!showDropOwc);
                }}
              >
                <div>{owc}</div>
                <img
                  src={dropdown1}
                  style={{ width: "25px", marginLeft: "-7px" }}
                />
                {showDropOwc && (
                  <div
                    style={{
                      position: "absolute",
                      left: "0",
                      bottom: "120%",
                      zIndex: 2,
                      color: user.color,
                      borderRadius: "5px",
                      backgroundColor: "white",
                      padding: "10px",
                      fontSize: "12px",
                      boxShadow: "0 2px 4px gray",
                    }}
                  >
                    <FlexRowBetween>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בנם");
                          setShowDropOwc(false);
                        }}
                      >
                        בנם
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בנה");
                          setShowDropOwc(false);
                        }}
                      >
                        בנה
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בתו");
                          setShowDropOwc(false);
                        }}
                      >
                        בתו
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בנו");
                          setShowDropOwc(false);
                        }}
                      >
                        בנו
                      </DropOption>
                      <DropOption
                        background={"black"}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("קשר");
                          setShowDropOwc(false);
                        }}
                        style={{ boxShadow: "0 2px 4px black" }}
                      >
                        <img src={delete1} style={{ width: "15px" }} />
                      </DropOption>
                    </FlexRowBetween>
                    <FlexRowBetween style={{ marginTop: "8px" }}>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בתם");
                          setShowDropOwc(false);
                        }}
                      >
                        בתם
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בתה");
                          setShowDropOwc(false);
                        }}
                      >
                        בתה
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדה");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדה
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדתם");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדתם
                      </DropOption>
                    </FlexRowBetween>
                    <FlexRowBetween style={{ marginTop: "8px" }}>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדם");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדם
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדתה");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדתה
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדתו");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדתו
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדו");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדו
                      </DropOption>
                    </FlexRowBetween>
                    <FlexRowBetween style={{ marginTop: "8px" }}>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחותה");
                          setShowDropOwc(false);
                        }}
                      >
                        אחותה
                      </DropOption>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחותו");
                          setShowDropOwc(false);
                        }}
                      >
                        אחותו
                      </DropOption>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחיו");
                          setShowDropOwc(false);
                        }}
                      >
                        אחיו
                      </DropOption>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחיה");
                          setShowDropOwc(false);
                        }}
                      >
                        אחיה
                      </DropOption>
                    </FlexRowBetween>
                  </div>
                )}
              </GenericDiv>
            </OverlayTrigger>
          </FlexRowBetween>
        )}

        <GenericButton
          hoverBackground={"rgb(223, 223, 223)"}
          background={user.color}
          type="submit"
          style={{ width: "60%", margin: "30px auto" }}
        >
          אישור
        </GenericButton>
      </InputBox>
      <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip>{limit ? "כולל מגבלות" : "ללא מגבלות"}</Tooltip>}
      >
        <div
          style={{
            position: "absolute",
            right: "15px",
            top: "15px",
            // , transform: "rotate(90deg)"
          }}
        >
          <Switcher checked={limit} onChange={setLimit} color={user.color} />
        </div>
      </OverlayTrigger>
    </CenteredForm>
  );
};

export default AddGuest;
