import { createSlice } from '@reduxjs/toolkit';
import ServerRoutes from '../api/routes';
import { get, post, get2, post2 } from '../api/http';


const initialState = {
    user: null,
    authenticated: false,
    error: null,
    authError: null,
    authLoading: false,
    loading: false,
}

const _actionStart = (state, action) => {
    state.authLoading = true;
    state.authError = null;
};
const _actionSuccess = (state, action) => {
    state.authLoading = false;
    state.authError = null;
};
const _actionFailed = (state, action) => {
    state.authLoading = false;
    state.authError = action.payload.error;
};

const _setUser = (state, action) => {
    state.user = action.payload.user;
    state.authenticated = action.payload.authenticated;
    state.authLoading = false;
};

const _editUserDetails = (state, action) => {
    for (const [key, value] of Object.entries(action.payload))
        state.user[key] = value;
};

const _stopLoading = (state) => {
    state.authLoading = false;
};

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: _setUser,
        editUserDetails: _editUserDetails,
        actionStart: _actionStart,
        stopLoading: _stopLoading,
        actionFailed: _actionFailed,
        actionSuccess: _actionSuccess
    },
});

const { reducer, actions } = auth;

export const { setUser, editUserDetails, actionStart, actionFailed, actionSuccess, stopLoading } = actions;

export default reducer;


export const login = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { data, error, status } = await post2(ServerRoutes.Auth.login, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            localStorage.setItem('bs', data.token);
            return dispatch(setUser({ user: data.user, authenticated: true }))
        }
    }
};

export const getDetails = (id) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { data, error, status } = await get2(ServerRoutes.Client.getInvitationDetails + id);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(setUser({ user: data, authenticated: false }))
        }

    }
};

export const cleanInvitationDetails = () => {
    return async (dispatch) => {
        return dispatch(setUser({ user: null, authenticated: false }));
    }
};

export const editTodos = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Auth.editTodos, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editUserDetails(body))
        }
    }
};

export const editDetails = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Auth.editMySelf, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editUserDetails(body))
        }
    }
};

export const editDetailsRudexOnly = (body) => {
    return async (dispatch) => {
        return dispatch(editUserDetails(body))
    }
};

export const editColorOnly = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Auth.editColorOnly, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editUserDetails(body))
        }
    }
};


export const editArray = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Auth.editArray, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editUserDetails(body))
        }
    }
};

export const editNumbers = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        dispatch(actionSuccess())
        return dispatch(editUserDetails(body))
    }
};

export const editUserPic = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Auth.editPic, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editUserDetails(body))
        }
    }
};

export const editUserPicPosition = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Auth.editPicPosition, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editUserDetails(body))
        }
    }
};

export const register1 = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Auth.register, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            return dispatch(actionSuccess())
        }
    }
};

// export const getDishes1 = () => {
//     return async (dispatch) => {
//         dispatch(actionStart())
//         const { data, error, status } = await get2(ServerRoutes.Dishes.getAll, {});
//         if (status !== 200)
//             return dispatch(actionFailed({ error }));
//         else return dispatch(setDishes(data));
//     }
// };

export const getUpdatedTables = () => {
    return async (dispatch) => {
        const result = await get(ServerRoutes.Auth.getUpdatedTables, {});
        dispatch(editUserDetails(result));
    }
};

export const getUpdatedSms = () => {
    return async (dispatch) => {
        const result = await get(ServerRoutes.Auth.getUpdatedSms, {});
        return dispatch(editUserDetails(result));
    }
};

export const checkAuth = () => {
    return async (dispatch) => {
        dispatch(actionStart());
        const { data, status } = await get2(ServerRoutes.Auth.checkAuth, {});
        if (status !== 200)
            return dispatch(stopLoading());
        else return dispatch(setUser({ user: data, authenticated: true }));
    }
}

export const logoutAll = () => {
    return async (dispatch) => {
        await post(ServerRoutes.Auth.logoutAll, {});
        localStorage.removeItem('bs');
        return dispatch(setUser({ user: null, authenticated: false }));
    }
};

export const editDetailsLocalOnly = (body) => {
    return async (dispatch) => {
        dispatch(actionSuccess())
        return dispatch(editUserDetails(body))
    }
};

export const logoutSpecific = () => {
    return async (dispatch) => {
        const { error, status } = await post2(ServerRoutes.Auth.logoutSpecific, {});
        if (status !== 200)
            dispatch(actionFailed(error));
        else {
            localStorage.removeItem('bs');
            return dispatch(setUser({ user: null, authenticated: false }));

        }
    }
};
