import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ClientRoutes from "./Routes";
import Main from "../components/admin/itay/Main";
import AdminGuests from "../components/admin/itay/Guests";
import AdminTodos from "../components/admin/itay/Todos";
import Potentials from "../components/admin/itay/potentials/Potentials";
import Sms from "../components/admin/itay/Sms";

const AdminSwitch = () => {
  return (
    <Switch>
      <Route
        path={ClientRoutes.Admin.main}
        component={(props) => <Main {...props} />}
      />
      <Route
        path={ClientRoutes.Admin.potentials}
        component={(props) => <Potentials {...props} />}
      />
      <Route
        path={ClientRoutes.Admin.sms}
        component={(props) => <Sms {...props} />}
      />
      <Route
        path={ClientRoutes.Admin.guests}
        component={(props) => <AdminGuests {...props} />}
      />
      <Route
        path={ClientRoutes.Admin.todos}
        component={(props) => <AdminTodos {...props} />}
      />
      <Redirect from="/" to={ClientRoutes.Admin.main} />
    </Switch>
  );
};

export default AdminSwitch;
