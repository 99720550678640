import React from 'react';
import { ModalStyle } from '../Style';

const GenericModal = ({ div, init, background = "radial-gradient(circle, #f2f2f2 0%, rgba(255,255,255,0.2) 100%)" }) => {
    return (<ModalStyle className="animate__animated animate__fadeIn" style={{ background: background, backdropFilter: "blur(4px)" }} onClick={(e) => {
        e.preventDefault();
        init(null);
    }}>
        {div}
    </ModalStyle>);
}
export default GenericModal;