import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';

import { editArray } from '../../store/reducers/authReducer';
import { CenteredForm, PlaceHolderImage, GenericButton, RespFont, InputBox } from '../common/Style'

import amount from '../../assets/client/amount1.svg';
import closeness from '../../assets/client/side1.svg';
import exitModalLogo from '../../assets/common/cancel1.svg';
import tableLogo from '../../assets/client/tableLogo.svg';
import { GetToast } from '../common/modals/Toast';
import ExitModal from '../common/modals/ExitModal';

const DeleteTables = (props) => {
    const { user } = useSelector(state => state.authReducer);
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { register, handleSubmit } = useForm();
    const onSubmit = async data => {
        let arr = [...props.data];
        arr = arr.splice(0, arr.length - (data.tables))
        dispatch(editArray({ array: arr })).then(() => {
            GetToast(addToast, "success", "הפעולה הצליחה")
            props.init(null)
        }, error => GetToast(addToast, "error", error));
    }

    return (<CenteredForm onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit(onSubmit)}>
        <ExitModal init={props.init} color={user.color} />
        <RespFont pSize={"20px"} cSize={"22px"} style={{ textAlign: "center", color: "#3A3C3F", fontWeight: "bold", padding: `30px 0`, paddingBottom: `0px` }}>הוספת שולחנות</RespFont>
        <InputBox labelColor={"gray"} color={"#3A3C3F"} >
            <div className='relativeBox'>
                <PlaceHolderImage fontSize={`30px`} top={`31px`} src={tableLogo} alt="tableLogo" />
                <input name="tables" type="number" min={1} max={100} required={true} ref={register()} />
                <label>כמות שולחנות למחיקה</label>
            </div>
            <GenericButton hoverBackground={"rgb(223, 223, 223)"} background={user.color} type="submit" style={{ width: "60%", margin: "50px auto" }}>אישור</GenericButton>
        </InputBox>
        {/* {eventsLoading && <SmallLoading background={true} />} */}
    </CenteredForm >)
}

export default DeleteTables;