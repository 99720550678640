import React, { useState } from "react";
import import1 from "../../../assets/print/import1.svg";
import notes1 from "../../../assets/print/notes1.png";
import bus from "../../../assets/print/bus4.svg";
import specialDishes1 from "../../../assets/print/specialDishes.svg";
import gifts from "../../../assets/print/gift11.png";
import wd from "../../../assets/print/chair.png";
import { ReportsOption, RespLogo } from "./Style";
import {
  FlexColumnBetween,
  OptionsFirst,
  RespFont,
  RespImg,
} from "../../common/Style";
import { postCsv } from "../../../store/reducers/dataReducer";
import readXlsxFile from "read-excel-file";
import { useSelector, useDispatch } from "react-redux";
import { useExcelDownloder } from "react-xls";
import GenericModal from "../../common/modals/GenericModal";
import AskForDownload from "../../common/modals/AskForDownload.js";

const Reports = ({
  transportation,
  specialDishes,
  exportedGuests,
  prepareTransportationStates,
  prepareSpecialDishesStates,
  prepareNotesStates,
  prepareGiftsStates,
  prepareIplanStates,
  prepareTablesStates,
  getData,
  setReport,
  getStatusByCallNumberFunc,
  report,
  color,
}) => {
  const dispatch = useDispatch();

  const [allowDownload, setAllowDownload] = useState(false);
  const [localReport, setLocalReport] = useState("הכל");
  const fileSelected = (event) => {
    readXlsxFile(event.target.files[0]).then((rows) => {
      dispatch(postCsv({ rows }));
    });
  };
  let widthS = window.innerWidth; // 1728

  return (
    <OptionsFirst
      className="animate__animated animate__fadeIn"
      style={{ overflowY: "auto", height: "100%" }}
    >
      <div>
        <ReportsOption
          style={{
            marginBottom: widthS > 768 ? "30px" : "20px",
            marginTop: "10px",
          }}
          onClick={() => {
            setLocalReport("שיחה ראשונה");
            setReport("שיחות");
            setAllowDownload(true);
            getStatusByCallNumberFunc("C1");
          }}
        >
          <RespFont>שיחה 1</RespFont>
        </ReportsOption>
        <ReportsOption
          style={{
            marginBottom: widthS > 768 ? "0px" : "10px",
            marginTop: "10px",
          }}
          onClick={() => {
            setLocalReport("שיחה שניה");
            setReport("שיחות");
            setAllowDownload(true);
            getStatusByCallNumberFunc("C2");
          }}
        >
          <RespFont>שיחה 2</RespFont>
        </ReportsOption>
      </div>
      <div>
        <ReportsOption
          style={{
            marginBottom: widthS > 768 ? "30px" : "20px",
            marginTop: "10px",
          }}
          onClick={() => {
            setLocalReport("שיחה שלישית");
            setReport("שיחות");
            setAllowDownload(true);
            getStatusByCallNumberFunc("C3");
          }}
        >
          <RespFont>שיחה 3</RespFont>
        </ReportsOption>
        <ReportsOption
          style={{
            marginBottom: widthS > 768 ? "0px" : "10px",
            marginTop: "10px",
          }}
          onClick={() => {
            setLocalReport("שיחה רביעית");
            setReport("שיחות");
            setAllowDownload(true);
            getStatusByCallNumberFunc("C4");
          }}
        >
          <RespFont>שיחה 4</RespFont>
        </ReportsOption>
      </div>
      <ReportsOption
        style={{
          marginTop: widthS > 768 ? "0px" : "10px",
          marginBottom: widthS > 768 ? "0px" : "10px",
        }}
        onClick={() => {
          setLocalReport("אייפלאן");
          setAllowDownload(false);
          prepareIplanStates();
        }}
        chosen={report === "אייפלאן"}
      >
        <RespFont style={{ color: "#3A3C3F" }}>אייפלאן</RespFont>
      </ReportsOption>
      <ReportsOption
        style={{ marginTop: widthS > 768 ? "0px" : "10px" }}
        onClick={() => {
          setLocalReport("מתנות");
          setAllowDownload(false);
          prepareGiftsStates();
        }}
        chosen={report === "מתנות"}
      >
        <RespFont style={{ color: "#3A3C3F" }}>מתנות</RespFont>
        <img src={gifts} />
      </ReportsOption>
      <ReportsOption
        style={{ marginTop: "10px" }}
        onClick={() => {
          setLocalReport("סידור");
          setAllowDownload(false);
          prepareTablesStates();
        }}
        chosen={report === "סידור"}
      >
        <RespFont style={{ color: "#3A3C3F" }}>סידור שולחנות</RespFont>
        <RespImg
          pSize={"70px"}
          cSize={"100px"}
          src={wd}
          style={{ padding: "0px 0px", marginTop: "0px" }}
        />
        {/* <img src={wd} /> */}
      </ReportsOption>
      {specialDishes && (
        <ReportsOption
          style={{ marginTop: "10px" }}
          onClick={() => {
            setLocalReport("מנות מיוחדות");
            setAllowDownload(false);
            prepareSpecialDishesStates();
          }}
          chosen={report === "מנות מיוחדות"}
          // style={{ padding: "20px 10px" }}
        >
          <RespFont>מנות מיוחדות</RespFont>
          <RespImg
            cSize={"90px"}
            pSize={"70px"}
            // style={{ padding: "10px 0px" }}
            src={specialDishes1}
          />
        </ReportsOption>
      )}
      {transportation && (
        <ReportsOption
          onClick={() => {
            setLocalReport("הסעה");
            setAllowDownload(false);
            prepareTransportationStates();
          }}
          chosen={report === "הסעה"}
          style={{ padding: "20px 10px", marginTop: "10px" }}
        >
          <RespFont>הסעה</RespFont>
          <RespImg
            cSize={"150px"}
            pSize={"90px"}
            // style={{ padding: "10px 0px" }}
            src={bus}
          />
        </ReportsOption>
      )}
      <ReportsOption
        style={{ marginTop: "10px" }}
        onClick={() => {
          setLocalReport("הערות");
          setAllowDownload(false);
          prepareNotesStates();
        }}
        chosen={report === "הערות"}
      >
        <RespFont>הערות</RespFont>
        <img src={notes1} />
      </ReportsOption>

      <ReportsOption
        style={{
          marginBottom: widthS > 768 ? "0px" : "30px",
          marginTop: "10px",
        }}
        onClick={() => {
          setAllowDownload(true);
          getData();
        }}
      >
        <RespFont>ייצוא</RespFont>
        <img src={import1} />
      </ReportsOption>
      {/* {console.log(report)} */}
      {
        // false
        allowDownload && exportedGuests.length && (
          <GenericModal
            init={setAllowDownload}
            div={
              <AskForDownload
                color={color}
                title={`אקסל ${localReport}`}
                filename={localReport}
                body={`האם ברצונך להוריד אקסל ${localReport}?`}
                no={setAllowDownload}
                data={exportedGuests}
                init={setAllowDownload}
              />
            }
          />
        )
      }
    </OptionsFirst>
  );
};

export default Reports;
