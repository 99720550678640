import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";

import { editArray } from "../../store/reducers/authReducer";

import {
  CenteredForm,
  PlaceHolderImage,
  GenericButton,
  InputBox,
  RespFont,
} from "../common/Style";

import exitModalLogo from "../../assets/common/cancel1.svg";
import { GetToast } from "../common/modals/Toast";
import ExitModal from "../common/modals/ExitModal";

const EditTables = (props) => {
  let height = window.screen.availHeight;
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { register, handleSubmit } = useForm();

  const { user } = useSelector((state) => state.authReducer);

  const onSubmit = async (data) => {
    let s;
    let a;
    let newArray = [];

    for (let i = 0; i < user.array.length; i++) {
      s = i + "side";
      a = i + "amount";
      newArray.push({ amount: parseInt(data[a]), side: data[s] });
    }
    dispatch(editArray({ array: [...newArray] })).then(
      (data) => {
        GetToast(addToast, "success", "הפעולה הצליחה");
        props.init(null);
      },
      (error) => GetToast(addToast, "error", error)
    );
  };

  let tablesArray = useMemo(() => {
    let tables = [];
    if (user.array.length > 0) {
      let s;
      let a;
      for (let i = 0; i < user.array.length; i++) {
        s = i + "side";
        a = i + "amount";
        tables.push(
          <div
            key={i}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "10px",
              alignItems: "baseline",
            }}
          >
            {i < 9 && <>&nbsp;&nbsp;</>}
            <div>{i + 1}</div>&nbsp;&nbsp;
            <InputBox
              labelColor={"gray"}
              color={"#3A3C3F"}
              style={{ width: "60%" }}
            >
              <div style={{ position: "relative" }}>
                <input
                  placeholder="קירבה"
                  defaultValue={user.array[i].side}
                  name={s}
                  type="text"
                  minLength={1}
                  maxLength={50}
                  required={true}
                  ref={register()}
                />
              </div>
            </InputBox>
            <InputBox
              labelColor={"gray"}
              color={"#3A3C3F"}
              style={{ width: "20%" }}
            >
              <div style={{ position: "relative" }}>
                <input
                  placeholder="כמות"
                  defaultValue={user.array[i].amount}
                  name={a}
                  type="number"
                  min={0}
                  max={200}
                  required={true}
                  ref={register()}
                />
              </div>
            </InputBox>
            <br />
          </div>
        );
      }
    }

    return [tables];
  }, [user.array]);

  return (
    <CenteredForm
      onSubmit={handleSubmit(onSubmit)}
      style={{ maxHeight: `${height * 0.8}px` }}
      onClick={(e) => e.stopPropagation()}
    >
      <ExitModal init={props.init} color={user.color} />
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          textAlign: "center",
          color: "#3A3C3F",
          fontWeight: "bold",
          padding: `30px 0`,
          paddingBottom: `1px`,
        }}
      >
        עריכת שולחנות
      </RespFont>

      <div
        style={{
          width: "96%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "10px auto",
          height: "100%",
          overflowY: "auto",
        }}
      >
        {tablesArray[0]}
      </div>

      <GenericButton
        style={{
          margin: "20px auto",
          width: "50%",
          background: user && user.color,
        }}
        type="submit"
      >
        אישור
      </GenericButton>
    </CenteredForm>
  );
};

export default EditTables;
