import { createSlice } from '@reduxjs/toolkit';
import { post2, get2 } from '../api/http';
import ServerRoutes from '../api/routes';

const initialState = {
    couples: {},
    couplesError: null,
    couplesLoading: false
}

const _addCouple = (state, action) => {
    let editedCouples = { ...state.couples }
    editedCouples[action.payload.id] = { ...action.payload.body, done: false, _id: action.payload.id, dateAdded: action.payload.dateAdded }
    state.couples = editedCouples;
};

const _setCouples = (state, action) => {
    state.couples = action.payload;
};

const _editCouple = (state, action) => {
    let editedCouples = { ...state.couples }
    for (const [key, value] of Object.entries(action.payload.body))
        editedCouples[action.payload.id][key] = value;
    state.couples = editedCouples;
};

const _deleteCouple = (state, action) => {
    let editedCouples = { ...state.couples }
    delete editedCouples[action.payload]
    state.couples = editedCouples;
};

const _actionStart = (state,) => {
    state.couplesLoading = true;
    state.couplesError = null;
};

const _actionSuccess = (state) => {
    state.couplesLoading = false;
    state.couplesError = null;
};

const _actionFailed = (state, action) => {
    state.couplesLoading = false;
    state.couplesError = action.payload.error;
};

const _cleanCouplesState = (state) => {
    state.couples = {};
    state.couplesError = null;
    state.couplesLoading = false
};

const couples = createSlice({
    name: 'couples',
    initialState,
    reducers: {
        addCouple1: _addCouple,
        cleanCouplesState: _cleanCouplesState,
        editCouple1: _editCouple,
        deleteCouple1: _deleteCouple,
        actionStart: _actionStart,
        actionFailed: _actionFailed,
        setCouples: _setCouples,
        actionSuccess: _actionSuccess,
    },
});

const { reducer, actions } = couples;

const { actionStart, actionFailed, actionSuccess, addCouple1, editCouple1, deleteCouple1, setCouples, cleanCouplesState } = actions;

export default reducer;

export const addCouple = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { data, error, status } = await post2(ServerRoutes.Couples.add, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(addCouple1({ id: data.id, dateAdded: data.dateAdded, body }));
        }
    }
};

export const getBetweenDates = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { data, error, status } = await post2(ServerRoutes.Couples.getBetweenDates, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(setCouples(data));
        }
    }
};

export const getAll = () => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { data, error, status } = await get2(ServerRoutes.Couples.getAll);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            console.log(data);
            dispatch(actionSuccess())
            return dispatch(setCouples(data));
        }
    }
};

export const cleanAll = () => {
    return async (dispatch) => {
        return dispatch(cleanCouplesState());
    }
};


export const editCouple = (id, body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Couples.edit + id, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editCouple1({ id, body }));
        }
    }
};

export const deleteCouple = (id) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Couples.delete, { id });
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(deleteCouple1(id));
        }
    }
};