import React from 'react';
import { CenteredForm, FlexRowBetween, GenericButton, MyHr } from '../Style';
import { useExcelDownloder } from 'react-xls';

const AskForDownload = ({ data, color, no, title, body, filename }) => {
    const { ExcelDownloder, Type } = useExcelDownloder()

    return (<CenteredForm onClick={(e) => e.stopPropagation()} className="animate__animated animate__fadeIn">
        <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "space-around" }}>
            <div style={{ width: "100%", marginTop: "30px" }}>
                <div style={{ textAlign: "center", fontSize: "1.2rem", marginBottom: "10px" }}>{title}</div>
                <MyHr />
            </div>
            <div style={{ width: "100%", marginTop: "30px" }}>
                <div style={{ textAlign: "center", marginBottom: "10px" }}>{body}</div>
                <MyHr />
            </div>
            <div style={{ width: "100%" }}>
                <FlexRowBetween style={{ width: "90%", margin: "20px auto" }}>
                    <ExcelDownloder
                        data={{ "נתונים": data }}
                        filename={filename}
                        type={Type.Button} // or type={'button'}
                        style={{ border: "none" }}
                    >
                        <GenericButton style={{ backgroundColor: color ? color : "#DEDEE0", padding: "9px 25px" }} onClick={() => no(null)}>כן</GenericButton>
                    </ExcelDownloder>
                    <GenericButton style={{ backgroundColor: "rgb(223, 223, 223)", color: "#3A3C3F", padding: "9px 25px" }} onClick={() => { no(null) }}>לא</GenericButton>
                </FlexRowBetween>
            </div>
        </div>
    </CenteredForm>)
}
export default AskForDownload;