import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  GuestOptions,
  CenteredForm,
  DropOption,
  RespFont,
  InputBox,
  GenericDiv,
  FlexRowBetween,
} from "../common/Style";
import dropdown1 from "../../assets/client/dropdown1.svg";
import delete1 from "../../assets/client/delete1.svg";
import "./style.css";
import ExitModal from "../common/modals/ExitModal";

const EditGuestsSms = (props) => {
  const [owc, setOwc] = useState("קשר");
  const [inviting, setInviting] = useState("");
  const { register, handleSubmit } = useForm();
  const [showDropOwc, setShowDropOwc] = useState(false);
  const { user } = useSelector((state) => state.authReducer);
  let height = window.screen.availHeight;
  const defaultPreSms1 = `${user.brideFN + " " + user.brideLN} ו${
    user.groomFN + " " + user.groomLN
  } שמחים להזמינכם לחתונתם!`;
  const onSubmit = async (data) => {
    let newOwc = "";
    if (
      (data?.inviting.length && owc === "קשר") ||
      (data?.inviting.length === 0 && owc !== "קשר")
    )
      alert("שדה המזמין והקשר באים ביחד - הכל או כלום");
    // else if (
    //   data?.inviting.includes(user.brideFN) ||
    //   data?.inviting.includes(user.groomFN) ||
    //   data?.inviting.includes("חתן") ||
    //   data?.inviting.includes("כלה")
    // )
    //   alert(
    //     "המזמין אינו יכול להיות חתן או כלה, יש להשאיר ריק את המזמין והקשר עבור מוזמנים ישירים של הזוג"
    //   );
    else {
      newOwc = owc;
      let update = { inviting: data.inviting, owc: newOwc };
      props.setGuestsInvitingAndOwc11(update);
    }
  };

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  return (
    <CenteredForm
      onClick={(e) => {
        e.stopPropagation();
        setShowDropOwc(false);
      }}
      onSubmit={handleSubmit(onSubmit)}
      style={{ maxHeight: `${height * 0.7}px`, paddingTop: "20px" }}
      id="form1"
    >
      <ExitModal init={props.init} color={user.color} />
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          textAlign: "center",
          color: "#3A3C3F",
          fontWeight: "bold",
          padding: `30px 0`,
          paddingBottom: `20px`,
        }}
      >
        עריכת מזמין + קשר
      </RespFont>
      <InputBox
        labelColor={"gray"}
        color={"#3A3C3F"}
        style={{ paddingBottom: "30px" }}
      >
        <FlexRowBetween style={{ width: "90%", margin: "0 auto" }}>
          <div style={{ position: "relative", width: "70%", margin: "4px 5%" }}>
            <input
              defaultValue={""}
              type="text"
              name="inviting"
              onChange={(e) => setInviting(e.target.value)}
              minLength={2}
              maxLength={50}
              ref={register()}
            />
            <label>המזמין</label>
          </div>
          <OverlayTrigger
            placement={"bottom"}
            overlay={<Tooltip>{"הקשר של בעל/ת השמחה למזמין"}</Tooltip>}
          >
            <GenericDiv
              background={user.color}
              style={{
                position: "relative",
                width: "20%",
                marginBottom: "-10px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowDropOwc(!showDropOwc);
              }}
            >
              <div>{owc}</div>
              <img
                src={dropdown1}
                style={{ width: "25px", marginLeft: "-7px" }}
              />
              {showDropOwc && (
                <div
                  style={{
                    position: "absolute",
                    left: "0",
                    bottom: "-20%",
                    left: "120%",
                    zIndex: 2,
                    color: user.color,
                    borderRadius: "5px",
                    backgroundColor: "white",
                    padding: "10px",
                    fontSize: "12px",
                    boxShadow: "0 2px 4px gray",
                  }}
                >
                  <FlexRowBetween>
                    <DropOption
                      background={addAlpha(user.color, 0.2)}
                      shadow={user.color}
                      onClick={() => {
                        setOwc("בנם");
                        setShowDropOwc(false);
                      }}
                    >
                      בנם
                    </DropOption>
                    <DropOption
                      background={addAlpha(user.color, 0.2)}
                      shadow={user.color}
                      onClick={() => {
                        setOwc("בנה");
                        setShowDropOwc(false);
                      }}
                    >
                      בנה
                    </DropOption>
                    <DropOption
                      background={addAlpha(user.color, 0.2)}
                      shadow={user.color}
                      onClick={() => {
                        setOwc("בתו");
                        setShowDropOwc(false);
                      }}
                    >
                      בתו
                    </DropOption>
                    <DropOption
                      background={addAlpha(user.color, 0.2)}
                      shadow={user.color}
                      onClick={() => {
                        setOwc("בנו");
                        setShowDropOwc(false);
                      }}
                    >
                      בנו
                    </DropOption>
                    <DropOption
                      background={"black"}
                      shadow={user.color}
                      onClick={() => {
                        setOwc("קשר");
                        setShowDropOwc(false);
                      }}
                      style={{ boxShadow: "0 2px 4px black" }}
                    >
                      <img src={delete1} style={{ width: "15px" }} />
                    </DropOption>
                  </FlexRowBetween>
                  <FlexRowBetween style={{ marginTop: "8px" }}>
                    <DropOption
                      background={addAlpha(user.color, 0.2)}
                      shadow={user.color}
                      onClick={() => {
                        setOwc("בתם");
                        setShowDropOwc(false);
                      }}
                    >
                      בתם
                    </DropOption>
                    <DropOption
                      background={addAlpha(user.color, 0.2)}
                      shadow={user.color}
                      onClick={() => {
                        setOwc("בתה");
                        setShowDropOwc(false);
                      }}
                    >
                      בתה
                    </DropOption>
                    <DropOption
                      shadow={user.color}
                      onClick={() => {
                        setOwc("נכדה");
                        setShowDropOwc(false);
                      }}
                    >
                      נכדה
                    </DropOption>
                    <DropOption
                      shadow={user.color}
                      onClick={() => {
                        setOwc("נכדתם");
                        setShowDropOwc(false);
                      }}
                    >
                      נכדתם
                    </DropOption>
                  </FlexRowBetween>
                  <FlexRowBetween style={{ marginTop: "8px" }}>
                    <DropOption
                      shadow={user.color}
                      onClick={() => {
                        setOwc("נכדם");
                        setShowDropOwc(false);
                      }}
                    >
                      נכדם
                    </DropOption>
                    <DropOption
                      shadow={user.color}
                      onClick={() => {
                        setOwc("נכדתה");
                        setShowDropOwc(false);
                      }}
                    >
                      נכדתה
                    </DropOption>
                    <DropOption
                      shadow={user.color}
                      onClick={() => {
                        setOwc("נכדתו");
                        setShowDropOwc(false);
                      }}
                    >
                      נכדתו
                    </DropOption>
                    <DropOption
                      shadow={user.color}
                      onClick={() => {
                        setOwc("נכדו");
                        setShowDropOwc(false);
                      }}
                    >
                      נכדו
                    </DropOption>
                  </FlexRowBetween>
                  <FlexRowBetween style={{ marginTop: "8px" }}>
                    <DropOption
                      style={{ color: "white" }}
                      background={addAlpha(user.color, 0.7)}
                      shadow={user.color}
                      onClick={() => {
                        setOwc("אחותה");
                        setShowDropOwc(false);
                      }}
                    >
                      אחותה
                    </DropOption>
                    <DropOption
                      style={{ color: "white" }}
                      background={addAlpha(user.color, 0.7)}
                      shadow={user.color}
                      onClick={() => {
                        setOwc("אחותו");
                        setShowDropOwc(false);
                      }}
                    >
                      אחותו
                    </DropOption>
                    <DropOption
                      style={{ color: "white" }}
                      background={addAlpha(user.color, 0.7)}
                      shadow={user.color}
                      onClick={() => {
                        setOwc("אחיו");
                        setShowDropOwc(false);
                      }}
                    >
                      אחיו
                    </DropOption>
                    <DropOption
                      style={{ color: "white" }}
                      background={addAlpha(user.color, 0.7)}
                      shadow={user.color}
                      onClick={() => {
                        setOwc("אחיה");
                        setShowDropOwc(false);
                      }}
                    >
                      אחיה
                    </DropOption>
                  </FlexRowBetween>
                </div>
              )}
            </GenericDiv>
          </OverlayTrigger>
        </FlexRowBetween>
      </InputBox>
      <FlexRowBetween
        style={{ width: "90%", margin: "0 auto", marginBottom: "20px" }}
      >
        <RespFont>
          {inviting !== "" && owc !== "קשר" ? (
            inviting +
            (owc[owc.length - 1] === "ם"
              ? " שמחים"
              : owc[owc.length - 1] === "ו"
              ? " שמח"
              : " שמחה") +
            " להזמינכם לחתונת " +
            owc +
            (owc.includes("ת") ? ` ${user.brideFN}.` : ` ${user.groomFN}.`)
          ) : (
            <>ברירת מחדל: {defaultPreSms1}</>
          )}
        </RespFont>
      </FlexRowBetween>
      <GuestOptions
        width={"21px"}
        color={user.color}
        padding={`10px 0`}
        style={{ justifyContent: "space-between" }}
      >
        <FlexRowBetween
          style={{ justifyContent: "center", backgroundColor: user.color }}
        >
          <button
            type="submit"
            form="form1"
            style={{
              outline: "none",
              width: "100%",
              background: "inherit",
              border: "none",
            }}
          >
            <FlexRowBetween style={{ justifyContent: "space-evenly" }}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "white",
                  padding: "10px",
                  fontWeight: "700",
                }}
              >
                שמירה
              </div>
            </FlexRowBetween>
          </button>
        </FlexRowBetween>
      </GuestOptions>
    </CenteredForm>
  );
};

export default EditGuestsSms;
