import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import GenericModal from "../../common/modals/GenericModal";
import {
  getAll,
  cleanAll,
  destroyUser,
} from "../../../store/reducers/userReducer";
import {
  AvivCardsHolder,
  FlexRowBetween,
  GenericButton,
  GenericDiv,
  InputBox,
  MainDiv,
} from "../../common/Style";
import EditUser from "./EditUser";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { GetToast } from "../../common/modals/Toast";
import {
  getwh,
  getAccepted1,
  getAllExcludeAccepted1,
  getMoney,
} from "../../../store/reducers/guestsReducer";
import CsvDownloader from "react-csv-downloader";
import ItayClientCard from "../aviv/ItayClientCard";
import LogOutAll from "../../common/LogOut/LogOutAll";
import ACalendar from "../aviv/calendar/Calendar";
import OpacityLoading from "../../common/loader/OpacityLoading";
import { getTodayTodos } from "../../../store/reducers/todosReducer";
import { reminder } from "../../../store/reducers/dataReducer";
import { useExcelDownloder } from "react-xls";
import { Link } from "react-router-dom";
import ClientRoutes from "../../../navigation/Routes";
import YesNoModal from "../../common/modals/YesNoModal";

const Main = (props) => {
  const { ExcelDownloder, Type } = useExcelDownloder();
  const { users, usersError, usersLoading } = useSelector(
    (state) => state.userReducer
  );
  const { guests } = useSelector((state) => state.guestsReducer);
  const [logout, setLogout] = useState(null);
  const [call, setCall] = useState(null);
  const [work, setWork] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [accept, setAccept] = useState(null);
  const [moneyOrSMS, setMoneyOrSMS] = useState(3); // 1 - get money, 2 - not answered, 3 - free choise
  const [notes, setNotes] = useState(false);
  const [print, setPrint] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [userId, setUserId] = useState(null);
  const [hall, setHall] = useState(null);
  const [city, setCity] = useState(null);
  const [date1, setDate1] = useState(null);
  const [couple, setCouple] = useState(null);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [searchText, setSearchText] = useState("");
  const [showCalendar, setShowCalendar] = useState(null);
  const [date, setDate] = useState(new Date());
  const { dataError } = useSelector((state) => state.dataReducer);

  console.log(userId);
  useEffect(() => {
    dispatch(getAll());
    return () => {
      dispatch(cleanAll());
    };
  }, []);

  useEffect(() => {
    if (usersError) GetToast(addToast, "error", usersError);
  }, [usersError]);

  let datas = [];
  if (guests) {
    if (moneyOrSMS === 1) {
      let posC = 0;
      let negC = 0;
      for (let i = 0; i < guests.length; i++) {
        if (guests[i].ans.includes("לא")) negC++;
        else posC++;
        if (print)
          datas.push({
            קירבה: guests[i].closeness,
            שם: guests[i].name,
            טלפון: guests[i].phone,
            הוזמנו: guests[i].sum,
            אישרו: guests[i].accept,
            הגיעו: guests[i].arrived,
            תשובה: guests[i].ans,
            הערות: guests[i].notes,
          });
      }
      console.log(`${posC} ענו ${negC} לא ענו`);
      datas.push({
        קירבה: "",
        שם: "",
        טלפון: "",
        הוזמנו: "",
        אישרו: "",
        הגיעו: "",
        תשובה: "",
        הערות: "",
        "סך הכל ענו": posC,
        "סך הכל לא ענו": negC,
      });
    }
    // רגיל ****************
    else if (moneyOrSMS === 2) {
      for (let i = 0; i < guests.length; i++) {
        let isI =
          guests[i].inviting !== undefined &&
          guests[i].inviting !== null &&
          guests[i].inviting !== "" &&
          guests[i].owc !== undefined &&
          guests[i].owc !== null &&
          guests[i].owc !== "";
        datas.push({
          phone: guests[i].phone,
          //   closeness: guests[i].closeness,
          //   name: guests[i].name,
          hall,
          city,
          date1,
          msg: isI
            ? `${couple.brideFN} ${
                guests[i].owc.includes("ת")
                  ? `*(${guests[i].owc} של ${guests[i].inviting})*`
                  : couple.brideLN
              } ו${couple.groomFN} ${
                guests[i].owc.includes("ת")
                  ? couple.groomLN
                  : `*(${guests[i].owc} של ${guests[i].inviting})*`
              }`
            : `${couple.brideFN + " " + couple.brideLN} ו${
                couple.groomFN + " " + couple.groomLN
              }`,
          notes: guests[i].notes,

          //   msg: isI
          //     ? couple.brideFN + " " + guests[i].owc.includes("ת")
          //       ? `*(${guests[i].owc} + " של " + ${guests[i].inviting})*`
          //       : couple.brideLN +
          //         "ו" +
          //         couple.groomFN +
          //         guests[i].owc.includes("ת")
          //       ? couple.groomLN
          //       : `*(${guests[i].owc} + " של " + ${guests[i].inviting})*`
          //     : `${couple.brideFN + " " + couple.brideLN} ו${
          //         couple.groomFN + " " + couple.groomLN
          //       }`,
          //   msg: `https://b-s.fun/n?n=${window.btoa(
          //     userId + " " + guests[i]._id + " " + guests[i].sum
          //   )}`,
        });
      }
    } else
      for (let i = 0; i < guests.length; i++) {
        datas.push({
          שם: guests[i].name.replace(",", ""),
          שולחן: guests[i].table,
          טלפון: guests[i].phone,
          קירבה: guests[i].closeness,
          // הוזמנו: guests[i].sum,
          אישרו: guests[i].accept,
          // הערות: guests[i].notes,
          הגיעו: guests[i].arrived,
          // מתנה: guests[i].gift,

          // name: guests[i].name.replace(",", ""),

          // closeness: guests[i].closeness,
          // name: guests[i].name,
          // phone: guests[i].phone,
          // arrived: guests[i].arrived,
          // sum: guests[i].sum,
          // gift: guests[i].gift,
          // accept: guests[i].accept,
          // table: guests[i].table,
          // ans: guests[i].ans,
          // notes: guests[i].notes,

          // שתי תמונות ****************
          // msg: `https://b-s.fun/wii?name=${window.btoa(userId + ' ' + guests[i]._id + ' ' + guests[i].sum)}`
          // תמונה אחת ****************
          // msg: `https://b-s.fun/wi?name=${window.btoa(userId + ' ' + guests[i]._id + ' ' + guests[i].sum)}`
          // רגיל ****************
          // msg: `https://b-s.fun/invitation?name=${window.btoa(userId + ' ' + guests[i]._id + ' ' + guests[i].sum)}`
        });
      }
  }

  const deleteUserTotal = async () => {
    dispatch(destroyUser(deleteUser._id, { pic: deleteUser.pic })).then(
      (data) => {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };

  const getMoney1 = async () => {
    setMoneyOrSMS(1);
    dispatch(getMoney({ uid: userId, call, accept, notes, print })).then(
      (data) => {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };

  const getNotAnswered = async () => {
    setMoneyOrSMS(2);
    dispatch(getwh(userId)).then(
      (data) => {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };
  const todos1 = async () => {
    let date = new Date();
    date.setHours(20);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    dispatch(getTodayTodos(date.toISOString())).then(
      (data) => {
        let arr = [];
        if (Object.keys(data.payload).length > 0) {
          console.log("in component");
          for (const [key, value] of Object.entries(data.payload)) {
            let userPhone = users.filter((x) => x._id === key)[0].phone;
            for (let i = 0; i < value.length; i++)
              arr.push({ phone: userPhone, msg: "תזכורת: " + value[i].title });
          }
          dispatch(reminder({ arr })).then((res) => {
            if (!res.payload?.error) {
              GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
              props.init();
            }
          });
        }
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };

  let clientsArray = useMemo(() => {
    let dates = {};
    let array = [];
    let date;
    if (users.length > 0) {
      for (let i = 0; i < users.length; i++) {
        if (
          users[i].groomFN.includes(searchText) ||
          users[i].brideFN.includes(searchText) ||
          users[i].phone.includes(searchText)
        ) {
          date = new Date(users[i].date);
          date = `${date.getFullYear()}/${
            date.getMonth() + 1
          }/${date.getDate()}`;
          array.push(
            <ItayClientCard
              key={i + 1}
              edit={setSelectedEdit}
              setUserId={setUserId}
              setHall={setHall}
              setCity={setCity}
              setDate1={setDate1}
              setCouple={setCouple}
              index={i + 1}
              data={users[i]}
            />
          );
          if (date in dates) {
            users[i].eventType
              ? (dates[date] =
                  dates[date] +
                  " + " +
                  users[i].brideFN +
                  " ו" +
                  users[i].groomFN +
                  ", " +
                  users[i].hall)
              : (dates[date] =
                  dates[date] +
                  " + " +
                  users[i].calendarName +
                  ", " +
                  users[i].hall);
          } else {
            users[i].eventType
              ? (dates[date] =
                  users[i].brideFN +
                  " ו" +
                  users[i].groomFN +
                  ", " +
                  users[i].hall)
              : (dates[date] = users[i].calendarName + ", " + users[i].hall);
          }
        }
      }
    }
    return [array, dates];
  }, [users, searchText]);
  return (
    <MainDiv
      className="animate__animated animate__fadeIn"
      style={{ maxHeight: "100vh" }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: `10px 13px`,
          alignItems: "center",
        }}
      >
        <GenericButton
          background={"#fda14b"}
          style={{
            height: "max-content",
            fontSize: "12px",
            padding: "3px 5px",
          }}
          onClick={() => setLogout(true)}
        >
          התנתקות
        </GenericButton>
        <GenericButton
          background={"#fda14b"}
          as={Link}
          to={ClientRoutes.Admin.potentials}
          style={{ fontSize: "12px", padding: "3px 5px" }}
        >
          פניות
        </GenericButton>
        <GenericButton
          background={"#fda14b"}
          as={Link}
          to={ClientRoutes.Admin.guests}
          style={{ fontSize: "12px", padding: "3px 5px" }}
        >
          עריכה
        </GenericButton>
        <GenericButton
          background={"#fda14b"}
          style={{
            height: "max-content",
            fontSize: "12px",
            padding: "3px 5px",
          }}
          onClick={() => setWork(!work)}
        >
          נתונים
        </GenericButton>
        <InputBox
          labelColor={"gray"}
          color={"#3A3C3F"}
          style={{ width: "100px", margin: "0px 0px" }}
        >
          <div
            style={{ width: "100%", position: "relative", margin: "0px 0px" }}
          >
            <input
              type="text"
              required={true}
              minLength={0}
              maxLength={50}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <label>חיפוש</label>
          </div>
        </InputBox>

        <GenericDiv
          hoverBackground={"rgb(223, 223, 223)"}
          background={"#fda14b"}
          style={{
            width: "max-content",
            height: "max-content",
            fontSize: "12px",
            padding: "3px 5px",
          }}
          onClick={() => setShowCalendar(true)}
        >
          יומן
        </GenericDiv>
      </div>
      <FlexRowBetween
        style={{
          justifyContent: "flex-start",
          marginRight: "30px",
          marginBottom: "20px",
        }}
      >
        <GenericButton
          background={"#fda14b"}
          as={Link}
          to={ClientRoutes.Admin.todos}
          style={{ fontSize: "12px", padding: "3px 5px" }}
        >
          {" "}
          שיחות
        </GenericButton>
        <GenericButton
          background={"#fda14b"}
          as={Link}
          to={ClientRoutes.Admin.sms}
          style={{ fontSize: "12px", padding: "3px 5px", marginRight: "30px" }}
        >
          {" "}
          הודעות חוזרות
        </GenericButton>
      </FlexRowBetween>
      {/* <div style={{ border: "1px solid black", width: "max-content" }} onClick={() => deleteGuestsByUserId()}>delete user's guests</div> */}
      {/* <div style={{ border: "1px solid black", width: "max-content" }} onClick={() => addMany1()}>addMany from csv</div> */}
      {/* <div style={{ border: "1px solid black", width: "max-content" }} onClick={() => getTablesGuests1()}>get Tables Guests</div> */}
      {/* <div style={{border:"1px solid black", width:"max-content"}} onClick={() => getAccepted(userId)}>get accepted</div> */}
      {/* <div style={{border:"1px solid black", width:"max-content"}} onClick={() => getAllExcludeAccepted(userId)}>get all exclude accepted</div> */}

      {work && (
        <>
          {/* GET MONEY START */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: `0 10px`,
              marginBottom: "20px",
            }}
          >
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"שיחה ראשונה"}</Tooltip>}
            >
              <GenericDiv
                hoverBackground={"rgb(223, 223, 223)"}
                background={"#fda14b"}
                style={{
                  width: "max-content",
                  opacity: call === "C1" ? 1 : 0.5,
                }}
                onClick={() => setCall("C1")}
              >
                C1
              </GenericDiv>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"שיחה שניה"}</Tooltip>}
            >
              <GenericDiv
                hoverBackground={"rgb(223, 223, 223)"}
                background={"#fda14b"}
                style={{
                  width: "max-content",
                  opacity: call === "C2" ? 1 : 0.5,
                }}
                onClick={() => setCall("C2")}
              >
                C2
              </GenericDiv>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"שיחה שלישית"}</Tooltip>}
            >
              <GenericDiv
                hoverBackground={"rgb(223, 223, 223)"}
                background={"#fda14b"}
                style={{
                  width: "max-content",
                  opacity: call === "C3" ? 1 : 0.5,
                }}
                onClick={() => setCall("C3")}
              >
                C3
              </GenericDiv>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"שיחה רביעית"}</Tooltip>}
            >
              <GenericDiv
                hoverBackground={"rgb(223, 223, 223)"}
                background={"#fda14b"}
                style={{
                  width: "max-content",
                  opacity: call === "C4" ? 1 : 0.5,
                }}
                onClick={() => setCall("C4")}
              >
                C4
              </GenericDiv>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"ללא פילטור שיחות"}</Tooltip>}
            >
              <GenericDiv
                hoverBackground={"rgb(223, 223, 223)"}
                background={"#fda14b"}
                style={{
                  width: "max-content",
                  opacity: call === null ? 1 : 0.5,
                }}
                onClick={() => setCall(null)}
              >
                NC
              </GenericDiv>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"לא נענו"}</Tooltip>}
            >
              <GenericDiv
                hoverBackground={"rgb(223, 223, 223)"}
                background={"#fda14b"}
                style={{
                  width: "max-content",
                  opacity: accept === "NA" ? 1 : 0.5,
                }}
                onClick={() => setAccept("NA")}
              >
                NA
              </GenericDiv>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"נענו"}</Tooltip>}
            >
              <GenericDiv
                hoverBackground={"rgb(223, 223, 223)"}
                background={"#fda14b"}
                style={{
                  width: "max-content",
                  opacity: accept === "A" ? 1 : 0.5,
                }}
                onClick={() => setAccept("A")}
              >
                A
              </GenericDiv>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"ללא פילטור מענה"}</Tooltip>}
            >
              <GenericDiv
                hoverBackground={"rgb(223, 223, 223)"}
                background={"#fda14b"}
                style={{
                  width: "max-content",
                  opacity: accept === null ? 1 : 0.5,
                }}
                onClick={() => setAccept(null)}
              >
                AA
              </GenericDiv>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"הערות"}</Tooltip>}
            >
              <GenericDiv
                hoverBackground={"rgb(223, 223, 223)"}
                background={"#fda14b"}
                style={{ width: "max-content", opacity: notes ? 1 : 0.5 }}
                onClick={() => setNotes(!notes)}
              >
                notes
              </GenericDiv>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"הדפסה"}</Tooltip>}
            >
              <GenericDiv
                hoverBackground={"rgb(223, 223, 223)"}
                background={"#fda14b"}
                style={{ width: "max-content", opacity: print ? 1 : 0.5 }}
                onClick={() => setPrint(!print)}
              >
                P
              </GenericDiv>
            </OverlayTrigger>
          </div>
          {/* GET MONEY END */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: `0 10px`,
              marginBottom: "30px",
            }}
          >
            {datas.length && (
              <CsvDownloader filename={"fff"} datas={datas}>
                <GenericButton
                  className="animate__animated animate__fadeIn"
                  background={"#fda14b"}
                >
                  הורדה
                </GenericButton>
              </CsvDownloader>
            )}
            <GenericDiv
              hoverBackground={"rgb(223, 223, 223)"}
              background={"#fda14b"}
              style={{ width: "max-content" }}
              onClick={() => getMoney1()}
            >
              get money
            </GenericDiv>
            <GenericDiv
              hoverBackground={"rgb(223, 223, 223)"}
              background={"#fda14b"}
              style={{ width: "max-content" }}
              onClick={() => getNotAnswered()}
            >
              get not answered
            </GenericDiv>
            <GenericDiv
              hoverBackground={"rgb(223, 223, 223)"}
              background={"#fda14b"}
              style={{ width: "max-content" }}
              onClick={() => todos1()}
            >
              todos
            </GenericDiv>
            {dataError?.kind === "reminder" && (
              <ExcelDownloder
                data={{ שגיאות: dataError.error }}
                filename={"שגיאות אקסל"}
                type={Type.Button} // or type={'button'}
                style={{ border: "none" }}
              >
                <GenericButton
                  className="animate__animated animate__fadeIn"
                  background={"#fda14b"}
                >
                  tE
                </GenericButton>
              </ExcelDownloder>
            )}
            {dataError?.kind === "sms" && (
              <ExcelDownloder
                data={{ שגיאות: dataError.error }}
                filename={"שגיאות אקסל"}
                type={Type.Button} // or type={'button'}
                style={{ border: "none" }}
              >
                <GenericButton
                  className="animate__animated animate__fadeIn"
                  background={"#fda14b"}
                >
                  sE
                </GenericButton>
              </ExcelDownloder>
            )}
          </div>
        </>
      )}

      <AvivCardsHolder>{clientsArray[0]}</AvivCardsHolder>

      {selectedEdit && (
        <GenericModal
          init={setSelectedEdit}
          div={
            <EditUser
              setDeleteUser={setDeleteUser}
              data={selectedEdit}
              init={setSelectedEdit}
            />
          }
        />
      )}
      {logout && (
        <GenericModal init={setLogout} div={<LogOutAll init={setLogout} />} />
      )}

      {showCalendar && (
        <GenericModal
          init={setShowCalendar}
          div={
            <ACalendar
              dates={clientsArray[1]}
              setDate={setDate}
              date={date}
              init={setShowCalendar}
            />
          }
        />
      )}

      {deleteUser && (
        <GenericModal
          init={setDeleteUser}
          div={
            <YesNoModal
              color={"#fda14b"}
              title={"מחיקת משתמש"}
              body={"האם את/ה בטוח/ה?"}
              no={setDeleteUser}
              yes={deleteUserTotal}
            />
          }
        />
      )}

      {usersLoading && <OpacityLoading />}
    </MainDiv>
  );
};

export default Main;
