import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import { editTodo } from "../../../store/reducers/todosReducer";
import {
  CenteredForm,
  PlaceHolderImage,
  GenericButton,
  InputBox,
  Footer,
  GenericDiv,
  FlexRowBetween,
  GuestOptions,
} from "../../common/Style";
import delete1 from "../../../assets/client/delete1.svg";
import exitModalLogo from "../../../assets/common/cancel1.svg";
import { GetToast } from "../../common/modals/Toast";
import { RTextArea } from "../../common/Style";
import GCalendar from "../../common/calendar/Calendar";
import GenericModal from "../../common/modals/GenericModal";
import ExitModal from "../../common/modals/ExitModal";
import GenericSvg from "../../common/coloredSvgs/GenericSvg";
import { svgs } from "../../common/coloredSvgs/svgs";

const EditTodo = (props) => {
  const dispatch = useDispatch();
  const { todosError } = useSelector((state) => state.todosReducer);
  const { user } = useSelector((state) => state.authReducer);
  const { addToast } = useToasts();
  const { register, handleSubmit } = useForm();
  const [showCalendar, setShowCalendar] = useState(null);
  const [date, setDate] = useState(
    props.data?.executionDate ? new Date(props.data.executionDate) : null
  );

  const handleSetDate = (e) => {
    let date1 = e;
    date1.setHours(20);
    setDate(date1);
  };

  const onSubmit = async (data) => {
    dispatch(
      editTodo(props.data._id, {
        title: data.title,
        belongTo: data.belongTo,
        rank: data.rank,
        description: data.description,
        executionDate: date ? date.toISOString() : null,
      })
    ).then(() => {
      if (todosError === null)
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!");
    });
    props.init(false);
  };

  return (
    <CenteredForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      id="form1"
    >
      <ExitModal init={props.init} color={user.color} />
      <div
        style={{
          textAlign: "center",
          fontWeight: "bold",
          padding: "20px",
          fontSize: "22px",
        }}
      >
        עריכת משימה
      </div>
      <InputBox
        labelColor={"gray"}
        color={"#3A3C3F"}
        borderBottomColor={user.color}
      >
        <div className="relativeBox">
          <input
            defaultValue={props.data.title}
            type="text"
            name="title"
            minLength={1}
            maxLength={50}
            required={true}
            ref={register()}
          />
          <label>כותרת</label>
        </div>
        <div className="relativeBox">
          <input
            defaultValue={props.data.belongTo}
            type="text"
            name="belongTo"
            minLength={1}
            maxLength={50}
            required={true}
            ref={register()}
          />
          <label>שיוך</label>
        </div>
        <FlexRowBetween style={{ width: "60%", margin: "10px auto" }}>
          <div
            className="relativeBox"
            style={{
              marginRight: "0px",
              padding: "0px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <input
              defaultValue={props.data.rank}
              type="number"
              name="rank"
              min={1}
              max={100}
              required={true}
              ref={register()}
              style={{ width: "50px", marginRight: "0px" }}
            />
            <label>חשיבות</label>
          </div>
          <GenericDiv
            hoverBackground={"rgb(223, 223, 223)"}
            background={user.color}
            style={{
              width: "max-content",
              //   margin: "20px auto",
              display: "flex",
              flexDirection: "column",
            }}
            onClick={() => setShowCalendar(true)}
          >
            <div>תאריך לביצוע</div>
            <div>
              {date &&
                date
                  .toISOString()
                  .replace(/T.*/, "")
                  .split("-")
                  .reverse()
                  .join("/")}
            </div>
          </GenericDiv>
        </FlexRowBetween>

        {/* {props.data.executionDate && */}
        {/* <> */}
        {/* <div>תאריך לביצוע</div> */}

        {/* </> */}
        {/* } */}

        <div
          style={{ position: "relative", width: "70%", margin: "20px auto" }}
        >
          <RTextArea
            defaultValue={props.data.description}
            color={user.color}
            placeholder="תוכן"
            name="description"
            type="text"
            rows={props.data.rank === 10 ? "5" : "2"}
            minLength={0}
            maxLength={500}
            ref={register()}
          />
        </div>

        {/* <GenericButton
          background={user.color}
          type="submit"
          style={{ width: "50%", margin: "30px auto", padding: "15px" }}
        >
          שמירה
        </GenericButton> */}
      </InputBox>

      <GuestOptions
        width={"21px"}
        color={user.color}
        padding={`10px 0`}
        style={{ justifyContent: "space-between" }}
      >
        <FlexRowBetween>
          <button
            type="submit"
            form="form1"
            style={{
              outline: "none",
              width: "100%",
              background: "inherit",
              border: "none",
            }}
          >
            <FlexRowBetween style={{ justifyContent: "space-evenly" }}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: user.color,
                }}
              >
                שמירה
              </div>
              <div style={{ padding: "8px 0" }}>
                <GenericSvg
                  fill={user.color}
                  size={"20px"}
                  svg={svgs.save}
                  viewBox={"0 0 49 49"}
                />
              </div>
            </FlexRowBetween>
          </button>
        </FlexRowBetween>
        <FlexRowBetween
          style={{ justifyContent: "center", background: user.color }}
        >
          <div>
            <img
              src={delete1}
              alt="delete"
              onClick={() => {
                props.init(false);
                props.setDeleteTodo(props.data._id);
              }}
            />
          </div>
        </FlexRowBetween>
        {/* <img src={delete1} alt="delete" onClick={() => { props.init(false); props.setDeleteTodo(props.data._id) }} /> */}
      </GuestOptions>
      {showCalendar && (
        <GenericModal
          init={setShowCalendar}
          div={
            <GCalendar
              background={user.color}
              setDate={handleSetDate}
              date={date}
              init={setShowCalendar}
            />
          }
        />
      )}
    </CenteredForm>
  );
};

export default EditTodo;
