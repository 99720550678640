import React from 'react'
import { DisplayNonePhone, FlexRowBetween, Service } from '../../common/Style';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import ClientRoutes from '../../../navigation/Routes';
import './style.css'
const NavBarMenu = props => {

    return <DisplayNonePhone>

        <FlexRowBetween style={{
            position: "fixed", top: "0", height: "6vh"
            , background: "linear-gradient(to bottom, #f2f2f2, #f2f2f2, #f2f2f2, #f2f2f2fa, #f2f2f2fa, #f2f2f2de)"
            , zIndex: 1, padding: "0 50px", paddingTop: "-10px"
        }}>
            <div style={{ fontFamily: "logo", fontSize: "22px", letterSpacing: "1.5px" }} className="scale">
                <HashLink style={{ color: "#3A3C3F", textDecoration: "none" }} smooth to={"/itay#home"}
                    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>Be Simple</HashLink></div>
            <FlexRowBetween style={{ width: "50%", cursor: "pointer" }}>
                <Service><HashLink style={{ color: "#3A3C3F", textDecoration: "none" }} smooth to={"/itay#services"}
                    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>שירותים וחבילות</HashLink></Service>
                <Service><HashLink style={{ color: "#3A3C3F", textDecoration: "none" }} smooth to={"/itay#quesAndAns"}
                    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>שאלות ותשובות</HashLink></Service>
                <Service><HashLink style={{ color: "#3A3C3F", textDecoration: "none" }} smooth to={"/itay#contactUs"}
                    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>יצירת קשר</HashLink></Service>
                <Service><Link style={{ color: "#3A3C3F", textDecoration: "none" }} to={ClientRoutes.UnAuth.login}>כניסה / הרשמה</Link></Service>
            </FlexRowBetween>
        </FlexRowBetween>
    </DisplayNonePhone>
}

export default NavBarMenu;