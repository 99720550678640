import { createSlice } from '@reduxjs/toolkit';
import { post2, get2 } from '../api/http';
import ServerRoutes from '../api/routes';

const initialState = {
    potentials: {},
    potentialsError: null,
    potentialsLoading: false
}

const _addPotential = (state, action) => {
    let editedPotentials = { ...state.potentials }
    editedPotentials[action.payload.id] = { ...action.payload.body, done: false, _id: action.payload.id, dateAdded: action.payload.dateAdded }
    state.potentials = editedPotentials;
};

const _setPotentials = (state, action) => {
    state.potentials = action.payload;
};

const _editPotential = (state, action) => {
    let editedPotentials = { ...state.potentials }
    console.log(action.payload.body);
    console.log(action.payload.id);
    for (const [key, value] of Object.entries(action.payload.body))
        editedPotentials[action.payload.id][key] = value;
    state.potentials = editedPotentials;
};

const _deletePotential = (state, action) => {
    let editedPotentials = { ...state.potentials }
    delete editedPotentials[action.payload]
    state.potentials = editedPotentials;
};

const _actionStart = (state,) => {
    state.potentialsLoading = true;
    state.potentialsError = null;
};

const _actionSuccess = (state) => {
    state.potentialsLoading = false;
    state.potentialsError = null;
};

const _actionFailed = (state, action) => {
    state.potentialsLoading = false;
    state.potentialsError = action.payload.error;
};

const _cleanPotentialsState = (state) => {
    state.potentials = {};
    state.potentialsError = null;
    state.potentialsLoading = false
};

const potentials = createSlice({
    name: 'potentials',
    initialState,
    reducers: {
        addPotential1: _addPotential,
        cleanPotentialsState: _cleanPotentialsState,
        editPotential1: _editPotential,
        deletePotential1: _deletePotential,
        actionStart: _actionStart,
        actionFailed: _actionFailed,
        setPotentials: _setPotentials,
        actionSuccess: _actionSuccess
    },
});

const { reducer, actions } = potentials;

export const { actionStart, actionFailed, actionSuccess, addPotential1, editPotential1, deletePotential1, setPotentials, cleanPotentialsState } = actions;

export default reducer;

export const addPotential = (body) => {
    console.log(body)
    return async (dispatch) => {
        dispatch(actionStart())
        const { data, error, status } = await post2(ServerRoutes.Potentials.add, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(addPotential1({ id: data.id, dateAdded: data.dateAdded, body }));
        }
    }
};

export const addPotentialE4U = (body) => {
    console.log(body)
    return async (dispatch) => {
        dispatch(actionStart())
        const { data, error, status } = await post2(ServerRoutes.Potentials.addE4U, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(addPotential1({ id: data.id, dateAdded: data.dateAdded, body }));
        }
    }
};


export const getAll = () => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { data, error, status } = await get2(ServerRoutes.Potentials.getAll);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(setPotentials(data));
        }
    }
};

export const cleanAll = () => {
    return async (dispatch) => {
        return dispatch(cleanPotentialsState());
    }
};


export const editPotentialDetails = (id, body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Potentials.editPotentialDetails + id, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editPotential1({ id, body }));
        }
    }
};

export const editStatus = (id, body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Potentials.editStatus + id, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editPotential1({ id, body }));
        }
    }
};

export const deletePotential = (id) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Potentials.delete, { id });
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(deletePotential1(id));
        }
    }
};