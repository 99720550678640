import React from 'react';
import { AvivCard } from '../../common/Style';
const AvivClientCard = (props) => {
    return (<AvivCard padding={`10px 8px`} key={props.index} onClick={() => props.edit(props.data)} style={{ cursor: "pointer" }}>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "row", width: "50%", justifyContent: "flex-start" }}>{props.data.date.replace(/T.*/, '').split('-').reverse().join('/')}</div>
            <div style={{ display: "flex", flexDirection: "row", width: "50%", justifyContent: "flex-start" }}>{props.data.hall}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: `7px` }}>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "flex-start" }}>
                {props.data.eventType ? (props.data.brideFN + " ו" + props.data.groomFN) : props.data.calendarName}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: `7px` }}>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "flex-start" }}>
                <div style={{ marginLeft: "5px" }}>קבלת פנים:</div>
                <div>{props.data.hour1}</div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "flex-start" }}>
                <div style={{ marginLeft: "5px" }}>מחיר:</div>
                <div>{props.data.price}</div>
            </div>
        </div>


    </AvivCard>)
}

export default AvivClientCard;

