import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import OpacityLoading from "../../common/loader/OpacityLoading";
import GenericModal from "../../common/modals/GenericModal";
import {
  getBetweenDates,
  editTodoAdmin,
} from "../../../store/reducers/todosReducer";
import {
  TodoCard,
  TodosHolder,
  MainDiv,
  GenericButton,
  GenericDiv,
  ClosenessTitle2,
  SomeRowStyle,
  FlexRowBetween,
} from "../../common/Style";
import { GetToast, dateFormat } from "../../common/modals/Toast";
import { Link } from "react-router-dom";
import ClientRoutes from "../../../navigation/Routes";
import EditGuestAdmin from "./EditGuestAdmin";
import Switch from "react-switch";
import CopyText from "../../common/others/CopyText";
import {
  cleanAll,
  getAcceptedAdmin,
  getGuestsAdmin,
  getGuestsWithFilter,
  getwh,
  getwhW,
} from "../../../store/reducers/guestsReducer";
import { patterns } from "../../user/brain/patterns";
import { smsAdmin, whaAdmin } from "../../../store/reducers/dataReducer";

const AdminTodos = () => {
  const [editTodoS, setEditTodo] = useState(false);
  const [pp, setPP] = useState(null);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const { todos, todosError, todosLoading } = useSelector(
    (state) => state.todosReducer
  );
  const { guests, getWhHadC } = useSelector((state) => state.guestsReducer);

  const { addToast } = useToasts();

  const searchGA = () => {
    let date = new Date();
    date.setHours(19);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    date.setDate(date.getDate() - 1);
    let start = date.toISOString();
    date.setDate(date.getDate() + 7);
    dispatch(getBetweenDates({ start, end: date.toISOString() })).then(
      (res) => {
        if (!res.payload?.error)
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      }
    );
  };

  const sendReminder = async (user) => {
    let msg = `היום זה קורה - החתונה של ${user.brideFN + " " + user.brideLN} ו${
      user.groomFN + " " + user.groomLN
    } 🎊🎉🍻\nמתנה באשראי - ${user.paybox}\nניווט לאירוע - ${
      user.waze
    }\nמתרגשים ומצפים לראותכם 🙏`;
    dispatch(getGuestsAdmin(user.uid)).then(async (res) => {
      if (res.payload.length > 0) {
        let arr = [];
        let ids = [];
        for (let i = 0; i < res.payload.length; i++) {
          ids.push(res.payload[i]._id);
          arr.push({
            phone: res.payload[i].phone,
            msg,
            sent: 4,
          });
        }
        await SendMessages(
          arr,
          true,
          ids,
          4,
          user.phone,
          user.smsSender,
          user.uid
        );
      }
    });
  };

  const sendThank = async (user) => {
    let msg = `תודה שלקחתם חלק ביום המאושר שלנו 🤗\nמקווים שנהנתם, נתראה בשמחות 🙏\nאוהבים ${user.brideFN} ו${user.groomFN} ❤`;
    dispatch(getAcceptedAdmin(user.uid)).then(async (res) => {
      if (res.payload.length > 0) {
        let arr = [];
        let ids = [];
        for (let i = 0; i < res.payload.length; i++) {
          ids.push(res.payload[i]._id);
          arr.push({
            phone: res.payload[i].phone,
            msg,
            sent: 5,
          });
        }
        await SendMessages(
          arr,
          true,
          ids,
          5,
          user.phone,
          user.smsSender,
          user.uid
        );
      }
    });
  };
  const getNotAnsweredW = async (user) => {
    dispatch(getwhW(user.uid)).then(
      async (res) => {
        if (res.payload.length > 0) {
          let arr = [];
          let msg;
          let ids = [];
          let isI;
          const regex = /^[5]{1}[0-9]{8}$/g;
          let data = [...res.payload];
          for (let i = 0; i < data.length; i++)
            if (data[i].phone.match(regex) !== null) {
              ids.push(data[i]._id);
              let secParam = `״${user.hall}״ ${user.city} ${dateFormat(
                user.date
              )}`
                .replaceAll(",", "")
                .replaceAll('"', "");
              isI =
                data[i].inviting !== undefined &&
                data[i].inviting !== null &&
                data[i].inviting !== "" &&
                data[i].owc !== undefined &&
                data[i].owc !== null &&
                data[i].owc !== "";
              msg = isI
                ? `${user.brideFN} ${
                    data[i].owc.includes("ת")
                      ? `*(${data[i].owc} של ${data[i].inviting})*`
                      : user.brideLN
                  } ו${user.groomFN} ${
                    data[i].owc.includes("ת")
                      ? user.groomLN
                      : `*(${data[i].owc} של ${data[i].inviting})*`
                  }`
                : `${user.brideFN + " " + user.brideLN} ו${
                    user.groomFN + " " + user.groomLN
                  }`;
              // msg = "לאירוע בר המצווה של טומי!";
              arr.push({
                templateMessageId:
                  data[i].sentW === 0 ? "f_msg" : "general_service_2",
                // data[i].sentW === 0
                //   ? "general_first_msg"
                //   : "general_second_msg",
                to: `+972${data[i].phone}`,
                parameters:
                  data[i].sentW === 0
                    ? `${msg},https://b-s.fun/n?n=${window.btoa(
                        user.uid + " " + data[i]._id + " " + data[i].sum
                      )}`
                    : `${msg},${secParam}`,
                crmData: {
                  webhookUrl:
                    "https://bs-wedding-server.herokuapp.com/guests/incomingWhatsapp/",
                  calendarName: `החתונה של ${user.brideFN} ${user.brideLN} ו${user.groomFN} ${user.groomLN}`,
                  guestPhone: `${data[i].phone}`,
                  userPhone: `${user.phone}`,
                  id: `${data[i]._id}`,
                  invitationLink: `https://b-s.fun/n?n=${window.btoa(
                    user.uid + " " + data[i]._id + " " + data[i].sum
                  )}`,
                  gLink: `https://b-s.fun/n?n=${window.btoa(
                    user.uid + " " + data[i]._id + " " + data[i].sum
                  )}`,
                },
              });
            }
          dispatch(
            whaAdmin({
              arr,
              ids,
            })
          ).then((res) => {
            if (!res.payload?.error) {
              setPP(null);
              dispatch(cleanAll());
              GetToast(addToast, "success", "ההודעות נשלחו בהצלחה!!");
            } else GetToast(addToast, "error", res.payload.error);
          });
        } else GetToast(addToast, "info", "אין עוד רשומות");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };

  const getNotAnswered = async (user) => {
    dispatch(getwh(user.uid)).then(
      async (res) => {
        if (res.payload.length > 0) {
          let arr = [];
          let preSms = false;
          const defaultPreSms1 = `${user.brideFN + " " + user.brideLN} ו${
            user.groomFN + " " + user.groomLN
          } שמחים להזמינכם לחתונתם!`;
          const defaultPreSms2 = `לחתונה של ${
            user.brideFN + " " + user.brideLN
          } ו${user.groomFN + " " + user.groomLN}`;
          const defaultPreSms3 = `${user.brideFN + " " + user.brideLN} ו${
            user.groomFN + " " + user.groomLN
          } מתחתנים!`;
          arr = res.payload.map((item, index) => {
            preSms =
              item.inviting !== undefined &&
              item.inviting !== null &&
              item.inviting !== "" &&
              item.owc !== undefined &&
              item.owc !== null &&
              item.owc !== "";
            return {
              id: item._id,
              שם: item.name,
              קירבה: item.closeness,
              טלפון: item.phone,
              הוזמנו: item.sum,
              אישרו: item.accept,
              הסעה: item.transportation,
              הגיעו: item.arrived,
              מתנה: item.gift,
              תשובה: item.ans,
              נשלחו: item.sent,
              הערות: item.notes,
              // הקדמה1: (item.preSMS1 !== undefined && item.preSMS1 !== null && item.preSMS1 !== "") ? item.preSMS1 : defaultPreSms1,
              הקדמה1: preSms
                ? item.inviting +
                  (item.owc[item.owc.length - 1] === "ם"
                    ? " שמחים"
                    : item.owc[item.owc.length - 1] === "ו"
                    ? " שמח"
                    : " שמחה") +
                  " להזמינכם לחתונת " +
                  item.owc +
                  (item.owc.includes("ת")
                    ? ` ${user.brideFN}.`
                    : ` ${user.groomFN}.`)
                : defaultPreSms1,
              // הקדמה2: (item.preSMS2 !== undefined && item.preSMS2 !== null && item.preSMS2 !== "") ? item.preSMS2 : defaultPreSms2,
              הקדמה2: preSms
                ? "לחתונת " + item.owc + " של " + item.inviting
                : defaultPreSms2,
              // הקדמה3: (item.preSMS3 !== undefined && item.preSMS3 !== null && item.preSMS3 !== "") ? item.preSMS3 : defaultPreSms3,
              הקדמה3: preSms
                ? item.owc +
                  " של " +
                  item.inviting +
                  (item.owc.includes("ת") ? " מתחתנת" : " מתחתן") +
                  "!"
                : defaultPreSms3,
              קישור: `https://b-s.fun/n?n=${window.btoa(
                user.uid + " " + item._id + " " + item.sum
              )}`,
            };
          });
          // }
          await vv(user, arr);
          // setPP(arr);
        } else GetToast(addToast, "info", "אין עוד רשומות");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };

  useEffect(() => {
    if (todosError) GetToast(addToast, "error", todosError);
  }, [todosError]);

  const setDone = async (id, done, place, date) => {
    dispatch(editTodoAdmin(id, { done }, place, date)).then(() => {
      if (todosError === null)
        GetToast(addToast, "success", "המשימה סומנה בהצלחה!");
    });
  };

  const vv = async (user, pp) => {
    let msg = "";
    let ids = [];
    let arr = [];
    if (pp.length) {
      for (let i = 0; i < pp.length; i++) {
        ids.push(pp[i].id);
        let sent = pp[i][`נשלחו`] + 1;
        msg =
          sent === 1
            ? patterns.first
            : sent === 2
            ? patterns.second
            : patterns.third;
        msg = msg
          .replace(
            `[הקדמה${pp[i][`נשלחו`] + 1}]`,
            pp[i][`הקדמה${pp[i][`נשלחו`] + 1}`]
          )
          .replace(`[הזמנה]`, pp[i]["קישור"]);
        // msg = `לכבוד ${pp[i]["שם"]},\n` + msg
        arr.push({
          phone: pp[i]["טלפון"],
          msg,
          sent: pp[i][`נשלחו`] + 1,
        });
      }
      await SendMessages(
        arr,
        false,
        ids,
        10,
        user.phone,
        user.smsSender,
        user.uid
      );
    } else alert("אין תוצאות");
  };

  const SendMessages = async (
    arr,
    title,
    ids,
    lowerThan,
    userPhone,
    smsSender,
    uid
  ) => {
    dispatch(
      smsAdmin({
        arr,
        title,
        ids,
        lowerThan,
        userPhone,
        smsSender,
        uid,
      })
    ).then((res) => {
      if (!res.payload?.error) {
        setPP(null);
        dispatch(cleanAll());
        GetToast(addToast, "success", "ההודעות נשלחו בהצלחה!!");
      } else GetToast(addToast, "error", res.payload.error);
    });
  };

  let todosArray = useMemo(() => {
    let arr = [];
    if (Object.keys(todos).length > 0) {
      for (const [key, value] of Object.entries(todos)) {
        arr.push(
          <div style={{ width: "100%" }}>
            <ClosenessTitle2 color={"#fda14b"} key={key}>
              {value[0].executionDate
                .replace(/T.*/, "")
                .split("-")
                .reverse()
                .join("/")}
            </ClosenessTitle2>
          </div>
        );
        for (let i = 0; i < value.length; i++)
          arr.push(
            <SomeRowStyle
              key={value[i]._id}
              style={{ cursor: "auto", padding: "10px" }}
            >
              <FlexRowBetween>
                <div>{value[i].name}</div>
                {/* <div onClick={vv}>S</div> */}
                {/* {(value[i].title.split(" ")[0] === "הודעה" ||
                  value[i].title.split(" ")[0] === "שיחה") && ( */}
                <div
                  style={{
                    cursor: "pointer",
                    padding: "0px 7px",
                    border: "2px solid silver",
                  }}
                  onClick={async () => {
                    if (
                      value[i].title.split(" ")[0] === "הודעה" ||
                      value[i].title.split(" ")[0] === "שיחה"
                    )
                      await getNotAnswered(value[i]);
                    else if (value[i].title.split(" ")[0] === "תזכורת")
                      await sendReminder(value[i]);
                    else if (value[i].title.split(" ")[0] === "ווצאפ")
                      await getNotAnsweredW(value[i]);
                    else if (value[i].title.split(" ")[0] === "תודה")
                      await sendThank(value[i]);
                    else alert("אין מה לשלוח");
                    setDone(value[i]._id, true, i, key);
                  }}
                >
                  A
                </div>
                {/* )} */}
                <div>{value[i].title}</div>
              </FlexRowBetween>
              <FlexRowBetween style={{ marginTop: "10px" }}>
                <CopyText dataToCopy={value[i].pass} text={"סיסמא"} />
                <CopyText dataToCopy={value[i].phone} text={"נייד"} />
                <Switch
                  width={34}
                  height={14}
                  onColor={"#fda14b"}
                  offColor={"#b1b1b1"}
                  // onHandleColor={user.color}
                  boxShadow={"0 2px 4px silver, 0 -1px 4px silver"}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  checked={value[i].done}
                  onChange={() => setDone(value[i]._id, !value[i].done, i, key)}
                  handleDiameter={20}
                />
              </FlexRowBetween>
            </SomeRowStyle>
          );
      }
    }
    return arr;
  }, [todos]);

  return (
    <MainDiv
      className="animate__animated animate__fadeIn"
      style={{
        overflow: "hidden",
        maxHeight: "100vh",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: `0 10px`,
          margin: "20px auto",
        }}
      >
        <GenericButton
          background={"#fda14b"}
          as={Link}
          to={ClientRoutes.Admin.main}
          style={{ fontSize: "14px" }}
        >
          ראשי
        </GenericButton>
        <GenericDiv
          hoverBackground={"rgb(223, 223, 223)"}
          background={"#fda14b"}
          onClick={searchGA}
        >
          ייבא
        </GenericDiv>
      </div>

      {/* <div style={{ marginTop: "100px" }}>
                <DropDownG label={"מיון"} data={sortOptions} value={sortBy} onChange={setSortBy} />
            </div> */}

      <TodosHolder style={{ marginTop: "0px" }}>{todosArray}</TodosHolder>

      {editTodoS && (
        <GenericModal
          init={setEditTodo}
          div={<EditGuestAdmin data={editTodoS} init={setEditTodo} />}
        />
      )}

      {todosLoading && <OpacityLoading />}
    </MainDiv>
  );
};

export default AdminTodos;
