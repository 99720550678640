import React, { useState } from "react";
import GenericModal from "../../common/modals/GenericModal";
import { ServicesHolder } from "../../common/Style";
import { Questions } from "../producers/Style";
import TitleAndContent from "../../common/modals/TitleAndContent";
const s1 = (
  <div>
    <div>אישורי הגעה הינו השירות החשוב ביותר!</div>
    <div>בכל אירוע אנו מתחייבים לאולם על מספר מנות ״פתיחה״ ו״רזרבה״.</div>
    <div>
      במידה ונתחייב על כמות גבוהה של פתיחה מסך המוזמנים ניאלץ לשלם מנות על אנשים
      שלא יושבים ונפסיד המון כסף.
    </div>
    <div>היתרון שחברה עושה עבורכם את אישורי ההגעה הוא האובייקטיביות:</div>
    <div>
      כאשר אתם תזמינו אורח מן הסתם תגובתו תהיה חיובית אך לא בטוח שאכן יגיע ולכן
      כאשר אנחנו נשאל אותו התשובה תהיה אמיתית
    </div>
    <div>
      לכן כמות האנשים הכוללת שתקבלו משירות אישורי ההגעה ייתן לכם אינדיקציה למספר
      מנות הפתיחה שתתחייבו עליהם לאולם
    </div>
    <div>
      כמובן כהמצלה - יש להוריד 10% כי בפועל לא כולם מגיעים וגם אם כן אז יש את
      מנות הרזרבה שיפתרו את זה - העיקר לא להפסיד!
    </div>
    <div>
      בנוסף דרך אישורי ההגעה האורחים יהנו מאופציה להעניק מתנה באשראי, להוסיף את
      האירוע ליומן ולנווט לאולם
    </div>
    <div>
      אתם תקבלו מערכת לניהול המוזמנים אשר תתעדכן ב Live וכמובן עוד המון יתרונות
      לניהול האירוע כמו שמוצג בשירותים
    </div>
  </div>
);
const s2 = (
  <div>
    <div>תהליך אישורי ההגעה נקבע בהתאם לחבילה שנרכשה כמפורט בשירותים</div>
    <div>כמובן אנו כאן לכל שאלה והרחבה בנושא 🙂</div>
  </div>
);
const content = [
  {
    q: "למה צריך אישורי הגעה לאירוע?",
    a: s1,
  },
  //   {
  //     q: "מהו תהליך אישורי ההגעה?",
  //     a: s2,
  //   },
  {
    q: "מה קורה אם יש מוזמנים שלא יודעים בוודאות אם יגיעו או לא?",
    a: "המלצה שלנו? - לא לקחת אותם בחשבון, אם יגיעו יש רזרבה...",
  },
  {
    q: "מה קורה אם אורחים לא זמינים?",
    a: "תוכלו להפיק דוח הערות ולראות אילו אורחים אינם זמינים ולבדוק אולי המספר שהזנתם עבורם שגוי",
  },
  {
    q: "מה עושים אחרי שיש לנו את הרשימה המגובשת של האנשים שאישרו הגעה?",
    a: "כמו שצוין לפני, נוריד 10% עבור מנות הפתיחה שנתחייב עליהם וננסה שהרזרבה תהיה גבוהה על מנת שבמידה ויבואו אנשים נוספים שלא ענו - יהיה להם מקום",
  },
  {
    q: "האם אתם מתזכרים את המוזמנים ביום האירוע?",
    a: "כמובן! גם אורחים שאמרו בפירוש שלא יגיעו יקבלו את הודעת התזכורת שכוללת מלבד הניווט - את המתנה באשראי ובכך יוכלו להעניק לנו מתנה גם כשאינם מגיעים",
  },
  {
    q: "האם אתם שולחים הודעת תודה בוקר למחורת האירוע?",
    a: "נכון! עבור האורחים שאישרו הגעתם",
  },
  {
    q: "מהן שעות הפעילות שלכם?",
    a: "09:00 - 17:00 למעט מקרים חריגים שיטופלו בווצאפ",
  },
  //   {
  //     q: "איך מתבצע התשלום על שירות אישורי ההגעה לחתונה או לאירוע?",
  //     a: "מספר הרשומות שיש לוודא איתן הגעה (קיים הסבר מעל המחירון) כפול עלות הרשומה בחבילה שבחרתם",
  //   },
  //   {
  //     q: "כמה פעמים מתקשרים לאורחים? השיחות מוגבלות?",
  //     a: "לפי החבילה שנרכשה",
  //   },
  {
    q: "האם אתם מספקים שירות של מנהל הושבה באירוע?",
    a: "כמובן, חלק מאוד חשוב בחיסכון הכספי של הזוג - בעצם השירות היחידי באירוע שנועד לחסוך כסף לזוג ע״י סגירת ״קרחות״ והימנעות מפתיחת רזרבות שלא לצורך",
  },
  {
    q: "מהן שעות הפעילות של הצוות באירוע?",
    a: "מגיעים לפני קבלת הפנים לראות שמספרי השולחנות כמו בסקיצה, הקמת עמדת הדיילות, ונשארים עד מנה ראשונה לראות שכולם יושבים לאכול בצורה מסודרת",
  },
  {
    q: "איך ניתן לדעת כמה אורחים הגיעו בפועל לאירוע?",
    a: "הדיילות מסמנות הגעה עבור כל אורח וכאשר תוציאו דוח למחורת תדעו הכל",
  },
  {
    q: "כמה עולה שירות הדיילות?",
    a: "כל מקרה לגופו, פרטים יימסרו בשיחת טלפון",
  },
  {
    q: "מה הזמן הנכון לסגור עם חברת אישורי הגעה וסידורי הושבה?",
    a: "כמה שיותר מוקדם",
  },
  // {
  //     q: "מהם אחוזי הדיוק שלכם?",
  //     a: "משתנה בין הקהל, לכן מומלץ כמו שנאמר מספר פעמים - להוריד 10% מסך המאשרים כדי לא להפסיד כסף באירוע"
  // },
  {
    q: "מה היתרונות שלנו?",
    a: "מחיר, איכות, שירות ורצון לעזור לזוג כמה שיותר! מערכת מתקדמת אך פשוטה, עוזרת לזוג מעבר למצופה וחוסכת המון זמן בסידור המוזמנים בשולחנות - זמן יקר בימים הלחוצים שלפני האירוע",
  },
];
const Page4 = () => {
  const [ans, setAns] = useState(null);
  let fff = content.map((item, index) => {
    return (
      <Questions key={index} onClick={() => setAns(item)}>
        {item.q}
      </Questions>
    );
  });

  return (
    <div
      style={{
        paddingBottom: "50px",
        width: "100%",
        background: "radial-gradient(circle, #6055d643 0%, #f2f2f2 100%)",
        textAlign: "center",
      }}
      id={"quesAndAns"}
    >
      <div
        style={{
          marginBottom: "10px",
          fontFamily: "openSansRegular",
          fontWeight: "600",
          fontSize: "30px",
          textShadow: "2px 2px 2px silver",
        }}
      >
        שאלות ותשובות
      </div>
      <ServicesHolder>{fff}</ServicesHolder>
      {ans && (
        <GenericModal
          init={setAns}
          div={<TitleAndContent title={ans.q} content={ans.a} init={setAns} />}
        />
      )}
    </div>
  );
};

export default Page4;
