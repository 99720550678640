import React from "react";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import {
  editDetailsLocalOnly,
  editColorOnly,
} from "../../../store/reducers/authReducer";
import { FlexRowBetween, GenericDiv } from "../Style";
import { useToasts } from "react-toast-notifications";
import { GetToast } from "../modals/Toast";

const SketchColorPalette = (props) => {
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  let widthS = window.innerWidth; // 1728

  const setMainColor1 = (color) => {
    dispatch(editDetailsLocalOnly({ [props.change]: color }));
  };

  const handleSave = () => {
    dispatch(editColorOnly({ color: user.color })).then(
      (res) => {
        if (!res.payload?.error)
          GetToast(addToast, "success", "הפעולה הצליחה!!");
        props.setFlag(!props.flag);
        props.onClose(false);
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {props.div}
      {/* <div onClick={() => props.setDisplay(!props.display)}
            ><img src={palette} alt="palette" style={{ width: "50px" }} /></div> */}
      {props.display && (
        <div
          style={{
            // bottom: props.top ? props.top : "130%",
            // position: "absolute",
            // left: "-22%",
            bottom: props.top ? props.top : "-50%",
            position: "absolute",
            left: widthS > 768 ? "0%" : "-22%",
            zIndex: 1,
            paddingBottom: "10px",
            background:
              "radial-gradient(circle, #f2f2f2 0%, rgba(255,255,255,0.2) 100%)",
            padding: "10px",
            backdropFilter: "blur(20px)",
          }}
        >
          <SketchPicker
            disableAlpha={true}
            style={{ width: "200px", border: "1px solid black" }}
            color={user[props.change]}
            onChange={(c) =>
              setMainColor1(
                "#" +
                  ((1 << 24) + (c.rgb.r << 16) + (c.rgb.g << 8) + c.rgb.b)
                    .toString(16)
                    .slice(1)
              )
            }
          />
          <FlexRowBetween style={{ marginTop: "10px" }}>
            <GenericDiv
              onClick={() => props.onClose(false)}
              style={{ width: "45%", padding: "7px 0" }}
            >
              יציאה
            </GenericDiv>
            <GenericDiv
              onClick={handleSave}
              background={"rgba(255,255,255,0.8)"}
              style={{
                width: "45%",
                padding: "7px 0",
                color: "#3A3C3F",
                fontWeight: "bold",
                // border: "2px solid white",
              }}
            >
              שמירה
            </GenericDiv>
          </FlexRowBetween>
        </div>
      )}
    </div>
  );
};

export default SketchColorPalette;
