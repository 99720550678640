import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Invitation from "./Invitation";

import {
  getDetails,
  cleanInvitationDetails,
} from "../../../store/reducers/authReducer";
import { GetToast } from "../modals/Toast";
import Loading from "../modals/Loading";

const GuestInvitation = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { user, authLoading, authError } = useSelector(
    (state) => state.authReducer
  );

  useEffect(() => {
    if (authError) GetToast(addToast, "error", authError);
  }, [authError]);

  const search = useLocation().search;
  const getId = (num) => {
    let name = new URLSearchParams(search).get("n");
    name = window.atob(name);
    name = name.split(" ");
    return name[num];
  };

  useEffect(() => {
    dispatch(getDetails(getId(0)));
    return () => {
      dispatch(cleanInvitationDetails());
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        overflowX: "hidden",
        // overflowY: "auto",
      }}
    >
      {user !== null && <Invitation amount={getId(2)} id={getId(1)} />}
      {authLoading && <Loading />}
    </div>
  );
};

export default GuestInvitation;
