import React from "react";
import logo from "../../../../assets/logo/logogo.png";
import miba from "../../../../assets/logo/miba.png";

import { FlexRowBetween } from "../../Style";

const dic = {
  itay: {
    link: "https://b-s.fun/itay",
    pic: logo,
    size: "40px",
    title: "BE SIMPLE EVENTS",
  },
  ohad: {
    link: "https://b-s.fun/itay",
    pic: logo,
    size: "40px",
    title: "BE SIMPLE EVENTS",
  },
  aviv: {
    link: "https://b-s.fun/itay",
    pic: logo,
    size: "40px",
    title: "BE SIMPLE EVENTS",
  },
  eliran: {
    link: "https://www.facebook.com/%D7%9E%D7%99-%D7%91%D7%90-%D7%90%D7%99%D7%A9%D7%95%D7%A8%D7%99-%D7%94%D7%92%D7%A2%D7%94-%D7%95%D7%A1%D7%99%D7%93%D7%95%D7%A8%D7%99-%D7%94%D7%95%D7%A9%D7%91%D7%94-365921506809702/",
    pic: miba,
    size: "50px",
    title: "MIBA EVENTS",
  },
  aviad: { link: "https://b-s.fun/itay", pic: logo },
};
const Footer = ({ producer }) => {
  //   console.log(producer);
  return (
    <a
      target={"_blank"}
      rel="noopener noreferrer"
      href={dic[producer.substring(1)].link}
      style={{
        width: "100%",
        marginTop: "40px",
        padding: "5px 0",
        color: "#3A3C3F",
        textDecoration: "none",
        marginTop: "30px",
        background:
          "linear-gradient(to bottom, rgba(255,255,255,0.2), rgba(255,255,255,0.4),rgba(255,255,255,0.6))",
      }}
    >
      <FlexRowBetween
        style={{ justifyContent: "space-around", padding: "10px 0" }}
      >
        <div>
          <FlexRowBetween
            style={{
              fontFamily: "ralewayLight",
              justifyContent: "center",
              fontStyle: "italic",
              fontSize: "14px",
            }}
          >
            {dic[producer.substring(1)].title}
          </FlexRowBetween>
          <FlexRowBetween
            style={{
              fontWeight: "100",
              justifyContent: "center",
              fontSize: "12px",
              fontStyle: "oblique",
            }}
          >
            אישורי הגעה וסידורי הושבה
          </FlexRowBetween>
        </div>
        <img
          src={dic[producer.substring(1)].pic}
          //   src={dic[producer.substring(1)].pic}
          alt="logo"
          style={{ width: dic[producer.substring(1)].size }}
        />
      </FlexRowBetween>
    </a>
  );
};

export default Footer;
