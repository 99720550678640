import React from "react";
import Generic from "./Generic";
const Package5 = (props) => {
  return (
    <Generic
      title={"VIP"}
      price={"1.5₪ לרשומה"}
      include={[
        true, // 1
        true, // 2
        true, // 3
        true, // 4
        true, // 5
        true, // 6
        true, // 7
        true, // 8
        true, // 9
        true, // 10
        true, // 11
        true, // 12
        true, // 13
        true, // 14
        true, // 15
        true, // 16
        true, // 17
        true, // 18
      ]}
    />
  );
};

export default Package5;
