import React, { useEffect, useState } from "react";
import {
  FlexRowBetween,
  GenericButton,
  GenericDiv,
  HomeInput,
  RespFont,
} from "../../Style";
import { addAlpha, CFV, GetToast } from "../../modals/Toast";
import minus from "../../../../assets/invitation/minus2.svg";
import plus from "../../../../assets/invitation/plus2.svg";
import minusB from "../../../../assets/invitation/minusB.svg";
import plusB from "../../../../assets/invitation/plusB.svg";
import { useForm } from "react-hook-form";
import { setAccept } from "../../../../store/reducers/guestsReducer";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";

const Company = ({
  setCount,
  count,
  onSubmit,
  done,
  phone,
  setPhone,
  name,
  setName,
  id,
  setNotes,
  self = false,
}) => {
  const dispatch = useDispatch();
  const [done1, setDone] = useState(false);

  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const [menP, setMenP] = useState(0);
  const [menS, setMenS] = useState(0);
  const [womenP, setWomenP] = useState(0);
  const [womenS, setWomenS] = useState(0);
  const plusButtonStyleInv3 = {
    boxShadow: `1px 1px 3px ${addAlpha(user.heartColor, 0.3)}`,
    color: "#3A3C3F",
    background: "rgba(255,255,255,0.1)",
  };
  const countStyleInv3 = {
    boxShadow: `1px 2px 4px ${addAlpha(user.heartColor, 0.3)}`,
    color: "#3A3C3F",
    fontSize: "15px",
    background: addAlpha(user.heartColor, 0.15),
  };
  const minusButtonStyleInv3 = {
    boxShadow: `-1px 1px 3px ${addAlpha("#3A3C3F", 0.2)}`,
    color: "#3A3C3F",
    fontSize: "15px",
    background: "rgba(255,255,255,0.1)",
  };
  const acceptButtonStyleInv3 = {
    borderRadius: "2px",
    boxShadow: `0 1px 3px ${addAlpha(user.heartColor, 0.6)}`,
    color: user.heartColor,
    background: addAlpha(user.heartColor, 0.1),
  };
  const negativeButtonStyleInv3 = {
    borderRadius: "2px",
    color: "#3A3C3F",
    boxShadow: `0 1px 3px ${addAlpha("#3A3C3F", 0.6)}`,
    background: addAlpha("#3A3C3F", 0.1),
  };

  const plusButtonStyle = user.invK === 3 ? plusButtonStyleInv3 : {};
  const countStyle = user.invK === 3 ? countStyleInv3 : {};
  const acceptButtonStyle = user.invK === 3 ? acceptButtonStyleInv3 : {};
  const negativeButtonStyle = user.invK === 3 ? negativeButtonStyleInv3 : {};
  const minusButtonStyle = user.invK === 3 ? minusButtonStyleInv3 : {};
  const [animate, setAnimate] = useState(
    "animate__animated animate__wobble animate__repeat-1 animate__slow"
  );
  useEffect(() => {
    setTimeout(() => {
      setAnimate("animate__animated animate__heartBeat");
    }, 6000);
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleOnSubmitSelf = async (data) => {
    // setNotes(
    //   user.isEnglish
    //     ? `${menP} men + ${womenP} women`
    //     : `${menP} גברים + ${womenP} נשים`
    // );
    onSubmit(
      parseInt(menP) + parseInt(womenP),
      `${menP} גברים + ${womenP} נשים`
    );
  };

  const handleOnSubmit = async (data) => {
    // dispatch(login(body)).then((res) => {
    //   if (res.payload?.error) GetToast(addToast, "error", res.payload.error);
    // });
    let body = {
      accept: parseInt(menP) + parseInt(womenP),
      ans: "M",
      notes: `${menP} גברים + ${womenP} נשים`,
    };
    dispatch(setAccept(body, id)).then((res) => {
      if (!res.payload?.error) {
        GetToast(
          addToast,
          "success",
          user.isEnglish ? "!Success" : "הפעולה הצליחה",
          8000
        );
        setDone(true);
        setTimeout(function () {
          setDone(false);
        }, 8000);
      } else GetToast(addToast, "error", res.payload.error);
    });
  };

  return (
    <>
      <FlexRowBetween
        style={{ width: "100%", marginTop: "20px", flexDirection: "column" }}
      ></FlexRowBetween>

      {user.isEnglish && (
        <div
          style={{
            direction: "ltr",
            width: "90%",
            margin: "0 auto",
            fontSize: "14px",
          }}
        >
          <div>
            We appreciate that some guests will only be able to participate for
            part of the simcha. If you are unable to join us for the whole
            seuda, please submit zero attending.
          </div>
        </div>
      )}
      {self && (
        <div
          style={{
            display: "flex",
            width: "90%",
            marginRight: "5%",
            marginTop: "20px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <HomeInput
            pHcolor={"rgba(56, 52, 61, 0.7)"}
            color={"silver"}
            onChange={(e) => setName(e.target.value)}
            placeholder={user.isEnglish ? "fullname" : "שם מלא"}
            value={name}
            style={{
              backgroundColor: "#f2f2f2",
              margin: "0px",
              width: "55%",
              paddingRight: "10px",
              paddingLeft: "10px",
              direction: user.isEnglish ? "ltr" : "rtl",
            }}
          />
          <HomeInput
            pHcolor={"rgba(56, 52, 61, 0.7)"}
            color={"silver"}
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            placeholder={user.isEnglish ? "phone number" : "מס׳ פלאפון"}
            // placeholder="מס׳ פלאפון"
            name="phone"
            style={{
              backgroundColor: "#f2f2f2",
              margin: "0px",
              width: "40%",
              paddingRight: "10px",
              paddingLeft: "10px",
              direction: user.isEnglish ? "ltr" : "rtl",
            }}
          />
        </div>
      )}
      <div
        style={{
          width: "90%",
          margin: "0 auto",
          textAlign: "center",
          fontSize: "14px",
          direction: user.isEnglish ? "ltr" : "rtl",
        }}
      >
        <FlexRowBetween style={{ marginTop: "20px" }}>
          <div style={{ width: "max-content" }}>
            {user.isEnglish ? "Men attending seuda:" : "מספר גברים:"}
          </div>
          <FlexRowBetween style={{ width: "min-content", marginRight: "10px" }}>
            <img
              onClick={() => {
                if (menP < 50) setMenP(menP + 1);
              }}
              src={plusB}
              style={{ width: "20px" }}
            />
            <div style={{ margin: "0 10px" }}>{menP}</div>
            <img
              onClick={() => {
                if (menP > 0) setMenP(menP - 1);
              }}
              src={minusB}
              style={{ width: "20px" }}
            />
          </FlexRowBetween>
        </FlexRowBetween>

        <FlexRowBetween style={{ marginTop: "5px" }}>
          <div style={{ width: "max-content" }}>
            {user.isEnglish ? "Women attending seuda:" : "מספר נשים:"}
          </div>
          <FlexRowBetween style={{ width: "min-content", marginRight: "10px" }}>
            <img
              onClick={() => {
                if (womenP < 50) setWomenP(womenP + 1);
              }}
              src={plusB}
              style={{ width: "20px" }}
            />
            <div style={{ margin: "0 10px" }}>{womenP}</div>
            <img
              onClick={() => {
                if (womenP > 0) setWomenP(womenP - 1);
              }}
              src={minusB}
              style={{ width: "20px" }}
            />
          </FlexRowBetween>
        </FlexRowBetween>
      </div>

      {/* <FlexRowBetween
        className={animate}
        style={{ justifyContent: "space-evenly", margin: "20px auto" }}
        id={"bla"}
      >
        <GenericButton
          onClick={() => onSubmit(parseInt(4))}
          style={{
            background: user.heartColor,
            color: "white",
            fontSize: "15px",
            padding: "7px 15px",
            ...acceptButtonStyle,
          }}
        >
          <div style={{ filter: user.invK === 3 ? "brightness(75%)" : "none" }}>
            {done ? "שליחה נוספת" : "אגיע ב 16:00"}
          </div>
        </GenericButton>
        <GenericButton
          onClick={() => onSubmit(parseInt(1))}
          style={{
            background: user.heartColor,
            color: "white",
            fontSize: "15px",
            padding: "7px 15px",
            ...acceptButtonStyle,
          }}
        >
          <div style={{ filter: user.invK === 3 ? "brightness(75%)" : "none" }}>
            {done ? "שליחה נוספת" : "אגיע ב 13:00"}
          </div>
        </GenericButton>
      </FlexRowBetween> */}
      <FlexRowBetween style={{ justifyContent: "center", marginTop: "30px" }}>
        <GenericButton
          onClick={self ? handleOnSubmitSelf : handleOnSubmit}
          style={{
            background: user.heartColor,
            color: "white",
            fontSize: "15px",
            padding: "7px 50px",
            direction: "ltr",
            ...acceptButtonStyle,
          }}
        >
          {user.isEnglish ? (
            <>{done1 || done ? "Thank you!" : "Submit"}</>
          ) : (
            <>{done1 || done ? "!תודה" : "שליחה"}</>
          )}
        </GenericButton>
      </FlexRowBetween>
    </>
  );
};

export default Company;
