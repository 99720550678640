import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { cleanAll, getAvivClients } from '../../../store/reducers/userReducer';
import LogOutAll from '../../common/LogOut/LogOutAll';
import { useToasts } from 'react-toast-notifications';
import GenericModal from '../../common/modals/GenericModal';
import { MainDiv, AvivCardsHolder, GenericButton, GenericDiv, InputBox } from '../../common/Style';
import AvivClientCard from './AvivClientCard';
import ACalendar from './calendar/Calendar';
import EditAvivUser from './EditAvivUser';
import OpacityLoading from '../../common/loader/OpacityLoading'
import 'react-calendar/dist/Calendar.css';
import './calendar/style.css'
import { GetToast } from '../../common/modals/Toast';

const Aviv = () => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [selectedEdit, setSelectedEdit] = useState(null);
    const [logout, setLogout] = useState(null);
    const { users, usersError, usersLoading } = useSelector(state => state.userReducer);
    const [showCalendar, setShowCalendar] = useState(null);
    const [date, setDate] = useState(new Date());
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        dispatch(getAvivClients());
        return () => {
            dispatch(cleanAll());
        }
    }, []);

    useEffect(() => {
        if (usersError)
            GetToast(addToast, "error", usersError)
    }, [usersError]);

    let clientsArray = useMemo(() => {
        let dates = {}
        let array = [];
        let date;
        if (users.length > 0) {
            for (let i = 0; i < users.length; i++) {
                if (users[i].groomFN.includes(searchText) || users[i].brideFN.includes(searchText) || users[i].phone.includes(searchText)) {
                    date = new Date(users[i].date)
                    date = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
                    array.push(<AvivClientCard key={i + 1} edit={setSelectedEdit} index={i + 1} data={users[i]} />)
                    if (date in dates) {
                        users[i].eventType ? dates[date] = (dates[date] + " + " + users[i].brideFN + " ו" + users[i].groomFN + ", " + users[i].hall)
                            : dates[date] = (dates[date] + " + " + users[i].calendarName + ", " + users[i].hall)
                    }
                    else {
                        users[i].eventType ? dates[date] = users[i].brideFN + " ו" + users[i].groomFN + ", " + users[i].hall
                            : dates[date] = users[i].calendarName + ", " + users[i].hall
                    }

                }
            }
        }
        return [array, dates];
    }, [users, searchText])
    return (<MainDiv className="animate__animated animate__fadeIn">
        <div style={{
            width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: `10px 18px`, alignItems: "center"
            , paddingBottom: `10px`
            , background: "radial-gradient(circle, silver 0%, #f2f2f2 100%)"
        }}>
            <GenericButton background={"#3A3C3F"} style={{ height: "max-content" }}
                onClick={() => setLogout(true)}>התנתקות</GenericButton>

            <InputBox labelColor={"gray"} color={"#3A3C3F"}>
                <div className='relativeBox'>
                    <input type="text" required={true} minLength={0} maxLength={50} onChange={(e) => setSearchText(e.target.value)} />
                    <label>חיפוש</label>
                </div>
            </InputBox>

            <GenericDiv hoverBackground={"rgb(223, 223, 223)"} background={"#3A3C3F"} style={{ width: "max-content", fontSize: "16px", padding: "6px 11px" }} onClick={() => setShowCalendar(true)}>יומן</GenericDiv>
        </div>

        <AvivCardsHolder>
            {clientsArray[0]}
        </AvivCardsHolder>


        {
            selectedEdit && <GenericModal
                init={setSelectedEdit}
                div={<EditAvivUser data={selectedEdit} init={setSelectedEdit} />}
            />
        }

        {
            showCalendar && <GenericModal
                init={setShowCalendar}
                div={<ACalendar dates={clientsArray[1]} setDate={setDate} date={date} init={setShowCalendar} />}
            />
        }

        {logout && <GenericModal
            init={setLogout}
            div={<LogOutAll init={setLogout} />}
        />}

        {usersLoading && <OpacityLoading />}
    </MainDiv>)
}

export default Aviv;