import React, { useMemo, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import OpacityLoading from "../../common/loader/OpacityLoading";
import GenericModal from "../../common/modals/GenericModal";
import {
  editGuestAdmin,
  getGuestAdmin,
} from "../../../store/reducers/guestsReducer";
import {
  TodoCard,
  TodosHolder,
  MainDiv,
  GenericButton,
  RInput,
  GenericDiv,
  PlaceHolderImage,
} from "../../common/Style";
import { GetToast } from "../../common/modals/Toast";
import { Link } from "react-router-dom";
import ClientRoutes from "../../../navigation/Routes";
import Switcher from "../../common/switcher/Switcher";
import EditGuestAdmin from "./EditGuestAdmin";
import { useForm } from "react-hook-form";
import clear from "../../../assets/client/clear.svg";

const AdminGuests = () => {
  const { register, handleSubmit } = useForm();
  const [editTodoS, setEditTodo] = useState(false);
  const dispatch = useDispatch();
  const { guests, guestsError, guestsLoading } = useSelector(
    (state) => state.guestsReducer
  );
  const { addToast } = useToasts();
  const inputRef = useRef();
  const [searchText, setSearchText] = useState("");

  const [value, setValue] = useState("");
  const [search, setSearch] = useState(true);

  const quickAns = async (data) => {
    let accept = parseInt(data.accept) >= 0 ? parseInt(data.accept) : null;
    let body = {
      sent: parseInt(data.sent),
      accept,
      ans: "W",
      notes: data.notes,
    };
    // console.log(body);
    // console.log(data._id);
    dispatch(editGuestAdmin(body, data._id)).then((res) => {
      if (!res.payload?.error) GetToast(addToast, "success", "success!!");
    });
    // props.init(false)
  };

  const onSubmit = async (data) => {
    dispatch(
      getGuestAdmin({ search: search ? "phone" : "name", value: searchText })
    );
  };

  const searchGA = () => {
    dispatch(getGuestAdmin({ search: search ? "phone" : "name", value }));
  };

  useEffect(() => {
    if (guestsError) GetToast(addToast, "error", guestsError);
  }, [guestsError]);

  let todosArray = useMemo(() => {
    let arr = [];
    if (Object.keys(guests).length > 0) {
      for (const [key, value] of Object.entries(guests))
        arr.push(
          <TodoCard
            key={key}
            onClick={() =>
              setEditTodo({ _id: key, sent: value.sent, accept: value.accept })
            }
          >
            <div className="info">
              <div>{value.name}</div>
              <GenericDiv
                background={"inherit"}
                style={{ border: "2px solid silver", color: "black" }}
                onClick={(e) => {
                  e.stopPropagation();
                  quickAns({
                    accept: 1,
                    sent: value.sent,
                    notes: "",
                    _id: key,
                  });
                }}
              >
                1
              </GenericDiv>
              <GenericDiv
                background={"inherit"}
                style={{ border: "2px solid silver", color: "black" }}
                onClick={(e) => {
                  e.stopPropagation();
                  quickAns({
                    accept: 2,
                    sent: value.sent,
                    notes: "",
                    _id: key,
                  });
                }}
              >
                2
              </GenericDiv>
              <GenericDiv
                background={"inherit"}
                style={{ border: "2px solid silver", color: "black" }}
                onClick={(e) => {
                  e.stopPropagation();
                  quickAns({
                    accept: 3,
                    sent: value.sent,
                    notes: "",
                    _id: key,
                  });
                }}
              >
                3
              </GenericDiv>
              {/* <GenericDiv
                background={"inherit"}
                style={{ border: "2px solid silver", color: "black" }}
                onClick={(e) => {
                  e.stopPropagation();
                  quickAns({
                    accept: 4,
                    sent: value.sent,
                    notes: "",
                    _id: key,
                  });
                }}
              >
                4
              </GenericDiv> */}
              <div>{value.uid}</div>
            </div>
            <div className="info" style={{ marginTop: "20px" }}>
              <GenericDiv
                background={"inherit"}
                style={{ border: "2px solid red", color: "black" }}
                onClick={(e) => {
                  e.stopPropagation();
                  quickAns({
                    accept: 0,
                    sent: value.sent,
                    notes: "לא מגיעים כרגע, יעדכנו במידה ויהיה שינוי",
                    _id: key,
                  });
                }}
              >
                0 + יעדכן
              </GenericDiv>
              <GenericDiv
                background={"inherit"}
                style={{ border: "2px solid red", color: "black" }}
                onClick={(e) => {
                  e.stopPropagation();
                  quickAns({
                    accept: 0,
                    sent: value.sent,
                    notes: "",
                    _id: key,
                  });
                }}
              >
                0
              </GenericDiv>
              <GenericDiv
                background={"inherit"}
                style={{ border: "2px solid red", color: "black" }}
                onClick={(e) => {
                  e.stopPropagation();
                  quickAns({
                    accept: null,
                    sent: value.sent,
                    notes:
                      "לא יודעים כרגע, לא להתקשר יותר - יעדכנו בהודעה במידה ויהיה שינוי",
                    _id: key,
                  });
                }}
              >
                לא להתקשר - יעדכנו
              </GenericDiv>
            </div>
          </TodoCard>
        );
    }
    return arr;
  }, [guests]);

  return (
    <MainDiv
      className="animate__animated animate__fadeIn"
      style={{
        overflow: "hidden",
        maxHeight: "100vh",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: `0 10px`,
          margin: "20px auto",
        }}
      >
        <GenericButton
          background={"#fda14b"}
          as={Link}
          to={ClientRoutes.Admin.main}
          style={{ fontSize: "14px" }}
        >
          ראשי
        </GenericButton>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="form1"
          style={{ position: "relative" }}
        >
          <PlaceHolderImage
            onClick={() => {
              setSearchText("");
              inputRef.current.focus();
            }}
            fontSize={`32px`}
            top={`50%`}
            src={clear}
            alt="clear"
            // style={{ cursor: "pointer", left:"7px" }}
            style={{
              cursor: "pointer",
              border: "1px solid silver",
              padding: "5px",
              borderRadius: "2px",
              left: "7px",
            }}
          />
          <RInput
            value={searchText}
            ref={inputRef}
            type="text"
            name="value"
            style={{ width: "150px" }}
            // let tempPhone = phone.replaceAll(/[^\d]/g, "");
            onChange={(e) => {
              if (search)
                setSearchText(e.target.value.replaceAll(/[^\d]/g, ""));
              else setSearchText(e.target.value);
            }}
          />
        </form>
        <Switcher checked={search} onChange={setSearch} color={"#fda14b"} />
        <GenericButton
          hoverBackground={"rgb(223, 223, 223)"}
          background={"#fda14b"}
          type="submit"
          form="form1"
          style={{ padding: "5px 10px", fontSize: "14px" }}
        >
          ייבא
        </GenericButton>
      </div>

      {/* <div style={{ marginTop: "100px" }}>
                <DropDownG label={"מיון"} data={sortOptions} value={sortBy} onChange={setSortBy} />
            </div> */}

      <TodosHolder style={{ marginTop: "0px" }}>{todosArray}</TodosHolder>

      {editTodoS && (
        <GenericModal
          init={setEditTodo}
          div={<EditGuestAdmin data={editTodoS} init={setEditTodo} />}
        />
      )}

      {guestsLoading && <OpacityLoading />}
    </MainDiv>
  );
};

export default AdminGuests;
