import React from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import exitModalLogo from '../../../assets/common/cancel1.svg';
import { GetToast } from '../../common/modals/Toast';
import { CenteredForm, PlaceHolderImage, GenericButton, InputBox, FlexRowBetween, RTextArea } from '../../common/Style';
import { editGuestAdmin } from '../../../store/reducers/guestsReducer';

const EditGuestAdmin = (props) => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { register, handleSubmit } = useForm();

    const onSubmit = async data => {
        let accept = parseInt(data.accept) >= 0 ? parseInt(data.accept) : null;
        let body = { sent: parseInt(data.sent), accept, ans: "W", notes: data.notes }
        dispatch(editGuestAdmin(body, props.data._id)).then((res) => {
            if (!res.payload?.error)
                GetToast(addToast, "success", "success!!")
        })
        props.init(false)
    }
    return (<CenteredForm onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit(onSubmit)}>
        <PlaceHolderImage src={exitModalLogo} alt="exitModalLogo" style={{ top: "8px", left: "8px", transform: "translate(0,0)", width: "25px", cursor: "pointer" }} onClick={() => props.init(null)} />
        <div style={{ textAlign: "center", fontWeight: "bold", padding: "20px", fontSize: "22px" }}>עריכת אורח</div>
        <InputBox labelColor={"gray"} color={"#3A3C3F"} borderBottomColor={"#fda14b"}>
            <FlexRowBetween style={{ width: "70%", margin: "0 auto" }}>

                <div style={{ position: "relative", width: "20%" }}>
                    <input defaultValue={props.data.accept} type="number" name="accept" min={0} max={30} ref={register()} />
                    <label>אישרו</label>
                </div>
                <div style={{ position: "relative", width: "20%" }}>
                    <input defaultValue={props.data.sent} type="number" name="sent" min={0} max={5} ref={register()} />
                    <label>הודעה</label>
                </div>
            </FlexRowBetween>
            <div style={{ position: "relative", width: "80%", margin: "20px auto" }}>
                <RTextArea defaultValue={props.data.notes} placeholder="הערות" name="notes" type="text" rows="2"
                    minLength={0} maxLength={100} ref={register()} style={{ margin: "0 auto" }} />
            </div>
            <GenericButton hoverBackground={"rgb(223, 223, 223)"} background={"#fda14b"} type="submit" style={{ width: "70%", margin: "30px auto" }}>שמירה</GenericButton>

        </InputBox>
    </CenteredForm >)
}

export default EditGuestAdmin;