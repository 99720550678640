import React, { useState, useEffect } from "react";
import { HomeInput, HomeTextArea, InputBox, InvDiv } from "../Style";
import { useToasts } from "react-toast-notifications";
import { GetToast } from "../modals/Toast";
import { AddGuestHimself } from "../../../store/reducers/guestsReducer";
import { useDispatch, useSelector } from "react-redux";
import OpacityLoading from "../loader/OpacityLoading";
import GenericModal from "../modals/GenericModal";
import TransportationConfirmationSelf from "./TransportationConfirmationSelf";
import Names from "./parts/Names";
import pic222 from "../../../assets/invitation/pic222.svg";
import pic111 from "../../../assets/invitation/pic111.svg";
import Extra from "./parts/Extra";
import Footer from "./parts/Footer";
import Links from "./parts/Links";
import GenericArivalConfirmation from "./parts/GenericArivalConfirmation";
import Output from "../../user/image/Output";
import SpecialDishes from "./parts/SpecialDishes";
import Company from "./parts/Company";

// import $ from 'jquery';
const SelfInvitation = (props) => {
  const { user } = useSelector((state) => state.authReducer);
  const borderedInvsStyle = {
    width: "100%",
    backgroundColor: "rgba(255,255,255,0.7)",
    boxShadow: "0 1px 2px silver",
    padding: "5px",
    boxShadow: "0 2px 4px silver",
    borderRadius: "3px",
    paddingBottom: "20px",
  };

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  useEffect(() => {
    setTimeout(function () {
      handleClickScroll("bla");
    }, 2000);
  }, []);
  const basicDivStyle = user.invK === 1 ? { width: "100%" } : borderedInvsStyle;
  const [transportation, setTransportation] = useState(false);
  const [count, setCount] = useState(
    parseInt(user?.defaultSelfSum !== null ? user.defaultSelfSum : 1)
  );
  // const [animateR, setAnimateR] = useState(
  //   "animate__animated animate__backInRight animate__delay-2s"
  // );
  // const [animateL, setAnimateL] = useState(
  //   "animate__animated animate__backInLeft animate__delay-2s"
  // );

  const { guestsLoading } = useSelector((state) => state.guestsReducer);
  const [done, setDone] = useState(false);
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");
  const [tiv, setTiv] = useState(0);
  const [tsim, setTsim] = useState(0);
  const [glt, setGlt] = useState(0);
  const [glat, setGlat] = useState(0);

  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const onSubmit = async (data, ggg = null) => {
    const regex = /^[0]{0,1}[5]{1}[0-9]{8}$/g;
    let tempPhone = phone.replaceAll(/[^\d]/g, "");
    const validatePhone = tempPhone.match(regex);
    const validateName = name.length >= 2 && name.length <= 50;
    if (validateName && validatePhone !== null) {
      let body = {
        name,
        phone: tempPhone,
        ans: "S",
        closeness: "הפצה עצמית",
        sum: count === 0 ? 1 : count,
        accept: data,
        uid: props.uid,
        dishes: [glt, tsim, tiv, glat],
      };
      console.log("notes:");
      console.log(notes);
      if (notes !== "") body["notes"] = notes;
      if (ggg !== null) body["notes"] = ggg;
      dispatch(AddGuestHimself(body)).then((res) => {
        if (!res.payload?.error) {
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
          setDone(true);
          setTimeout(function () {
            setDone(false);
          }, 8000);
        } else GetToast(addToast, "error", res.payload.error);
      });
    } else
      GetToast(
        addToast,
        "warning",
        "שם בין 2 ל 50 תווים, מספר טלפון ללא סימנים ורווחים, נא להקליד ידנית ספרה ספרה",
        8000
      );
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setAnimateR(
  //       "animate__animated animate__wobble animate__repeat-1 animate__slow"
  //     );
  //     setAnimateL(
  //       "animate__animated animate__wobble animate__repeat-1 animate__slow"
  //     );
  //   }, 6000);
  // }, []);

  return (
    <InvDiv
      gradient={user.gradient}
      style={{ padding: user.invK === 1 ? "0" : "15px" }}
    >
      <div style={{ ...basicDivStyle }}>
        <Output />
        {user.inv === 1 && <Names data={user} />}

        {/* <form
          id="form1"
          style={{ width: "80%", margin: "0 auto" }}
          onSubmit={handleSubmit(onSubmit)}
        > */}
        {/* <InputBox
          labelColor={"gray"}
          color={"#3A3C3F"}
          style={{ height: "min-content", marginTop: "5px" }}
        > */}
        {/* <div
          style={{
            display: "flex",
            width: "90%",
            marginRight: "5%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <HomeInput
            pHcolor={"rgba(56, 52, 61, 0.7)"}
            color={"silver"}
            onChange={(e) => setName(e.target.value)}
            placeholder="שם מלא"
            value={name}
            style={{
              backgroundColor: "#f2f2f2",
              margin: "0px",
              width: "55%",
              paddingRight: "10px",
            }}
          />
          <HomeInput
            pHcolor={"rgba(56, 52, 61, 0.7)"}
            color={"silver"}
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            placeholder="מס׳ פלאפון"
            name="phone"
            style={{
              backgroundColor: "#f2f2f2",
              margin: "0px",
              width: "40%",
              paddingRight: "10px",
            }}
          />
              </div> */}
        {/* <div
              className={`relativeBox ${animateR}`}
              style={{ margin: "0px", width: "55%" }}
            >
              <input
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
              />
              <label>שם מלא</label>
            </div>
            <div
              className={`relativeBox ${animateL}`}
              style={{ margin: "0px", width: "40%" }}
            >
              <input
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                type="text"
              />
              <label>מס' פלאפון</label>
            </div> */}
        {/* </InputBox> */}
        {/* </form> */}
        {user.isCompany ? (
          <Company
            count={count}
            setCount={setCount}
            onSubmit={onSubmit}
            done={done}
            id={props.id}
            self={true}
            phone={phone}
            setPhone={setPhone}
            name={name}
            setName={setName}
            setNotes={setNotes}
          />
        ) : (
          <GenericArivalConfirmation
            self={true}
            phone={phone}
            setPhone={setPhone}
            name={name}
            setName={setName}
            count={count}
            setCount={setCount}
            onSubmit={onSubmit}
            done={done}
          />
        )}

        <Links setTransportation={setTransportation} data={user} />
        {user.inv === 1 && <Extra data={user} />}
        {user.specialDishes && (
          <SpecialDishes
            showSubmit={false}
            setTiv={setTiv}
            setTsim={setTsim}
            setGlt={setGlt}
            setGlat={setGlat}
            tiv={tiv}
            tsim={tsim}
            glt={glt}
            glat={glat}
            handleSetDishes={() => {}}
          />
        )}
        {user.sharePics && (
          <a
            target={"_blank"}
            rel="noopener noreferrer"
            href={user.sharePicsLink}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              color: "#3A3C3F",
              textDecoration: "none",
              fontSize: "14px",
              marginTop: "20px",
            }}
          >
            <img alt="wazeIcon" src={pic222} style={{ width: "25px" }} />
            <div>{user.sharePicsPlaceholder}</div>
            <img alt="wazeIcon" src={pic111} style={{ width: "25px" }} />
          </a>
        )}
        {user.blessing && (
          <div style={{ width: "90%", margin: "0px auto", marginTop: "20px" }}>
            <HomeTextArea
              type="text"
              rows="3"
              placeholder={user.blessingPlaceholder}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
        )}
      </div>

      <Footer producer={user.producer} />
      {guestsLoading && <OpacityLoading />}

      {transportation && (
        <GenericModal
          init={setTransportation}
          div={
            <TransportationConfirmationSelf
              uid={props.uid}
              setDone={setDone}
              color={user.heartColor}
              init={setTransportation}
              details={user.transportationDetails}
              setCount={setCount}
              count={count}
            />
          }
        />
      )}
    </InvDiv>
  );
};
export default SelfInvitation;
