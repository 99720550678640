import React, { useEffect } from 'react';
import { DisplayNoneComputer } from '../../common/Style';
import { HashLink } from 'react-router-hash-link';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PersonIcon from '@material-ui/icons/Person';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import HomeIcon from '@material-ui/icons/Home';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import ClientRoutes from '../../../navigation/Routes';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: "linear-gradient(to bottom, #f2f2f2, #f2f2f2, #f2f2f2, #f2f2f2fa, #f2f2f2fa, #f2f2f2de)",

    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    },
    title: {
        flexGrow: 1,
        fontFamily: "logo",
        letterSpacing: "1px",
        color: "#3A3C3F"
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const BurgerMenu = props => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return <DisplayNoneComputer>

        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar style={{ display: "flex", flexDirection: "row", direction: "ltr", width: "100%", justifyContent: "space-between" }}>
                    <Typography variant="h6" noWrap className={classes.title}>
                        Be Simple
                    </Typography>
                    <IconButton
                        // color="inherit"
                        color="#3A3C3F"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        className={clsx(open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
            </main>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {/* {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
                        <ChevronRightIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <HashLink style={{ color: "#3A3C3F", textDecoration: "none" }} smooth to={"/itay#home"}
                        scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        <ListItem button style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", direction: "rtl" }} >
                            <ListItemText primary={"ראשי"} style={{ display: "flex" }} />
                            <ListItemIcon style={{ display: "flow-root" }}><HomeIcon /></ListItemIcon>
                        </ListItem>
                    </HashLink>
                    <HashLink style={{ color: "#3A3C3F", textDecoration: "none" }} smooth to={"/itay#services"}
                        scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        <ListItem button style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", direction: "rtl" }} >
                            <ListItemText primary={"שירותים וחבילות"} style={{ display: "flex" }} />
                            <ListItemIcon style={{ display: "flow-root" }}><MenuBookIcon /></ListItemIcon>
                        </ListItem>
                    </HashLink>
                    <HashLink style={{ color: "#3A3C3F", textDecoration: "none" }} smooth to={"/itay#quesAndAns"}
                        scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        <ListItem button style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", direction: "rtl" }} >
                            <ListItemText primary={"שאלות ותשובות"} style={{ display: "flex" }} />
                            <ListItemIcon style={{ display: "flow-root" }}><QuestionAnswerIcon /></ListItemIcon>
                        </ListItem>
                    </HashLink>
                    <HashLink style={{ color: "#3A3C3F", textDecoration: "none" }} smooth to={"/itay#contactUs"}
                        scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        <ListItem button style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", direction: "rtl" }} >
                            <ListItemText primary={"יצירת קשר"} style={{ display: "flex" }} />
                            <ListItemIcon style={{ display: "flow-root" }}><PermContactCalendarIcon /></ListItemIcon>
                        </ListItem>
                    </HashLink>
                    <Link to={ClientRoutes.UnAuth.login} style={{ color: "#3A3C3F", textDecoration: "none" }}>
                        <ListItem button style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", direction: "rtl" }} >
                            <ListItemText primary={"כניסה / הרשמה"} style={{ display: "flex" }} />
                            <ListItemIcon style={{ display: "flow-root" }}><PersonIcon /></ListItemIcon>
                        </ListItem>
                    </Link>
                </List>
            </Drawer>
        </div>

    </DisplayNoneComputer>
}

export default BurgerMenu;