import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutSpecific } from '../../../store/reducers/authReducer'
// import { useHistory } from "react-router-dom";

import { CenteredForm, FlexRowBetween, GenericButton, MyHr } from '../Style'

const LogOutAll = (props) => {
    // let history = useHistory();
    const { user } = useSelector(state => state.authReducer);
    const dispatch = useDispatch();
    const handleClose = () => { props.init(null); };
    const logout = () => {
        handleClose()
        dispatch(logoutSpecific())
        // history.push('aviv')
    }

    return (
        <CenteredForm onClick={(e) => e.stopPropagation()}>
            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "space-around" }}>
                <div style={{ width: "100%", marginTop: "30px" }}>
                    <div style={{ textAlign: "center", fontSize: "1.2rem", marginBottom: "10px" }}>{"התנתקות"}</div>
                    <MyHr />
                </div>
                <div style={{ width: "100%", marginTop: "30px" }}>
                    <div style={{ textAlign: "center", marginBottom: "10px" }}>{"האם את/ה בטוח/ה שברצונך להתנתק?"}</div>
                    <MyHr />
                </div>
                <div style={{ width: "100%" }}>
                    <FlexRowBetween style={{ width: "90%", margin: "20px auto" }}>
                        <GenericButton style={{ backgroundColor: user.color ? user.color : "#DEDEE0", padding: "9px 25px" }} onClick={logout}>כן</GenericButton>
                        <GenericButton style={{ backgroundColor: "rgb(223, 223, 223)", color: "#3A3C3F", padding: "9px 25px" }} onClick={handleClose}>לא</GenericButton>
                    </FlexRowBetween>
                </div>
            </div>
        </CenteredForm>
    )
}

export default LogOutAll;
