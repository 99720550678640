import React, { useMemo, useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import OpacityLoading from "../../common/loader/OpacityLoading";
import GenericModal from "../../common/modals/GenericModal";
import plus from "../../../assets/client/plus.svg";
import direction from "../../../assets/client/direction.svg";
import { getAll, cleanAll } from "../../../store/reducers/paymentsReducer";
import YesNoModal from "../../common/modals/YesNoModal";
import {
  FlexRowBetween,
  TodoCard,
  TodosHolder,
  MainDiv,
  RespFont,
} from "../../common/Style";
import { GetToast } from "../../common/modals/Toast";
import { slide as Menu } from "react-burger-menu";
import ClientRoutes from "../../../navigation/Routes";
import { useHistory } from "react-router-dom";
import LogOutAll from "../../common/LogOut/LogOutAll";
import "../style.css";
import Dropdown from "react-bootstrap/Dropdown";
import expenses from "../../../assets/client/expenses.svg";
import brain from "../../../assets/client/brain.svg";
import logout1 from "../../../assets/client/logout.svg";
import editInv from "../../../assets/client/editInv.svg";
import tables from "../../../assets/client/editTables.svg";
import video from "../../../assets/client/video2.svg";
import guests1 from "../../../assets/client/guests.svg";
import { MenuCss } from "../../common/modals/Toast";
import todoList from "../../../assets/client/todoList.svg";

const Payments = () => {
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [addPayment, setAddPayment] = useState(false);
  const [moreDetails, setMoreDetails] = useState(false);
  const [logout, setLogout] = useState(false);
  const dispatch = useDispatch();
  const { payments, paymentsError, paymentsLoading } = useSelector(
    (state) => state.paymentsReducer
  );
  const { user } = useSelector((state) => state.authReducer);
  const { addToast } = useToasts();

  const getPayments = () => {
    dispatch(getAll());
  };

  useEffect(() => {
    getPayments();
    return () => {
      dispatch(cleanAll());
    };
  }, []);

  useEffect(() => {
    if (paymentsError) GetToast(addToast, "error", paymentsError);
  }, [paymentsError]);

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  let paymentsArray = useMemo(() => {
    let totalGifts = 0;
    let totalFees = 0;
    let arr = [];
    let sortedArray = [];
    if (Object.keys(payments).length > 0) {
      for (const [key, value] of Object.entries(payments))
        sortedArray.push(value);

      switch (sortBy) {
        case "dateAdded":
          if (sortDirection)
            sortedArray.sort(
              (a, b) => new Date(b[sortBy]) - new Date(a[sortBy])
            );
          else
            sortedArray.sort(
              (a, b) => new Date(a[sortBy]) - new Date(b[sortBy])
            );
          break;
        case "fullname":
          if (sortDirection)
            sortedArray.sort((a, b) => b[sortBy].localeCompare(a[sortBy]));
          else sortedArray.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
          break;
        default:
          if (sortDirection) sortedArray.sort((a, b) => b[sortBy] - a[sortBy]);
          else sortedArray.sort((a, b) => a[sortBy] - b[sortBy]);
      }

      for (let i = 0; i < sortedArray.length; i++) {
        totalFees += sortedArray[i].fee;
        totalGifts += sortedArray[i].gift;
        arr.push(
          <TodoCard
            color={user.color}
            key={i}
            onClick={() => setMoreDetails(!moreDetails)}
          >
            <RespFont className="info">
              <div>{sortedArray[i].fullname}</div>
              <div>{sortedArray[i].gift}₪</div>
              {moreDetails && (
                <>
                  <RespFont cSize={"14px"} pSize={"12px"}>
                    {sortedArray[i].fee}₪
                  </RespFont>
                  <div>
                    <div>{sortedArray[i].phone}</div>
                    <RespFont cSize={"14px"} pSize={"12px"}>
                      {sortedArray[i].dateAdded
                        .replace(/T.*/, "")
                        .split("-")
                        .reverse()
                        .join("/")}
                    </RespFont>
                  </div>
                </>
              )}
            </RespFont>
          </TodoCard>
        );
      }
    }
    return [arr, totalFees, totalGifts];
  }, [payments, sortBy, sortDirection, moreDetails]);

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  return (
    <MainDiv
      onClick={() => setIsOpen(false)}
      className="animate__animated animate__fadeIn"
      style={{
        overflow: "hidden",
        background: `radial-gradient(circle, ${addAlpha(
          user.color,
          0.25
        )} 0%, #f2f2f2 100%)`,
        maxHeight: "100vh",
      }}
    >
      <Menu
        noOverlay
        isOpen={isOpen}
        onStateChange={handleStateChange}
        right
        width={"max-content"}
        styles={MenuCss(user.color)}
      >
        <div
          className="menu-item"
          style={{ marginTop: "0px" }}
          onClick={() => history.push(ClientRoutes.User.guests)}
        >
          <div>מוזמנים</div>
          <img src={guests1} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.tables)}
        >
          <div>מערך שולחנות</div>
          <img src={tables} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.instructions)}
        >
          <div>הדרכות</div>
          <img src={video} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.editDetails)}
        >
          <div>עריכת הזמנה</div>
          <img src={editInv} style={{ width: "23px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.todos)}
        >
          <div>יומן משימות</div>
          <img src={todoList} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.expenses)}
        >
          <div>יומן הוצאות</div>
          <img src={expenses} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.print)}
        >
          <div>מוח המערכת</div>
          <img src={brain} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          style={{
            position: "absolute",
            bottom: "20px",
            fontWeight: "bold",
            fontSize: "20px",
          }}
          onClick={() => setLogout(true)}
        >
          <div>התנתקות</div>
          <img src={logout1} style={{ width: "25px" }} />
        </div>

        {/* <div className="menu-item" style={{ position: "absolute", right: "30px", bottom: "20px", fontWeight: "bold", fontSize: "23px" }}
                    onClick={() => setLogout(true)}>התנתקות</div> */}
      </Menu>

      <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip>{"מיון"}</Tooltip>}
      >
        <Dropdown
          style={{
            position: "absolute",
            left: "17px",
            top: "18px",
            zIndex: 9,
          }}
        >
          <Dropdown.Toggle
            style={{
              background: user.color,
              border: "none",
              height: "32px",
              width: "30px",
              borderRadius: "20%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            id="dropdown-basic"
          ></Dropdown.Toggle>
          <Dropdown.Menu style={{ textAlign: "right" }}>
            <Dropdown.Item onClick={() => setSortBy("fullname")}>
              שם
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSortBy("gift")}>
              סכום
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSortBy("dateAdded")}>
              תאריך הוספה
            </Dropdown.Item>
            {/* <Dropdown.Item onClick={() => setSortBy("executionDate")}>תאריך לביצוע</Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </OverlayTrigger>

      <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip>{"כיוון"}</Tooltip>}
      >
        <div
          onClick={() => setSortDirection(!sortDirection)}
          style={{
            position: "absolute",
            left: "60px",
            top: "18px",
            zIndex: 9,
            background: user.color,
            padding: "4px 4px",
            borderRadius: "20%",
            cursor: "pointer",
          }}
        >
          <img
            src={direction}
            alt="direction"
            style={{ width: "20px", paddingBottom: "1.8px" }}
          />
        </div>
      </OverlayTrigger>

      <FlexRowBetween
        style={{
          width: "100%",
          padding: "20px 5%",
          alignItems: "center",
          position: "fixed",
          zIndex: 1,
          justifyContent: "center",
          background: `#f2f2f2`,
          paddingRight: "5%",
          boxShadow: "0 2px 20px silver",
          paddingBottom: "15px",
        }}
      >
        <div
          style={{
            fontSize: "22px",
            fontWeight: "bold",
            textAlign: "center",
            color: "black",
          }}
        >
          העברות באשראי
        </div>
      </FlexRowBetween>
      <div
        style={{
          width: "100%",
          marginTop: "80px",
          textAlign: "center",
          paddingBottom: "20px",
        }}
      >
        <TodoCard color={user.color} today={true}>
          <RespFont className="info">
            <div>{"סה״כ מתנות: " + paymentsArray[2]}₪</div>
            <div>{"סה״כ עמלות: " + paymentsArray[1].toFixed(2)}₪</div>
          </RespFont>
        </TodoCard>
      </div>
      <TodosHolder>{paymentsArray[0]}</TodosHolder>

      {logout && (
        <GenericModal init={setLogout} div={<LogOutAll init={setLogout} />} />
      )}

      {paymentsLoading && <OpacityLoading />}
    </MainDiv>
  );
};

export default Payments;
