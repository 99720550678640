import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GuestRowStyle,
  BaseInput,
  FlexRowBetween,
  RespFont,
} from "../common/Style";
import { useToasts } from "react-toast-notifications";
import {
  setGuestsTable,
  setGuestGift,
} from "../../store/reducers/guestsReducer";
import save from "../../assets/client/save1.svg";
import callLogo from "../../assets/client/call1Red.svg";
import ix from "../../assets/client/ix.svg";
import vi from "../../assets/client/vi.svg";
import person from "../../assets/client/person2.svg";
import table1 from "../../assets/client/table2.svg";
import present from "../../assets/client/present1.svg";
import noPhone from "../../assets/client/noPhone.svg";
import sms from "../../assets/client/sms1.svg";
import cancel from "../../assets/client/cancel1.svg";
import { GetToast } from "../common/modals/Toast";
import { svgs } from "../common/coloredSvgs/svgs";
import GenericSvg from "../common/coloredSvgs/GenericSvg";

const GuestRowNew = (props) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(props.checked);
  const [showEditTable, setShowEditTable] = useState(false);
  const [table, setTable] = useState();
  const { user } = useSelector((state) => state.authReducer);
  useEffect(() => {
    setChecked(false);
  }, [props.multiSelect]);

  const updateGuestT = async (items, table1) => {
    dispatch(setGuestsTable(items, table1)).then(
      (data) => {
        GetToast(addToast, "success", "הפעולה הצליחה");
        setTable();
        setShowEditTable(false);
      },
      (error) => GetToast(addToast, "error", error)
    );
  };

  const updateGuestG = async (item, gift) => {
    dispatch(setGuestGift(item, gift)).then(
      (data) => {
        GetToast(addToast, "success", "הפעולה הצליחה");
        setTable();
        setShowEditTable(false);
      },
      (error) => GetToast(addToast, "error", error)
    );
  };

  const setGuestsTable1 = async (items, table) => {
    if (user.array[table - 1] !== undefined)
      await updateGuestT(items, parseInt(table));
    else if (parseInt(table) === 0) await updateGuestT(items, null);
    else alert("שולחן לא תקין או לא קיים");
  };

  const setGuestGift1 = async (item, gift) => {
    if (parseInt(gift) >= 1 && parseInt(gift) <= 99999)
      await updateGuestG(item, gift);
    else if (parseInt(gift) === 0) await updateGuestG(item, null);
    else alert("מתנה יכולה להיות מספר בין 1 ל 9999");
  };

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }
  const add_del = () => {
    props.add_del({
      _id: props.data._id,
      closeness: props.data.closeness,
      amount:
        props.data.accept >= 0 && props.data.accept !== null
          ? props.data.accept
          : props.data.sum,
    });
  };

  const handleClick = (e) => {
    if (e.type === "click") {
      if (props.multiSelect) add_del();
      else props.setEdit(props.data._id);
    } else if (e.type === "contextmenu") {
      console.log("Right click");
    }
  };

  return (
    <GuestRowStyle
      background={props.checked ? addAlpha(user.color, 0.4) : null}
    >
      {/* {(props.data.sent === 0) &&
                <img onClick={() => {
                    if (props.multiSelect) {
                        add_del();
                    }
                    else
                        props.setEdit(props.data._id)
                }} src={sms} alt="sms" style={{ position: "absolute", top: "2px", right: "5px", width: "20px", cursor: "pointer" }} />} */}

      <FlexRowBetween
        onClick={handleClick}
        onContextMenu={handleClick}
        //  onClick={() => {
        //     if (props.multiSelect) {
        //         add_del();
        //     }
        //     else
        //         props.setEdit(props.data._id)
        // }}
        style={{
          padding: `10px 7px`,
          width: "100%",
          cursor: "pointer",
          textAlign: "center",
        }}
      >
        {!props.editTablesMode && !props.giftMode && (
          <div
            style={{
              width: "7px",
              boxShadow: "0 1px 2px silver",
              height: "23px",
              borderRadius: "2px",
              backgroundColor: props.background,
              position: "absolute",
              left: "7px",
            }}
          />
        )}
        <RespFont
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {props.data.name}
        </RespFont>
        {!props.editTablesMode && !props.giftMode && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              filter: "brightness(95%)",
            }}
          >
            {(props.data.phone === "" || props.data.phone === null) && (
              <img
                src={noPhone}
                alt="noPhone"
                style={{ marginLeft: "3px", width: "18px" }}
              />
            )}
            {props.callMode && props.data?.notes?.includes("כרגע,") && (
              <img
                src={callLogo}
                alt="callLogo"
                style={{ marginLeft: "3px", width: "18px" }}
              />
            )}
            {/* wasInvitedMode */}
            {props.wasInvitedMode && (
              <img
                src={props.data.wasInvited ? vi : ix}
                style={{
                  width: props.data.wasInvited ? `22px` : "13px",
                  marginLeft: "3px",
                }}
              />
            )}
            <img src={person} style={{ width: `16px`, marginLeft: "3px" }} />
            {/* <GenericSvg fill={props.background !== "#f2f2f2" ? props.background : "grey"} size={"18px"} svg={svgs.person} viewBox={"0 0 1200 1200"} /> */}
            <RespFont style={{ paddingLeft: "15px" }}>
              {(parseInt(props.data.accept) >= 0
                ? props.data.accept + "/"
                : "") + props.data.sum}
            </RespFont>
          </div>
        )}
      </FlexRowBetween>
      {(props.editTablesMode || props.giftMode) && (
        <>
          {showEditTable ? (
            <div
              className="animate__animated animate__headShake"
              style={{
                cursor: "auto",
                display: "flex",
                paddingBottom: "5px",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img
                src={cancel}
                alt="cancel"
                style={{ cursor: "pointer", width: "18px" }}
                onClick={() => {
                  setShowEditTable(false);
                }}
              />
              <BaseInput
                style={{
                  width: "70px",
                  paddingTop: "1px",
                  paddingBottom: "1px",
                  fontSize: "14px",
                }}
                type="number"
                min="1"
                max="100"
                onChange={(e) => setTable(e.target.value)}
              />

              <img
                src={save}
                alt="save"
                style={{ cursor: "pointer", width: "18px" }}
                onClick={() => {
                  if (props.giftMode) {
                    setGuestGift1(
                      { _id: props.data._id, closeness: props.data.closeness },
                      table
                    );
                  } else {
                    setGuestsTable1(
                      {
                        [props.data._id]: {
                          _id: props.data._id,
                          closeness: props.data.closeness,
                        },
                      },
                      table
                    );
                  }
                }}
              />
            </div>
          ) : (
            <FlexRowBetween style={{ justifyContent: "flex-start" }}>
              <div
                onClick={() => {
                  if (props.multiSelect) {
                    add_del();
                  } else props.setEdit(props.data._id);
                }}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "50%",
                  textAlign: "center",
                }}
              >
                <img src={person} style={{ width: `16px` }} />
                <RespFont style={{ marginRight: "5px" }}>
                  {(parseInt(props.data.accept) >= 0
                    ? props.data.accept + "/"
                    : "") + props.data.sum}
                </RespFont>
              </div>
              {(props.editTablesMode || props.giftMode) && (
                <div
                  className="second"
                  onClick={() => {
                    if (props.multiSelect) {
                      add_del();
                    } else setShowEditTable(true);
                  }}
                  style={{ background: props.background }}
                >
                  <img
                    src={props.giftMode ? present : table1}
                    style={{ padding: `3px 0`, width: `18px` }}
                  />
                  <RespFont
                    pSize={"14px"}
                    cSize={"16px"}
                    style={{ marginRight: "5px" }}
                  >
                    {props.giftMode
                      ? props.data.gift !== null
                        ? props.data.gift
                        : ""
                      : props.data.table !== null
                      ? props.data.table
                      : ""}
                  </RespFont>
                </div>
              )}
            </FlexRowBetween>
          )}
        </>
      )}
    </GuestRowStyle>
  );
};
export default GuestRowNew;
