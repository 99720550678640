import React, { useState, useEffect } from "react";
import { HomeTextArea, InvDiv } from "../Style";
import pic222 from "../../../assets/invitation/pic222.svg";
import pic111 from "../../../assets/invitation/pic111.svg";
import Names from "./parts/Names";
import Extra from "./parts/Extra";
import minusB from "../../../assets/invitation/minusB.svg";
import plusB from "../../../assets/invitation/plusB.svg";
import Footer from "./parts/Footer";
import Output from "../../user/image/Output";
import { useSelector } from "react-redux";
import Links from "./parts/Links";
import SpecialDishes from "./parts/SpecialDishes";
import GenericArivalConfirmation from "./parts/GenericArivalConfirmation";
import { GetToast } from "../modals/Toast";
import { useToasts } from "react-toast-notifications";

const InvitationDemo = () => {
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const [count, setCount] = useState(1);
  const borderedInvsStyle = {
    width: "100%",
    backgroundColor: "rgba(255,255,255,0.7)",
    boxShadow: "0 1px 2px silver",
    padding: "5px",
    boxShadow: "0 2px 4px silver",
    borderRadius: "3px",
    paddingBottom: "20px",
  };

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  useEffect(() => {
    setTimeout(function () {
      handleClickScroll("bla");
    }, 2000);
  }, []);

  const basicDivStyle = user.invK === 1 ? { width: "100%" } : borderedInvsStyle;
  const [tiv, setTiv] = useState(0);
  const [tsim, setTsim] = useState(0);
  const [glt, setGlt] = useState(0);
  const [glat, setGlat] = useState(0);
  const [done, setDone] = useState(false);
  const [notes, setNotes] = useState("");
  const handleSubmit = () => {
    GetToast(addToast, "success", "הפעולה הצליחה", 8000);
    setDone(true);
    setTimeout(function () {
      setDone(false);
    }, 8000);
  };

  return (
    <InvDiv
      gradient={user.gradient}
      style={{
        width: "352px",
        margin: "50px auto",
        height: "712px",
        borderRadius: "10px",
        border: "5px solid black",
        padding: user.invK === 1 ? "0" : "15px",
      }}
    >
      <div style={{ ...basicDivStyle }}>
        <Output />
        {user.inv === 1 && <Names data={user} />}
        <GenericArivalConfirmation
          count={count}
          setCount={setCount}
          onSubmit={handleSubmit}
          done={done}
        />

        <Links setTransportation={() => {}} data={user} />
        {user.inv === 1 && <Extra data={user} />}
        {user.specialDishes && (
          <SpecialDishes
            setTiv={setTiv}
            setTsim={setTsim}
            setGlt={setGlt}
            setGlat={setGlat}
            tiv={tiv}
            tsim={tsim}
            glt={glt}
            glat={glat}
            minusB={minusB}
            plusB={plusB}
            handleSetDishes={() => {}}
          />
        )}
        {user.sharePics && (
          <a
            target={"_blank"}
            rel="noopener noreferrer"
            href={user.sharePicsLink}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              color: "#3A3C3F",
              textDecoration: "none",
              fontSize: "14px",
              marginTop: "20px",
            }}
          >
            <img alt="wazeIcon" src={pic222} style={{ width: "25px" }} />
            <div>{user.sharePicsPlaceholder}</div>
            <img alt="wazeIcon" src={pic111} style={{ width: "25px" }} />
          </a>
        )}
        {user.blessing && (
          <div style={{ width: "90%", margin: "0px auto", marginTop: "20px" }}>
            <HomeTextArea
              type="text"
              rows="3"
              placeholder={user.blessingPlaceholder}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
        )}
      </div>
      <Footer producer={user.producer} />
    </InvDiv>
  );
};

export default InvitationDemo;
