import React, { useState } from "react";
import Switch from "react-switch";
import {
  editUserById,
  addBasicTodos,
} from "../../../store/reducers/userReducer";
import { GetToast } from "../../common/modals/Toast";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import { AvivCard } from "../../common/Style";
import options from "../../../assets/client/options.svg";
const ItayClientCard = (props) => {
  // const [couple, setCouple1] = useState(
  //   `החתונה של ${props.data.brideFN + " " + props.data.brideLN} ו${
  //     props.data.groomFN + " " + props.data.groomLN
  //   }!`
  // );

  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const setSmsPhone = async (data) => {
    dispatch(editUserById({ smsPhone: data }, props.data._id)).then((res) => {
      if (!res.payload?.error)
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
    });
  };
  const setApproved = async (data) => {
    dispatch(editUserById({ approved: data }, props.data._id)).then((res) => {
      if (!res.payload?.error)
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
    });
  };
  const setProducer = async () => {
    dispatch(
      editUserById(
        {
          producer:
            props.data.producer === "/itay"
              ? "/ohad"
              : props.data.producer === "/ohad"
              ? "/aviv"
              : "/itay",
        },
        props.data._id
      )
    ).then((res) => {
      if (!res.payload?.error)
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
    });
  };
  const setBasicTodos = async (data) => {
    dispatch(
      addBasicTodos(
        { _id: props.data._id, date: props.data.date },
        props.data._id
      )
    ).then((res) => {
      if (!res.payload?.error)
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
    });
  };
  let color = props.data.producer === "/itay" ? "#f1ae6e" : "#3A3C3F";
  return (
    <AvivCard padding={`10px 8px`} shadow={color} key={props.index}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "50%",
            justifyContent: "flex-start",
          }}
        >
          {props.data.date.replace(/T.*/, "").split("-").reverse().join("/")}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: props.data.isTodosRecorded ? "50%" : "30%",
            justifyContent: "flex-start",
          }}
        >
          {props.data.hall}
        </div>
        {!props.data.isTodosRecorded && (
          <div
            onClick={setBasicTodos}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "20%",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
          >
            Todos
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          marginTop: `7px`,
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {
            props.setUserId(props.data._id);
            props.setHall(props.data.hall);
            props.setCity(props.data.city);
            props.setDate1(
              props.data.date.replace(/T.*/, "").split("-").reverse().join("/")
            );
            props.setCouple({
              brideFN: props.data.brideFN,
              brideLN: props.data.brideLN,
              groomFN: props.data.groomFN,
              groomLN: props.data.groomLN,
            });
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor:
              props.data.isPaid === false ? "pink" : "transparent",
            width: "50%",
            justifyContent: "flex-start",
            cursor: "pointer",
          }}
        >
          {props.data.eventType
            ? props.data.brideFN + " ו" + props.data.groomFN === "ו"
              ? "ישן - למחוק"
              : props.data.brideFN + " ו" + props.data.groomFN
            : props.data.calendarName}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "25%",
            justifyContent: "flex-start",
          }}
        >
          <Switch
            width={34}
            height={14}
            onColor={"#ffc48c"}
            offColor={"#c9c6c6"}
            onHandleColor={"#fda14b"}
            offHandleColor={"#929292"}
            checkedIcon={false}
            uncheckedIcon={false}
            checked={props.data.approved}
            onChange={() => setApproved(!props.data.approved)}
            handleDiameter={20}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "25%",
            justifyContent: "center",
          }}
        >
          <img
            alt="options"
            src={options}
            width="30"
            onClick={() => props.edit(props.data)}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          marginTop: `7px`,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "50%",
            justifyContent: "flex-start",
            backgroundColor:
              props.data.danielAmount > 0 && !props.data.isDanielReserved
                ? "pink"
                : props.data.danielAmount > 0 && props.data.isDanielReserved
                ? "#d7f0ae"
                : "transparent",
          }}
        >
          <div style={{ marginLeft: "5px" }}>קבלת פנים:</div>
          <div>{props.data.hour1}</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "25%",
            justifyContent: "flex-start",
          }}
        >
          <div style={{ marginLeft: "5px" }}>פעיל:</div>
          <div>
            <Switch
              width={34}
              height={14}
              onColor={"#ffc48c"}
              offColor={"#c9c6c6"}
              onHandleColor={"#fda14b"}
              offHandleColor={"#929292"}
              checkedIcon={false}
              uncheckedIcon={false}
              checked={props.data.smsPhone}
              onChange={() => setSmsPhone(!props.data.smsPhone)}
              handleDiameter={20}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "25%",
            justifyContent: "flex-start",
            direction: "ltr",
          }}
        >
          <div
            onClick={setProducer}
            style={{
              padding: "2px 10px",
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "2px 2px 2px 10px",
            }}
          >
            {props.data.producer}
          </div>
        </div>
      </div>
    </AvivCard>
  );
};

export default ItayClientCard;
