import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import { addCouple } from '../../../store/reducers/couplesReducer';
import { CenteredForm, PlaceHolderImage, GenericButton, InputBox, RTextArea, GenericDiv, FlexRowBetween, GuestOptions } from '../../common/Style'
import exitModalLogo from '../../../assets/common/cancel1.svg';
import { addAlpha, GetToast } from '../../common/modals/Toast';
import GenericModal from '../../common/modals/GenericModal';
import GCalendar from '../../common/calendar/Calendar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown'
import ExitModal from '../../common/modals/ExitModal';
const options = ["הודעה ראשונה", "הודעה שניה", "הודעה שלישית", "ווצאפ ראשון", "ווצאפ שני", "שיחה ראשונה", "שיחה שניה", "שיחה שלישית", "שיחה רביעית", "תזכורת", "תודה"];

const AddCouple = (props) => {
    const dispatch = useDispatch();
    const { couplesError } = useSelector(state => state.couplesReducer);
    const { user } = useSelector(state => state.authReducer);
    const { addToast } = useToasts();
    const { register, handleSubmit } = useForm();
    const [showCalendar, setShowCalendar] = useState(null);
    const [title, setTitle] = useState("");
    const ops = options.map((item, index) => <Dropdown.Item key={index} onClick={() => setTitle(item)}>{item}</Dropdown.Item>)

    const [date, setDate] = useState(null);
    const onSubmit = async data => {
        const dis = () => {
            if(date)
            dispatch(addCouple({ fullname: data.fullname, phone1: data.phone1, phone2: data.phone2, description: data.description, weddingDate: date1 ? date1.toISOString() : null })).then((res) => {
                if (res.payload.error)
                    GetToast(addToast, "error", res.payload.error)
                    else{
                        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה")
                        props.init(false)
                    }
            })
            else alert("לא נבחר תאריך לאירוע")
        }
        if (options.includes(title))
            if (date)
                dis()
            else alert("לא נבחר תאריך לביצוע")
        else dis()
    }

    let date1;
    if (date) {
        date1 = date;
        date1.setHours(20);
    }
    return (<CenteredForm onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit(onSubmit)} id="form1">
        <ExitModal init={props.init} color={user.color} />
        <div style={{ textAlign: "center", fontWeight: "bold", padding: "20px", fontSize: "22px" }}>אירוע חדש</div>
        <InputBox labelColor={"gray"} color={"#3A3C3F"} borderBottomColor={user.color}>
            <FlexRowBetween style={{width:"90%", margin:"0 auto"}}>
            <div className='relativeBox' style={{marginRight:"0", marginLeft:"20px"}}>
                <input type="text" name="fullname" minLength={1} maxLength={50} required={true} ref={register()} />
                <label>שם בעל/י השמחה</label>
            </div>
                

                <GenericDiv hoverBackground={"rgb(223, 223, 223)"} background={user.color} style={{
                width: "max-content", marginLeft: "0px"
                , display: "flex", flexDirection: "column"
            }} onClick={() => setShowCalendar(true)}><div>{date ?
                (date1.toISOString().replace(/T.*/, '').split('-').reverse().join('/')):
                <div>תאריך</div>}</div></GenericDiv>
                </FlexRowBetween>
            <FlexRowBetween style={{width:"90%", margin:"0 auto", marginTop:"10px"}}>

            <div className='relativeBox' style={{ width: "40%", marginRight: "0px" }}>
                        <input type="text" name="phone1" required={true}
                            pattern={"[0]{0,1}[5]{1}[0-9]{8}"}
                            ref={register()} />
                        <label>טלפון 1</label>
                    </div>
                    <div className='relativeBox' style={{ width: "40%", marginLeft: "0px" }}>
                        <input type="text" name="phone2"
                            pattern={"[0]{0,1}[5]{1}[0-9]{8}"}
                            ref={register()} />
                        <label>טלפון 2</label>
                    </div>
                            </FlexRowBetween>

            

            <div style={{ position: "relative", width: "90%", margin: "10px auto" }}>
                <RTextArea color={user.color} placeholder="פרטים" name="description" type="text" rows="2"
                    minLength={0} maxLength={500} ref={register()} />
            </div>

            {/* <GenericButton background={user.color} type="submit" style={{ width: "50%", margin: "10px auto", padding: "10px" }}>הוספה</GenericButton> */}
        </InputBox>

        {/* <GuestOptions width={"21px"} color={user.color} padding={`20px 0`} style={{ justifyContent: "space-between" }}> */}
            <FlexRowBetween style={{backgroundColor: user.color, padding:"12px 0"}}>

                    <button type="submit" form="form1" style={{ outline: "none", width: "100%"
                    , background: "none", border: "none", fontWeight:"bold", color:"white" }}>
                        הוספה
                    </button>
            </FlexRowBetween>
        {/* </GuestOptions> */}

        {
            showCalendar && <GenericModal
                init={setShowCalendar}
                div={<GCalendar background={user.color} setDate={setDate} date={date} init={setShowCalendar} />}
            />
        }
    </CenteredForm >)
}

export default AddCouple;