import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import dropdown1 from "../../../assets/client/dropdown1.svg";
import errors from "../../../assets/print/errors1.svg";
import shield from "../../../assets/print/shield.svg";

import delete1 from "../../../assets/client/delete1.svg";
import {
  CenteredForm,
  GenericButton,
  GenericDiv,
  RespFont,
  FlexRowBetween,
  InputBox,
  DropOption,
  Responsive,
} from "../../common/Style";
import { GetToast } from "../../common/modals/Toast";
import ExitModal from "../../common/modals/ExitModal";
import { postCsv } from "../../../store/reducers/dataReducer";
import Switcher from "../../common/switcher/Switcher";
import OpacityLoading from "../../common/loader/OpacityLoading";
import GenericSvg from "../../common/coloredSvgs/GenericSvg";
import { svgs } from "../../common/coloredSvgs/svgs";

// const inputs = {
//     "0": true, "1": true, "2": true, "3": true, "4": true, "5": true, "6": true, "7": true, "8": true, "9": true,
//     "10": true, "11": true, "12": true, "13": true, "14": true, "15": true, "16": true, "17": true, "18": true, "19": true
// }
const inputs = {};
const inputsErrors = {};
const AddGuests = (props) => {
  let height = window.screen.availHeight;
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { register, handleSubmit } = useForm();
  const [amount, setAmount] = useState(1);
  const { user } = useSelector((state) => state.authReducer);
  const [inviting, setInviting] = useState("");
  const defaultPreSms1 = `${user.brideFN + " " + user.brideLN} ו${
    user.groomFN + " " + user.groomLN
  } שמחים להזמינכם לחתונתם!`;
  const [closeness, setCloseness] = useState("");
  const [limit, setLimit] = useState(true);
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState(true);
  const [owc, setOwc] = useState("קשר");
  const [showDropOwc, setShowDropOwc] = useState(false);
  const [edit, setEdit] = useState(false);
  const width = window.screen.availWidth;
  const [animate, setAnimate] = useState(
    "animate__animated animate__heartBeat animate__infinite"
  );
  const handleOnSelect = (item) => {
    setCloseness(item.id);
  };
  const handleOnSearch = (string, results) => {
    setCloseness(string);
  };

  const digitOrNull = (value) =>
    parseInt(value) >= 0 ? parseInt(value) : null;

  const onSubmit = async (data) => {
    setChecked(false);
    let rows = [
      ["שם מלא", "טלפון", "קירבה", "מוזמנים", "אישרו", "מזמין", "קשר", "מספר"],
    ];
    let newOwc = "";
    if (
      user.eventType &&
      ((data?.inviting.length && owc === "קשר") ||
        (data?.inviting.length === 0 && owc !== "קשר"))
    )
      GetToast(addToast, "warning", "שדה המזמין והקשר באים ביחד - הכל או כלום");
    else if (closeness.length > 1) {
      newOwc = owc;
      for (let i = 0; i < amount; i++) {
        if (inputs[i] !== false)
          rows.push([
            data[`${i}name`],
            data[`${i}phone`].length ? data[`${i}phone`] : null,
            closeness,
            digitOrNull(data[`${i}sum`]),
            digitOrNull(data[`${i}accept`]),
            data.inviting,
            newOwc === "קשר" ? "" : newOwc,
            i + 1,
          ]);
      }
      if (rows.length === 1)
        GetToast(addToast, "warning", "לא נבחרו רשומות להוספה");
      else {
        dispatch(postCsv({ rows })).then((res) => {
          setChecked(true);
          if (!res.payload?.error) {
            GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
            setTimeout(function () {
              setLoading(false);
              props.init();
            }, 2000);
          } else {
            setLoading(false);
            GetToast(
              addToast,
              "error",
              "לא כל הרשומות נטענו בהצלחה, ראו פירוט בכל שורה"
            );

            for (let i = 0; i < amount; i++) inputs[i] = false;
            for (let i = 0; i < res.payload?.error.length; i++) {
              inputs[res.payload?.error[i]["מספר"] - 1] = true;
              inputsErrors[res.payload?.error[i]["מספר"] - 1] =
                res.payload?.error[i]["סיבה"];
            }

            setFlag(!flag);
          }
        });
      }
    } else
      GetToast(addToast, "warning", "שדה קירבה לא יכול להיות פחות מ 2 תווים");
  };
  const handleSwitch = (e, i) => {
    inputs[i] = e;
    setFlag(!flag);
  };

  let guestsArray = useMemo(() => {
    let guests = [];
    for (let i = 0; i < amount; i++) {
      guests.push(
        <div
          key={i}
          style={{
            display: checked && inputs[i] === false ? "none" : "flex",
            flexDirection: "column",
            width: "94%",
            margin: "0 auto",
            padding: "10px 15px",
            backgroundColor: addAlpha(user.color, 0.15),
            marginTop: "10px",
            borderRadius: "5px",
            paddingTop: "0px",
          }}
        >
          <FlexRowBetween>
            <div style={{ position: "relative", width: "50%" }}>
              <input
                type="text"
                name={`${i}name`}
                minLength={2}
                maxLength={50}
                required={true}
                ref={register()}
              />
              <label>שם מלא</label>
            </div>
            <div style={{ position: "relative", width: "40%" }}>
              <input
                type="text"
                name={`${i}phone`}
                pattern={limit && "[0]{0,1}[5]{1}[0-9]{8}"}
                ref={register()}
              />
              <label>מס' פלאפון</label>
            </div>
          </FlexRowBetween>
          <FlexRowBetween style={{ alignItems: "flex-end" }}>
            <div style={{ position: "relative", width: "30%" }}>
              <input
                type="number"
                name={`${i}sum`}
                min={1}
                max={limit ? 30 : 1000}
                required={true}
                ref={register()}
              />
              <label>כמות</label>
            </div>
            <div style={{ position: "relative", width: "30%" }}>
              <input
                type="number"
                name={`${i}accept`}
                min={0}
                max={limit ? 30 : 1000}
                ref={register()}
              />
              <label>אישרו</label>
            </div>

            {checked && inputs[i] && (
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip>
                    {inputsErrors[i] !== undefined && inputsErrors[i] !== ""
                      ? inputsErrors[i]
                      : "רשומה זו נכנסה בהצלחה!"}
                  </Tooltip>
                }
              >
                <img
                  src={
                    inputsErrors[i] !== undefined && inputsErrors[i] !== ""
                      ? errors
                      : shield
                  }
                  style={{ width: "30px" }}
                  className={animate}
                />
              </OverlayTrigger>
            )}
          </FlexRowBetween>
        </div>
      );
    }

    return [guests];
  }, [flag, amount]);

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  return (
    <CenteredForm
      onSubmit={handleSubmit(onSubmit)}
      style={{ height: "min-content", maxHeight: `${height * 0.8}px` }}
      onClick={(e) => e.stopPropagation()}
    >
      <ExitModal init={props.init} color={user.color} />
      <FlexRowBetween style={{ marginTop: "60px" }}>
        <div
          className="relativeBox"
          style={{
            zIndex: "1",
            marginRight: "5%",
            width: "60%",
          }}
        >
          <ReactSearchAutocomplete
            items={props.categories}
            onSelect={handleOnSelect}
            autoFocus
            onSearch={handleOnSearch}
            fuseOptions={{ minMatchCharLength: 1 }}
            maxResults={show ? 10 : 0}
            showNoResults={false}
            placeholder="קירבה"
            styling={{
              searchIconMargin: "0 5px 0 10px",
              clearIconMargin: "4px 0 0 5px",
              borderRadius: "5px",
              width: "100%",
              height: "30px",
            }}
          />
        </div>
        <OverlayTrigger
          placement={"bottom"}
          overlay={
            <Tooltip>
              {show ? "הסתרת השלמות לחיפוש" : "הצגת השלמות לחיפוש"}
            </Tooltip>
          }
        >
          <GenericDiv
            style={{ marginLeft: "5%", height: "30px", padding: "10px 15px" }}
            background={user.color}
            onClick={() => setShow(!show)}
          >
            {show ? "הסתר" : "הצג"}
          </GenericDiv>
        </OverlayTrigger>
      </FlexRowBetween>

      <InputBox labelColor={"gray"} color={"#3A3C3F"}>
        {user.eventType && (
          <FlexRowBetween
            style={{
              width: "90%",
              margin: "10px auto",
              marginBottom: "20px",
              alignItems: "flex-end",
            }}
          >
            <div style={{ position: "relative", width: "65%" }}>
              <input
                type="text"
                name="inviting"
                onChange={(e) => setInviting(e.target.value)}
                minLength={2}
                maxLength={50}
                ref={register()}
              />
              <label>המזמין</label>
            </div>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"הקשר של בעל/ת השמחה למזמין"}</Tooltip>}
            >
              <GenericDiv
                background={user.color}
                style={{
                  position: "relative",
                  height: "30px",
                  padding: "10px 10px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropOwc(!showDropOwc);
                }}
              >
                <div>{owc}</div>
                <img
                  src={dropdown1}
                  style={{ width: "25px", marginLeft: "-7px" }}
                />
                {showDropOwc && (
                  <div
                    style={{
                      position: "absolute",
                      left: "0",
                      top: "120%",
                      zIndex: 2,
                      color: user.color,
                      borderRadius: "5px",
                      backgroundColor: "white",
                      padding: "10px",
                      fontSize: "12px",
                      boxShadow: "0 2px 4px gray",
                    }}
                  >
                    <FlexRowBetween>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בנם");
                          setShowDropOwc(false);
                        }}
                      >
                        בנם
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בנה");
                          setShowDropOwc(false);
                        }}
                      >
                        בנה
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בתו");
                          setShowDropOwc(false);
                        }}
                      >
                        בתו
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בנו");
                          setShowDropOwc(false);
                        }}
                      >
                        בנו
                      </DropOption>
                      <DropOption
                        background={"black"}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("קשר");
                          setShowDropOwc(false);
                        }}
                        style={{ boxShadow: "0 2px 4px black" }}
                      >
                        <img src={delete1} style={{ width: "15px" }} />
                      </DropOption>
                    </FlexRowBetween>
                    <FlexRowBetween style={{ marginTop: "8px" }}>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בתם");
                          setShowDropOwc(false);
                        }}
                      >
                        בתם
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בתה");
                          setShowDropOwc(false);
                        }}
                      >
                        בתה
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדה");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדה
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדתם");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדתם
                      </DropOption>
                    </FlexRowBetween>
                    <FlexRowBetween style={{ marginTop: "8px" }}>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדם");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדם
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדתה");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדתה
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדתו");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדתו
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדו");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדו
                      </DropOption>
                    </FlexRowBetween>
                    <FlexRowBetween style={{ marginTop: "8px" }}>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחותה");
                          setShowDropOwc(false);
                        }}
                      >
                        אחותה
                      </DropOption>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחותו");
                          setShowDropOwc(false);
                        }}
                      >
                        אחותו
                      </DropOption>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחיו");
                          setShowDropOwc(false);
                        }}
                      >
                        אחיו
                      </DropOption>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחיה");
                          setShowDropOwc(false);
                        }}
                      >
                        אחיה
                      </DropOption>
                    </FlexRowBetween>
                  </div>
                )}
              </GenericDiv>
            </OverlayTrigger>
          </FlexRowBetween>
        )}
        {user.eventType && (
          <FlexRowBetween
            style={{ width: "90%", margin: "0 auto", marginBottom: "20px" }}
          >
            <RespFont>
              {inviting !== "" && owc !== "קשר" ? (
                inviting +
                (owc[owc.length - 1] === "ם"
                  ? " שמחים"
                  : owc[owc.length - 1] === "ו"
                  ? " שמח"
                  : " שמחה") +
                " להזמינכם לחתונת " +
                owc +
                (owc.includes("ת") ? ` ${user.brideFN}.` : ` ${user.groomFN}.`)
              ) : (
                <>ברירת מחדל: {defaultPreSms1}</>
              )}
            </RespFont>
          </FlexRowBetween>
        )}

        {guestsArray[0]}
        <FlexRowBetween style={{ width: "90%", margin: "20px auto" }}>
          <OverlayTrigger
            placement={"bottom"}
            overlay={<Tooltip>{"הוספת שורה"}</Tooltip>}
          >
            <Responsive
              padding={true}
              padC={"4px 7px"}
              padP={"4px 9px"}
              style={{
                cursor: "pointer",
                paddingBottom: "8px",
                background: "#f2f2f2",
                borderRadius: "0 10px 0 10px",
                boxShadow: "0 2px 4px gray",
              }}
              onClick={() => {
                if (amount < 20) setAmount(amount + 1);
              }}
            >
              <GenericSvg
                fill={user.color}
                size={width > 500 ? "18px" : "15px"}
                svg={svgs.plus}
                viewBox={"0 0 45.402 45.402"}
              />
            </Responsive>
          </OverlayTrigger>
          <GenericButton
            style={{
              margin: "0 auto",
              width: "50%",
              background: user && user.color,
            }}
            type="submit"
          >
            אישור
          </GenericButton>
          <OverlayTrigger
            placement={"bottom"}
            overlay={<Tooltip>{"מחיקת שורה"}</Tooltip>}
          >
            <Responsive
              padding={true}
              padC={"0px 0px"}
              padP={"2px"}
              style={{
                cursor: "pointer",
                background: "#f2f2f2",
                borderRadius: "10px 0 10px 0",
                boxShadow: "0 2px 4px gray",
              }}
              onClick={() => {
                if (amount > 1) setAmount(amount - 1);
              }}
            >
              <GenericSvg
                fill={user.color}
                size={width > 500 ? "33px" : "28px"}
                svg={svgs.minus}
                viewBox={"0 0 16 16"}
              />
            </Responsive>
          </OverlayTrigger>
        </FlexRowBetween>
        <div style={{ height: "10px" }}></div>
      </InputBox>
      {loading && <OpacityLoading />}
    </CenteredForm>
  );
};

export default AddGuests;
