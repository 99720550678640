import React from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import { GuestOptions, CenteredForm, PlaceHolderImage, GenericButton, RespFont, InputBox, RTextArea } from '../../common/Style'
import callLogo from '../../../assets/client/call1.svg';
import whatsappLogo from '../../../assets/client/whatsapp1.svg';
import exitModalLogo from '../../../assets/common/cancel1.svg';
import { GetToast } from '../../common/modals/Toast';
import { editUserPD } from '../../../store/reducers/userReducer';

const EditAvivUser = (props) => {
    let height = window.screen.availHeight;
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { register, handleSubmit } = useForm();

    const onSubmit = async data => {
        dispatch(editUserPD({
            description: data.description, price: data.price
        }, props.data._id))
            .then(res => {
                if (!res.payload?.error) {
                    GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!")
                    props.init()
                }
            })
    }

    return (<CenteredForm onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit(onSubmit)} style={{ maxHeight: `${height * 0.8}px` }}>
        <PlaceHolderImage src={exitModalLogo} alt="exitModalLogo" style={{ top: "8px", left: "8px", transform: "translate(0,0)", width: "32px", cursor: "pointer" }} onClick={() => props.init(null)} />
        <RespFont pSize={"20px"} cSize={"22px"} style={{ textAlign: "center", color: "#3A3C3F", fontWeight: "bold", padding: `30px 0`, paddingBottom: `10px` }}>עריכת משתמש</RespFont>
        <InputBox labelColor={"gray"} color={"#3A3C3F"} style={{ paddingBottom: "30px" }}>

            <div className='relativeBox'>
                <input defaultValue={props.data.price} type="text" placeholder='לא חובה' name="price" minLength={0} maxLength={50} ref={register()} />
                <label>מחיר</label>
            </div>
            <div style={{ position: "relative", width: "80%", margin: "20px auto" }}>
                <RTextArea defaultValue={props.data.description} placeholder="הערות" name="description" type="text" rows="3"
                    minLength={0} maxLength={500} ref={register()} />
            </div>

            <GenericButton background={"#3A3C3F"} type="submit" style={{ width: "60%", margin: "20px auto" }}>אישור</GenericButton>
        </InputBox>
        <GuestOptions color={"#3A3C3F"} width={`25px`} padding={`10px 0`}>
            <a target="_blank" rel="noreferrer" href={'https://api.whatsapp.com/send?phone=972' + props.data.phone + '&text='}><img alt="whatsapp" src={whatsappLogo} /></a>
            <a href={"tel:" + props.data.phone}><img alt="call" src={callLogo} /></a>
        </GuestOptions>
    </CenteredForm >)

}

export default EditAvivUser;
