import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import OpacityLoading from "../../common/loader/OpacityLoading";
import copy from "../../../assets/common/copy.svg";
import delete1 from "../../../assets/client/deleteBlack.svg";
import GenericModal from "../../common/modals/GenericModal";
import {
  getBetweenDates,
  editTodoAdmin,
} from "../../../store/reducers/todosReducer";
import {
  TodoCard,
  TodosHolder,
  MainDiv,
  GenericButton,
  GenericDiv,
  ClosenessTitle2,
  FlexRowBetween,
  RespFont,
} from "../../common/Style";
import { GetToast } from "../../common/modals/Toast";
import { Link } from "react-router-dom";
import ClientRoutes from "../../../navigation/Routes";
import EditGuestAdmin from "./EditGuestAdmin";
import Switch from "react-switch";
import { deleteSms, getAll } from "../../../store/reducers/smsReducer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import YesNoModal from "../../common/modals/YesNoModal";

const Sms = () => {
  const dispatch = useDispatch();
  const { sms, smsError, smsLoading } = useSelector(
    (state) => state.smsReducer
  );
  const [deleteTodoS, setDeleteTodo] = useState(false);

  const { addToast } = useToasts();
  useEffect(() => {
    if (smsError) GetToast(addToast, "error", smsError);
  }, [smsError]);

  useEffect(() => {
    getAllFunc();
  }, []);

  const deleteSmsFunc = async () => {
    dispatch(deleteSms(deleteTodoS)).then((res) => {
      if (!res.payload?.error) GetToast(addToast, "success", "הפעולה בוצעה");
    });
  };

  const getAllFunc = async () => {
    dispatch(getAll());
  };

  let todosArray = useMemo(() => {
    let arr = [];
    if (Object.keys(sms).length > 0) {
      for (const [key, value] of Object.entries(sms)) {
        arr.push(
          <TodoCard key={value._id} style={{ cursor: "auto", padding: "10px" }}>
            <FlexRowBetween>
              <div>{value.couple + " " + value.platform ? "W" : "M"}</div>
              <div>{value.userPhone}</div>
            </FlexRowBetween>

            <FlexRowBetween style={{ marginTop: "20px" }}>
              <img
                src={delete1}
                style={{ width: "20px", cursor: "pointer" }}
                onClick={() => setDeleteTodo(value._id)}
              />
              <CopyToClipboard
                text={value.guestPhone}
                onCopy={() => GetToast(addToast, "info", "המספר הועתק בהצלחה!")}
              >
                <div
                  style={{
                    padding: "5px 5px",
                    borderRadius: "3px",
                    border: "1px solid rgba(0, 0, 0, 0.11)",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt={"copy"}
                    src={copy}
                    style={{
                      width: "20px",
                      marginLeft: "15px",
                    }}
                  />
                  <RespFont>{value.guestPhone}</RespFont>
                </div>
              </CopyToClipboard>
            </FlexRowBetween>
            <div style={{ flexWrap: "wrap", marginTop: "20px" }}>
              {value.msg}
            </div>
          </TodoCard>
        );
      }
    }
    return arr;
  }, [sms]);

  return (
    <MainDiv
      className="animate__animated animate__fadeIn"
      style={{
        overflow: "hidden",
        maxHeight: "100vh",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: `0 10px`,
          margin: "20px auto",
        }}
      >
        <GenericButton
          background={"#fda14b"}
          as={Link}
          to={ClientRoutes.Admin.main}
          style={{ fontSize: "14px" }}
        >
          ראשי
        </GenericButton>
        <GenericDiv
          hoverBackground={"rgb(223, 223, 223)"}
          background={"#fda14b"}
          onClick={getAllFunc}
        >
          ייבא
        </GenericDiv>
      </div>

      {/* <div style={{ marginTop: "100px" }}>
                <DropDownG label={"מיון"} data={sortOptions} value={sortBy} onChange={setSortBy} />
            </div> */}

      <TodosHolder style={{ marginTop: "0px" }}>{todosArray}</TodosHolder>

      {smsLoading && <OpacityLoading />}

      {deleteTodoS && (
        <GenericModal
          init={setDeleteTodo}
          div={
            <YesNoModal
              color={"#fda14b"}
              title={"מחיקת משימה"}
              body={"האם את/ה בטוח/ה?"}
              no={setDeleteTodo}
              yes={deleteSmsFunc}
            />
          }
        />
      )}
    </MainDiv>
  );
};

export default Sms;
