import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import { editCouple } from '../../../store/reducers/couplesReducer';
import { CenteredForm, PlaceHolderImage, GenericButton, InputBox, Footer, GenericDiv, FlexRowBetween, GuestOptions } from '../../common/Style'
import delete1 from '../../../assets/client/delete1.svg';
import exitModalLogo from '../../../assets/common/cancel1.svg';
import { addAlpha, GetToast } from '../../common/modals/Toast';
import { RTextArea } from '../../common/Style';
import GCalendar from '../../common/calendar/Calendar';
import GenericModal from '../../common/modals/GenericModal';
import ExitModal from '../../common/modals/ExitModal';
import GenericSvg from '../../common/coloredSvgs/GenericSvg';
import { svgs } from '../../common/coloredSvgs/svgs';
import whatsappLogo from '../../../assets/client/whatsapp1.svg';
import callLogo from '../../../assets/client/call1.svg';

const EditCouple = (props) => {
    const dispatch = useDispatch();
    const { couplesError } = useSelector(state => state.couplesReducer);
    const { user } = useSelector(state => state.authReducer);
    const { addToast } = useToasts();
    const { register, handleSubmit } = useForm();
    const [showCalendar, setShowCalendar] = useState(null);
        const [date, setDate] = useState(new Date(props.data.weddingDate));
    const handleSetDate = (e) => {
        let date1 = e;
        date1.setHours(20);
        console.log(date1);
        setDate(date1)
    }

    const onSubmit = async data => {
        dispatch(editCouple(props.data._id, {
            fullname: data.fullname, phone1: data.phone1, phone2: data.phone2
            , description: data.description, weddingDate: date.toISOString()
        })).then((res) => {
            if (res.payload.error)
                    GetToast(addToast, "error", res.payload.error)
                    else{
                        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה")
                        props.init(false)
                    }
        })
        props.init(false)
    }

    return (<CenteredForm onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit(onSubmit)} id="form1">
        <ExitModal init={props.init} color={user.color} />
        <div style={{ textAlign: "center", fontWeight: "bold", padding: "20px", fontSize: "22px" }}>עריכת אירוע</div>
        <InputBox labelColor={"gray"} color={"#3A3C3F"} borderBottomColor={user.color}>
            <FlexRowBetween style={{width:"90%", margin:"0 auto"}}>
            <div className='relativeBox' style={{marginRight:"0", marginLeft:"20px"}}>
                <input defaultValue={props.data.fullname} type="text" name="fullname" minLength={1} maxLength={50} required={true} ref={register()} />
                <label>שם בעל/י השמחה</label>
            </div>
                

                <GenericDiv hoverBackground={"rgb(223, 223, 223)"} background={user.color} style={{
                width: "max-content", marginLeft: "0px"
                , display: "flex", flexDirection: "column"
            }} onClick={() => setShowCalendar(true)}><div>{date.toISOString().replace(/T.*/, '').split('-').reverse().join('/')}</div></GenericDiv>
                </FlexRowBetween>
            <FlexRowBetween style={{width:"90%", margin:"0 auto", marginTop:"10px"}}>

            <div className='relativeBox' style={{ width: "40%", marginRight: "0px" }}>
                        <input defaultValue={props.data.phone1} type="text" name="phone1"
                            pattern={"[0]{0,1}[5]{1}[0-9]{8}"} required={true}
                            ref={register()} />
                        <label>טלפון 1</label>
                    </div>
                    <div className='relativeBox' style={{ width: "40%", marginLeft: "0px" }}>
                        <input defaultValue={props.data.phone2} type="text" name="phone2"
                            pattern={"[0]{0,1}[5]{1}[0-9]{8}"}
                            ref={register()} />
                        <label>טלפון 2</label>
                    </div>
                            </FlexRowBetween>

            <div style={{ position: "relative", width: "90%", margin: "10px auto" }}>
                <RTextArea defaultValue={props.data.description} color={user.color} placeholder="תוכן" name="description" type="text" rows="2"
                    minLength={0} maxLength={500} ref={register()} />
            </div>

            {/* <GenericButton background={user.color} type="submit" style={{ width: "50%", margin: "20px auto", padding: "15px" }}>אישור</GenericButton> */}
        </InputBox>
<GuestOptions width={"21px"} color={user.color} padding={`10px 0`} style={{ justifyContent: "space-between" }}>
            <FlexRowBetween style={{ justifyContent: "space-around", boxShadow: `0 -1px 4px ${addAlpha(user.color, 0.44)}` }}>

                    <button type="submit" form="form1" style={{ outline: "none", width: "100%", background: "inherit", border: "none" }}>
                        <FlexRowBetween style={{ justifyContent: "space-evenly" }}>
                            <div style={{ fontWeight: "bold", fontSize: "16px", color: user.color }}>שמירה</div>
                            <div style={{ padding: "8px 0" }}>
                                <GenericSvg fill={user.color} size={"20px"} svg={svgs.save} viewBox={"0 0 49 49"} />
                            </div>
                        </FlexRowBetween>
                    </button>
            </FlexRowBetween>
            <FlexRowBetween style={{ justifyContent: "space-around", background: `linear-gradient(to right, ${user.color}, ${addAlpha(user.color, 0.95)})` }}>
                    {(props.data.phone1) &&
                        <a target="_blank" rel="noreferrer" href={
                            `https://wa.me/972${props.data.phone1}/?text=`
                        }><img alt="whatsapp" src={whatsappLogo} /></a>}
                {(props.data.phone2) &&
                        <a target="_blank" rel="noreferrer" href={
                            `https://wa.me/972${props.data.phone2}/?text=`
                        }><img alt="whatsapp" src={whatsappLogo} /></a>}
                    <div><img src={delete1} alt="edit" onClick={() => { props.init(); props.setDeleteGuest([{ closeness: props.data.closeness, _id: props.data._id }]) }} /></div>
            </FlexRowBetween>

        </GuestOptions>
        {/* <Footer background={user.color}>
            <img src={delete1} alt="delete" onClick={() => { props.init(false); props.setDeleteCouple(props.data._id) }} />
        </Footer> */}
        {
            showCalendar && <GenericModal
                init={setShowCalendar}
                div={<GCalendar background={user.color} setDate={handleSetDate} date={date} init={setShowCalendar} />}
            />
        }
    </CenteredForm >)
}

export default EditCouple;