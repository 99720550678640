import React, { useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './style.css'

const DropDownG = props => {
    const handleChange = (event) => {
        props.onChange(event.target.value)
    };
    return (<div>
        <FormControl sx={{ m: 1, minWidth: 100 }}>
            <InputLabel id="demo-simple-select-helper-label">{props.label}</InputLabel>
            <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={props.value}
                label={props.label}
                onChange={handleChange}
            >
                {props.data}
            </Select>
        </FormControl>
    </div>)
}

export default DropDownG;