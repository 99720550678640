import React from 'react';
import ClientRoutes from '../../navigation/Routes';

import { MainDiv, GradientText } from '../common/Style'
import { useHistory } from "react-router-dom";

const ChooseProvider = () => {
    let history = useHistory();

    return (<MainDiv style={{ background: "linear-gradient(rgb(226, 92, 74), #9154e0d5)", justifyContent: "center" }}>
        <GradientText style={{
            display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", left: "2vh", top: "2vh", fontFamily: "logo",
            background: "#f2f2f2", borderRadius: "15px"
        }}>B.S</GradientText>

        <GradientText style={{ textAlign: "center", marginTop: "3vh", width: "50%", position: "absolute", top: "15vh" }}>
            הנכם צופים בדף זה מכיוון שזה עתה הורדתם את האפליקציה
        </GradientText>

        <div style={{
            width: "90%"
        }}>
            <GradientText style={{ padding: "1vh 2vh", textAlign: "center", marginTop: "3vh", width: "max-content", margin: "0 auto" }}>
                אנא בחרו נותן שירות:
            </GradientText>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", marginTop: "5vh" }}>
                <GradientText onClick={() => history.push(ClientRoutes.Producers.aviv)}>אביב</GradientText>
                <GradientText onClick={() => history.push(ClientRoutes.Producers.ohad)}>אוהד</GradientText>
            </div>
        </div>
    </MainDiv >
    )
}

export default ChooseProvider;