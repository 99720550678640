import React, { useState } from "react";
import GenericModal from "../../common/modals/GenericModal";
import TitleAndContent from "../../common/modals/TitleAndContent";
import {
  FlexRowBetween,
  GenericDiv,
  MainDiv,
  RespFont,
  ServicesHolder,
} from "../../common/Style";
import { Questions } from "../../unAuth/producers/Style";
import video1 from "../../../assets/client/video1.svg";
import { getImageUrl, GetToast, MenuCss } from "../../common/modals/Toast";
import { editTodos } from "../../../store/reducers/authReducer";
import { useToasts } from "react-toast-notifications";
import expenses1 from "../../../assets/client/expenses.svg";
import brain from "../../../assets/client/brain.svg";
import andA from "../../../assets/user/tools/andA.png";
import iA from "../../../assets/user/tools/iA.png";
import logout1 from "../../../assets/client/logout.svg";
import editInv from "../../../assets/client/editInv.svg";
import tables from "../../../assets/client/editTables.svg";
import video from "../../../assets/client/video2.svg";
import guests1 from "../../../assets/client/guests.svg";
import todoList from "../../../assets/client/todoList.svg";
import { slide as Menu } from "react-burger-menu";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import ClientRoutes from "../../../navigation/Routes";
import LogOutAll from "../../common/LogOut/LogOutAll";

const Instruction = () => {
  let history = useHistory();
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [isOpen, setIsOpen] = useState(false);
  const [logout, setLogout] = useState(false);

  const setWatchVideo = (index) => {
    let todos = [...user.todos];
    todos[index] = { ...todos[index], done: true };
    dispatch(editTodos({ todos })).then((res) => {
      if (res.payload?.error) GetToast(addToast, "error", res.payload?.error);
    });
  };

  const addGuests = (
    <div>
      <div style={{ fontWeight: "bold", marginBottom: "20px" }}>
        לראות הכל!!!
      </div>
      <div style={{ fontWeight: "bold", marginBottom: "20px" }}>
        לא למחוק אנשים שאינם מגיעים - מאוד חשוב שיהיה לכם את התיעוד הזה בסופו של
        דבר!!!
      </div>
      <a
        target="_blank"
        rel="noreferrer"
        href={"https://youtu.be/CUeyjYHAGX8"}
        style={{ textDecoration: "none" }}
      >
        <GenericDiv
          onClick={() => setWatchVideo(0)}
          background={user.color}
          style={{
            width: "max-content",
            padding: "10px 20px",
            margin: "20px auto",
            marginTop: "10px",
          }}
        >
          <RespFont pSize={"18px"} cSize={"20px"}>
            סרטון
          </RespFont>
          <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
        </GenericDiv>
      </a>
      <div>אם משהו לא מובן או אינכם מצליחים יש לפנות למנהל המערכת</div>
    </div>
  );

  const fixes = (
    <div>
      <a
        target="_blank"
        rel="noreferrer"
        href={"https://youtu.be/w2rKQd--Oro"}
        style={{ textDecoration: "none" }}
      >
        <GenericDiv
          onClick={() => setWatchVideo(0)}
          background={user.color}
          style={{
            width: "max-content",
            padding: "10px 20px",
            margin: "20px auto",
            marginTop: "10px",
          }}
        >
          <RespFont pSize={"18px"} cSize={"20px"}>
            סרטון
          </RespFont>
          <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
        </GenericDiv>
      </a>
      <div>אם משהו לא מובן או אינכם מצליחים יש לפנות למנהל המערכת</div>
    </div>
  );

  const editInvitation = (
    <div>
      <a
        target="_blank"
        rel="noreferrer"
        href={"https://www.youtube.com/watch?v=uI6ELVL5g4s"}
        style={{ textDecoration: "none" }}
      >
        <GenericDiv
          onClick={() => setWatchVideo(1)}
          background={user.color}
          style={{
            width: "max-content",
            padding: "10px 20px",
            margin: "20px auto",
            marginTop: "10px",
          }}
        >
          <RespFont pSize={"18px"} cSize={"20px"}>
            סרטון
          </RespFont>
          <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
        </GenericDiv>
      </a>
      <div style={{ fontWeight: "bold" }}>לא לשכוח לשמור בסוף</div>
      <div>אם משהו לא מובן או אינכם מצליחים יש לפנות למנהל המערכת</div>
    </div>
  );

  const messages = (
    <div>
      <a
        target="_blank"
        rel="noreferrer"
        href={"https://youtu.be/MHCrp7JiZJ0"}
        style={{ textDecoration: "none" }}
      >
        <GenericDiv
          onClick={() => setWatchVideo(2)}
          background={user.color}
          style={{
            width: "max-content",
            padding: "10px 20px",
            margin: "20px auto",
            marginTop: "10px",
          }}
        >
          <RespFont pSize={"18px"} cSize={"20px"}>
            סרטון
          </RespFont>
          <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
        </GenericDiv>
      </a>
      <div>אם משהו לא מובן או אינכם מצליחים יש לפנות למנהל המערכת</div>
    </div>
  );

  const expenses = (
    <div>
      היכנסו לעמוד ההוצאות ובו תוכלו למלא את כל הוצאות החתונה כולל את מספר
      המנות, עלות המנה ולהוציא אקסל שיסכום לכם את כל הוצאות החתונה
    </div>
  );

  const iplan = (
    <div>
      <div>ראשית יש לייצא אקסל מהמערכת בדרך הבאה:</div>
      <div>תפריט, מוח המערכת, הפקת דוחות, אייפלאן, ייצוא</div>
      <div>
        לאחר מכן יש להוריד תבנית מהאתר של אייפלאן, למחוק את התוכן לדוגמא ולהדביק
        את האקסל שלי לפי הכותרות
      </div>
      <div>
        לטעון למערכת אייפלאן ולאחר מכן ללכת לסידור השולחנות ולבחור צד כלה בלבד
        מכיוון שאלה האורחים אשר אישרו הגעה ואילו צד חתן לא
      </div>
      <div>
        כמובן שלא באמת צד כלה אישרו וצד חתן לא, זו הדרך שלי להעביר לכם את כל
        הנתונים ולדאוג לזה שתושיבו רק את מי שצריך
      </div>
      <div>
        הקבוצות עצמן נשארו כמו במערכת כדי שיהיה לכם נוח לבחור את מי להושיב לצד
        מי, פשוט תפלטרו (באייפלאן) על צד כלה בלבד על מנת להושיב רק את מי שאישר
      </div>
    </div>
  );

  const mazminKesher = {
    q: "מזמין + קשר",
    a: (
      <div>
        <div style={{ fontWeight: "bold", marginBottom: "20px" }}>
          חובה חובה חובה לבצע את נושא ״המזמין + קשר״ שמוסבר בסרטון האקסל בדקה
          10:55, להלן סרטון ייעודי:
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://youtu.be/L4H_LYFxw_Y"}
          style={{ textDecoration: "none" }}
        >
          <GenericDiv
            background={user.color}
            style={{
              width: "max-content",
              padding: "10px 20px",
              margin: "20px auto",
              marginBottom: "0",
              marginTop: "10px",
            }}
          >
            <RespFont pSize={"18px"} cSize={"20px"}>
              סרטון
            </RespFont>
            <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
          </GenericDiv>
        </a>
      </div>
    ),
    s: { borderRight: "4px solid red" },
  };

  const fixCalls = {
    q: "תיקונים לאחר שיחות",
    a: (
      <div>
        <div style={{ fontWeight: "bold", marginBottom: "20px" }}>
          יש לצפות בסרטון זה לאחר סיום כל סבב שיחות, שעות השיחות הן 9-20 לכן
          מומלץ לחכות לשעה 20:00 ואז לסדר מה שצריך, במידה ולא תעשו חלק זה תוכלו
          להפסיד הרבה אורחים אז שווה לצפות כל פעם ולפעול במידת הצורך.
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://youtu.be/ee-VP7lnFTY"}
          style={{ textDecoration: "none" }}
        >
          <GenericDiv
            background={user.color}
            style={{
              width: "max-content",
              padding: "10px 20px",
              margin: "20px auto",
              marginBottom: "0",
              marginTop: "10px",
            }}
          >
            <RespFont pSize={"18px"} cSize={"20px"}>
              סרטון
            </RespFont>
            <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
          </GenericDiv>
        </a>
      </div>
    ),
    s: { borderRight: "4px solid red" },
  };

  const afatsa = {
    q: "הפצה עצמית",
    a: (
      <div>
        <div style={{ fontWeight: "bold", marginBottom: "20px" }}>
          מומלץ מאוד לשלוח קישור לאורחים בעת ההזמנה מ- 2 סיבות:
          <ul>
            <li>כל רשומה שתאשר הגעה דרך קישור זה - חצי מחיר!!</li>
            <li>
              נותן לכם את הביטחון לא לקחת בחשבון אורח שנותר בסיום התהליך ללא
              תשובה מכיוון ש״תקפתם״ אותו מכל הכיוונים - לא ענה בקישור ששלחתם לו,
              ל 3 הודעות + 4 שיחות שלנו
            </li>
          </ul>
        </div>
        <div style={{ fontWeight: "bold", marginBottom: "20px" }}>
          חשוב לבצע הפצה עצמית לאחר שכל האורחים במערכת כדי שהסדר (שם + קטגוריה
          שקבעתם לכל אורח) יישמר.
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://youtu.be/zLnpJ7Sherg"}
          style={{ textDecoration: "none" }}
        >
          <GenericDiv
            background={user.color}
            style={{
              width: "max-content",
              padding: "10px 20px",
              margin: "20px auto",
              marginBottom: "0",
              marginTop: "10px",
            }}
          >
            <RespFont pSize={"18px"} cSize={"20px"}>
              סרטון
            </RespFont>
            <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
          </GenericDiv>
        </a>
      </div>
    ),
    s: { borderRight: "4px solid rgb(33, 184, 143)" },
  };

  const gifts = {
    q: "מילוי מתנות",
    a: (
      <div>
        <div style={{ fontWeight: "bold", marginBottom: "20px" }}>
          כל הנתונים הרלוונטיים אצלינו במערכת, אז במקום לכתוב על דף את כל המתנות
          של האורחים כאשר אתם פותחים מעטפות, תקלו על עצמיכם בסדר ובנוחות ותצפו
          בסרטון הבא:
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://youtu.be/J488zddXWXE"}
          style={{ textDecoration: "none" }}
        >
          <GenericDiv
            background={user.color}
            style={{
              width: "max-content",
              padding: "10px 20px",
              margin: "20px auto",
              marginBottom: "0",
              marginTop: "10px",
            }}
          >
            <RespFont pSize={"18px"} cSize={"20px"}>
              סרטון
            </RespFont>
            <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
          </GenericDiv>
        </a>

        <div style={{ fontWeight: "bold", marginTop: "20px" }}>
          המערכת תוציא לכם אקסל מסודר להמשך החיים שתדעו אתם והמשפחות כיצד להחזיר
          בעתיד לאורחים.
        </div>
      </div>
    ),
    s: { borderRight: "4px solid rgb(33, 184, 143)" },
  };

  const editClos = {
    q: "עריכת קירבה מרובה",
    a: (
      <div>
        <div style={{ fontWeight: "bold", marginBottom: "20px" }}>
          במידה והעלתם אורחים בצורה לא מסודרת ואתם רוצים לפצל לקבוצות ניתן לעשות
          זו בבת אחת למספר אורחים ולא אחד אחד:
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://youtu.be/EzzfIE9ITgM"}
          style={{ textDecoration: "none" }}
        >
          <GenericDiv
            background={user.color}
            style={{
              width: "max-content",
              padding: "10px 20px",
              margin: "20px auto",
              marginBottom: "0",
              marginTop: "10px",
            }}
          >
            <RespFont pSize={"18px"} cSize={"20px"}>
              סרטון
            </RespFont>
            <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
          </GenericDiv>
        </a>
      </div>
    ),
    s: { borderRight: "4px solid rgb(33, 184, 143)" },
  };

  const iphoneApp = {
    q: "הורדת האפליקציה לאייפון",
    a: <img src={iA} alt={"iA"} style={{ width: "100%", margin: "0 auto" }} />,
    s: { borderRight: "4px solid rgb(33, 184, 143)" },
  };

  const andApp = {
    q: "הורדת האפליקציה לאנדרואיד",
    a: (
      <img
        src={andA}
        alt={"andA"}
        style={{ width: "100%", margin: "0 auto" }}
      />
    ),
    s: { borderRight: "4px solid rgb(33, 184, 143)" },
  };

  const oshava = {
    q: "הושבה במערכת",
    a: (
      <div>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://youtu.be/-zJJbBDIXNo"}
          style={{ textDecoration: "none" }}
        >
          <GenericDiv
            background={user.color}
            style={{
              width: "max-content",
              padding: "10px 20px",
              margin: "20px auto",
              marginBottom: "0",
              marginTop: "10px",
            }}
          >
            <RespFont pSize={"18px"} cSize={"20px"}>
              סרטון
            </RespFont>
            <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
          </GenericDiv>
        </a>
      </div>
    ),
    s: { borderRight: "4px solid rgb(33, 184, 143)" },
  };

  const ishur = {
    q: "עריכת אישור הגעה",
    a: editInvitation,
    s: { borderRight: "4px solid red" },
  };

  const arrangementOptions = (
    <div>
      <div>פירוט אופציות ההושבה לפי הסדר:</div>
      <div style={{ textAlign: "right", padding: "0 10px", marginTop: "10px" }}>
        <ul>
          <li>ללא הושבה כלל - האורחים פשוט יושבים היכן שרוצים</li>
          <li>הכוונה באמצעות פתקים ״שמור ____״</li>
          <li>הושבה דיגיטלית בלבד</li>
          <li>
            הושבה דיגיטלית + פתקים ״שמור ____״ כדי לתת מענה למי שלא פתח את
            ההודעות עם מספר השולחן
          </li>
          <li>
            הושבה דיגיטלית + דיילת כדי לתת מענה למי שלא פתח את ההודעות עם מספר
            השולחן
          </li>
          <li>הושבה דיגיטלית עם חלופה לדיילת על מנת לחסוך בעלות השירות</li>
          <li>
            סימון כל אורח שנכנס ומתן פתק עם מספר השולחן שלו באמצעות צוות דיילות
          </li>
        </ul>
      </div>
      <a
        target="_blank"
        rel="noreferrer"
        href={"https://youtu.be/xCx4DpaEsZk"}
        style={{ textDecoration: "none" }}
      >
        <GenericDiv
          background={user.color}
          style={{
            width: "max-content",
            padding: "10px 20px",
            margin: "20px auto",
            marginTop: "10px",
          }}
        >
          <RespFont pSize={"18px"} cSize={"20px"}>
            סרטון
          </RespFont>
          <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
        </GenericDiv>
      </a>
      <div>
        לכל אופציה היתרונות והחסרונות שלה, וכל אירוע וה״ווייב״ שלו, חשוב רק
        להבין את הדברים ורק אז להחליט מה הכי נכון עבורכם 🙏
      </div>
    </div>
  );

  const optsOsh = {
    q: "אופציות הושבה",
    a: arrangementOptions,
    s: { borderRight: "4px solid red" },
  };

  const tablesArrangement = (
    <div>
      <div>מומלץ לשים על מהירות 1.5</div>
      <div>לצפות עד הסוף!</div>
      <a
        target="_blank"
        rel="noreferrer"
        href={
          user.producer === "/ohad"
            ? "https://youtu.be/dNoUFu7K6as"
            : "https://youtu.be/-zJJbBDIXNo"
        }
        style={{ textDecoration: "none" }}
      >
        <GenericDiv
          background={user.color}
          style={{
            width: "max-content",
            padding: "10px 20px",
            margin: "20px auto",
            marginTop: "10px",
          }}
        >
          <RespFont pSize={"18px"} cSize={"20px"}>
            סרטון
          </RespFont>
          <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
        </GenericDiv>
      </a>
      <div>עדיף לעבוד מהמחשב</div>
      <div>
        בבקשה שכל צד יושיב את האורחים שלו כדי שבאמת תדעו לשים אנשים שקשורים אחד
        לשני ובכך למנוע מצב בו האורחים לא יקשיבו ויהיה בלאגן
      </div>
    </div>
  );

  const otsaot = {
    q: "מילוי הוצאות",
    a: expenses,
    s: { borderRight: "4px solid rgb(33, 184, 143)" },
  };

  const iplanS = {
    q: "אייפלאן",
    a: iplan,
    s: { borderRight: "4px solid rgb(33, 184, 143)" },
  };

  const content = [
    // {
    //   q: "הוספת אורחים למערכת",
    //   a: addGuests,
    //   s: { borderRight: "4px solid red" },
    // },
    {
      q: "עריכת הזמנה",
      a: editInvitation,
      s: { borderRight: "4px solid red" },
    },
    // {
    //   q: "שליחת הודעות",
    //   a: messages,
    //   s: { borderRight: "4px solid red" },
    // },
    {
      q: "מילוי הוצאות",
      a: expenses,
      s: { borderRight: "4px solid rgb(33, 184, 143)" },
    },
    {
      q: "ייצוא לאייפלאן",
      a: iplan,
      s: { borderRight: "4px solid rgb(33, 184, 143)" },
    },
    {
      q: "מילוי מתנות",
      a: gifts,
      s: { borderRight: "4px solid rgb(0, 110, 255)" },
    },
    {
      q: "אופציות הושבה",
      a: arrangementOptions,
      s: { borderRight: "4px solid red" },
    },
    {
      q: "הושבה במערכת",
      a: tablesArrangement,
      s: { borderRight: "4px solid red" },
    },
    {
      q: "תיקונים",
      a: fixes,
      s: { borderRight: "4px solid red" },
    },
    {
      q: "עריכת קירבה מרובה",
      a: editClos,
      s: { borderRight: "4px solid rgb(33, 184, 143)" },
    },
  ];
  const [ans, setAns] = useState(null);
  let fff = content.map((item, index) => {
    return (
      <Questions
        style={{ ...item.s, height: "60px" }}
        key={index}
        onClick={() => setAns(item)}
      >
        {item.q}
      </Questions>
    );
  });
  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }
  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  return (
    <MainDiv
      onClick={() => setIsOpen(false)}
      className="animate__animated animate__fadeIn"
      style={{
        background: `radial-gradient(circle, ${addAlpha(
          user.color,
          0.25
        )} 0%, #f2f2f2 100%)`,
      }}
    >
      <Menu
        noOverlay
        isOpen={isOpen}
        onStateChange={handleStateChange}
        right
        width={"max-content"}
        styles={MenuCss(user.color)}
      >
        <div
          className="menu-item"
          style={{ marginTop: "0px" }}
          onClick={() => history.push(ClientRoutes.User.guests)}
        >
          <div>מוזמנים</div>
          <img src={guests1} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.tables)}
        >
          <div>מערך שולחנות</div>
          <img src={tables} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.instructions)}
        >
          <div>הדרכות</div>
          <img src={video} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.editDetails)}
        >
          <div>עריכת הזמנה</div>
          <img src={editInv} style={{ width: "23px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.todos)}
        >
          <div>יומן משימות</div>
          <img src={todoList} style={{ width: "28px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.expenses)}
        >
          <div>יומן הוצאות</div>
          <img src={expenses1} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.print)}
        >
          <div>מוח המערכת</div>
          <img src={brain} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          style={{
            position: "absolute",
            bottom: "20px",
            fontWeight: "bold",
            fontSize: "20px",
          }}
          onClick={() => setLogout(true)}
        >
          <div>התנתקות</div>
          <img src={logout1} style={{ width: "25px" }} />
        </div>
      </Menu>
      <RespFont
        style={{
          margin: "10px auto",
          fontFamily: "openSansRegular",
          fontWeight: "600",
          textShadow: "2px 2px 2px silver",
        }}
        pSize={"22px"}
        cSize={"30px"}
      >
        הדרכות
      </RespFont>
      <FlexRowBetween
        style={{
          marginTop: "30px",
          padding: "0 20px",
          justifyContent: "space-evenly",
          textAlign: "center",
        }}
      >
        קיימות מספר אופציות להוסיף את האורחים למערכת, אפשר גם לשלב מספר דרכים
        ולעבוד מכמה מכשירים במקביל - מה שנוח לכם
      </FlexRowBetween>
      <FlexRowBetween
        style={{ justifyContent: "space-evenly", marginTop: "30px" }}
      >
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://youtu.be/CUeyjYHAGX8"}
          style={{ textDecoration: "none" }}
        >
          <GenericDiv
            background={user.color}
            style={{
              width: "max-content",
              padding: "10px 20px",
            }}
          >
            <RespFont pSize={"16px"} cSize={"20px"}>
              אקסל
            </RespFont>
            <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
          </GenericDiv>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://youtu.be/NfvVyX0FrQ8"}
          style={{ textDecoration: "none" }}
        >
          <GenericDiv
            background={user.color}
            style={{
              width: "max-content",
              padding: "10px 20px",
            }}
          >
            <RespFont pSize={"16px"} cSize={"20px"}>
              ידני
            </RespFont>
            <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
          </GenericDiv>
        </a>
      </FlexRowBetween>
      <FlexRowBetween
        style={{ justifyContent: "space-evenly", marginTop: "30px" }}
      >
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://youtu.be/j3Z6J1XTT7g"}
          style={{ textDecoration: "none" }}
        >
          <GenericDiv
            background={user.color}
            style={{
              width: "max-content",
              padding: "10px 20px",
            }}
          >
            <RespFont pSize={"16px"} cSize={"20px"}>
              אייפון
            </RespFont>
            <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
          </GenericDiv>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://youtu.be/fnWimdfjcds"}
          style={{ textDecoration: "none" }}
        >
          <GenericDiv
            background={user.color}
            style={{
              width: "max-content",
              padding: "10px 20px",
            }}
          >
            <RespFont pSize={"16px"} cSize={"20px"}>
              אנדרואיד
            </RespFont>
            <img src={video1} style={{ width: "30px", marginRight: "20px" }} />
          </GenericDiv>
        </a>
      </FlexRowBetween>
      <FlexRowBetween
        style={{
          marginTop: "30px",
          padding: "0 20px",
          justifyContent: "space-evenly",
          textAlign: "center",
        }}
      >
        גם אם אתם לא מוסיפים אורחים למערכת דרך אקסל - עליכם לצפות בסרטון אקסל
        החל מדקה 08:12 עד הסוף כדי לראות דברים אחרים חשובים.
      </FlexRowBetween>
      <FlexRowBetween
        style={{
          marginTop: "30px",
          padding: "0 20px",
          justifyContent: "space-evenly",
          textAlign: "center",
        }}
      >
        לאחר שכל האורחים במערכת יש לאשר אורחים שאינכם רוצים שנבדוק איתם הגעה
        (משפחה גרעינית/ סבים וסבתות/ מלויים ומלוות או כל מי שתרצו) ע״י חיפוש
        ועריכת שדה ״אישרו״ בהתאם לכמות שתגיע (לדוגמא אם יגיעו זוג אז לסמן 2 בשדה
        ״אישרו״ ולשמור. אורחים אלה בעצם לא יחושבו בתשלום בגלל שאנו לא בודקים
        איתם הגעה, עדיין יקבלו הודעת תזכורת בוקר האירוע, יהיו חלק מההושבה ויקבלו
        הודעת תודה למחורת, פשוט בחינם! תחשבו טוב טוב את מי אתם מאשרים שלא יווצר
        מצב שניסיתם לחסוך כמה שקלים אבל בסוף תפסידו הרבה מנות)
      </FlexRowBetween>
      <FlexRowBetween
        style={{
          marginTop: "30px",
          padding: "0 20px",
          justifyContent: "space-evenly",
          textAlign: "center",
        }}
      >
        חשוב מאוד להבין שכאשר אתם מוסיפים אנשים למערכת אולי אתם מזהים אותם לפי
        כינוי אבל ברגע ההושבה שדיילת מחפשת אותם במערכת היא לעולם לא תמצא 🤣😂,
        אז במידה ואתם עושים הושבה יש לרשום את שם האורח כפי שהוא, כמובן שגם אם
        הוספתם מישהו עם כינוי תוכלו אחרי זה לעבור ולשנות - יש לשים לב לנושא הזה.
      </FlexRowBetween>
      <FlexRowBetween style={{ width: "90%", margin: "20px auto" }}>
        <GenericDiv
          style={{ width: "30%", padding: "10px 0" }}
          background={"red"}
        >
          חובה
        </GenericDiv>
        <GenericDiv
          style={{ width: "30%", padding: "10px 0" }}
          background={"rgb(0, 110, 255)"}
        >
          מומלץ
        </GenericDiv>
        <GenericDiv
          style={{ width: "30%", padding: "10px 0" }}
          background={"rgb(33, 184, 143)"}
        >
          אופציונאלי
        </GenericDiv>
      </FlexRowBetween>

      <RespFont style={{ fontWeight: "bold" }}>
        מומלץ לשים על מהירות 1.5
      </RespFont>
      <ServicesHolder>
        <Questions
          style={{ borderRight: "4px solid red", height: "60px" }}
          // key={index}
          onClick={() => setAns(mazminKesher)}
        >
          מזמין + קשר - חובה חובה חובה!!
        </Questions>
        <Questions
          style={{ borderRight: "4px solid red", height: "60px" }}
          // key={index}
          onClick={() => setAns(ishur)}
        >
          {ishur.q}
        </Questions>
        <Questions
          style={{ borderRight: "4px solid rgb(0, 110, 255)", height: "60px" }}
          // key={index}
          onClick={() => setAns(afatsa)}
        >
          {afatsa.q}
        </Questions>
        <Questions
          style={{ borderRight: "4px solid red", height: "60px" }}
          // key={index}
          onClick={() => setAns(fixCalls)}
        >
          {fixCalls.q}
        </Questions>
        <Questions
          style={{ borderRight: "4px solid rgb(0, 110, 255)", height: "60px" }}
          // key={index}
          onClick={() => setAns(oshava)}
        >
          {oshava.q}
        </Questions>
        <Questions
          style={{ borderRight: "4px solid rgb(0, 110, 255)", height: "60px" }}
          // key={index}
          onClick={() => setAns(iphoneApp)}
        >
          {iphoneApp.q}
        </Questions>
        <Questions
          style={{ borderRight: "4px solid rgb(0, 110, 255)", height: "60px" }}
          // key={index}
          onClick={() => setAns(andApp)}
        >
          {andApp.q}
        </Questions>
        <Questions
          style={{ borderRight: "4px solid rgb(0, 110, 255)", height: "60px" }}
          // key={index}
          onClick={() => setAns(gifts)}
        >
          {gifts.q}
        </Questions>
        <Questions
          style={{ borderRight: "4px solid rgb(33, 184, 143)", height: "60px" }}
          // key={index}
          onClick={() => setAns(optsOsh)}
        >
          {optsOsh.q}
        </Questions>
        <Questions
          style={{ borderRight: "4px solid rgb(33, 184, 143)", height: "60px" }}
          // key={index}
          onClick={() => setAns(otsaot)}
        >
          {otsaot.q}
        </Questions>
        <Questions
          style={{ borderRight: "4px solid rgb(33, 184, 143)", height: "60px" }}
          // key={index}
          onClick={() => setAns(iplanS)}
        >
          {iplanS.q}
        </Questions>
        <Questions
          style={{ borderRight: "4px solid rgb(33, 184, 143)", height: "60px" }}
          // key={index}
          onClick={() => setAns(editClos)}
        >
          {editClos.q}
        </Questions>
      </ServicesHolder>
      {ans && (
        <GenericModal
          init={setAns}
          div={<TitleAndContent title={ans.q} content={ans.a} init={setAns} />}
        />
      )}
      {logout && (
        <GenericModal init={setLogout} div={<LogOutAll init={setLogout} />} />
      )}
    </MainDiv>
  );
};

export default Instruction;
