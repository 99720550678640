import React, { useMemo, useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Switch from "react-switch";
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import OpacityLoading from '../../../common/loader/OpacityLoading'
import GenericModal from '../../../common/modals/GenericModal';
import direction from '../../../../assets/client/direction.svg';
import whatsapp from '../../../../assets/client/whatsapp1Black.svg';
import call from '../../../../assets/client/call1Black.svg';
import { editStatus, deletePotential, getAll, cleanAll } from '../../../../store/reducers/potentialsReducer';
import YesNoModal from '../../../common/modals/YesNoModal';
import { FlexRowBetween, TodoCard, TodosHolder, MainDiv, GenericButton } from '../../../common/Style';
import { GetToast } from '../../../common/modals/Toast';
import Dropdown from 'react-bootstrap/Dropdown'
import { Link } from 'react-router-dom';
import EditPotential from './EditPotential';
import ClientRoutes from '../../../../navigation/Routes';

const Potentials = () => {
    const [sortDirection, setSortDirection] = useState(false);
    const [sortBy, setSortBy] = useState("");
    const [editTodoS, setEditTodo] = useState(false);
    const [deleteTodoS, setDeleteTodo] = useState(false);
    const dispatch = useDispatch();
    const { potentials, potentialsError, potentialsLoading } = useSelector(state => state.potentialsReducer);
    const { user } = useSelector(state => state.authReducer);
    const { addToast } = useToasts();

    const getTodos = () => {
        dispatch(getAll())
    }

    useEffect(() => {
        getTodos()
        return () => {
            dispatch(cleanAll());
        }
    }, []);

    useEffect(() => {
        if (potentialsError)
            GetToast(addToast, "error", potentialsError)
    }, [potentialsError]);

    const setDone = async (id, done) => {
        dispatch(editStatus(id, { done })).then((res) => {
            if (!res.payload?.error)
                GetToast(addToast, "success", "הפעולה בוצעה")
        })
    }

    const deleteTodoFunc = async () => {
        dispatch(deletePotential(deleteTodoS)).then((res) => {
            if (!res.payload?.error)
                GetToast(addToast, "success", "הפעולה בוצעה")
        })
    }


    let todosArray = useMemo(() => {
        let arr = []
        let sortedArray = [];
        if (Object.keys(potentials).length > 0) {
            for (const [key, value] of Object.entries(potentials))
                sortedArray.push(value)

            switch (sortBy) {
                case "dateAdded":
                    if (sortDirection) sortedArray.sort((a, b) => new Date(b[sortBy]) - new Date(a[sortBy]));
                    else sortedArray.sort((a, b) => new Date(a[sortBy]) - new Date(b[sortBy]));
                    break;
                default:
                    if (sortDirection) sortedArray.sort((a, b) => (b[sortBy]) - (a[sortBy]));
                    else sortedArray.sort((a, b) => (a[sortBy]) - (b[sortBy]));
            }

            for (let i = 0; i < sortedArray.length; i++) {
                arr.push(<TodoCard color={user.color} key={i} onClick={() => setEditTodo(sortedArray[i])}>
                    <div className='info'>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div>{sortedArray[i].name}</div>
                            <a target="_blank" rel="noreferrer" href={`https://wa.me/972${sortedArray[i].phone}/?text=${""}`}
                                style={{ marginRight: "20px" }}>
                                <img alt="whatsapp" src={whatsapp} style={{ width: `25px` }} /></a>
                            <a href={"tel:" + ((sortedArray[i].phone)[0] === "0" ? sortedArray[i].phone : ("0" + sortedArray[i].phone + ""))}
                                style={{ marginRight: "20px" }}>
                                <img alt="call" style={{ width: `25px` }} src={call} /></a>
                        </div>
                        <OverlayTrigger key={(i + 1 * 1000)} placement={'bottom'} overlay={<Tooltip>{"סטטוס"}</Tooltip>}>
                            <div onClick={(e) => e.stopPropagation()}>
                                <Switch
                                    width={34}
                                    height={14}
                                    onColor={'#b1b1b1'}
                                    offColor={'#b1b1b1'}
                                    onHandleColor={user.color}
                                    boxShadow={'0 2px 4px silver, 0 -1px 4px silver'}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    checked={sortedArray[i].done}
                                    onChange={() => setDone(sortedArray[i]._id, !sortedArray[i].done)}
                                    handleDiameter={20}
                                />
                            </div>
                        </OverlayTrigger>
                    </div>
                </TodoCard>);
            }
        }
        return arr;
    }, [potentials, sortBy, sortDirection])

    function addAlpha(color, opacity) {
        const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return color + _opacity.toString(16).toUpperCase();
    }

    return (
        <MainDiv className="animate__animated animate__fadeIn" style={{
            overflow: "hidden", background: `radial-gradient(circle, ${addAlpha(user.color, 0.25)} 0%, #f2f2f2 100%)`, maxHeight: "100vh"
        }}>
            <GenericButton background={"#fda14b"} as={Link} to={ClientRoutes.Admin.main}
                style={{ fontSize: "14px", position: "absolute", right: "25px", top: "18px", zIndex: 9 }}>ראשי</GenericButton>

            <OverlayTrigger placement={'bottom'} overlay={<Tooltip>{"מיון"}</Tooltip>}>
                <Dropdown style={{
                    position: "absolute", left: "25px", top: "18px", zIndex: 9
                }}>
                    <Dropdown.Toggle style={{
                        background: user.color, border: "none", height: "32px", width: "30px", borderRadius: "20%", display: "flex",
                        justifyContent: "center", alignItems: "center"
                    }} id="dropdown-basic">

                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ textAlign: "right" }}>
                        <Dropdown.Item onClick={() => setSortBy("done")}>סטטוס</Dropdown.Item>
                        <Dropdown.Item onClick={() => setSortBy("dateAdded")}>תאריך</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </OverlayTrigger>

            <OverlayTrigger placement={'bottom'} overlay={<Tooltip>{"כיוון"}</Tooltip>}>
                <div onClick={() => setSortDirection(!sortDirection)} style={{
                    position: "absolute", left: "82px", top: "18px", zIndex: 9,
                    background: user.color, padding: "4px 4px", borderRadius: "20%", cursor: "pointer"
                }}>
                    <img src={direction} alt="direction" style={{ width: "20px", paddingBottom: "1.8px" }} />
                </div>
            </OverlayTrigger>

            <FlexRowBetween style={{
                width: "100%", padding: "20px 5%", alignItems: "center", position: "fixed", zIndex: 1, justifyContent: "center"
                , background: `#f2f2f2`, paddingRight: "5%", boxShadow: "0 2px 20px silver", paddingBottom: "15px"
            }}>
                <div style={{ fontSize: "22px", fontWeight: "bold", textAlign: "center", color: "black" }}>פניות</div>
            </FlexRowBetween>

            <TodosHolder style={{ marginTop: "70px" }}>
                {todosArray}
            </TodosHolder>


            {editTodoS && <GenericModal
                init={setEditTodo}
                div={<EditPotential setDeleteTodo={setDeleteTodo} data={editTodoS} init={setEditTodo} />}
            />}

            {deleteTodoS && <GenericModal
                init={setDeleteTodo}
                div={<YesNoModal
                    color={user.color}
                    title={"מחיקת פניה"}
                    body={"האם את/ה בטוח/ה?"}
                    no={setDeleteTodo}
                    yes={deleteTodoFunc}
                />}
            />}

            {potentialsLoading && <OpacityLoading />}
        </MainDiv>)
}

export default Potentials;