import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import dropdown1 from "../../../assets/client/dropdown1.svg";
import errors from "../../../assets/print/errors1.svg";
import shield from "../../../assets/print/shield.svg";
import clear from "../../../assets/client/clear.svg";
import upload from "../../../assets/client/upload.svg";

import delete1 from "../../../assets/client/delete1.svg";
import {
  CenteredForm,
  GenericButton,
  GenericDiv,
  RespFont,
  FlexRowBetween,
  InputBox,
  DropOption,
  PlaceHolderImage,
} from "../../common/Style";
import { GetToast } from "../../common/modals/Toast";
import ExitModal from "../../common/modals/ExitModal";
import { postCsv } from "../../../store/reducers/dataReducer";
import OpacityLoading from "../../common/loader/OpacityLoading";
import ColorCheckbox from "../../common/others/ColorCheckbox";
import { cardContentClasses } from "@mui/material";
let inputs = {};
let inputsErrors = {};
let multyChoise = {};

const AddGuestsFromC = (props) => {
  const { user } = useSelector((state) => state.authReducer);
  const defaultPreSms1 = `${user.brideFN + " " + user.brideLN} ו${
    user.groomFN + " " + user.groomLN
  } שמחים להזמינכם לחתונתם!`;
  const [doneB, setDoneB] = useState(true);
  const [isMultyChoiseChanged, setIsMultyChoiseChanged] = useState(false);
  const [arrC, setArrC] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [inviting, setInviting] = useState("");

  const [dis, setDis] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [contactsT, setContactsT] = useState([]);
  useEffect(() => {
    const supported = "contacts" in navigator && "ContactsManager" in window;
    if (supported) {
      setDis(false);
    }
  }, []);

  const add_del = (item) => {
    if (multyChoise[item._id] !== undefined) {
      delete multyChoise[item._id];
    } else multyChoise[item._id] = { tel: item.tel, name: item.name };
    setIsMultyChoiseChanged(!isMultyChoiseChanged);
  };

  let iPhoneArray = useMemo(() => {
    let ii = [];
    if (arrC !== null)
      for (let i = 0; i < arrC.length; i++) {
        if (
          arrC[i].name !== undefined &&
          (searchText.length === 0 ||
            arrC[i].name?.toLowerCase().includes(searchText.toLowerCase()))
          // searchText
          //   .toLowerCase()
          //   .split(" ")
          //   .filter((i) =>
          //     sortedArray[i]["FN"]?.toLowerCase().includes(i.toLowerCase())
          //   ).length > 0)
          // ).length === searchText.toLowerCase().split(" ").length
        )
          ii.push(
            <FlexRowBetween
              key={i}
              style={{ justifyContent: "flex-start", width: "min-content" }}
            >
              <ColorCheckbox
                checked={
                  multyChoise[arrC[i]._id] !== undefined ||
                  props.phones[arrC[i].tel] === true
                }
                disabled={props.phones[arrC[i].tel] === true}
                color={
                  props.phones[arrC[i].tel] === true
                    ? addAlpha(user.color, 0.5)
                    : user.color
                }
                handleCHange={() => {
                  if (props.phones[arrC[i].tel] === true)
                    GetToast(addToast, "warning", "כבר קיים ברשימת האורחים");
                  else
                    add_del({
                      _id: arrC[i]._id,
                      name: arrC[i].name,
                      tel: arrC[i].tel,
                    });
                }}
              />
              <RespFont style={{ width: "max-content" }}>
                {arrC[i].name}
              </RespFont>
            </FlexRowBetween>
          );
      }
    return ii;
  }, [searchText, arrC, isMultyChoiseChanged]);

  // vcf iphone start
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const vcfText = event.target.result;
      const vcfData = parseVcf(vcfText);
      const cc = [];
      const sortedArray = [...vcfData.entries];
      sortedArray.sort(function (a, b) {
        var x = a["FN"];
        var y = b["FN"];

        if (typeof x == "string") {
          x = ("" + x).toLowerCase();
        }
        if (typeof y == "string") {
          y = ("" + y).toLowerCase();
        }

        return x < y ? -1 : x > y ? 1 : 0;
      });
      let tn = "";
      for (let i = 0; i < sortedArray.length; i++) {
        let key = Object.keys(sortedArray[i]).filter((x) => x.includes("TEL"));
        let t = sortedArray[i][key[0]];
        if (t !== undefined) {
          t = t.replaceAll(/[^\d]/g, "");
          if (t[0] === "9" && t[1] === "7" && t[2] === "2") t = t.substring(3);
          if (t[0] === "0") t = t.substring(1);
        }
        cc.push({
          _id: i,
          name: sortedArray[i]["FN"],
          tel: t,
        });
        // cc.push(
        //   <FlexRowBetween
        //     key={i}
        //     style={{ justifyContent: "flex-start", width: "min-content" }}
        //   >
        //     <ColorCheckbox
        //       // checked={transportation}
        //       color={user.color}
        //       handleCHange={() =>
        //         add_del({
        //           _id: i,
        //           name: sortedArray[i]["FN"],
        //           tel: t,
        //         })
        //       }
        //     />
        //     <RespFont style={{ width: "max-content" }}>
        //       {sortedArray[i]["FN"]}
        //     </RespFont>
        //   </FlexRowBetween>
        // );
      }
      setArrC(cc);
    };
    reader.readAsText(file, "UTF-8");
  };
  const parseVcf = (vcfText) => {
    const lines = vcfText.split(/\r?\n/);
    const vcfData = {
      meta: {},
      entries: [],
    };
    let currentEntry = null;
    for (let line of lines) {
      if (line.startsWith("BEGIN:VCARD")) {
        currentEntry = {};
      } else if (line.startsWith("END:VCARD")) {
        vcfData.entries.push(currentEntry);
        currentEntry = null;
      } else if (currentEntry !== null) {
        const [key, value] = line.split(":");
        currentEntry[key] = value;
      } else if (line.startsWith("FN:")) {
        vcfData.meta.name = line.substring(3);
      } else if (line.startsWith("VERSION:")) {
        vcfData.meta.version = line.substring(8);
      }
    }
    return vcfData;
  };
  // vcf iphone end

  async function getContacts() {
    const props = ["name", "tel"];
    const opts = { multiple: true };
    try {
      const contacts = await navigator.contacts.select(props, opts);
      renderResults(contacts);
    } catch (ex) {
      alert(ex.toString());
    }
  }

  function renderResults(contacts) {
    const tempG = [];
    contacts.forEach((contact) => {
      let tempO = {};
      if (contact.name) tempO["name"] = contact.name[0];
      if (contact.tel) {
        let t = contact.tel[0];
        if (t[0] === "0") t = t.substring(1);
        t = t.replaceAll("-", "");
        t = t.replaceAll(/[^\d]/g, "");
        if (t[0] === "9" && t[1] === "7" && t[2] === "2") t = t.substring(3);
        tempO["tel"] = t;
      }
      tempG.push(tempO);
    });
    setContacts(tempG);
    setDoneB(false);
  }
  let height = window.screen.availHeight;
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { register, handleSubmit } = useForm();
  const [closeness, setCloseness] = useState("");
  const [limit, setLimit] = useState(true);
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState(true);
  const [owc, setOwc] = useState("קשר");
  const [showDropOwc, setShowDropOwc] = useState(false);
  const [edit, setEdit] = useState(false);
  const width = window.screen.availWidth;
  const [animate, setAnimate] = useState(
    "animate__animated animate__heartBeat animate__infinite"
  );
  const handleOnSelect = (item) => {
    setCloseness(item.id);
  };
  const handleOnSearch = (string, results) => {
    setCloseness(string);
  };

  const digitOrNull = (value) =>
    parseInt(value) >= 0 ? parseInt(value) : null;

  const onSubmit = async (data) => {
    setChecked(false);
    let rows = [
      ["שם מלא", "טלפון", "קירבה", "מוזמנים", "אישרו", "מזמין", "קשר", "מספר"],
    ];
    let newOwc = "";
    if (
      user.eventType &&
      ((data?.inviting.length && owc === "קשר") ||
        (data?.inviting.length === 0 && owc !== "קשר"))
    )
      GetToast(addToast, "warning", "שדה המזמין והקשר באים ביחד - הכל או כלום");
    else if (closeness.length > 1) {
      newOwc = owc;
      for (let i = 0; i < contacts.length; i++) {
        if (inputs[i] !== false)
          rows.push([
            data[`${i}name`],
            data[`${i}phone`].length ? data[`${i}phone`] : null,
            closeness,
            digitOrNull(data[`${i}sum`]),
            digitOrNull(data[`${i}accept`]),
            data.inviting,
            newOwc === "קשר" ? "" : newOwc,
            i + 1,
          ]);
      }
      if (rows.length === 1)
        GetToast(addToast, "warning", "לא נבחרו רשומות להוספה");
      else {
        dispatch(postCsv({ rows })).then((res) => {
          setChecked(true);
          if (!res.payload?.error) {
            GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
            setTimeout(function () {
              setLoading(false);
              multyChoise = {};
              inputs = {};
              setContacts([]);
              props.init();
            }, 2000);
          } else {
            multyChoise = {};
            setLoading(false);
            GetToast(
              addToast,
              "error",
              "לא כל הרשומות נטענו בהצלחה, ראו פירוט בכל שורה"
            );

            for (let i = 0; i < contacts.length; i++) inputs[i] = false;
            for (let i = 0; i < res.payload?.error.length; i++) {
              inputs[res.payload?.error[i]["מספר"] - 1] = true;
              inputsErrors[res.payload?.error[i]["מספר"] - 1] =
                res.payload?.error[i]["סיבה"];
            }

            setFlag(!flag);
          }
        });
      }
    } else
      GetToast(addToast, "warning", "שדה קירבה לא יכול להיות פחות מ 2 תווים");
  };
  const handleSwitch = (e, i) => {
    inputs[i] = e;
    setFlag(!flag);
  };

  let guestsArray = useMemo(() => {
    let guests = [];
    for (let i = 0; i < contacts.length; i++) {
      guests.push(
        <div
          key={i}
          style={{
            display: checked && inputs[i] === false ? "none" : "flex",
            flexDirection: "column",
            width: "94%",
            margin: "0 auto",
            padding: "10px 15px",
            backgroundColor: addAlpha(user.color, 0.15),
            marginTop: "10px",
            borderRadius: "5px",
            paddingTop: "0px",
          }}
        >
          <FlexRowBetween>
            <div style={{ position: "relative", width: "50%" }}>
              <input
                type="text"
                name={`${i}name`}
                defaultValue={contacts[i]["name"]}
                minLength={2}
                maxLength={50}
                required={true}
                ref={register()}
              />
              <label>שם מלא</label>
            </div>
            <div style={{ position: "relative", width: "40%" }}>
              <input
                type="text"
                name={`${i}phone`}
                defaultValue={contacts[i]["tel"]}
                pattern={limit && "[0]{0,1}[5]{1}[0-9]{8}"}
                ref={register()}
              />
              <label>מס' פלאפון</label>
            </div>
          </FlexRowBetween>
          <FlexRowBetween style={{ alignItems: "flex-end" }}>
            <div style={{ position: "relative", width: "30%" }}>
              <input
                type="number"
                name={`${i}sum`}
                defaultValue={1}
                min={1}
                max={limit ? 30 : 1000}
                required={true}
                ref={register()}
              />
              <label>כמות</label>
            </div>
            <div style={{ position: "relative", width: "30%" }}>
              <input
                type="number"
                name={`${i}accept`}
                min={0}
                max={limit ? 30 : 1000}
                ref={register()}
              />
              <label>אישרו</label>
            </div>

            {checked && inputs[i] && (
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip>
                    {inputsErrors[i] !== undefined && inputsErrors[i] !== ""
                      ? inputsErrors[i]
                      : "רשומה זו נכנסה בהצלחה!"}
                  </Tooltip>
                }
              >
                <img
                  src={
                    inputsErrors[i] !== undefined && inputsErrors[i] !== ""
                      ? errors
                      : shield
                  }
                  style={{ width: "30px" }}
                  className={animate}
                />
              </OverlayTrigger>
            )}
          </FlexRowBetween>
        </div>
      );
    }

    return [guests];
  }, [flag, contacts.length, contacts, limit]);

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  const customInit = () => {
    multyChoise = {};
    props.init();
  };

  return (
    <CenteredForm
      onSubmit={handleSubmit(onSubmit)}
      style={{ height: "min-content", maxHeight: `${height * 0.8}px` }}
      onClick={(e) => e.stopPropagation()}
    >
      <ExitModal init={customInit} color={user.color} />
      <FlexRowBetween style={{ marginTop: "50px" }}>
        <div
          className="relativeBox"
          style={{
            zIndex: "1",
            marginRight: "5%",
            width: "60%",
          }}
        >
          <ReactSearchAutocomplete
            items={props.categories}
            onSelect={handleOnSelect}
            autoFocus
            onSearch={handleOnSearch}
            fuseOptions={{ minMatchCharLength: 1 }}
            maxResults={show ? 10 : 0}
            showNoResults={false}
            placeholder="קירבה"
            styling={{
              searchIconMargin: "0 5px 0 10px",
              clearIconMargin: "4px 0 0 5px",
              borderRadius: "5px",
              width: "100%",
              height: "30px",
            }}
          />
        </div>
        <OverlayTrigger
          placement={"bottom"}
          overlay={
            <Tooltip>
              {show ? "הסתרת השלמות לחיפוש" : "הצגת השלמות לחיפוש"}
            </Tooltip>
          }
        >
          <GenericDiv
            style={{ marginLeft: "5%", height: "30px", padding: "10px 15px" }}
            background={user.color}
            onClick={() => setShow(!show)}
          >
            {show ? "הסתר" : "הצג"}
          </GenericDiv>
        </OverlayTrigger>
      </FlexRowBetween>

      <InputBox labelColor={"gray"} color={"#3A3C3F"}>
        {user.eventType && (
          <FlexRowBetween
            style={{
              width: "90%",
              margin: "10px auto",
              marginBottom: "20px",
              alignItems: "flex-end",
            }}
          >
            <div style={{ position: "relative", width: "65%" }}>
              <input
                type="text"
                name="inviting"
                onChange={(e) => setInviting(e.target.value)}
                minLength={2}
                maxLength={50}
                ref={register()}
              />
              <label>המזמין</label>
            </div>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"הקשר של בעל/ת השמחה למזמין"}</Tooltip>}
            >
              <GenericDiv
                background={user.color}
                style={{
                  position: "relative",
                  height: "30px",
                  padding: "10px 10px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropOwc(!showDropOwc);
                }}
              >
                <div>{owc}</div>
                <img
                  src={dropdown1}
                  style={{ width: "25px", marginLeft: "-7px" }}
                />
                {showDropOwc && (
                  <div
                    style={{
                      position: "absolute",
                      left: "0",
                      top: "120%",
                      zIndex: 2,
                      color: user.color,
                      borderRadius: "5px",
                      backgroundColor: "white",
                      padding: "10px",
                      fontSize: "12px",
                      boxShadow: "0 2px 4px gray",
                    }}
                  >
                    <FlexRowBetween>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בנם");
                          setShowDropOwc(false);
                        }}
                      >
                        בנם
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בנה");
                          setShowDropOwc(false);
                        }}
                      >
                        בנה
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בתו");
                          setShowDropOwc(false);
                        }}
                      >
                        בתו
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בנו");
                          setShowDropOwc(false);
                        }}
                      >
                        בנו
                      </DropOption>
                      <DropOption
                        background={"black"}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("קשר");
                          setShowDropOwc(false);
                        }}
                        style={{ boxShadow: "0 2px 4px black" }}
                      >
                        <img src={delete1} style={{ width: "15px" }} />
                      </DropOption>
                    </FlexRowBetween>
                    <FlexRowBetween style={{ marginTop: "8px" }}>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בתם");
                          setShowDropOwc(false);
                        }}
                      >
                        בתם
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בתה");
                          setShowDropOwc(false);
                        }}
                      >
                        בתה
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדה");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדה
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדתם");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדתם
                      </DropOption>
                    </FlexRowBetween>
                    <FlexRowBetween style={{ marginTop: "8px" }}>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדם");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדם
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדתה");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדתה
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדתו");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדתו
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדו");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדו
                      </DropOption>
                    </FlexRowBetween>
                    <FlexRowBetween style={{ marginTop: "8px" }}>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחותה");
                          setShowDropOwc(false);
                        }}
                      >
                        אחותה
                      </DropOption>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחותו");
                          setShowDropOwc(false);
                        }}
                      >
                        אחותו
                      </DropOption>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחיו");
                          setShowDropOwc(false);
                        }}
                      >
                        אחיו
                      </DropOption>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחיה");
                          setShowDropOwc(false);
                        }}
                      >
                        אחיה
                      </DropOption>
                    </FlexRowBetween>
                  </div>
                )}
              </GenericDiv>
            </OverlayTrigger>
          </FlexRowBetween>
        )}
        {user.eventType && (
          <FlexRowBetween
            style={{ width: "90%", margin: "0 auto", marginBottom: "20px" }}
          >
            <RespFont>
              {inviting !== "" && owc !== "קשר" ? (
                inviting +
                (owc[owc.length - 1] === "ם"
                  ? " שמחים"
                  : owc[owc.length - 1] === "ו"
                  ? " שמח"
                  : " שמחה") +
                " להזמינכם לחתונת " +
                owc +
                (owc.includes("ת") ? ` ${user.brideFN}.` : ` ${user.groomFN}.`)
              ) : (
                <>ברירת מחדל: {defaultPreSms1}</>
              )}
            </RespFont>
          </FlexRowBetween>
        )}

        {guestsArray[0]}
        <FlexRowBetween style={{ width: "90%", margin: "20px auto" }}>
          {contacts.length ? (
            <GenericButton
              style={{
                margin: "0 auto",
                width: "50%",
                background: user && user.color,
              }}
              type="submit"
            >
              אישור
            </GenericButton>
          ) : (
            <GenericButton
              onClick={getContacts}
              disabled={dis}
              background={"gray"}
            >
              {dis
                ? "ייבוא ישיר מתאפשר במכשירי אנדרואיד בדפדפן כרום"
                : "ייבוא אנשי קשר"}
            </GenericButton>
          )}
        </FlexRowBetween>
        <div style={{ height: "10px" }}></div>
      </InputBox>
      {loading && <OpacityLoading />}
      {doneB && (
        <FlexRowBetween
          style={{
            justifyContent: "space-between",
            width: "90%",
            margin: "0 auto",
            marginTop: "-130px",
          }}
        >
          <InputBox
            labelColor={"gray"}
            color={"rgba(0,0,0,0.6)"}
            style={{ width: "110px", margin: "0px" }}
          >
            <div
              style={{
                position: "relative",
                width: "110px",
                marginTop: "-10px",
              }}
            >
              <PlaceHolderImage
                onClick={() => setSearchText("")}
                fontSize={`27px`}
                top={`30.5px`}
                src={clear}
                alt="clear"
                // style={{ cursor: "pointer" }}
                style={{
                  cursor: "pointer",
                  border: "1px solid silver",
                  padding: "4px",
                  borderRadius: "2px",
                }}
              />
              <input
                value={searchText}
                type="text"
                required={true}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <label>חיפוש</label>
            </div>
          </InputBox>
          <div style={{ direction: "ltr" }}>
            <label
              htmlFor="vcf-file-upload"
              className="custom-file-upload"
              style={{
                margin: "0px auto",
                direction: "rtl",
              }}
            >
              <img src={upload} style={{ width: "25px" }} />
            </label>
            <input
              id="vcf-file-upload"
              type="file"
              onChange={handleFileUpload}
              accept=".vcf"
            />
          </div>
          <GenericDiv
            background={user.color}
            style={{
              width: "min-content",
              // margin: "0 auto",
              // marginTop: "-70px",
            }}
            onClick={() => {
              setContacts(Object.values(multyChoise));
              setDoneB(false);
            }}
          >
            סיום
          </GenericDiv>
        </FlexRowBetween>
      )}
      {doneB && (
        <FlexRowBetween
          style={{
            flexWrap: "wrap",
            width: "90%",
            margin: "10px auto",
            height: "300px",
            overflow: "auto",
          }}
        >
          {iPhoneArray}
          {/* {arrC} */}
        </FlexRowBetween>
      )}
      {/* <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip>{limit ? "כולל מגבלות" : "ללא מגבלות"}</Tooltip>}
      >
        <div
          style={{
            position: "absolute",
            right: "15px",
            top: "15px",
            // , transform: "rotate(90deg)"
          }}
        >
          <Switcher checked={limit} onChange={setLimit} color={user.color} />
        </div>
      </OverlayTrigger> */}
    </CenteredForm>
  );
};

export default AddGuestsFromC;
