import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import { CenteredForm, PlaceHolderImage, GenericButton, RespFont, InputBox, RTextArea, FlexRowBetween } from '../../common/Style'
import exitModalLogo from '../../../assets/common/cancel1.svg';
import sum from '../../../assets/client/amount1.svg';
import { GetToast } from '../../common/modals/Toast';
import { setAcceptAndTransportationHimself } from '../../../store/reducers/guestsReducer';
import OpacityLoading from '../loader/OpacityLoading';

const TransportationConfirmationSelf = (props) => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { register, handleSubmit } = useForm();
    const { guestsLoading } = useSelector(state => state.guestsReducer);
    const onSubmit = async data => {
        const transportation = parseInt(data.transportation);
        const accept = parseInt(data.accept);
        if (transportation > accept)
            alert("מספר המגיעים בהסעה אינו יכול להיות גדול ממספר המגיעים לאירוע")
            else{
                dispatch(setAcceptAndTransportationHimself({
                    transportation: parseInt(data.transportation), accept: parseInt(data.accept), ans: "M", notes: data.notes,
                    name: data.name, phone: data.phone, closeness: "הפצה עצמית", sum: parseInt(data.accept), uid: props.uid
                })).then(res => {
                    if (!res.payload?.error) {
                        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!")
                        props.setDone(true)
                        props.setCount(parseInt(data.accept))
                setTimeout(function () { props.setDone(false); }, 8000);

                        props.init(null)
                    }
                    else GetToast(addToast, "error", res.payload.error)
                })
            }
    }
    return (<CenteredForm onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit(onSubmit)}>
        <PlaceHolderImage src={exitModalLogo} alt="exitModalLogo" style={{ top: "8px", left: "8px", transform: "translate(0,0)", width: "32px", cursor: "pointer" }} onClick={() => props.init(null)} />
        <RespFont pSize={"20px"} cSize={"22px"} style={{ textAlign: "center", color: "#3A3C3F", fontWeight: "bold", padding: `30px 0`, paddingBottom: `10px` }}>הרשמה להסעה</RespFont>
        <RespFont pSize={"14px"} cSize={"16px"} style={{ textAlign: "center", color: "#3A3C3F", padding: " 0 30px", paddingBottom: `10px` }}>{props.details.split('??').map(x => <div>{x}</div>)}</RespFont>


        <InputBox labelColor={"gray"} color={"#3A3C3F"}>
            <FlexRowBetween style={{width:"80%", margin:"10px auto"}}>

        <div className='relativeBox' style={{ margin: "0px", width: "55%" }}>
                        <input type="text" name="name" minLength={2} maxLength={50} required={true} ref={register()} />
                        <label>שם מלא</label>
                    </div>
                    <div className='relativeBox' style={{ margin: "0px", width: "40%" }}>
                        <input type="text" name="phone"
                                 pattern={"[0]{0,1}[5]{1}[0-9]{8}"}
                            required={true}
                            ref={register()} />
                        <label>מס' פלאפון</label>
                    </div>
                            </FlexRowBetween>
            <div className='relativeBox'>
                <PlaceHolderImage fontSize={`23px`} top={`33px`} src={sum} alt="sum" />
                <input type="number" defaultValue={props.count} name="accept" min={0} max={30} required={true} ref={register()} />
                <label>כמות מגיעים לאירוע</label>
            </div>
            <div className='relativeBox'>
                <PlaceHolderImage fontSize={`23px`} top={`33px`} src={sum} alt="sum" />
                <input type="number" name="transportation" min={0} max={30} required={true} ref={register()} />
                <label>כמות מגיעים בהסעה</label>
            </div>
            <div style={{ position: "relative", width: "60%", margin: "20px auto" }}>
                <RTextArea placeholder="במידה ויש יותר מהסעה אחת כתבו לנו לאיזו תרצו להשתייך" name="notes" type="text" rows="2"
                    minLength={0} maxLength={100} ref={register()} style={{ margin: "0 auto" }} />
            </div>
            <GenericButton hoverBackground={"rgb(223, 223, 223)"} background={props.color} type="submit" style={{ width: "60%", margin: "30px auto" }}>אישור</GenericButton>
        </InputBox>
        {guestsLoading && <OpacityLoading />}
    </CenteredForm >)
}

export default TransportationConfirmationSelf;
