import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMessages, cleanMessages1 } from '../../../store/reducers/messagesReducer'
import { OptionsFirst, RespFont, TodoCard, TodosHolder } from '../../common/Style';
const IncomingMsg = ({ numbers }) => {
    const dispatch = useDispatch();
    const { messages, messagesError, messagesLoading } = useSelector(state => state.messagesReducer);

    useEffect(() => {
        console.log(numbers)
        if (numbers.length)
            dispatch(getMessages({ numbers }))
        return () => {
            dispatch(cleanMessages1());
        }
    }, []);

    return (<OptionsFirst className="animate__animated animate__fadeIn" style={{ margin: "10px 0px", overflowX: "auto" }}>
        <TodosHolder style={{ overflowY: "auto", overflowX: "hidden", height: "99%" }}>
            {messages.length > 0 ? messages.map((item, index) => <TodoCard key={index} style={{ cursor: "auto", padding: "5px", overflowY: "auto", overflowX: "hidden" }}>
                <RespFont>{item.des + ": " + item.msg}</RespFont></TodoCard>) : <div style={{ textAlign: "center", width: "100%" }}>אין הודעות</div>}
        </TodosHolder>
    </OptionsFirst>)
}

export default IncomingMsg;