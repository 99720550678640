import React, { useState } from "react";
import {
  OptionsFirst,
  RespFont,
  OptionsSecond,
  GradientText,
  RespImg,
} from "../../common/Style";
import opt1 from "./assets/opt1.svg";
import opt2 from "./assets/Scenes05.svg";
import opt3 from "./assets/opt3.svg";
import opt4 from "./assets/opt43.png";
import { Option, RespLogo } from "./Style";
import l1 from "../../../assets/logo/l4.png";
import expenses from "../../../assets/client/expenses.svg";
import brain from "../../../assets/client/brain.svg";
import logout1 from "../../../assets/client/logout.svg";
import editInv from "../../../assets/client/editInv.svg";
import tables from "../../../assets/client/editTables.svg";
import guests1 from "../../../assets/client/guests.svg";
import { useSelector } from "react-redux";
import AddGuests from "./AddGuests";
import Messages from "./Messages";
import Reports from "./Reports";
import OpacityLoading from "../../common/loader/OpacityLoading";
import { useHistory } from "react-router-dom";
import ClientRoutes from "../../../navigation/Routes";
import { slide as Menu } from "react-burger-menu";
import LogOutAll from "../../common/LogOut/LogOutAll";
import GenericModal from "../../common/modals/GenericModal";
import { MenuCss } from "../../common/modals/Toast";
import IncomingMsg from "./IncomingMsg";

const Options = ({
  specialDishes,
  transportation,
  exportedGuests,
  prepareTransportationStates,
  prepareSpecialDishesStates,
  prepareNotesStates,
  prepareTablesStates,
  prepareIplanStates,
  prepareGiftsStates,
  getStatusByCallNumberFunc,
  getData,
  setReport,
  report,
  prepareFirstMsg,
  prepareAutoMsg,
  prepareSecondMsg,
  prepareThirdMsg,
  prepareKdamMsg,
  prepareReminderMsg,
  prepareThankMsg,
  msg1,
  msg2,
  setMsg1,
  setMsg2,
  color,
  setAccept,
  setNotes,
  setPhone,
  setSort,
  accept,
  notes,
  phone,
  sort,
  acceptOptions,
  notesOptions,
  phoneOptions,
  sortOptions,
  defaultPreSms1,
  defaultPreSms2,
  defaultPreSms3,
  title,
  numbers,
  setLowerThan,
  lowerThan,
  customMsg,
  setCustomMsg,
  setSelfInv,
}) => {
  const { dataLoading } = useSelector((state) => state.dataReducer);
  const { messagesLoading } = useSelector((state) => state.messagesReducer);
  let history = useHistory();

  const [option, setOption] = useState(1);
  return (
    <OptionsSecond style={{ width: "100%", minHeight: "100vh" }}>
      {/* <RespLogo src={l1} onClick={() => history.goBack()} /> */}

      <OptionsFirst>
        <Option onClick={() => setOption(1)}>
          <RespFont>הוספת מוזמנים</RespFont>
          <img src={opt1} />
        </Option>
        <Option onClick={() => setOption(2)} style={{ padding: "25px 10px" }}>
          <RespFont>הפקת דוחות</RespFont>
          <img src={opt2} />
        </Option>
        <Option onClick={() => setOption(3)}>
          <RespFont>שליחת הודעות</RespFont>
          <img src={opt3} />
        </Option>
      </OptionsFirst>
      {/* <AddGuests /> */}
      {option === 1 ? (
        <AddGuests setSelfInv={setSelfInv} />
      ) : option === 2 ? (
        <Reports
          specialDishes={specialDishes}
          transportation={transportation}
          prepareSpecialDishesStates={prepareSpecialDishesStates}
          prepareTransportationStates={prepareTransportationStates}
          prepareNotesStates={prepareNotesStates}
          prepareTablesStates={prepareTablesStates}
          prepareGiftsStates={prepareGiftsStates}
          prepareIplanStates={prepareIplanStates}
          getData={getData}
          setReport={setReport}
          getStatusByCallNumberFunc={getStatusByCallNumberFunc}
          report={report}
          exportedGuests={exportedGuests}
          color={color}
        />
      ) : option === 3 ? (
        <Messages
          exportedGuests={exportedGuests}
          prepareAutoMsg={prepareAutoMsg}
          prepareFirstMsg={prepareFirstMsg}
          prepareSecondMsg={prepareSecondMsg}
          prepareKdamMsg={prepareKdamMsg}
          prepareThirdMsg={prepareThirdMsg}
          prepareReminderMsg={prepareReminderMsg}
          prepareThankMsg={prepareThankMsg}
          getData={getData}
          color={color}
          setAccept={setAccept}
          setNotes={setNotes}
          setPhone={setPhone}
          setSort={setSort}
          accept={accept}
          notes={notes}
          phone={phone}
          sort={sort}
          acceptOptions={acceptOptions}
          notesOptions={notesOptions}
          phoneOptions={phoneOptions}
          sortOptions={sortOptions}
          defaultPreSms1={defaultPreSms1}
          defaultPreSms2={defaultPreSms2}
          defaultPreSms3={defaultPreSms3}
          title={title}
          numbers={numbers}
          setLowerThan={setLowerThan}
          lowerThan={lowerThan}
          customMsg={customMsg}
          setCustomMsg={setCustomMsg}
        />
      ) : (
        <IncomingMsg numbers={numbers} />
      )}
      {/* <OptionsFirst>
            <Option onClick={() => setOption(1)}>
                <RespFont>הוספת מוזמנים</RespFont>
                <img src={opt1} />
            </Option>
            <Option onClick={() => setOption(2)}>
                <RespFont>הפקת דוחות</RespFont>
                <img src={opt2} />
            </Option>
            <Option onClick={() => setOption(3)}>
                <RespFont>שליחת הודעות</RespFont>
                <img src={opt3} />
            </Option>
        </OptionsFirst> */}
      {dataLoading && <OpacityLoading />}
      {messagesLoading && <OpacityLoading />}
    </OptionsSecond>
  );
};

export default Options;
