import React from 'react';
import { TableLine } from '../common/Style';

const TheadTables = (props) => {
    let background = "";
    if (props.sit === props.amount)
        background = "#d7f0ae";
    else if (props.sit > props.amount)
        background = "#f0aeae";
    else if (props.sit < props.amount)
        background = "#f0d4ae";
    return (
        <TableLine onClick={() => {
            if (props.multy)
                props.addOrDeleteAllTable(props.table)
        }
        } style={{ backgroundColor: background, fontWeight: "600" }}>
            <div>{props.table + " - " + props.closeness}</div>
            <div>{props.sit + "/" + props.amount}</div>
        </TableLine>
    )
}
export default TheadTables;