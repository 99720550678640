import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Cropper from "react-easy-crop";
import "./style.css";
import gallery from "../../../assets/client/galleryWhite.svg";
import filledArrow from "./filledArrow.svg";
import { GetToast, Output } from "../../common/modals/Toast";
import {
  FlexColumnBetween,
  FlexRowBetween,
  GenericDiv,
} from "../../common/Style";
import {
  editUserPic,
  editUserPicPosition,
} from "../../../store/reducers/authReducer";
import { deletePhoto } from "../../../store/reducers/photosReducer";
import GenericModal from "../../common/modals/GenericModal";
import EditPics from "../EditPics";
import minusB from "../../../assets/invitation/minusB.svg";
import plusB from "../../../assets/invitation/plusB.svg";

const Image = (props) => {
  const [editPic, setEditPic] = useState(false);
  const { user } = useSelector((state) => state.authReducer);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropAreaAspectX, setCropAreaAspectX] = useState(user.cropAreaAspect.x);
  const [cropAreaAspectY, setCropAreaAspectY] = useState(user.cropAreaAspect.y);
  const [croppedArea, setCroppedArea] = useState(null);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const deletePhoto1 = async (id) => dispatch(deletePhoto(id));
  const deletePhotoOnly = async () => {
    await deletePhoto1(user.pic);
    dispatch(editUserPic({ pic: "" })).then(
      (res) => {
        if (!res.payload?.error)
          GetToast(addToast, "success", "הפעולה הצליחה!!");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };

  const setPicPosition = () => {
    props.save({
      ...user,
      picPosition: croppedArea,
      cropAreaAspect: { x: cropAreaAspectX, y: cropAreaAspectY },
    });
    props.setPicPosition(croppedArea);
    props.setCropAreaAspect({ x: cropAreaAspectX, y: cropAreaAspectY });
    props.setView(false);
  };

  return (
    <div className="pp">
      <div className="cropper">
        <Cropper
          image={props.pic}
          aspect={cropAreaAspectX / cropAreaAspectY}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropAreaChange={(croppedArea) => {
            setCroppedArea(croppedArea);
          }}
        />
      </div>
      <FlexColumnBetween
        style={{ width: "90%", margin: "0px auto", minHeight: "70px" }}
      >
        <div>
          <GenericDiv
            style={{ marginTop: "20px" }}
            background={user.color}
            onClick={() => setPicPosition()}
          >
            הדמיה
          </GenericDiv>

          <GenericDiv
            style={{ marginTop: "20px" }}
            background={user.color}
            onClick={() => {
              if (user.pic === "") {
                setEditPic(true);
              } else deletePhotoOnly();
            }}
          >
            <img src={gallery} style={{ width: "20px", marginLeft: "10px" }} />
            <div>{user.pic === "" ? "העלאת תמונה" : "מחיקת תמונה"}</div>
          </GenericDiv>
        </div>

        <FlexRowBetween>
          <FlexRowBetween
            style={{
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <img
              src={plusB}
              onClick={() => {
                if (cropAreaAspectY + 0.1 <= 10)
                  setCropAreaAspectY(cropAreaAspectY + 0.1);
              }}
              style={{
                width: "25px",
                boxShadow: "0 1px 2px gray",
                padding: "3px",
                marginBottom: "15px",
              }}
            />
            <img
              src={minusB}
              onClick={() => {
                if (cropAreaAspectY + 0.1 >= 1)
                  setCropAreaAspectY(cropAreaAspectY - 0.1);
              }}
              style={{
                width: "25px",
                boxShadow: "0 1px 2px gray",
                padding: "3px",
              }}
            />
          </FlexRowBetween>

          <FlexRowBetween style={{ justifyContent: "center" }}>
            <div>{cropAreaAspectY.toFixed(1)}</div>
            <div style={{ margin: "0 3px" }}>/</div>
            <div>{cropAreaAspectX.toFixed(1)}</div>
          </FlexRowBetween>

          <FlexRowBetween
            style={{ justifyContent: "center", marginTop: "20px" }}
          >
            <img
              src={plusB}
              onClick={() => {
                if (cropAreaAspectX + 0.1 <= 10)
                  setCropAreaAspectX(cropAreaAspectX + 0.1);
              }}
              style={{
                width: "25px",
                boxShadow: "0 1px 2px gray",
                padding: "3px",
                marginLeft: "15px",
              }}
            />
            <img
              src={minusB}
              onClick={() => {
                if (cropAreaAspectX - 0.1 >= 1)
                  setCropAreaAspectX(cropAreaAspectX - 0.1);
              }}
              style={{
                width: "25px",
                boxShadow: "0 1px 2px gray",
                padding: "3px",
              }}
            />
          </FlexRowBetween>
        </FlexRowBetween>
      </FlexColumnBetween>
      {editPic && (
        <GenericModal init={setEditPic} div={<EditPics init={setEditPic} />} />
      )}
    </div>
  );
};

export default Image;
