export const todosSvg = (width, height, color) => (
  <svg
    id="b774b191-6793-4f7e-bda1-74532d03a454"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 865.76 682.89"
  >
    <path
      d="M174.46,194.48c.64,27-28.31,81.75-28.31,81.75,7.95,6.36,21.63,76.33,21.63,76.33-12.4-.64-18.45,10.81-18.45,10.81l-27.67-84.6c-1.91-13,0-19.08,0-19.08l30.22-90c3.18-8.23,40.82-11.09,40.82-11.09s45.31-5.5,59.43,4.83c0,0,17.32,11.13,30.75,46.61L263.82,228.2l-1.35-23.41L270,335.67S233.75,354.55,181.18,347l-19.44-10.39L143,272.22l31.51-77.74"
      fill={color}
    />
    <path
      d="M197,136.06l1.31,22.31s-9.23,1-5.09,7.51c0,0,1.62,3.6,13.83,5.7a28.61,28.61,0,0,0,7,.32c6.16-.49,18.09-2.29,20.47-8.9,1.32-3.69-.66-5.06-5.91-5.78,0,0-.08,0-.13.05l.32-14.68L204.1,128.94l-3.95.6Z"
      fill="#f9b499"
    />
    <path
      d="M391.69,511.88V34.63a14,14,0,0,1,14-14H762.34S727,27.06,728.65,71.14V514.08a21.33,21.33,0,0,1-21.32,21.32H415.21A23.52,23.52,0,0,1,391.69,511.88Z"
      fill="#ccc"
    />
    <circle cx="448.49" cy="113.25" r="26.12" opacity="0.2" />
    <path
      d="M448.49,129.94a2.77,2.77,0,0,1-1.66-.56l-16.55-12.64a2.73,2.73,0,0,1,3.31-4.34l14.7,11.22,36-34.35a2.73,2.73,0,0,1,3.77,4l-37.74,36A2.72,2.72,0,0,1,448.49,129.94Z"
      fill="#d1d3d4"
    />
    <circle cx="448.49" cy="198.1" r="26.12" opacity="0.2" />
    <path
      d="M448.49,214.19a2.7,2.7,0,0,1-1.66-.56L430.28,201a2.73,2.73,0,0,1,3.31-4.34l14.7,11.23,36-34.35a2.73,2.73,0,1,1,3.77,3.95l-37.74,36A2.71,2.71,0,0,1,448.49,214.19Z"
      fill="#d1d3d4"
    />
    <circle cx="448.12" cy="282.95" r="26.12" opacity="0.2" />
    <path
      d="M448.12,299.64a2.68,2.68,0,0,1-1.65-.56l-16.55-12.63a2.73,2.73,0,1,1,3.31-4.34l14.7,11.22L484,259a2.73,2.73,0,1,1,3.76,3.95l-37.73,36A2.73,2.73,0,0,1,448.12,299.64Z"
      fill="#d1d3d4"
    />
    <circle cx="448.12" cy="367.81" r="26.12" opacity="0.2" />
    <path
      d="M448.12,383.9a2.74,2.74,0,0,1-1.65-.56L429.92,370.7a2.73,2.73,0,1,1,3.31-4.34l14.7,11.22L484,343.23a2.73,2.73,0,0,1,3.76,4L450,383.14A2.74,2.74,0,0,1,448.12,383.9Z"
      fill="#d1d3d4"
    />
    <circle cx="452.91" cy="452.66" r="26.12" opacity="0.2" />
    <path
      d="M452.91,468.75a2.75,2.75,0,0,1-1.66-.56L434.7,455.55a2.73,2.73,0,1,1,3.31-4.34l14.7,11.23,36.05-34.35a2.73,2.73,0,1,1,3.77,4l-37.74,36A2.71,2.71,0,0,1,452.91,468.75Z"
      fill="#d1d3d4"
    />
    <g opacity="0.2">
      <rect x="502.66" y="95.91" width="164.67" height="2.73" />
      <rect x="502.66" y="106.45" width="128.27" height="2.73" />
      <rect x="502.66" y="117" width="82.34" height="2.73" />
      <rect x="502.66" y="127.55" width="32.66" height="2.73" />
    </g>
    <g opacity="0.2">
      <rect x="502.66" y="180.92" width="164.67" height="2.73" />
      <rect x="502.66" y="191.46" width="128.27" height="2.73" />
      <rect x="502.66" y="202.01" width="82.34" height="2.73" />
      <rect x="502.66" y="212.56" width="32.66" height="2.73" />
    </g>
    <g opacity="0.2">
      <rect x="502.66" y="265.92" width="164.67" height="2.73" />
      <rect x="502.66" y="276.47" width="128.27" height="2.73" />
      <rect x="502.66" y="287.02" width="82.34" height="2.73" />
      <rect x="502.66" y="297.57" width="32.66" height="2.73" />
    </g>
    <g opacity="0.2">
      <rect x="502.66" y="350.62" width="164.67" height="2.73" />
      <rect x="502.66" y="361.17" width="128.27" height="2.73" />
      <rect x="502.66" y="371.72" width="82.34" height="2.73" />
      <rect x="502.66" y="382.26" width="32.66" height="2.73" />
    </g>
    <g opacity="0.2">
      <rect x="504.53" y="435.48" width="164.67" height="2.73" />
      <rect x="504.53" y="446.02" width="128.27" height="2.73" />
      <rect x="504.53" y="456.57" width="82.34" height="2.73" />
      <rect x="504.53" y="467.12" width="32.66" height="2.73" />
    </g>
    <path
      d="M728.65,93.71h72.7V59.61a39,39,0,0,0-39-39h0s-30.62,4.46-33.79,39.79Z"
      fill="#ccc"
    />
    <path
      d="M728.65,93.71h72.7V59.61a39,39,0,0,0-39-39h0s-30.62,4.46-33.79,39.79Z"
      opacity="0.2"
    />
    <path
      d="M163.3,361.31a178.87,178.87,0,0,0,3.36,23.38c2.15,10,5.86,35.93,10.39,102.88l12,137.78s19.29,5.86,29.62-3.79c0,0,5.17-136.4,4.83-155.34,0,0-2.73-72.34,0-79.57L240.08,470,156,546.82s2.42,27.55,17.57,28.93l102.3-81.63s4.82-4.82,4.48-22.74L270,335.67S231.45,357,162,345.08Z"
      fill="#2f2e41"
    />
    <path
      d="M156.54,550.52l-16.08,13.62A9.57,9.57,0,0,0,137.73,575c4.41,11.17,14,33.85,22.39,43.24a9.6,9.6,0,0,0,8.75,3.11l.19,0A9.51,9.51,0,0,0,175.59,607c-5.47-9.09-11.67-23.54-2-31.27C173.6,575.75,160.65,575.93,156.54,550.52Z"
      fill={color}
    />
    <path
      d="M189.1,625.35v9.09a9.2,9.2,0,0,0,5.54,8.44l42.51,18.42a7.48,7.48,0,0,0,7.07-.61l5.37-3.5a7.5,7.5,0,0,0,.39-12.28l-31.26-23.35S209.73,630.65,189.1,625.35Z"
      fill={color}
    />
    <path
      d="M97.33,386.59l14.47-13.68s3.65,30.14,34.19,36.58L131.2,423.81S102.1,418.08,97.33,386.59Z"
      fill="#bcbec0"
    />
    <path
      d="M97.33,386.59,64.41,419s0,29.11,32.92,36.26l33.87-31.48S103.53,418.56,97.33,386.59Z"
      fill="#2f2e41"
    />
    <polygon
      points="343.51 152.34 417.94 116.7 377.38 190.99 359.73 185.26 343.51 165.22 343.51 152.34"
      fill="#2f2e41"
    />
    <polygon
      points="399.84 149.84 384.64 132.65 417.94 116.7 399.84 149.84"
      fill="#d1d3d4"
    />
    <path
      d="M223.56,386.65s2-12.14,4.74-15.15c0,0,9.71,95,26.12,102.66,0,0-9.36-1.15-15.43,2.32l-12.78,10.17,6,42.36-10.45,8.34,1.56-52L240.08,470Z"
      opacity="0.2"
    />
    <polygon
      points="265.39 207.19 268.38 223.86 263.82 228.2 261.89 200.51 265.39 207.19"
      opacity="0.2"
    />
    <path
      d="M198.72,127.3s7,28.22,30,22.34l.08-4.28S217.93,140.7,198.72,127.3Z"
      fill="#f7aa92"
    />
    <ellipse cx="223.81" cy="116.7" rx="21.75" ry="31.43" fill="#f9b499" />
    <path
      d="M202.07,116.7S227.21,133,241.79,99c0,0,2.8,6.77,3.24,10.8a17.28,17.28,0,0,0,2.35-8.1,22.48,22.48,0,0,0-9.7-19.7c-8.72-6.2-24.83-12.64-46.26.45,0,0-15.71,8.38-14.66,34.22,0,0,1,19.91,22,22,0,0,4.43.94,1.64-8.53C200.4,130.17,192.16,122.15,202.07,116.7Z"
      fill="#2f2e41"
    />
    <ellipse cx="174.73" cy="100.87" rx="22.28" ry="27.17" fill="#2f2e41" />
    <ellipse cx="202.07" cy="123.63" rx="5.05" ry="6.93" fill="#f9b499" />
    <path
      d="M161.93,325.2l3.66-3.49s-14.59-42.94-7.67-56.54c0,0,27.67-48.42,16.94-74.18,0,0,.93,28.09-28.71,85.24C146.15,276.23,150.19,275.49,161.93,325.2Z"
      opacity="0.2"
    />
    <path
      d="M111.8,372.91,343.51,152.34v12.88l16.22,20L377.38,191,146,409.49S115.63,404.8,111.8,372.91Z"
      fill="#d1d3d4"
    />
    <path
      d="M115.68,386.72l227.83-221.5,16.22,20L131.2,403.74S121.66,399,115.68,386.72Z"
      opacity="0.21"
    />
    <path
      d="M149.33,363.37s9.55,30.38,16.7,29.9c0,0,15.75-4.13,17.65-14.94,0,0-8.58-9.71-3.65-7.64,0,0,6.68,6.52,9.06,4,0,0,3.49-6.21-23.06-22.11C166,352.56,157.6,351.77,149.33,363.37Z"
      fill="#f9b499"
    />
    <path
      d="M141.74,340.17l7.59,23.2s7.25-10.81,16.7-10.81l-4.1-27.36Z"
      fill={color}
    />
    <path
      d="M286.72,278.07c-2.47-9.22-5.06-25.9,5.74-33,0,0,11.89-7.24,13.28,13.6L312.8,252S316.21,276.41,296,283A7.23,7.23,0,0,1,286.72,278.07Z"
      fill="#f9b499"
    />
  </svg>
);

export const expensesSvg = (width, height, color) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 865.76 682.89"
  >
    <path
      d="M350.73,178.81a45,45,0,1,1-48-41.71A45,45,0,0,1,350.73,178.81Z"
      fill={color}
    ></path>
    <g opacity="0.1">
      <path
        d="M302.72,137.1a45,45,0,0,0-41.7,48s31.61-.79,38.3-14S302.72,137.1,302.72,137.1Z"
        fill="#fff"
      ></path>
    </g>
    <path
      d="M339.21,178.91a34.17,34.17,0,1,1-36.49-31.71,34.17,34.17,0,0,1,36.49,31.71Z"
      fill={color}
    ></path>
    <g opacity="0.2">
      <path d="M339.21,178.91a34.17,34.17,0,1,1-36.49-31.71,34.17,34.17,0,0,1,36.49,31.71Z"></path>
    </g>
    <path
      d="M340.27,180.26a33.72,33.72,0,1,1-36-31.32,33.72,33.72,0,0,1,36,31.32Z"
      fill={color}
    ></path>
    <g opacity="0.2">
      <path
        d="M340.27,180.26a33.72,33.72,0,1,1-36-31.32,33.72,33.72,0,0,1,36,31.32Z"
        fill="#fff"
      ></path>
    </g>
    <path
      d="M307.76,179.61l-.59-8.6h0a17.53,17.53,0,0,1,4.65.87,13.29,13.29,0,0,1,2.23,1.2l.1-.27,1.11-3.2.26-.73-1.65-1.51-.71.39a15.87,15.87,0,0,0-6.36-1.36l-.33-4.74L305,160.57l0,1.2-2,.07.33,4.83c-6.6,1.11-9.49,5.12-9.19,9.46.41,5.91,5.61,7.11,10.42,7.93l.59,8.53a16.29,16.29,0,0,1-8.75-2.66l-1.62,4.5a18.63,18.63,0,0,0,10.74,2.76l.25,3.58-1.66.15,1.74,1,3.5-.26-.34-4.77c6.65-1.15,9.58-5.14,9.28-9.48C317.85,181.57,312.58,180.43,307.76,179.61ZM304.2,179c-2.52-.55-4.41-1.32-4.56-3.41s1.05-3.5,4-4.18Zm4.45,13.35-.53-7.53c2.61.54,4.57,1.32,4.72,3.48.12,1.83-1.1,3.37-4.19,4.05Z"
      fill={color}
    ></path>
    <g opacity="0.3">
      <path d="M307.76,179.61l-.59-8.6h0a17.53,17.53,0,0,1,4.65.87,13.29,13.29,0,0,1,2.23,1.2l.1-.27,1.11-3.2.26-.73-1.65-1.51-.71.39a15.87,15.87,0,0,0-6.36-1.36l-.33-4.74L305,160.57l0,1.2-2,.07.33,4.83c-6.6,1.11-9.49,5.12-9.19,9.46.41,5.91,5.61,7.11,10.42,7.93l.59,8.53a16.29,16.29,0,0,1-8.75-2.66l-1.62,4.5a18.63,18.63,0,0,0,10.74,2.76l.25,3.58-1.66.15,1.74,1,3.5-.26-.34-4.77c6.65-1.15,9.58-5.14,9.28-9.48C317.85,181.57,312.58,180.43,307.76,179.61ZM304.2,179c-2.52-.55-4.41-1.32-4.56-3.41s1.05-3.5,4-4.18Zm4.45,13.35-.53-7.53c2.61.54,4.57,1.32,4.72,3.48.12,1.83-1.1,3.37-4.19,4.05Z"></path>
    </g>
    <path
      d="M307.46,195.9l.33,4.78-3.5.24L304,196.2a18.94,18.94,0,0,1-10.7-2.81l1.62-4.5a16.52,16.52,0,0,0,8.76,2.65L303,183c-4.81-.81-10-2-10.41-7.91-.32-4.43,2.58-8.37,9.19-9.48l-.34-4.83,3.5-.24.33,4.74a18.47,18.47,0,0,1,8.84,2l-1.45,4.49a17.71,17.71,0,0,0-7.07-1.82l.62,8.61c4.81.82,10.08,2,10.49,7.88C317,190.78,314.1,194.74,307.46,195.9Zm-4.78-18-.53-7.59c-2.94.68-4.17,2.3-4,4.18s2.05,2.86,4.57,3.41Zm8.62,9.3c-.14-2.09-2.08-2.89-4.69-3.48l.53,7.55c3.07-.69,4.29-2.24,4.16-4.07Z"
      fill={color}
    ></path>
    <path
      d="M269.15,205.68a3,3,0,0,1-.24-.33l-.61-1c-.26-.42-.58-1-.91-1.59s-.73-1.36-1.09-2.19c-.17-.42-.37-.84-.57-1.3s-.36-.93-.56-1.46c-.4-1-.72-2.08-1.07-3.22a44.28,44.28,0,0,1-1.7-15.4,33.7,33.7,0,0,1,.36-3.38,14.64,14.64,0,0,1,.23-1.49c.1-.44.18-1,.29-1.4.14-.88.4-1.67.57-2.36a16.47,16.47,0,0,1,.54-1.76c.18-.48.28-.81.38-1.1a1.22,1.22,0,0,1,.16-.37,1.44,1.44,0,0,1-.09.4c-.09.28-.2.66-.33,1.11s-.32,1.07-.47,1.76-.39,1.49-.54,2.37c-.08.45-.17.91-.26,1.39s-.13,1-.22,1.49c-.15,1-.22,2.17-.32,3.36a53.77,53.77,0,0,0,.19,7.72,51.58,51.58,0,0,0,1.5,7.58c.35,1.12.65,2.21,1,3.2.18.5.35,1,.54,1.47l.55,1.29c.34.84.72,1.55,1,2.19s.61,1.19.86,1.63l.55,1A1.22,1.22,0,0,1,269.15,205.68Z"
      fill="#fff"
    ></path>
    <path
      d="M295.79,178.49a2.91,2.91,0,0,1-1.06-.91,5.45,5.45,0,0,1-1.19-3.16,5.39,5.39,0,0,1,1-3.23,2.74,2.74,0,0,1,1-1,7.32,7.32,0,0,0-.8,1.12,5.52,5.52,0,0,0,.21,6.13C295.4,178.12,295.83,178.44,295.79,178.49Z"
      fill="#fff"
    ></path>
    <path
      d="M302.49,164.89a6,6,0,0,1-.2-2.86c.1,0,.21.61.27,1.39A3,3,0,0,1,302.49,164.89Z"
      fill="#fff"
    ></path>
    <path
      d="M305.75,146.1a5.16,5.16,0,0,1-1.11,0l-1.28.05-1.7.06a9.12,9.12,0,0,0-2.05.23c-.73.19-1.47.25-2.32.39a41.7,41.7,0,0,0-5.19,1.5,39.84,39.84,0,0,0-4.91,2.29c-.72.43-1.46.81-2,1.23s-1.19.8-1.69,1.17-1,.73-1.34,1-.74.59-1,.82a4.35,4.35,0,0,1-.85.69c.24-.27.49-.53.76-.78s.57-.55,1-.87.81-.71,1.33-1.09,1.05-.81,1.67-1.21,1.31-.83,2-1.27a35.86,35.86,0,0,1,4.94-2.32,36.55,36.55,0,0,1,5.25-1.49c.84-.15,1.62-.31,2.35-.38s1.48-.15,2.07-.19,1.21,0,1.72-.06l1.28,0Z"
      fill="#fff"
    ></path>
    <path
      d="M314.32,224.12a5.13,5.13,0,0,1-.78.27,18.53,18.53,0,0,1-2.2.53,32.71,32.71,0,0,1-7.39.62,33.42,33.42,0,0,1-7.44-1.09c-.91-.27-1.66-.49-2.16-.7a4.18,4.18,0,0,1-.76-.31,3.72,3.72,0,0,1,.8.19c.51.16,1.26.36,2.19.58a37,37,0,0,0,7.33,1,38.07,38.07,0,0,0,7.39-.51c.94-.17,1.69-.32,2.22-.43A2.76,2.76,0,0,1,314.32,224.12Z"
      fill="#fff"
    ></path>
    <path
      d="M816.8,81a63,63,0,1,1-71.75-52.7l.11,0A62.86,62.86,0,0,1,816.79,81Z"
      fill="#f5f5f5"
    ></path>
    <path
      d="M802,83.28a48,48,0,1,1-54.7-40.15l.12,0A47.93,47.93,0,0,1,802,83.28Z"
      fill="#ebebeb"
    ></path>
    <path
      d="M801.74,81.79c-.28,0-.79-2-2.11-5.51A51,51,0,0,0,792,62.56a49.53,49.53,0,0,0-7.59-7.66,47.2,47.2,0,0,0-23.57-10.1,46.57,46.57,0,0,0-7.18-.37,47.69,47.69,0,0,0-7.35.75A50.69,50.69,0,0,0,739.13,47a44.58,44.58,0,0,0-6.57,3,42.3,42.3,0,0,0-5.86,3.67A44.45,44.45,0,0,0,721.65,58a47.9,47.9,0,0,0-7.57,9.73,48.5,48.5,0,0,0-6.67,25.35c0,3.68.26,5.84,0,5.9s-.94-2-1.42-5.82a44.55,44.55,0,0,1,1.23-16.34,48.54,48.54,0,0,1,12-21.25,49.33,49.33,0,0,1,11.61-8.85,48.2,48.2,0,0,1,7.07-3.18,51.77,51.77,0,0,1,7.72-2.08,54.34,54.34,0,0,1,8-.79,48.9,48.9,0,0,1,7.74.46,46.25,46.25,0,0,1,7.34,1.62,45.49,45.49,0,0,1,6.69,2.57A48.27,48.27,0,0,1,794.08,61,44.72,44.72,0,0,1,801,75.86C801.94,79.61,802,81.76,801.74,81.79Z"
      fill="#e0e0e0"
    ></path>
    <path
      d="M764.19,118l1.36,8.24-7.35,1.22-1.34-8c-7.35.75-14.69-.37-19.29-2.87l2.56-9.61a30,30,0,0,0,15.15,2.87l-2-12.29c-8.5-.64-18.07-1.84-19.82-12.38-1.29-7.81,3.27-15.43,14.7-18.68l-1.36-8.18,7.35-1.21,1.32,8a33.85,33.85,0,0,1,15.69,2l-2.19,9.66a30.68,30.68,0,0,0-11.89-2l2.08,12.52c8.47.56,17.91,1.84,19.62,12.17C780,107.05,775.53,114.6,764.19,118ZM751.44,86.74l-1.72-10.46c-3.68,1.53-5,3.92-4.54,6.34s2.86,3.49,6.26,4ZM767,102.37c-.42-2.57-2.79-3.67-6.14-4.21l1.69,10.16c3.55-1.54,4.85-3.71,4.45-6.08Z"
      fill="#e0e0e0"
    ></path>
    <path
      d="M102.23,95.22A38.58,38.58,0,1,1,58.29,62.87a38.58,38.58,0,0,1,43.94,32.35Z"
      fill="#f5f5f5"
    ></path>
    <path
      d="M93.14,96.58A29.4,29.4,0,1,1,59.65,71.93,29.39,29.39,0,0,1,93.14,96.58Z"
      fill="#ebebeb"
    ></path>
    <path
      d="M93,95.68A17.07,17.07,0,0,1,91.7,92.3,32,32,0,0,0,87,83.87a31,31,0,0,0-4.67-4.71,29.94,29.94,0,0,0-6.44-4,29.06,29.06,0,0,0-8-2.25,29.39,29.39,0,0,0-8.93.23,31.51,31.51,0,0,0-4.39,1.16,30,30,0,0,0-4,1.84,28.51,28.51,0,0,0-3.68,2.28A29.66,29.66,0,0,0,36.39,93.16,31.46,31.46,0,0,0,35,102.7a16.09,16.09,0,0,1,0,3.67,8.88,8.88,0,0,1-.86-3.58,27.57,27.57,0,0,1,.75-10,31.65,31.65,0,0,1,2.59-6.52,30.82,30.82,0,0,1,8.07-9.45,29.53,29.53,0,0,1,3.86-2.49,27.26,27.26,0,0,1,4.33-2,33.81,33.81,0,0,1,4.74-1.27,31.87,31.87,0,0,1,4.87-.47,29.17,29.17,0,0,1,4.76.27,29.86,29.86,0,0,1,4.48,1,29.14,29.14,0,0,1,4.1,1.58A29.49,29.49,0,0,1,88.18,83a27.44,27.44,0,0,1,4.26,9.19A8.64,8.64,0,0,1,93,95.68Z"
      fill="#e0e0e0"
    ></path>
    <path
      d="M69.93,117.83l.85,5.07-4.52.76-.83-4.93A21.6,21.6,0,0,1,53.6,116.9L55.16,111a18.35,18.35,0,0,0,9.3,1.84l-1.25-7.53c-5.22-.39-11-1.14-12.16-7.61-.79-4.77,2-9.46,9-11.46l-.83-5,4.52-.75.81,4.92a20.81,20.81,0,0,1,9.63,1.25l-1.33,5.94a18.59,18.59,0,0,0-7.34-1.22L66.77,99c5.22.35,11,1.1,12,7.48C79.65,111.18,76.88,115.79,69.93,117.83ZM62.11,98.62,61,92.21c-2.29.93-3,2.4-2.77,3.89s1.74,2.22,3.84,2.52Zm9.57,9.61C71.42,106.65,70,106,68,105.64l1,6.24c2.1-.88,2.89-2.2,2.61-3.65Z"
      fill="#e0e0e0"
    ></path>
    <path
      d="M619.43,595a52.41,52.41,0,1,1-59.73-43.89h.1A52.37,52.37,0,0,1,619.43,595Z"
      fill="#f5f5f5"
    ></path>
    <path
      d="M607.1,596.82a39.93,39.93,0,1,1-45.51-33.44h.07A39.91,39.91,0,0,1,607.1,596.82Z"
      fill="#ebebeb"
    ></path>
    <path
      d="M606.9,595.57a23,23,0,0,1-1.83-4.59,42.79,42.79,0,0,0-6.31-11.32,39.9,39.9,0,0,0-15.06-11.74,36.68,36.68,0,0,0-5.22-1.83,38.5,38.5,0,0,0-11.64-1.49,40.43,40.43,0,0,0-6.12.62,39,39,0,0,0-20.54,10.71,39.73,39.73,0,0,0-9.92,16.32,42.41,42.41,0,0,0-1.93,12.85,22.57,22.57,0,0,1,0,4.93,12,12,0,0,1-1.15-4.85,36.9,36.9,0,0,1,1-13.6,39.72,39.72,0,0,1,10-17.69,40.91,40.91,0,0,1,9.66-7.34,38.54,38.54,0,0,1,5.9-2.65,40.16,40.16,0,0,1,31.08,1.49,40.25,40.25,0,0,1,9.18,6,41.26,41.26,0,0,1,6.42,7,37,37,0,0,1,5.84,12.3A12,12,0,0,1,606.9,595.57Z"
      fill="#e0e0e0"
    ></path>
    <path
      d="M575.62,625.59l1.14,6.87-6.14,1-1.1-6.67a29.62,29.62,0,0,1-16.08-2.39l2.14-8a25.19,25.19,0,0,0,12.62,2.39l-1.69-10.25C559.42,608,551.44,607,550,598.25c-1.06-6.5,2.72-12.86,12.24-15.56l-1.12-6.7,6.13-1,1.1,6.68a28.12,28.12,0,0,1,13.07,1.68l-1.84,8a25.73,25.73,0,0,0-9.9-1.63l1.72,10.41c7.06.48,14.92,1.51,16.35,10.14C588.79,616.6,585,622.89,575.62,625.59ZM565,599.52l-1.43-8.71c-3.13,1.29-4.12,3.27-3.79,5.29s2.39,3.07,5.22,3.48Zm13,13.08c-.35-2.15-2.32-3-5.11-3.49l1.4,8.45C577.23,616.4,578.32,614.57,578,612.6Z"
      fill="#e0e0e0"
    ></path>
    <polygon
      points="719.13 112.97 669.01 357.8 453.98 357.8 505.86 112.97 719.13 112.97"
      fill="#e6e7e8"
    ></polygon>
    <rect
      x="387.97"
      y="302.22"
      width="209.67"
      height="270.18"
      rx="6.43"
      transform="translate(985.6 874.62) rotate(-180)"
      fill="#2f2e41"
    ></rect>
    <rect
      x="371.67"
      y="302.22"
      width="209.67"
      height="270.18"
      rx="6.43"
      transform="translate(953.01 874.62) rotate(-180)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="371.67"
        y="302.22"
        width="209.67"
        height="270.18"
        rx="6.43"
        transform="translate(953.01 874.62) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="385.14"
      y="322.13"
      width="183.29"
      height="39.19"
      rx="2.41"
      transform="translate(953.58 683.45) rotate(-180)"
      fill="#fff"
    ></rect>
    <g opacity="0.2">
      <rect
        x="480.37"
        y="386.18"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(996.97 808.59) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="476.5"
      y="386.18"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(989.24 808.59) rotate(-180)"
      fill={color}
    ></rect>
    <path
      d="M496.62,347.19a3.13,3.13,0,0,0,2,.59,2.53,2.53,0,0,0,2.36-1.27,9.09,9.09,0,0,0,.75-4.34,3.42,3.42,0,0,1-1.53,1.19,5.42,5.42,0,0,1-2.15.42,5.93,5.93,0,0,1-2.68-.6,4.55,4.55,0,0,1-1.9-1.79,5.71,5.71,0,0,1-.69-2.9,5.11,5.11,0,0,1,5.5-5.48,4.82,4.82,0,0,1,4.4,2.06q1.31,2.06,1.3,6.31a19.16,19.16,0,0,1-.49,4.79,5.06,5.06,0,0,1-1.69,2.82,5.29,5.29,0,0,1-3.34.95,5.18,5.18,0,0,1-3.75-1.27,4.82,4.82,0,0,1-1.47-3.2h2.38A2.79,2.79,0,0,0,496.62,347.19Zm4.05-6.44a3.18,3.18,0,0,0,.79-2.26,3.4,3.4,0,0,0-.83-2.44,3,3,0,0,0-2.26-.88,2.91,2.91,0,0,0-2.26.9,3.71,3.71,0,0,0,0,4.64,3.17,3.17,0,0,0,2.45.89A2.74,2.74,0,0,0,500.67,340.75Z"
      fill="#2f2e41"
    ></path>
    <path
      d="M508.17,335.32c.89-1.48,2.41-2.22,4.58-2.22s3.69.74,4.58,2.22a12,12,0,0,1,1.33,6.15,12.14,12.14,0,0,1-1.33,6.21,5.8,5.8,0,0,1-9.16,0,12.24,12.24,0,0,1-1.33-6.21A12.05,12.05,0,0,1,508.17,335.32Zm7.71,3a4.2,4.2,0,0,0-1-2.08,3.32,3.32,0,0,0-4.32,0,4.27,4.27,0,0,0-1,2.08,17.2,17.2,0,0,0-.24,3.12,18,18,0,0,0,.23,3.2,4,4,0,0,0,1,2.09,3.34,3.34,0,0,0,4.34,0,4,4,0,0,0,1-2.09,19.16,19.16,0,0,0,.22-3.2A17.3,17.3,0,0,0,515.88,338.35Z"
      fill="#2f2e41"
    ></path>
    <path d="M524.59,346.85l-2.75,6.27h-1.68l1.61-6.27Z" fill="#2f2e41"></path>
    <path
      d="M527.85,335.32c.89-1.48,2.41-2.22,4.58-2.22a4.91,4.91,0,0,1,4.58,2.22,12.05,12.05,0,0,1,1.33,6.15,12.24,12.24,0,0,1-1.33,6.21,5.8,5.8,0,0,1-9.16,0,12.14,12.14,0,0,1-1.33-6.21A12,12,0,0,1,527.85,335.32Zm7.7,3a4.27,4.27,0,0,0-1-2.08,2.8,2.8,0,0,0-2.16-.8,2.77,2.77,0,0,0-2.16.8,4.28,4.28,0,0,0-1,2.08,18.27,18.27,0,0,0-.23,3.12,19.16,19.16,0,0,0,.22,3.2,4,4,0,0,0,1,2.09,3.34,3.34,0,0,0,4.34,0,4,4,0,0,0,1-2.09,18,18,0,0,0,.23-3.2A17.2,17.2,0,0,0,535.55,338.35Z"
      fill="#2f2e41"
    ></path>
    <path
      d="M542.41,335.32A4.91,4.91,0,0,1,547,333.1c2.17,0,3.69.74,4.58,2.22a12,12,0,0,1,1.33,6.15,12.14,12.14,0,0,1-1.33,6.21,5.8,5.8,0,0,1-9.16,0,12.24,12.24,0,0,1-1.33-6.21A12.05,12.05,0,0,1,542.41,335.32Zm7.71,3a4.2,4.2,0,0,0-1-2.08,2.77,2.77,0,0,0-2.16-.8,2.8,2.8,0,0,0-2.16.8,4.27,4.27,0,0,0-1,2.08,17.2,17.2,0,0,0-.24,3.12,19,19,0,0,0,.23,3.2,4,4,0,0,0,1,2.09,2.84,2.84,0,0,0,2.17.8,2.81,2.81,0,0,0,2.17-.8,4,4,0,0,0,1-2.09,19,19,0,0,0,.23-3.2A17.2,17.2,0,0,0,550.12,338.35Z"
      fill="#2f2e41"
    ></path>
    <g opacity="0.2">
      <rect
        x="526.44"
        y="386.18"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(1089.12 808.59) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="522.58"
      y="386.18"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(1081.39 808.59) rotate(-180)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="433.66"
        y="386.18"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(903.56 808.59) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="429.8"
      y="386.18"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(895.83 808.59) rotate(-180)"
      fill={color}
    ></rect>
    <path
      d="M493,410.81a3.16,3.16,0,0,0,2,.59,2.54,2.54,0,0,0,2.36-1.27,9.09,9.09,0,0,0,.75-4.34,3.41,3.41,0,0,1-1.54,1.2,5.38,5.38,0,0,1-2.14.42,5.78,5.78,0,0,1-2.68-.61,4.43,4.43,0,0,1-1.9-1.79,5.71,5.71,0,0,1-.69-2.9,5.49,5.49,0,0,1,1.46-4,5.41,5.41,0,0,1,4-1.48,4.8,4.8,0,0,1,4.4,2.05c.87,1.37,1.31,3.48,1.31,6.31a19.16,19.16,0,0,1-.49,4.79,5.18,5.18,0,0,1-1.69,2.82,5.25,5.25,0,0,1-3.34.95,5.18,5.18,0,0,1-3.75-1.27,4.85,4.85,0,0,1-1.48-3.2H492A2.83,2.83,0,0,0,493,410.81Zm4.06-6.44a3.22,3.22,0,0,0,.78-2.26,3.41,3.41,0,0,0-.82-2.44,3,3,0,0,0-2.27-.88,2.94,2.94,0,0,0-2.26.9,3.34,3.34,0,0,0-.83,2.35,3.29,3.29,0,0,0,.81,2.29,3.11,3.11,0,0,0,2.44.89A2.77,2.77,0,0,0,497,404.37Z"
      fill="#fff"
    ></path>
    <g opacity="0.2">
      <rect
        x="387.21"
        y="386.18"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(810.66 808.59) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="383.34"
      y="386.18"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(802.92 808.59) rotate(-180)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="480.37"
        y="432.8"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(996.97 901.84) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="476.5"
      y="432.8"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(989.24 901.84) rotate(-180)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="526.44"
        y="432.8"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(1089.12 901.84) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="522.58"
      y="432.8"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(1081.39 901.84) rotate(-180)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="433.66"
        y="432.8"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(903.56 901.84) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="429.8"
      y="432.8"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(895.83 901.84) rotate(-180)"
      fill={color}
    ></rect>
    <path
      d="M496.69,445.92a2.88,2.88,0,0,0-1.84-.52,3,3,0,0,0-2.68,1.37,9,9,0,0,0-.93,4.56,3.94,3.94,0,0,1,1.72-1.55,5.67,5.67,0,0,1,2.46-.54,5.34,5.34,0,0,1,2.65.65,4.49,4.49,0,0,1,1.79,1.87,6.82,6.82,0,0,1,0,5.77,4.57,4.57,0,0,1-1.85,2,5.73,5.73,0,0,1-2.95.71,5.82,5.82,0,0,1-3.67-1,5.45,5.45,0,0,1-1.87-2.92,17.8,17.8,0,0,1-.53-4.7q0-8.32,5.86-8.32a5,5,0,0,1,3.58,1.23,4.91,4.91,0,0,1,1.54,3H497.6A2.75,2.75,0,0,0,496.69,445.92Zm-4.19,6.33a3,3,0,0,0-1,2.4,3.39,3.39,0,0,0,.9,2.48,3.32,3.32,0,0,0,2.51.93,2.94,2.94,0,0,0,2.26-.89,3.33,3.33,0,0,0,.83-2.36,3.6,3.6,0,0,0-.81-2.47,3,3,0,0,0-2.37-.92A3.48,3.48,0,0,0,492.5,452.25Z"
      fill="#fff"
    ></path>
    <g opacity="0.2">
      <rect
        x="387.21"
        y="432.8"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(810.66 901.84) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="383.34"
      y="432.8"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(802.92 901.84) rotate(-180)"
      fill={color}
    ></rect>
    <path
      d="M533.6,445.05a3.73,3.73,0,0,1,5,0,4,4,0,0,1,0,5.21,3.7,3.7,0,0,1-5,0,4,4,0,0,1,0-5.21Zm1,2.6c0,1.33.48,2,1.43,2a1.24,1.24,0,0,0,1.06-.49,2.48,2.48,0,0,0,.38-1.51q0-2-1.44-2C535.13,445.67,534.65,446.33,534.65,447.65Zm11.84-3.25-9.07,15.81h-2.54L544,444.4Zm-3.65,9.94a3.74,3.74,0,0,1,5,0,4,4,0,0,1,0,5.21,3.68,3.68,0,0,1-5,0,4,4,0,0,1,0-5.21Zm1,2.6c0,1.32.47,2,1.43,2s1.43-.66,1.43-2-.48-2-1.43-2S543.88,455.62,543.88,456.94Z"
      fill="#fff"
    ></path>
    <g opacity="0.2">
      <rect
        x="480.37"
        y="479.43"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(996.97 995.1) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="476.5"
      y="479.43"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(989.24 995.1) rotate(-180)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="526.44"
        y="479.43"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(1089.12 995.1) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="522.58"
      y="479.43"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(1081.39 995.1) rotate(-180)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="433.66"
        y="479.43"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(903.56 995.1) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="429.8"
      y="479.43"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(895.83 995.1) rotate(-180)"
      fill={color}
    ></rect>
    <path
      d="M490.82,491.07a6,6,0,0,1,3.8-1.18,6.16,6.16,0,0,1,2.75.57,4.07,4.07,0,0,1,1.75,1.54,4.25,4.25,0,0,1,.59,2.21,3.91,3.91,0,0,1-.75,2.41,3.72,3.72,0,0,1-1.79,1.34v.09a3.81,3.81,0,0,1,2.09,1.49,4.73,4.73,0,0,1,.75,2.76,4.59,4.59,0,0,1-.62,2.38,4.13,4.13,0,0,1-1.82,1.65,6.38,6.38,0,0,1-2.88.6,6.24,6.24,0,0,1-4-1.26,4.67,4.67,0,0,1-1.7-3.62h2.5a2.78,2.78,0,0,0,.94,2,3.23,3.23,0,0,0,2.27.76,2.85,2.85,0,0,0,2.13-.76,2.66,2.66,0,0,0,.75-2,2.49,2.49,0,0,0-1-2.26,5.66,5.66,0,0,0-3.1-.67h-.59V497h.61a5.55,5.55,0,0,0,2.81-.61,2.05,2.05,0,0,0,1-1.87,2.24,2.24,0,0,0-.71-1.73,2.82,2.82,0,0,0-2-.65,2.78,2.78,0,0,0-2,.65,2.59,2.59,0,0,0-.84,1.6h-2.52A4.34,4.34,0,0,1,490.82,491.07Z"
      fill="#fff"
    ></path>
    <g opacity="0.2">
      <rect
        x="387.21"
        y="479.43"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(810.66 995.1) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="383.34"
      y="479.43"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(802.92 995.1) rotate(-180)"
      fill={color}
    ></rect>
    <path d="M545.36,497.3v2.18H536V497.3Z" fill="#fff"></path>
    <g opacity="0.2">
      <rect
        x="480.37"
        y="524.65"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(996.97 1085.54) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="476.5"
      y="524.65"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(989.24 1085.54) rotate(-180)"
      fill={color}
    ></rect>
    <path
      d="M494.64,547.45a2.93,2.93,0,0,1-2.14-.85,2.86,2.86,0,0,1-.86-2.1,2.94,2.94,0,0,1,3-3,2.94,2.94,0,0,1,2.95,3,2.93,2.93,0,0,1-.39,1.49,3,3,0,0,1-1.07,1.06A2.82,2.82,0,0,1,494.64,547.45Z"
      fill="#fff"
    ></path>
    <g opacity="0.2">
      <rect
        x="526.44"
        y="524.65"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(1089.12 1085.54) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="522.58"
      y="524.65"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(1081.39 1085.54) rotate(-180)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="433.66"
        y="524.65"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(903.56 1085.54) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="429.8"
      y="524.65"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(895.83 1085.54) rotate(-180)"
      fill={color}
    ></rect>
    <path
      d="M442.12,542.77h4.59v-4.7h2.41v4.7h4.59V545h-4.59v4.71h-2.41V545h-4.59Z"
      fill="#fff"
    ></path>
    <g opacity="0.2">
      <rect
        x="387.21"
        y="524.65"
        width="36.24"
        height="36.24"
        rx="2.41"
        transform="translate(810.66 1085.54) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="383.34"
      y="524.65"
      width="36.24"
      height="36.24"
      rx="2.41"
      transform="translate(802.92 1085.54) rotate(-180)"
      fill={color}
    ></rect>
    <path
      d="M546.74,540.18v2.18H534.65v-2.18Zm0,5.16v2.18H534.65v-2.18Z"
      fill="#fff"
    ></path>
    <rect
      x="500.69"
      y="101.77"
      width="237.85"
      height="11.2"
      fill="#bcbec0"
    ></rect>
    <polygon
      points="719.13 112.97 625.08 572.4 639.26 572.4 732.56 112.97 719.13 112.97"
      fill="#bcbec0"
    ></polygon>
    <polygon
      points="732.56 112.97 804.97 575.74 788.54 575.74 724.84 150.98 732.56 112.97"
      fill="#d1d3d4"
    ></polygon>
    <polygon
      points="676.1 390.99 769.14 390.99 769.14 401.08 674.05 401.08 676.1 390.99"
      fill="#bcbec0"
    ></polygon>
    <path
      d="M492.64,297.81a.56.56,0,0,1-.32-.11.52.52,0,0,1-.07-.71l45-55.05a.5.5,0,0,1,.84.11l15.39,34.08,74.77-94.48a.49.49,0,0,1,.53-.17.49.49,0,0,1,.36.41L641,275.77l23.65-18.55a.5.5,0,0,1,.62.78l-24.35,19.1a.46.46,0,0,1-.49.07.48.48,0,0,1-.31-.4L628.24,183.2l-74.53,94.2a.5.5,0,0,1-.46.18.49.49,0,0,1-.39-.29l-15.4-34.09L493,297.63A.5.5,0,0,1,492.64,297.81Z"
      fill={color}
    ></path>
    <circle cx="666.32" cy="256.77" r="5.15" fill={color}></circle>
    <circle cx="656.03" cy="230.23" r="5.15" fill="#2f2e41"></circle>
    <path
      d="M506.5,297.81a.51.51,0,0,1-.37-.84l67.79-72.28a.49.49,0,0,1,.5-.14.47.47,0,0,1,.35.37l14.05,64,19.59-17.41a.5.5,0,0,1,.48-.11.51.51,0,0,1,.34.36L613.46,289l40.19-56.78a.5.5,0,0,1,.7-.12.51.51,0,0,1,.12.7l-40.83,57.67a.47.47,0,0,1-.5.2.49.49,0,0,1-.39-.37l-4.3-17.54-19.6,17.42a.49.49,0,0,1-.82-.26l-14-63.87-67.14,71.59A.46.46,0,0,1,506.5,297.81Z"
      fill="#2f2e41"
    ></path>
    <path
      d="M450.75,126h0a30.54,30.54,0,0,1-53.27-27.87l29.13,9.19Z"
      fill={color}
    ></path>
    <path
      d="M426.61,107.37l-29.13-9.19a30.52,30.52,0,0,1,27.95-21.31Z"
      fill="#2f2e41"
    ></path>
    <path
      d="M457.14,107.37A30.24,30.24,0,0,1,450.75,126l-24.14-18.67-1.18-30.5c.39,0,.79,0,1.18,0A30.53,30.53,0,0,1,457.14,107.37Z"
      fill="#e6e7e8"
    ></path>
    <path
      d="M547.79,406.24H533.6a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5h14.19a.5.5,0,0,1,.5.5A.5.5,0,0,1,547.79,406.24Z"
      fill="#fff"
    ></path>
    <circle cx="540.7" cy="411.37" r="1.93" fill="#fff"></circle>
    <circle cx="540.7" cy="400.33" r="1.93" fill="#fff"></circle>
    <path
      d="M169.64,403.31l11.12,3s23.21,26.49,2.91,77.54H125.36a47.23,47.23,0,0,1-41.75-25.06c-8.51-16.07-13.75-38.56.15-63.56l56.49-27.62Z"
      fill="#2f2e41"
    ></path>
    <path
      d="M165.74,217.63s-2.21,2.69-3.64,13.11l-26.44,11,1.18-15s9.22-8.13,16.67-23.9C153.51,202.81,155.54,213.15,165.74,217.63Z"
      fill="#f9b499"
    ></path>
    <path
      d="M165.74,217.63a31.44,31.44,0,0,0-3.64,13.11s-6.35-7.14-7.52-24.69C154.58,206.05,158.16,214.32,165.74,217.63Z"
      fill="#f7a48b"
    ></path>
    <rect
      x="158.62"
      y="149.1"
      width="44.27"
      height="71.81"
      rx="22.14"
      transform="translate(267.6 428.65) rotate(-155.28)"
      fill="#f9b499"
    ></rect>
    <path
      d="M171.8,170.77s47.68,7.86,43.42-15.57c0,0-5.48-42.3-59.95-30.13,0,0-25.56,3.05-37.43,41.39,0,0-3.65,24.95-31,35.9,0,0-24,8-28,45.08A51.74,51.74,0,0,0,65,279,46,46,0,0,0,86.8,298.52l29.83-54.16s11.83-14.61,20.21-17.65c0,0,9.73-8.54,16.67-23.9,0,0-2.35-6.44,1.38-14.55l6.69-14.54Z"
      fill="#2f2e41"
    ></path>
    <ellipse
      cx="162.1"
      cy="170.77"
      rx="11.56"
      ry="9.59"
      transform="translate(-47.96 277.14) rotate(-73.35)"
      fill="#f9b499"
    ></ellipse>
    <path
      d="M176.29,261.55s32.9,44,24.29,61.19c0,0-3.23,8.72-15.77,23.69a42.23,42.23,0,0,0-8,14.72c-2.21,7.21-4.52,17.76-3.82,28.89a45,45,0,0,0,1.06,6.62c.83,3.88-1.56,14.36-55.84,7.26,0,0-19.68-2.15-26.76-9.53L124,284Z"
      fill={color}
    ></path>
    <path
      d="M83.76,305.22,67.44,341.8s-12.58,26.76,8.16,48.59c0,0,13.36,13.84,50.88,6.76,0,0,67.34-5.84,99.63-20.91,0,0,12.6,11.07,31.67-9.84l9.92,9.69a4.67,4.67,0,0,0,6.56,0h0a4.68,4.68,0,0,0,.11-6.5l-13-13.88a67.83,67.83,0,0,1-4.34-5.22c-2.26-3-8.54-8.12-22.61-1.89l-18.92,8.92L137,335l1.58-3Z"
      fill="#f9b499"
    ></path>
    <path
      d="M137,335l-15.4,20.6s21.27-13.57,37.39-14.32Z"
      fill="#f7a48b"
    ></path>
    <path
      d="M186.45,416s80.66-2.2,111.32,2.91c0,0,27.26,1.27,41.32,27.25s53.66,90.72,53.66,90.72l-41.31,26.84s-54.09-52.82-64.74-82.63l-103,2.74S200.05,445.51,186.45,416Z"
      fill="#f9b499"
    ></path>
    <path
      d="M286.7,481.13a66.18,66.18,0,0,1,0-14.06s-14.49,10.27-40.89,15.15Z"
      fill="#f7a48b"
    ></path>
    <path
      d="M351.44,563.76c8.33,8.42,31.73,22.09,31.73,22.09v3.39c46.4,21.88,58.1,11.7,58.1,11.7-29.43-17.72-48.52-64-48.52-64Z"
      fill="#e1e1e2"
    ></path>
    <path
      d="M399.77,615.66a87.82,87.82,0,0,1,23.39,6c6.79,4.91,24.15,10.94,24.15,10.94,27.15,6.79,24.51-6.79,24.51-6.79,2.64-14.34-30.55-24.9-30.55-24.9s-11.7,10.18-58.1-11.7C374.11,616,399.77,615.66,399.77,615.66Z"
      fill={color}
    ></path>
    <rect
      x="154.73"
      y="402.29"
      width="139.51"
      height="13.28"
      transform="translate(448.97 817.85) rotate(-180)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="154.73"
        y="402.29"
        width="139.51"
        height="13.28"
        transform="translate(448.97 817.85) rotate(-180)"
      ></rect>
    </g>
    <rect
      x="246.5"
      y="344.95"
      width="132.55"
      height="13.28"
      transform="translate(755.59 192.34) rotate(111.88)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="246.5"
        y="344.95"
        width="132.55"
        height="13.28"
        transform="translate(755.59 192.34) rotate(111.88)"
        fill="#fff"
      ></rect>
    </g>
    <circle cx="286.7" cy="408.93" r="4.5" fill="#fff"></circle>
    <rect
      x="91.42"
      y="482.22"
      width="184.21"
      height="28.56"
      transform="translate(367.05 992.99) rotate(-180)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="91.42"
        y="482.22"
        width="184.21"
        height="28.56"
        transform="translate(367.05 992.99) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="91.42"
        y="482.22"
        width="184.21"
        height="9.65"
        transform="translate(367.05 974.08) rotate(-180)"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="206.51"
        y="482.22"
        width="35.61"
        height="28.56"
        transform="translate(448.64 992.99) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="130.17"
        y="482.22"
        width="10.45"
        height="28.56"
        transform="translate(270.79 992.99) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="116.02"
        y="482.22"
        width="5.53"
        height="28.56"
        transform="translate(237.58 992.99) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="116.02"
      y="510.78"
      width="184.21"
      height="28.56"
      transform="translate(416.25 1050.12) rotate(-180)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="116.02"
        y="510.78"
        width="184.21"
        height="28.56"
        transform="translate(416.25 1050.12) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="116.02"
        y="510.78"
        width="184.21"
        height="9.65"
        transform="translate(416.25 1031.2) rotate(-180)"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="231.11"
        y="510.78"
        width="35.61"
        height="28.56"
        transform="translate(497.84 1050.12) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="154.77"
        y="510.78"
        width="10.45"
        height="28.56"
        transform="translate(319.99 1050.12) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="140.62"
        y="510.78"
        width="5.53"
        height="28.56"
        transform="translate(286.78 1050.12) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="91.42"
      y="539.34"
      width="184.21"
      height="28.56"
      transform="translate(367.05 1107.24) rotate(-180)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="91.42"
        y="539.34"
        width="184.21"
        height="28.56"
        transform="translate(367.05 1107.24) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="91.42"
        y="539.34"
        width="184.21"
        height="9.65"
        transform="translate(367.05 1088.32) rotate(-180)"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="206.51"
        y="539.34"
        width="35.61"
        height="28.56"
        transform="translate(448.64 1107.24) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="130.17"
        y="539.34"
        width="10.45"
        height="28.56"
        transform="translate(270.79 1107.24) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="116.02"
        y="539.34"
        width="5.53"
        height="28.56"
        transform="translate(237.58 1107.24) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="99.89"
      y="567.9"
      width="184.21"
      height="28.56"
      transform="translate(383.99 1164.37) rotate(-180)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="99.89"
        y="567.9"
        width="184.21"
        height="28.56"
        transform="translate(383.99 1164.37) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="99.89"
        y="567.9"
        width="184.21"
        height="9.65"
        transform="translate(383.99 1145.45) rotate(-180)"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="214.98"
        y="567.9"
        width="35.61"
        height="28.56"
        transform="translate(465.57 1164.37) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="138.64"
        y="567.9"
        width="10.45"
        height="28.56"
        transform="translate(287.72 1164.37) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="124.49"
        y="567.9"
        width="5.53"
        height="28.56"
        transform="translate(254.52 1164.37) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <rect
      x="116.02"
      y="595.73"
      width="184.21"
      height="28.56"
      transform="translate(416.25 1220.01) rotate(-180)"
      fill={color}
    ></rect>
    <g opacity="0.2">
      <rect
        x="116.02"
        y="595.73"
        width="184.21"
        height="28.56"
        transform="translate(416.25 1220.01) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="116.02"
        y="595.73"
        width="184.21"
        height="9.65"
        transform="translate(416.25 1201.1) rotate(-180)"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="231.11"
        y="595.73"
        width="35.61"
        height="28.56"
        transform="translate(497.84 1220.01) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="154.77"
        y="595.73"
        width="10.45"
        height="28.56"
        transform="translate(319.99 1220.01) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <g opacity="0.2">
      <rect
        x="140.62"
        y="595.73"
        width="5.53"
        height="28.56"
        transform="translate(286.78 1220.01) rotate(-180)"
        fill="#fff"
      ></rect>
    </g>
    <path
      d="M138.54,332,83.76,305.22l32.87-60.86s19.78-33.48,48.38-11.57c0,0,23.13,14,4,50.82Z"
      fill={color}
    ></path>
    <g opacity="0.2">
      <path d="M138.54,332,83.76,305.22l32.87-60.86s19.78-33.48,48.38-11.57c0,0,23.13,14,4,50.82Z"></path>
    </g>
    <path
      d="M408.7,399l-6.32,14.57h-2.61l6.39-14.3h-8.41v-2.2H408.7Z"
      fill="#fff"
    ></path>
    <path
      d="M395.44,456.76v-1.95l7.63-11h3.14v10.66h2.11v2.25h-2.11v3.45h-2.55v-3.45Zm8.34-10.27-5.36,8h5.36Z"
      fill="#fff"
    ></path>
    <path d="M397.86,492.64v-2.36h4.73v16.56H400v-14.2Z" fill="#fff"></path>
    <path
      d="M399.53,537.42c.89-1.48,2.41-2.21,4.58-2.21s3.69.73,4.58,2.21a12,12,0,0,1,1.33,6.15,12.14,12.14,0,0,1-1.33,6.21,5.8,5.8,0,0,1-9.16,0,12.24,12.24,0,0,1-1.33-6.21A12.05,12.05,0,0,1,399.53,537.42Zm7.71,3a4.28,4.28,0,0,0-1-2.08,3.32,3.32,0,0,0-4.32,0,4.27,4.27,0,0,0-1,2.08,17.2,17.2,0,0,0-.24,3.12,18,18,0,0,0,.23,3.2,4,4,0,0,0,1,2.09,3.34,3.34,0,0,0,4.34,0,4.11,4.11,0,0,0,1-2.09,19.16,19.16,0,0,0,.22-3.2A18.27,18.27,0,0,0,407.24,540.45Z"
      fill="#fff"
    ></path>
    <path
      d="M442.46,401.2a4.14,4.14,0,0,1,2.46-3.87,7.75,7.75,0,0,1,6,0,4.17,4.17,0,0,1,1.85,1.62,4.28,4.28,0,0,1,.62,2.25,3.93,3.93,0,0,1-.65,2.24,4.26,4.26,0,0,1-1.71,1.49,4.2,4.2,0,0,1,2,1.56,4.32,4.32,0,0,1,.75,2.53,4.62,4.62,0,0,1-.75,2.61,4.87,4.87,0,0,1-2.09,1.75,7.76,7.76,0,0,1-6.08,0,4.9,4.9,0,0,1-2.08-1.75,4.62,4.62,0,0,1-.75-2.61,4.34,4.34,0,0,1,.75-2.55,4.26,4.26,0,0,1,2-1.54A3.92,3.92,0,0,1,442.46,401.2Zm3,5.6a2.55,2.55,0,0,0-.94,2.13,2.67,2.67,0,0,0,.91,2.1,3.57,3.57,0,0,0,2.47.81,3.52,3.52,0,0,0,2.45-.82,2.7,2.7,0,0,0,.9-2.09,2.57,2.57,0,0,0-.93-2.12,3.7,3.7,0,0,0-2.42-.74A3.87,3.87,0,0,0,445.47,406.8Zm4.63-7.23a3.23,3.23,0,0,0-2.19-.69,3.17,3.17,0,0,0-2.15.69,2.45,2.45,0,0,0-.8,2,2.36,2.36,0,0,0,.83,1.86,3.16,3.16,0,0,0,2.12.71,3.12,3.12,0,0,0,2.14-.73,2.34,2.34,0,0,0,.84-1.86A2.41,2.41,0,0,0,450.1,399.57Z"
      fill="#fff"
    ></path>
    <path
      d="M452.7,445.88h-7.61v4.52a3.5,3.5,0,0,1,1.42-1.07,4.75,4.75,0,0,1,2-.43,5.35,5.35,0,0,1,3.1.82,4.57,4.57,0,0,1,1.7,2.06,7,7,0,0,1,.51,2.64,6.67,6.67,0,0,1-.64,3,4.85,4.85,0,0,1-1.94,2,7,7,0,0,1-7.12-.47,4.93,4.93,0,0,1-1.79-3.25h2.52a2.85,2.85,0,0,0,1.11,1.69,3.59,3.59,0,0,0,2.16.63,3,3,0,0,0,2.42-1,3.94,3.94,0,0,0,.81-2.59,3.56,3.56,0,0,0-.82-2.51,3.13,3.13,0,0,0-2.41-.88,3.07,3.07,0,0,0-1.87.56,3.11,3.11,0,0,0-1.11,1.51H442.7V443.6h10Z"
      fill="#fff"
    ></path>
    <path
      d="M444.15,503.32q2.19-1.89,3.45-3.1a13.94,13.94,0,0,0,2.1-2.54,4.83,4.83,0,0,0,.84-2.63,3.25,3.25,0,0,0-.65-2.14,2.52,2.52,0,0,0-2-.77,2.59,2.59,0,0,0-2.08.85,3.63,3.63,0,0,0-.79,2.29h-2.5a5,5,0,0,1,5.34-5.34,5.3,5.3,0,0,1,3.88,1.36,4.86,4.86,0,0,1,1.4,3.64,6.44,6.44,0,0,1-.83,3.14,12.51,12.51,0,0,1-2,2.71c-.76.79-1.74,1.71-2.92,2.76l-1,.91h7.2v2.15h-11v-1.88Z"
      fill="#fff"
    ></path>
    <path
      d="M787.53,495.36a351.46,351.46,0,0,0-.51-37.29,474.27,474.27,0,0,0-11.68-76c-3.26-14.06-7.27-27.89-11.45-41.69-.13-.42-.78-.3-.67.13C776.14,389.22,786.75,439.45,786,490c-.2,14.23-1.83,28.07-3.18,42.14,0,.38.58.53.66.14C786.17,520.34,787,507.72,787.53,495.36Z"
      fill={color}
    ></path>
    <path
      d="M763.22,340.61S743.63,390.31,748,402s13.43,14.23,13.43,14.23-12,17.07-3.61,33.15,27,15.29,28.21,33.47C786,482.79,787.08,406.73,763.22,340.61Z"
      fill={color}
    ></path>
    <path
      d="M783.92,451.39a.42.42,0,0,0,0-.27,456,456,0,0,0-19-100c0-.09-.19,0-.16,0q5.81,21.8,10.29,43.84a21.71,21.71,0,0,0-9.35-5.21c-.07,0-.1.08,0,.1a24.55,24.55,0,0,1,9.64,6.45c.59,2.84,1.14,5.69,1.67,8.65-4.37-4.9-10.44-7.31-16.52-9.62-.08,0-.13.1,0,.13,6.33,2.57,12.62,5.89,16.92,11.38q2.24,12.38,3.92,24.82A23.64,23.64,0,0,0,769.66,425c-.12,0-.21.17-.07.21a24.72,24.72,0,0,1,11.84,7.58h.08c.39,2.92.76,5.85,1.1,8.77a30.22,30.22,0,0,0-8.18-4.94c-.07,0-.11.1,0,.13a27.78,27.78,0,0,1,8.19,5.43.13.13,0,0,0,.12,0c.31,2.85.64,5.58.92,8.37a39.87,39.87,0,0,0-16.6-11.87.1.1,0,0,0-.13.07.1.1,0,0,0,.06.13,43.43,43.43,0,0,1,16.36,12.61.4.4,0,0,0,.41.13q.65,6.39,1.13,12.8s.1.07.1,0C784.57,460.17,784.35,455.77,783.92,451.39Z"
      fill="#3f4347"
    ></path>
    <path
      d="M768.18,384.88a24.52,24.52,0,0,0-11.76-3.67.07.07,0,1,0,0,.14,47.66,47.66,0,0,1,11.64,3.8C768.2,385.22,768.33,385,768.18,384.88Z"
      fill="#3f4347"
    ></path>
    <path
      d="M770.56,437.5a24.72,24.72,0,0,0-8.83-3,.09.09,0,1,0,0,.17,33.32,33.32,0,0,1,8.73,3.06C770.57,437.76,770.67,437.56,770.56,437.5Z"
      fill="#3f4347"
    ></path>
    <path
      d="M839,402.81a133.07,133.07,0,0,0-22.66,19.8,81.48,81.48,0,0,0-15.89,27A201.45,201.45,0,0,0,788.61,514a156.58,156.58,0,0,0,3.49,36.25.35.35,0,1,0,.68-.13,202.78,202.78,0,0,1,.36-63.36c3.36-20.57,8.65-42.94,21.71-59.65,7.11-9.09,16-16.25,24.77-23.65C840.07,403.1,839.49,402.46,839,402.81Z"
      fill={color}
    ></path>
    <path
      d="M791.46,499.23A122.23,122.23,0,0,1,808,478.12c9.79-9.8,18-16.76,19.32-23.23s-9.82-11.26-9.82-11.26,13.56,2.44,17.26-3.25,5.51-38.64,5.51-38.64S815.64,419.35,804.13,444,791.46,499.23,791.46,499.23Z"
      fill={color}
    ></path>
    <path
      d="M831.75,410.19c-10.37,10.2-20.82,21.05-26.93,34.44a136.74,136.74,0,0,0-5.45,14.23h0c-.31,1-.64,1.95-.94,2.93a.38.38,0,0,0,0,.15,280.89,280.89,0,0,0-7,30.45s.1.1.11,0c1.05-5.14,2.23-10.33,3.54-15.51h.14a21.69,21.69,0,0,1,6.48-1.65c.14,0,.13-.27,0-.25a23.61,23.61,0,0,0-6.4,1.16c1.19-4.66,2.49-9.32,4-13.91a68.7,68.7,0,0,1,14.63-1.91v-.08a55.45,55.45,0,0,0-14.37,1.22c.24-.78.51-1.56.78-2.35a72.68,72.68,0,0,1,9.73-1.65m0-.11a47.77,47.77,0,0,0-9.53,1.17c1.35-4,2.84-7.89,4.41-11.7a68.38,68.38,0,0,1,4.65-9.05,60.54,60.54,0,0,1,7.9-1.42c.07,0,.07-.13,0-.13A21.77,21.77,0,0,0,810,437c1.3-2.08,2.67-4.13,4.11-6.13h0c5.54-1.22,11.14-2.36,16.81-1.29.08,0,.12-.16,0-.17-5.38-1.43-10.73-.6-16.09.45.57-.78,1.17-1.54,1.79-2.3A35.78,35.78,0,0,1,826.9,426c.07,0,.08-.12,0-.12a26.74,26.74,0,0,0-9.63.86c4.56-5.69,9.61-11,14.61-16.31.29-.31.12-.4-.13-.21Z"
      fill="#3f4347"
    ></path>
    <path
      d="M831.48,432.65a23,23,0,0,0-4.91-.17.19.19,0,0,0-.13.23.21.21,0,0,0,.13.13c1.62,0,3.26-.09,4.88,0a.11.11,0,0,0,.08-.12A.18.18,0,0,0,831.48,432.65Z"
      fill="#3f4347"
    ></path>
    <path
      d="M813.72,464.62a22.4,22.4,0,0,0-7.59,1.52v.11c2.51-.59,5-.83,7.59-1.26a.19.19,0,0,0,.19-.19A.19.19,0,0,0,813.72,464.62Z"
      fill="#3f4347"
    ></path>
    <path
      d="M808.4,453.58a16.72,16.72,0,0,0-3.23.28c-.1,0-.1.2,0,.2a15.87,15.87,0,0,0,3.23-.15C808.6,453.88,808.6,453.58,808.4,453.58Z"
      fill="#3f4347"
    ></path>
    <path
      d="M719.2,437.77a111.36,111.36,0,0,1,22.92,10.8,68.3,68.3,0,0,1,19.09,18.14,169.64,169.64,0,0,1,24.56,49.44,132.6,132.6,0,0,1,5.56,30.14.3.3,0,0,1-.36.23.29.29,0,0,1-.22-.23,171.55,171.55,0,0,0-14.94-51.21c-7.48-15.87-16.92-32.72-31.3-43.25-7.87-5.69-16.7-9.46-25.51-13.42C718.41,438.26,718.73,437.62,719.2,437.77Z"
      fill={color}
    ></path>
    <path
      d="M780,504.82a102.85,102.85,0,0,0-18.25-13.26c-10.2-5.69-18.5-9.39-21-14.34S746,465.84,746,465.84s-10.41,5.1-14.72,1.35-13.39-30-13.39-30,24,8.54,39,25.88S780,504.82,780,504.82Z"
      fill={color}
    ></path>
    <path
      d="M778.66,499.42c-12-22.57-28.06-43-50-56.44h0a110.54,110.54,0,0,1,16.08,12.59,25.48,25.48,0,0,0-12.92,2.72.08.08,0,0,0,0,.12l.06,0a35.24,35.24,0,0,1,13.29-2.25.17.17,0,0,0,.14,0c.78.74,1.55,1.5,2.32,2.25a40.16,40.16,0,0,0-6.9.93m0,.08a35.55,35.55,0,0,1,7.28-.6,148,148,0,0,1,11.38,12.88,11.39,11.39,0,0,0-4.82.34.1.1,0,0,0,0,.13l0,.05a25.33,25.33,0,0,1,5.15-.07c.67.84,1.32,1.67,2,2.52a46.49,46.49,0,0,0-14.58,1.42v.12a57.46,57.46,0,0,1,15-1c.61.81,1.19,1.65,1.79,2.47a31.33,31.33,0,0,0-8.68,1c-.11,0-.08.2,0,.17a33.52,33.52,0,0,1,9-.7c2.59,3.56,5.07,7.2,7.44,10.88a8.33,8.33,0,0,0-3.81.23m0,.1a17.16,17.16,0,0,1,4.11.2c2.15,3.33,4.27,6.7,6.2,10.09.27.24.74,0,.55-.27Z"
      fill="#3f4347"
    ></path>
    <path
      d="M760,480.71a18.35,18.35,0,0,0-7,.85m0,.1A55.7,55.7,0,0,1,760,481c.2,0,.2-.23,0-.24Z"
      fill="#3f4347"
    ></path>
    <path
      d="M739,453.21a13.29,13.29,0,0,0-3.21.18.1.1,0,0,0-.1.1.09.09,0,0,0,.1.1c1-.11,2.09-.07,3.16-.1a.14.14,0,0,0,.17-.11A.15.15,0,0,0,739,453.21Z"
      fill="#3f4347"
    ></path>
    <polygon
      points="810.93 579.41 756.7 579.41 764.43 522.76 766.41 508.25 801.22 508.25 803.2 522.76 810.93 579.41"
      fill="#3f4347"
    ></polygon>
    <polygon
      points="803.2 522.76 764.43 522.76 766.41 508.25 801.22 508.25 803.2 522.76"
      fill="#2e3135"
    ></polygon>
    <rect
      x="761.43"
      y="503.2"
      width="44.77"
      height="12.31"
      fill="#3f4347"
    ></rect>
  </svg>
);

export const introductionSvg = (width, height, color) => (
  <svg
    id="ab6845ad-8244-4cc5-81b2-cbd36820fcca"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 865.76 682.89"
  >
    <path
      d="M200.66,570s-126-129.21-74.47-325.19c0,0,27.58-173.11,193.1-102.06,0,0,103.15,37.62,182.31-5.57,0,0,185.67-108.2,236,35.84,0,0,52.15,149.72-29.41,266.76Z"
      fill={color}
    />
    <path
      d="M200.66,570s-126-129.21-74.47-325.19c0,0,27.58-173.11,193.1-102.06,0,0,103.15,37.62,182.31-5.57,0,0,185.67-108.2,236,35.84,0,0,52.15,149.72-29.41,266.76Z"
      fill="#fff"
      opacity="0.7"
    />
    <circle cx="650.67" cy="181.32" r="8.45" fill="#fff" />
    <path
      d="M649,205.63c-25.63,0-46.47-11.09-46.47-24.72s20.84-24.72,46.47-24.72,46.47,11.09,46.47,24.72S674.57,205.63,649,205.63Zm0-48.26c-25,0-45.28,10.56-45.28,23.54S624,204.44,649,204.44s45.28-10.56,45.28-23.53S673.92,157.37,649,157.37Z"
      fill="#2f2e41"
    />
    <path
      d="M649,227.38c-13.63,0-24.72-20.85-24.72-46.47s11.09-46.47,24.72-46.47,24.72,20.84,24.72,46.47S662.58,227.38,649,227.38Zm0-91.75c-13,0-23.53,20.31-23.53,45.28S636,226.19,649,226.19s23.53-20.31,23.53-45.28S661.93,135.63,649,135.63Z"
      fill="#2f2e41"
    />
    <path
      d="M629.2,215.21c-7.29,0-13-2.33-16.29-6.78-8.45-11.31,1.37-33,21.9-48.33,9.9-7.39,20.78-12.24,30.65-13.66s17.77.83,21.91,6.36,4.09,13.64-.12,22.82-11.89,18.12-21.79,25.51-20.78,12.24-30.64,13.66A39.85,39.85,0,0,1,629.2,215.21Zm41.88-68a39,39,0,0,0-5.45.41c-9.68,1.39-20.37,6.16-30.11,13.43-20,14.95-29.72,35.88-21.66,46.67,3.86,5.17,11.24,7.26,20.79,5.89s20.36-6.16,30.1-13.43S682.1,184,686.17,175.12s4.11-16.43.25-21.6C683.33,149.38,678,147.21,671.08,147.21Z"
      fill="#2f2e41"
    />
    <path
      d="M669,219c-11.19,0-25.8-7-38.2-19.41-8.73-8.73-15.09-18.81-17.91-28.37s-1.73-17.71,3.16-22.6,12.91-6,22.59-3.15,19.64,9.17,28.38,17.91h0c8.73,8.73,15.09,18.81,17.9,28.37s1.74,17.71-3.15,22.59C678.66,217.52,674.21,219,669,219Zm-40.16-73.89c-5,0-9.07,1.47-12,4.35-4.56,4.57-5.58,12.17-2.86,21.42s9,19.27,17.61,27.87C649.34,216.42,671.45,223,681,213.52c4.56-4.56,5.58-12.17,2.85-21.42s-9-19.27-17.61-27.86h0c-8.59-8.6-18.49-14.85-27.86-17.61A33.83,33.83,0,0,0,628.88,145.13Z"
      fill="#2f2e41"
    />
    <rect
      x="452.52"
      y="33.44"
      width="157.66"
      height="101.14"
      rx="12.92"
      fill="#e6e7e8"
    />
    <path
      d="M469.88,157.88V128.14h29.25S488.22,153.42,469.88,157.88Z"
      fill="#e6e7e8"
    />
    <rect x="479.3" y="54.76" width="102.63" height="10.41" fill="#fff" />
    <rect x="479.3" y="75.58" width="102.63" height="10.41" fill="#fff" />
    <rect x="480.04" y="99.38" width="102.63" height="10.41" fill="#fff" />
    <rect x="648.66" y="265.49" width="131.69" height="84.68" fill="#e6e7e8" />
    <circle cx="717.87" cy="320" r="12.21" fill={color} />
    <circle cx="739.25" cy="286.86" r="5.02" fill={color} />
    <circle cx="683.62" cy="320" r="8.04" fill={color} />
    <rect
      x="698.17"
      y="313.91"
      width="1.19"
      height="14.39"
      transform="translate(274.21 962.3) rotate(-81.17)"
      fill="#2f2e41"
    />
    <rect
      x="719.55"
      y="299.91"
      width="22.24"
      height="1.19"
      transform="translate(72.24 737.15) rotate(-55.91)"
      fill="#2f2e41"
    />
    <path
      d="M141.56,370.57a.44.44,0,0,1-.09-.88l25.15-5.46a.45.45,0,1,1,.19.87l-25.15,5.46Z"
      fill="#2f2e41"
    />
    <path
      d="M136.7,381.55a.45.45,0,0,1-.43-.35.43.43,0,0,1,.33-.53l38.72-8.84a.44.44,0,0,1,.53.34.43.43,0,0,1-.33.53l-38.72,8.84Z"
      fill="#e6e7e8"
    />
    <path
      d="M138.67,392.16a.45.45,0,0,1-.09-.88l39.29-8.75a.45.45,0,0,1,.53.34.45.45,0,0,1-.34.53l-39.29,8.75Z"
      fill="#2f2e41"
    />
    <path
      d="M146.65,422.12a.45.45,0,0,1-.44-.36.44.44,0,0,1,.36-.52l38.9-7.4a.45.45,0,0,1,.52.36.46.46,0,0,1-.36.52l-38.9,7.39Z"
      fill="#2f2e41"
    />
    <path
      d="M156.06,432a.44.44,0,0,1-.43-.34.45.45,0,0,1,.33-.54l25-5.87a.46.46,0,0,1,.54.34.44.44,0,0,1-.33.53L156.16,432Z"
      fill="#e6e7e8"
    />
    <path
      d="M151.32,444.09a.44.44,0,0,1-.44-.35.45.45,0,0,1,.35-.53L190.32,435a.46.46,0,0,1,.53.35.45.45,0,0,1-.35.53l-39.09,8.17Z"
      fill="#2f2e41"
    />
    <path
      d="M158.32,471a.44.44,0,0,1-.09-.88l36.93-7.73a.44.44,0,0,1,.52.34.43.43,0,0,1-.34.53L158.41,471Z"
      fill="#2f2e41"
    />
    <path
      d="M163.21,481.58a.44.44,0,0,1-.43-.33.46.46,0,0,1,.32-.55L193.66,473a.44.44,0,1,1,.22.86l-30.56,7.72Z"
      fill="#e6e7e8"
    />
    <path
      d="M166.1,489.52a.44.44,0,0,1-.43-.34.45.45,0,0,1,.33-.54l29-6.91a.44.44,0,0,1,.54.33.45.45,0,0,1-.33.54l-29,6.91Z"
      fill="#e6e7e8"
    />
    <path
      d="M591.12,552.32l43.27,60.4V570.65a7.2,7.2,0,0,1-3.9-.53S597.21,552.06,591.12,552.32Z"
      fill="#2f2e41"
    />
    <path
      d="M490.85,377.87s-5.58-58.66,23.46-69.05l65.48,7.69s11.83,6.09,16.05,13.94c0,0,23.57,10.37,32,21.32,0,0,14,23.3,18.25,41.82,0,0,6.46,37.05,0,73.54l-4.42,63.42s1.8,29.53-5.23,38.69a4.62,4.62,0,0,1-5.94.88c-8.13-4.4-30.54-16.22-39.37-17.8,0,0-10.91-3.89-38.51-50.15L504.8,414.69Z"
      fill={color}
    />
    <path d="M507.91,355.2s-.46,51.75-1.4,62.62l7.56,13.82Z" opacity="0.2" />
    <path
      d="M645.19,390.11s70.75,8.74,63,49.63c0,0-1.26,23.55-23,77.2,0,0-37.91,86.57-34.5,115.8l-12-17.93,1.65-6.63a41.13,41.13,0,0,0-.39-11s-1.81-4.28,4.8-26.51l29-82.25s23.6-62.54-25.19-70.34C648.58,418.06,647.43,397,645.19,390.11Z"
      fill="#a7a9ac"
    />
    <path
      d="M648.58,418.06s33.38,1.48,30.85,41.48c0,0,.49,11.41-7.34,33.62l-27.32,77.49s-5.36,18.07-4.8,26.51a35.12,35.12,0,0,1-1.26,17.65l-4.32-6.47V570.65s6.19-1.84,7.2-27.67l.07-12.43,1.06-15.17,3.36-48.25S649.08,431.34,648.58,418.06Z"
      fill="#bcbec0"
    />
    <path
      d="M195.33,647.51l18.8-273.23a1.66,1.66,0,0,1,1.7-1.55L480,378.68a1.65,1.65,0,0,1,1.34.74L660.14,646.87a1.65,1.65,0,0,1-1.38,2.58c-39-.13-431.16-1.4-461.71-.17A1.65,1.65,0,0,1,195.33,647.51Z"
      fill="#d1d3d4"
    />
    <path
      d="M466.31,383.41c.39-1.28,1.56-2.45,4.67-2,0,0,13.95-.32,16.91-1.95,0,0,5.64-4.66,8.55,1,0,0,7,8.56,9.14,23.72,0,0,4.28,16.33-6.61,9.53,0,0-4.08,7.38-16.33,5.44,0,0-4.33-.26-5.55-3.16a3.81,3.81,0,0,1,.09-3c1.27-2.88,4.65-13.76-9.7-25.9A3.53,3.53,0,0,1,466.31,383.41Z"
      fill={color}
    />
    <path
      d="M466.31,383.41c.39-1.28,1.56-2.45,4.67-2,0,0,13.95-.32,16.91-1.95,0,0,5.64-4.66,8.55,1,0,0,7,8.56,9.14,23.72,0,0,4.28,16.33-6.61,9.53,0,0-4.08,7.38-16.33,5.44,0,0-4.33-.26-5.55-3.16a3.81,3.81,0,0,1,.09-3c1.27-2.88,4.65-13.76-9.7-25.9A3.53,3.53,0,0,1,466.31,383.41Z"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M471,381.45l7.83,9.65a20.72,20.72,0,0,1,3.94,7.59,23.51,23.51,0,0,1-2.94,19.78s-2-.2-2.75-3a4.08,4.08,0,0,1,.24-2.71c1-2.38,2.93-9-2.7-17.91,0,0-3.81-5.26-7.12-7.78,0,0-2.8-2.58-.62-4.68C466.86,382.39,467.9,381.56,471,381.45Z"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M481.39,380.82s8.06,8,9.71,13.75c0,0,4.69-6-2-15.87,0,0-1.48,1.7-8.19,2.17"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M493.92,393.42s4.31,15.57-2.71,25.51a12.85,12.85,0,0,0,7.76-5.21S501.5,400.41,493.92,393.42Z"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M466.18,385.07l-39.33,7.46s-4.47,2.52-11.28-1.95l-9.52-5.51s-3.11-3-12.05-.9L384.55,386a6.33,6.33,0,0,0-4.82,4.16c-2.23,6.51-6.37,19.81-2.84,19.51,0,0,1.55.59,3.69-1.55,0,0-.58,4.66,1.36,6.41l6.07,6.25a1.68,1.68,0,0,0,2.57-.16c.46-.69.74-1.88.41-4a.16.16,0,0,1,.28-.14l3.72,4.16a1.62,1.62,0,0,0,1.81.42c1-.42,2-1.68.31-5.4,0,0,3.88,4.06,6.61,2.81,0,0,1.75-.28-.78-4,0,0,10,4.67,17.91-1.55,0,0,.56-1.56,7.55-.59l48.21,1.95s7.59-14-9.13-27.23A1.8,1.8,0,0,1,466.18,385.07Z"
      fill="#f9b499"
    />
    <path
      d="M425.56,412.07s.54-4.06,18.72-2.52a26.17,26.17,0,0,0,3.07.09l17.26-.44s8.76,1.21,6.86-9.08a19.14,19.14,0,0,0-1.66-4.88c-1.49-3.06-4.48-9-5.47-9.82l1.84-.35s-.1.84,1.3,2c0,0,15.89,11.52,9.13,27.23Z"
      fill="#f7a48b"
    />
    <path
      d="M380.58,408.08a10.83,10.83,0,0,0,1.27-2.24c.35-.78.66-1.58.93-2.39s.53-1.63.76-2.45.42-1.66.63-2.5v.14a3,3,0,0,1,.34-1.56,4.22,4.22,0,0,1,.92-1.2,5.33,5.33,0,0,1,1.21-.81,7.21,7.21,0,0,1,1.34-.5,13.91,13.91,0,0,0-1.17.77,7.16,7.16,0,0,0-1,.93,4.18,4.18,0,0,0-.66,1.1,2.77,2.77,0,0,0-.19,1.16v.14c-.16.86-.36,1.72-.58,2.57a24.64,24.64,0,0,1-.87,2.5,14.16,14.16,0,0,1-1.22,2.35A7.54,7.54,0,0,1,380.58,408.08Z"
      fill="#f7a48b"
    />
    <path
      d="M400.71,387.43c-1.3,1.13-2.56,2.3-3.75,3.54a39.59,39.59,0,0,0-3.35,3.86,15.91,15.91,0,0,0-2.42,4.39,5.18,5.18,0,0,0-.24,2.36,3.67,3.67,0,0,0,1.07,2l0,0a1.67,1.67,0,0,0,.84.25,2.21,2.21,0,0,0,1-.22,5.4,5.4,0,0,0,1.7-1.31,12.35,12.35,0,0,0,1.35-1.85c.42-.64.77-1.34,1.13-2l.36-.69.37.68a14.22,14.22,0,0,0,2.36,3.13,11.14,11.14,0,0,0,3.21,2.27,9.73,9.73,0,0,0,3.83.94,11.16,11.16,0,0,0,2-.08c.67-.08,1.32-.27,2-.39-.65.18-1.29.44-2,.56a10.62,10.62,0,0,1-2,.23,11,11,0,0,1-2.06-.14,10.58,10.58,0,0,1-2-.62,11.8,11.8,0,0,1-3.49-2.25,13.59,13.59,0,0,1-2.59-3.28h.73a24,24,0,0,1-1.14,2.12,13,13,0,0,1-1.41,2,5.94,5.94,0,0,1-2,1.56,2.68,2.68,0,0,1-2.72-.2h0a4.52,4.52,0,0,1-1.27-2.5,6,6,0,0,1,.3-2.73,14.47,14.47,0,0,1,2.67-4.54,34.51,34.51,0,0,1,3.59-3.76A44.61,44.61,0,0,1,400.71,387.43Z"
      fill="#f7a48b"
    />
    <path
      d="M390.49,401.32c-.19,1.35-.44,2.68-.74,4s-.67,2.66-1,3.89a3.71,3.71,0,0,0,.1,1.78,13.28,13.28,0,0,0,.79,1.77c.59,1.22,1.12,2.46,1.6,3.73q-1.18-1.65-2.22-3.39a10.4,10.4,0,0,1-1-1.85,4.59,4.59,0,0,1-.17-2.21c.36-1.38.75-2.61,1.18-3.91S390,402.57,390.49,401.32Z"
      fill="#f7a48b"
    />
    <path
      d="M397.11,415.66a5.82,5.82,0,0,1-2.64-4.51,6.86,6.86,0,0,1,.36-2.67,8.36,8.36,0,0,1,.54-1.22,10.44,10.44,0,0,1,.76-1.08c-.1.43-.24.84-.35,1.25s-.21.81-.26,1.22a9.51,9.51,0,0,0-.14,2.43,8.07,8.07,0,0,0,.53,2.36A9.81,9.81,0,0,0,397.11,415.66Z"
      fill="#f7a48b"
    />
    <line
      x1="468.49"
      y1="498.85"
      x2="468.46"
      y2="498.85"
      fill="none"
      stroke="#ed1c24"
      stroke-miterlimit="10"
    />
    <path
      d="M586.38,453.31S595,459.85,602,473.47c0,0,5.77-2.62-6-20.16,0,0,4.45-3.93,5.5-7.34,0,0,6,5.77,7.86,12,0,0,1.57-9.69-7.34-14.4a134.87,134.87,0,0,0,4.19-20.17s6.06-38,16.24-50.55c0,0-16,8.65-24.36,43.74a149.85,149.85,0,0,0-2.61,21.22S594.84,452.78,586.38,453.31Z"
      opacity="0.2"
    />
    <path
      d="M598.35,502.65a32.65,32.65,0,0,1-29.79,7.9l-.58-.15s-8-.84-5.2-7.75c0,0,11.21-7.94-14.06-34.67,0,0-8.48-6.16-3.28-8.44a3.66,3.66,0,0,1,.53-.21s.79-1.31,15.2-1.31c0,0,10-.17,14.67-2.84,0,0,6.41-5.73,10.54-1.88,0,0,17.55,17.81,18,36.67C604.41,490,607,511.39,598.35,502.65Z"
      fill={color}
    />
    <path
      d="M598.35,502.65a32.65,32.65,0,0,1-29.79,7.9l-.58-.15s-8-.84-5.2-7.75c0,0,11.21-7.94-14.06-34.67,0,0-8.48-6.16-3.28-8.44a3.66,3.66,0,0,1,.53-.21s.79-1.31,15.2-1.31c0,0,10-.17,14.67-2.84,0,0,6.41-5.73,10.54-1.88,0,0,17.55,17.81,18,36.67C604.41,490,607,511.39,598.35,502.65Z"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M568.56,510.55l-.58-.15s-8-.84-5.2-7.75c0,0,11.21-7.94-14.06-34.67,0,0-8.48-6.16-3.28-8.44C554.1,465.74,573.72,483.13,568.56,510.55Z"
      fill="#fff"
      opacity="0.3"
    />
    <path
      d="M553.82,458.17s18.61,18.45,23.32,30.23c0,0,3.47-11.79-13.33-30.51Z"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M575.84,455.18s6.28,10.44,6.28,15.15c0,0,2.81-6.22-1.61-17.91A26.12,26.12,0,0,0,575.84,455.18Z"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M585.77,510s16-10.73,6.86-31.61c0,0,6.09,4.45,5.72,24.28A29.36,29.36,0,0,1,585.77,510Z"
      fill="#fff"
      opacity="0.2"
    />
    <polygon
      points="372.35 457.89 468.46 488.4 390.49 552.83 235.77 496.75 250.79 488.04 372.35 457.89"
      fill={color}
    />
    <polygon
      points="372.35 457.89 468.46 488.4 390.49 552.83 235.77 496.75 250.79 488.04 372.35 457.89"
      opacity="0.1"
    />
    <polygon
      points="235.77 496.75 238.81 514.86 389.77 571.82 474.06 496.75 468.46 488.4 390.49 552.83 235.77 496.75"
      fill={color}
    />
    <polygon
      points="192.1 355.2 200.5 349.63 362.76 387.43 389.77 542.99 384.28 547.49 354.55 396.18 192.1 355.2"
      fill={color}
    />
    <polygon
      points="192.1 355.2 200.5 349.63 362.76 387.43 389.77 542.99 384.28 547.49 354.55 396.18 192.1 355.2"
      opacity="0.2"
    />
    <polygon
      points="389.77 542.99 425.49 508.19 381.62 496.01 389.77 542.99"
      opacity="0.1"
    />
    <polygon
      points="379.88 486.03 391.27 490.38 405.28 477.39 376.23 464.97 379.88 486.03"
      opacity="0.1"
    />
    <path
      d="M388.25,592l-60,32.71h-6l4.8-7.64,57.21-32.18a3.44,3.44,0,0,1,4.67,1.28l.63,1.1a3.49,3.49,0,0,1,.19.39A3.44,3.44,0,0,1,388.25,592Z"
      fill="#bcbec0"
    />
    <path
      d="M388.25,592l-60,32.71h-6l67.5-37A3.44,3.44,0,0,1,388.25,592Z"
      opacity="0.1"
    />
    <path
      d="M398.17,607l-36.12,26.34h-3.61l2.89-6.15,34.45-25.92c1-.74,2.24-.28,2.81,1l.38.89c0,.11.08.21.12.32A3.27,3.27,0,0,1,398.17,607Z"
      fill="#bcbec0"
    />
    <path
      d="M398.17,607l-36.12,26.34h-3.61l40.65-29.83A3.27,3.27,0,0,1,398.17,607Z"
      opacity="0.1"
    />
    <path
      d="M484.73,524.9s33.54-13.38,44.18-14.35c0,0,36.35,62.18,41.79,76.37,0,0-3.55,6-28.31,16.32l-57.66-74Z"
      fill={color}
    />
    <polygon
      points="484.73 526.63 484.73 524.9 495.73 520.68 530.29 527.78 540.38 559.51 550.88 562.91 542.81 580.57 549.17 600.28 542.39 603.24 484.73 529.19 484.73 526.63"
      opacity="0.1"
    />
    <path
      d="M541.09,311.27s2.59,14.51,1.56,19.18l-2.86,68.17,9.08-38.36s1.75-11,12.9-19c.34-.24.68-.47,1-.69,3.39-2.18,24.1-16.08,12.89-24l-8.61-31.29Z"
      fill="#f9b499"
    />
    <path
      d="M541.09,311.27s2.72,13.48,1.56,19.18c0,0,26.26-11.37,27.42-34.39l-3-10.84Z"
      fill="#f7a48b"
    />
    <ellipse cx="533.54" cy="265.89" rx="36.28" ry="50.62" fill="#f9b499" />
    <path
      d="M578.22,273.88l-11.71-1.4L562,272c-2.38-2.25,0-6.06,0-6.06a7.13,7.13,0,0,0,0-7,40.55,40.55,0,0,0-5.44-13.48c-8.55-.25-8-3.25-8-3.25-1.81-4.54-18.39,3-18.39,3C507.65,255,495.47,247,495.47,247c-4.72-2.23-4.21-5.45-2.55-8.09a19.75,19.75,0,0,1,4.34-4.61c-2.36-2.86,0-8,0-8,2-5.7,9.88-4.14,9.88-4.14,3.62-11.15,11.4-9.59,11.4-9.59,6.48-5.19,22.29-8.3,22.29-8.3,24.74-2.85,36.9,14.68,41.62,24.21a33.89,33.89,0,0,1,3.24,11.19C588.26,262.49,578.22,273.88,578.22,273.88Z"
      fill="#2f2e41"
    />
    <path
      d="M569.71,262s-3,1.36-1.48,4.09l-1.72,6.4L562,272c-2.38-2.25,0-6.06,0-6.06a7.13,7.13,0,0,0,0-7,40.55,40.55,0,0,0-5.44-13.48c-8.55-.25-8-3.25-8-3.25-1.81-4.54-18.39,3-18.39,3C507.65,255,495.47,247,495.47,247c-4.72-2.23-4.21-5.45-2.55-8.09.12.37,3.44,10.54,23.55,3.16,0,0,29-17.37,41.21-8.3,0,0,2.34,2.08,2.07,7.26,0,0-1.66,4.37,3.37,7C563.12,248,574.74,253.14,569.71,262Z"
      opacity="0.2"
    />
    <ellipse cx="569.82" cy="277.15" rx="8.78" ry="11.27" fill="#f9b499" />
    <path
      d="M542.56,320.81l-16.69,12.84-6.82,34.49,14.49-13.84,7.55,15.85,1.56-39.7A31.22,31.22,0,0,0,542.56,320.81Z"
      fill={color}
    />
    <path
      d="M542.56,320.81l-16.69,12.84-6.82,34.49,14.49-13.84,7.55,15.85,1.56-39.7A31.22,31.22,0,0,0,542.56,320.81Z"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M552.61,351.05s13.81-1.49,23.48,11.72l10.29,14-5.07-50.84s2-10.62-6.68-13.26l1.06,3.86s7.77,4.33-.06,13.09c0,0-3.85,5.12-12.83,10.93A29.21,29.21,0,0,0,552.61,351.05Z"
      fill={color}
    />
    <path
      d="M552.61,351.05s13.81-1.49,23.48,11.72l10.29,14-5.07-50.84s2-10.62-6.68-13.26l1.06,3.86s7.77,4.33-.06,13.09c0,0-3.85,5.12-12.83,10.93A29.21,29.21,0,0,0,552.61,351.05Z"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M406.18,465S391.46,475.52,396,477.66a4.15,4.15,0,0,0,4.66,0S400.77,473.07,406.18,465Z"
      fill="#f7a48b"
    />
    <path
      d="M562.78,502.65l-92.95-3.77c-.45,0-.9,0-1.34,0A36.21,36.21,0,0,1,455.14,496a26.21,26.21,0,0,1-15.21-17.73l-5.52,5c-3.66,4.38-6.68,4.25-8.05,3.85a3,3,0,0,1-.8-.34c-4.84-2.15,1.89-11.17,2.52-12l-6,6.23c-6.28,6.27-8.8,4-8.8,4-4.93-1.85.22-10.42,2.25-13.48l-3.46,4.94c-6.74,9.3-9.34,6-9.34,6-6.31-2.17,2.08-15.79,6-21.55a7,7,0,0,1,4.78-3l18.28-2.73a7.35,7.35,0,0,1,4.47.76l28.77,15a11.44,11.44,0,0,0,5.91,1.28L548.72,468C573.59,493.6,562.78,502.65,562.78,502.65Z"
      fill="#f9b499"
    />
    <path
      d="M402.75,482.55,416.57,465l11.81-2.76-8.8,5.28-3.26,3-5.58,7.8S405.77,484.9,402.75,482.55Z"
      fill="#f7a48b"
    />
    <path
      d="M416.77,485,430.67,468a.06.06,0,0,1,.1.07l-2.64,6.65S419.83,483.93,416.77,485Z"
      fill="#f7a48b"
    />
    <path
      d="M455.14,496a26.21,26.21,0,0,1-15.21-17.73l-5.52,5c-3.66,4.38-6.68,4.25-8.05,3.85h0s11.53-18.9,17.8-9.28Z"
      fill="#f7a48b"
    />
    <path
      d="M562.78,502.65c9.48-12.47-14.06-34.67-14.06-34.67l6.18,15.07C559,496,543,495.88,543,495.88L503.84,493c-24.25-1.28-34,5.84-34,5.84Z"
      fill="#f7a48b"
    />
    <polygon
      points="192.1 355.2 233.49 492.75 384.28 547.49 354.55 396.18 192.1 355.2"
      fill={color}
    />
    <polygon
      points="329.51 493.36 327.06 500.04 350.87 506.49 362 463.32 358.44 451.35 354.92 453.3 329.51 493.36"
      opacity="0.2"
    />
    <polygon
      points="245.65 379.87 249.33 378.27 261.16 416.48 238.83 424.27 230.57 387.43 245.65 379.87"
      opacity="0.2"
    />
    <polygon
      points="223.87 384.97 245.09 378.27 256.7 411.32 231.91 421.26 223.87 384.97"
      fill="#2f2e41"
    />
    <path
      d="M231.46,396.63a.48.48,0,0,1-.42-.28.46.46,0,0,1,.25-.59L246,389.69a.45.45,0,1,1,.35.83l-14.74,6.07A.39.39,0,0,1,231.46,396.63Z"
      fill="#fff"
    />
    <path
      d="M235.77,400.22a.46.46,0,0,1-.43-.31.45.45,0,0,1,.28-.57l10.44-3.59a.44.44,0,0,1,.57.28.45.45,0,0,1-.28.57l-10.43,3.59Z"
      fill="#fff"
    />
    <polygon
      points="328.62 451.35 332.4 462.2 319.49 493.58 350.87 501.37 358.44 463.76 354.92 448.56 328.62 451.35"
      fill="#2f2e41"
    />
    <circle cx="340.72" cy="455.28" r="1.75" fill="#fff" />
    <path
      d="M443.38,588.86l-.94,3.52,61,15.93s16.38-38.71,17.17-47.74l-1.56-7.74s-9.71,40.58-19.24,51.72Z"
      opacity="0.1"
    />
    <path
      d="M436.58,587l-15.09,21.34s63,28.93,81.33,33.91l40-77.39-23-9.1Z"
      fill="#2f2e41"
    />
    <polygon
      points="519.81 540.29 528.39 539.09 543.67 609.1 521.87 609.96 514.15 542.99 519.81 540.29"
      fill="#e6e7e8"
    />
    <polygon
      points="526.29 596.81 527.56 605.53 538.66 586.14 536.62 576.8 526.29 596.81"
      opacity="0.1"
    />
    <polygon
      points="519.81 555.73 519.05 552.83 520.61 548.43 531.82 554.81 533.18 561.02 519.81 555.73"
      opacity="0.1"
    />
    <path
      d="M443.38,588.86s-6.89,24.54-4.35,29.59l91.26,6.24s-8.69-62.25-11.24-71.86c0,0-9.15,39.26-19.24,51.72Z"
      fill={color}
    />
    <path
      d="M432.13,585.73l67.68,18.82s5.56-5,13.9-32.73L524,535.12l-54.2-11.71s-15.83,17.13-23.64,35c-.33.76-.67,1.5-1.05,2.23Z"
      fill="#fff"
    />
    <path
      d="M85.41,95.45V320a9,9,0,0,0,9,9H416.66a9,9,0,0,0,9-9V95.45a9,9,0,0,0-9-9H94.4A9,9,0,0,0,85.41,95.45Z"
      fill="#e6e7e8"
    />
    <path
      d="M276,86.46H251.05s1.88,6.71,4.48,9.2c0,0-6.59,10.65,8,14.94v17l-2.16,3.87-4.29-1.93-6,7.82v2.19S219.49,149.68,215.41,160c0,0-12.49,20.89-7.52,68.24,0,0,2,36.24,18.41,39.75,0,0,.58,13.16,4.38,15.49l-7,15.64h24.39l17.41-9.28,31-173.34-4.82-30Z"
      opacity="0.2"
    />
    <path
      d="M85.41,299.11V320a9,9,0,0,0,9,9H416.66a9,9,0,0,0,9-9V299.11Z"
      fill={color}
    />
    <rect
      x="102.51"
      y="311.39"
      width="311.3"
      height="2.66"
      transform="translate(516.32 625.44) rotate(-180)"
      fill="#fff"
    />
    <rect
      x="351.11"
      y="303.93"
      width="4.82"
      height="18.85"
      transform="translate(707.05 626.72) rotate(-180)"
      fill="#2f2e41"
    />
    <path
      d="M249.69,264.18s-.3,15.2,4.38,16.37h83s4.09,1.46,3.79-9.07l.59-20.74,9.64-7.9s9.82,9.81,27.43,1.38a44.26,44.26,0,0,0,14.34-11.14c8.79-10.2,27.2-34.39,29-60.1h0c-.45-.16-13.9-5.35-20-13.9a1.33,1.33,0,0,0-2.17,0L370.7,197.82s-2.92-20.2-9.63-35.95c-3.68-8.65-8.51-16-14.63-17.83l-26.18-9s1.12,2.28-.49,6.23l-6.37,10.09-9.31-9.44L293,153.11l-18.84-17.78s-32.38,13.1-35.89,24.21c0,0-6.42,11.1-7.59,49.69C230.69,209.23,229.81,260.67,249.69,264.18Z"
      fill={color}
    />
    <path
      d="M361.07,161.87c3.38,16.63,4.2,49,4.34,56a4.59,4.59,0,0,0,1.07,2.93c1.32,1.53,2.32.77,2.93-.14a4.61,4.61,0,0,0,.72-2.45,39.05,39.05,0,0,1,1.79-9.71,21.53,21.53,0,0,1,3.3-6.45l14.19-19.09c8.47-12.28,16.65-9.94,16.65-9.94,14,3.78,15.79,0,15.79,0h0c-.45-.16-13.9-5.35-20-13.9a1.33,1.33,0,0,0-2.17,0L370.7,197.82S367.78,177.62,361.07,161.87Z"
      opacity="0.2"
    />
    <path
      d="M378.54,244.22s-19.83-3.71-26.55-11.31l-.88,9.93S362.13,252.61,378.54,244.22Z"
      opacity="0.2"
    />
    <path
      d="M308.73,249.86s-6.2.29-11.28-22.22A24.88,24.88,0,0,1,282.72,230c-2.05-.29-8.55-5.55-8.55-5.55s13.4-62-21.76-78.19l-8.33,5.89-3.69,3.71s23.33,9.54,21.28,32.34l-.59,23.68s-11.69,7.31-12,11.69c0,0-.58,6.72,9.65,4.09,0,0-8.47,6.54-3.22,12.62,0,0,6.43-12.32,14.62-7.35l7.3,3.5s1.17,1.46,10.53,2.05c0,0,5.78-1.17,9.47,5.26a16.13,16.13,0,0,0,7.33,6.14s-13.14,21.92-55.09,14.32c0,0-.16,16.37,4.38,16.37h27.77s-8.85-1.61-7.28-5.33a8,8,0,0,0,4.21-7.68S291.78,267,308.73,249.86Z"
      opacity="0.2"
    />
    <path
      d="M297.66,228.57s4,19,11.07,21.29c0,0,23.75-3.14,31.64-18.93,0,0-.21-4.08-7.25,1.8,1.45-1.31,10.27-9.39,7.25-11,0,0-1.75-2.83-8.53,4.37.47-.59,8.9-11.27,6.56-15.11,0,0-2.43-3-7.53,2.88,2.81-4.27,5.89-10,2.92-10.77,0,0-2.63-2.2-12.05,8.33,0,0-7.9,11.2-10.52,9.12,0,0,4.46-8.75,2.11-12.64a1.93,1.93,0,0,0-1.57-1h0a1.74,1.74,0,0,0-1.1.32c-1.59,1.09-4.27,4.43-7.76,14.24C302.89,221.51,301,229.48,297.66,228.57Z"
      fill="#f9b499"
    />
    <path
      d="M339.61,229.72s-6.69,10.31-11,8.34c0,0-1.86,0,4.28-5.15C332.92,232.91,337,229.05,339.61,229.72Z"
      fill="#f7a48b"
    />
    <path
      d="M339.61,221.63s-10.2,11-12.5,10.51,4.71-6,4.71-6S336.87,220.12,339.61,221.63Z"
      fill="#f7a48b"
    />
    <path
      d="M337.51,210.4s-9.52,14.06-13,13.52c0,0-1.8-.62,3.43-6C327.91,217.91,333.56,208.82,337.51,210.4Z"
      fill="#f7a48b"
    />
    <path
      d="M309.35,222.93c3.07,6.14,8.77-2.39,8.77-2.39,4.05-6.24,15.67-17.45,15.67-17.45s-2.63-2.2-12.05,8.33c0,0-7.9,11.2-10.52,9.12,0,0,4.46-8.75,2.11-12.64a1.93,1.93,0,0,0-1.57-1h0c.32,1.8-.63,4.76-1.43,6.8a17.57,17.57,0,0,0-1.24,7.13A8.93,8.93,0,0,0,309.35,222.93Z"
      fill="#f7a48b"
    />
    <path
      d="M341,273.41s-7.46-7.28-30.32,1.82l-13,5.32h39.43S340.92,282.22,341,273.41Z"
      opacity="0.2"
    />
    <polygon
      points="338.5 280.55 341 299.11 248.06 299.11 254.07 280.55 338.5 280.55"
      fill="#2f2e41"
    />
    <path
      d="M433.5,144.16s5.58-5.52-1.72-6.84L417,138.44s-14.46.1-14.6,21.45c0,0,5.9,8.13,19.41,13.09,0,0,4.63-7.06,7.35-8.35,0,0,4.66.86,6.34-3.43,0,0,3.54-4.15-.75-7,0,0,.86-4-1.29-5.72C433.5,148.46,435.22,146.88,433.5,144.16Z"
      fill="#f9b499"
    />
    <path
      d="M428.35,141.3s-4.92,4.38-10.14,4.58a.52.52,0,0,0-.52.58c.43,3.15,2,18.15-5.52,21.61,0,0,5.87,4.1,9.68,4.91,0,0,4.35-5.91,7.35-8.35,0,0-6,.72-5.86-2.72,0,0-3-.66-1.49-4.77,0,0-4.2-1.16-.42-6.27a.38.38,0,0,0-.22-.6c-.93-.16-2.33-.81-.52-3.39A13.31,13.31,0,0,0,428.35,141.3Z"
      fill="#f7a48b"
    />
    <path
      d="M429.2,145.45c-1.36.91-2.58,1.86-3.86,2.76-.63.46-1.28.88-1.92,1.33s-1.32.84-2,1.33a9.63,9.63,0,0,0,4.5-1.83,12.27,12.27,0,0,0,1.84-1.61A8.27,8.27,0,0,0,429.2,145.45Z"
      fill="#f7a48b"
    />
    <path
      d="M429.2,154.33c-1.27.51-2.45.94-3.67,1.38l-1.82.66a18.29,18.29,0,0,0-1.86.77,8.5,8.5,0,0,0,4-.47,8.79,8.79,0,0,0,1.84-.92A5,5,0,0,0,429.2,154.33Z"
      fill="#f7a48b"
    />
    <path
      d="M430.35,159.62a28.93,28.93,0,0,0-3.59.85c-.58.19-1.16.37-1.73.61a9.79,9.79,0,0,0-1.69.83,10.1,10.1,0,0,0,3.72-.46,12.45,12.45,0,0,0,1.74-.71A5.42,5.42,0,0,0,430.35,159.62Z"
      fill="#f7a48b"
    />
    <polygon
      points="436.91 45.9 424.08 137.91 420.69 138.16 433.11 45.9 436.91 45.9"
      fill="#2f2e41"
    />
    <rect
      x="112.3"
      y="110.72"
      width="71.03"
      height="49.98"
      rx="5.14"
      transform="translate(295.63 271.43) rotate(-180)"
      fill="#fff"
    />
    <polygon
      points="153.81 123.83 153.81 146.24 133.64 135.03 153.81 123.83"
      fill="#e6e7e8"
    />
    <rect
      x="112.3"
      y="173.93"
      width="71.03"
      height="49.98"
      rx="5.14"
      transform="translate(295.63 397.85) rotate(-180)"
      fill="#fff"
    />
    <polygon
      points="153.81 187.04 153.81 209.45 133.64 198.24 153.81 187.04"
      fill="#e6e7e8"
    />
    <rect
      x="112.3"
      y="235.32"
      width="71.03"
      height="49.98"
      rx="5.14"
      transform="translate(295.63 520.63) rotate(-180)"
      fill="#fff"
    />
    <polygon
      points="153.81 248.43 153.81 270.84 133.64 259.63 153.81 248.43"
      fill="#e6e7e8"
    />
    <ellipse
      cx="283.37"
      cy="98.41"
      rx="6.36"
      ry="8.22"
      transform="translate(-13.41 54.78) rotate(-10.79)"
      fill="#f9b499"
    />
    <polygon
      points="314.72 112.33 314.72 131.62 304.44 137.32 286.44 129.21 285.83 95.66 314.72 112.33"
      fill="#f9b499"
    />
    <path
      d="M314.72,119.27v12.35L308,135.33s-21-9.2-15.38-23C292.66,112.32,300.47,124.68,314.72,119.27Z"
      fill="#f7a48b"
    />
    <ellipse cx="308.04" cy="89.2" rx="22.69" ry="31.46" fill="#f9b499" />
    <path
      d="M278.78,92.17s3.57-4.37,8.25,0c0,0,2.15-9.44.84-14.7A11.45,11.45,0,0,0,293,68.15s8.56,7.79,16.34,3.07c0,0-4.39-3.4-2.19-6.68,0,0,5.37,11.84,14.24,8.88,0,0-2.18-1.76-1.19-4.16,0,0,5.39,7.54,8.83,8l1.16,5.19s3.81-.85,5.4-3.94c1.07-2.08,1.14-5.15-1.58-9.63,0,0,4.16-8.54-3.3-12.93,0,0-3.72-2.63-13.37-2,0,0-7-12.93-19.73-9.21,0,0-7.23.23-8.54,13,0,0-13.82-2.64-16.23,12.27a9.51,9.51,0,0,0-.26,1.94C272.47,75,273.09,81.71,278.78,92.17Z"
      fill="#2f2e41"
    />
    <path
      d="M278.78,92.17s3.57-4.37,8.25,0c0,0,2.15-9.44.84-14.7A11.45,11.45,0,0,0,293,68.15s8.56,7.79,16.34,3.07c0,0-4.39-3.4-2.19-6.68,0,0,5.37,11.84,14.24,8.88,0,0-2.18-1.76-1.19-4.16,0,0,5.39,7.54,8.83,8l1.16,5.19s3.81-.85,5.4-3.94a13.4,13.4,0,0,0-7.5-7.1s8.65-6.26,2.62-10.42c0,0-2.24-2.89-11.64.69,0,0-8-16.36-19.16-4,0,0-1-6.37,3.51-8.78,0,0-11.61-1.64-11,10.64,0,0-6.69-1.86-9.09,4,0,0,3.5.22,2,2.74,0,0-9.91-2.08-12.75,5.56C272.47,75,273.09,81.71,278.78,92.17Z"
      opacity="0.2"
    />
    <path
      d="M313.41,151.35l-9.32-9.42L293,153.11l-18.85-17.78,4.52-6.27a4.22,4.22,0,0,1,5.59-1.14l2.16,1.29,18,8.11L315,131.54a2.23,2.23,0,0,1,2.27.08c1.89,1.21,5.07,4.2,2.5,9.65Z"
      fill={color}
    />
    <path
      d="M313.41,151.35l-9.32-9.42L293,153.11l-18.85-17.78,4.52-6.27a4.22,4.22,0,0,1,5.59-1.14l2.16,1.29,18,8.11L315,131.54a2.23,2.23,0,0,1,2.27.08c1.89,1.21,5.07,4.2,2.5,9.65Z"
      opacity="0.2"
    />
    <path
      d="M173.91,496.89a1.78,1.78,0,0,1-.71-.15c-5.3-2.3-8.88-5.85-10.64-10.55-3.13-8.33.16-18.42,3.47-25.42a55.7,55.7,0,0,1,6.12-9.82c11.83-15.52,8.06-27,2.81-34a29.53,29.53,0,0,0-9.65-8.12c-31.77-17.12-25.15-51.38-25.08-51.73a1.79,1.79,0,0,1,3.5.72c-.27,1.3-6.13,32,23.28,47.87a32.91,32.91,0,0,1,10.79,9.11c6,7.91,10.37,21-2.81,38.29a51.25,51.25,0,0,0-5.73,9.18c-3,6.38-6,15.48-3.36,22.64,1.41,3.75,4.34,6.62,8.72,8.53a1.78,1.78,0,0,1-.71,3.42Z"
      fill="#fff"
    />
    <path
      d="M188.38,496.15a1.76,1.76,0,0,1-1.29-.55,1.78,1.78,0,0,1,.06-2.52c4.2-4,6.32-8.28,6.31-12.71,0-6.86-5.05-13.08-9.27-17.08a43.47,43.47,0,0,0-9.62-6.76c-21.75-11.6-21.88-28.38-20-37.36a34.47,34.47,0,0,1,5.6-12.55c17.24-25,.09-51.94-.09-52.21a1.8,1.8,0,0,1,.52-2.47,1.78,1.78,0,0,1,2.47.52c.19.29,18.66,29.14,0,56.18a31.2,31.2,0,0,0-5,11.26c-2,9.76-.5,23.5,18.21,33.48a47.3,47.3,0,0,1,10.39,7.32c4.73,4.48,10.38,11.54,10.39,19.67,0,5.44-2.49,10.59-7.42,15.29A1.76,1.76,0,0,1,188.38,496.15Z"
      fill="#fff"
    />
  </svg>
);
