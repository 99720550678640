import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { GuestOptions, CenteredForm, RespFont, InputBox, FlexRowBetween } from '../common/Style'
import './style.css';
import ExitModal from '../common/modals/ExitModal';

const EditGuestsCloseness = (props) => {
    const { register, handleSubmit } = useForm();
    const { user } = useSelector(state => state.authReducer);
    let height = window.screen.availHeight;

    const onSubmit = async data => props.setGuestsCloseness11(data.closeness);

    return (<CenteredForm onClick={(e) => { e.stopPropagation(); }} onSubmit={handleSubmit(onSubmit)} style={{ maxHeight: `${height * 0.7}px` }} id="form1">
        <ExitModal init={props.init} color={user.color} />
        <RespFont pSize={"20px"} cSize={"22px"} style={{ textAlign: "center", color: "#3A3C3F", fontWeight: "bold", padding: `30px 0`, paddingBottom: `10px` }}>עריכת קירבה (קטגוריה)</RespFont>
        <InputBox labelColor={"gray"} color={"#3A3C3F"} style={{ paddingBottom: "30px" }}>

            <FlexRowBetween style={{ width: "90%", margin: "0 auto" }}>
                <div style={{ position: "relative", width: "70%", margin: "4px auto" }}>
                    <input defaultValue={""} type="text" name="closeness" minLength={2} maxLength={50} ref={register()} />
                    <label>קירבה</label>
                </div>
            </FlexRowBetween>
        </InputBox>
        <GuestOptions width={"21px"} color={user.color} padding={`10px 0`} style={{ justifyContent: "space-between" }}>
            <FlexRowBetween style={{ justifyContent: "center", backgroundColor: user.color }}>

                <button type="submit" form="form1" style={{ outline: "none", width: "100%", background: "inherit", border: "none" }}>

                    <FlexRowBetween style={{ justifyContent: "space-evenly" }}>
                        <div style={{ fontWeight: "bold", fontSize: "16px", color: "white", padding: "10px", fontWeight: "700" }}>שמירה</div>
                    </FlexRowBetween>
                </button>
            </FlexRowBetween>
        </GuestOptions>
    </CenteredForm >)
}

export default EditGuestsCloseness;