import React from "react";
import ICalendarLink from "react-icalendar-link";

const Cal = (props) => {
  let date = props.data.date.split("T")[0];
  let hour = props.data.hour1.split(":");
  let title = props.data.eventType
    ? `${props.data.brideFN} ו${props.data.groomFN} - החתונה!`
    : props.data.calendarName !== ""
    ? props.data.calendarName
    : "אירוע";
  const event = {
    title: title,
    description: `"${props.data.hall}", ${props.data.waze}`,
    startTime: date + `T${parseInt(hour[0]) - 3}:${hour[1]}:00+00:00`,
    endTime: date + "T21:30:00+00:00",
    location: `${props.data.address}, ${props.data.city}`,
  };
  return (
    <ICalendarLink id="hhh" event={event}>
      {props.div}
    </ICalendarLink>
  );
};
export default Cal;
