import React, { useState } from "react";
import DropDownG from "../../common/others/DropDownG";
import MenuItem from "@mui/material/MenuItem";
import { patterns } from "./patterns";
import {
  FlexRowBetween,
  FlexRowColumn,
  GenericButton,
  GenericDiv,
  InputBox,
  OptionsFirst,
  RespFont,
  RespImg,
  RInput,
  Rotate90,
  RTextArea,
} from "../../common/Style";
import { useSelector, useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Switcher from "../../common/switcher/Switcher";
import { useExcelDownloder } from "react-xls";
import { sms } from "../../../store/reducers/dataReducer";
import {
  editNumbers,
  getUpdatedSms,
} from "../../../store/reducers/authReducer";
import { ReportsOption } from "./Style";
import import1 from "../../../assets/print/import1.svg";
import sms1 from "../../../assets/print/msg2.png";
import AskForDownload from "../../common/modals/AskForDownload.js";
import GenericModal from "../../common/modals/GenericModal";
import { sha512 } from "js-sha512";
import { useToasts } from "react-toast-notifications";
import { dateFormat, GetToast } from "../../common/modals/Toast";

const dic = {
  0: "יום ראשון הקרוב",
  1: "יום שני הקרוב",
  2: "יום שלישי הקרוב",
  3: "יום רביעי הקרוב",
  4: "יום חמישי הקרוב",
  5: "יום שישי הקרוב",
};

const Messages = ({
  prepareFirstMsg,
  prepareSecondMsg,
  prepareThirdMsg,
  prepareAutoMsg,
  prepareReminderMsg,
  prepareKdamMsg,
  prepareThankMsg,
  exportedGuests,
  getData,
  color,
  setAccept,
  setNotes,
  setPhone,
  setSort,
  accept,
  notes,
  phone,
  sort,
  acceptOptions,
  notesOptions,
  phoneOptions,
  sortOptions,
  defaultPreSms1,
  defaultPreSms2,
  defaultPreSms3,
  title,
  numbers,
  setLowerThan,
  lowerThan,
  customMsg,
  setCustomMsg,
}) => {
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [allowDownload, setAllowDownload] = useState(false);
  const [allowSMS, setAllowSMS] = useState(false);
  const { addToast } = useToasts();
  const sendSMS = () => {
    let dateFlag1 = true;
    let dateFlag2 = true;
    let arr = [];
    let ids = [];
    let pre1 = "";
    let msg = "";
    // `מספר שולחן: ${exportedGuests[i]["שולחן"]}`
    let localPre =
      lowerThan === 1
        ? defaultPreSms1
        : lowerThan === 2
        ? defaultPreSms2
        : lowerThan === 3
        ? defaultPreSms3
        : "";
    if (lowerThan)
      if (lowerThan === 4) {
        for (let i = 0; i < exportedGuests.length; i++) {
          ids.push(exportedGuests[i].id);
          // if (exportedGuests[i]["שולחן"] > 0)
          //   if (
          //     user.array[exportedGuests[i]["שולחן"] - 1].side.split(" ")[0] ===
          //     "או"
          //   ) {
          //     const searchRegExp = /[a-zA-Zא-ת]/gi;
          //     const tables = user.array[exportedGuests[i]["שולחן"] - 1].side
          //       .substring(3)
          //       .replaceAll(searchRegExp, "");
          //     msg = customMsg.replace(
          //       `[שולחן]`,
          //       `הנכם יושבים בשולחנות: ${exportedGuests[i]["שולחן"]}, ${tables}`
          //     );
          //   } else
          //     msg = customMsg.replace(
          //       `[שולחן]`,
          //       exportedGuests[i]["שולחן"] > 0
          //         ? `מספר שולחן: ${exportedGuests[i]["שולחן"]}`
          //         : ``
          //     );
          // else msg = customMsg.replace(`[שולחן]`, ``);
          msg = customMsg.replace(`[שולחן]`, exportedGuests[i]["שולחן"]);
          msg = msg.replace(/^\s*\n/gm, "");
          arr.push({ phone: exportedGuests[i]["טלפון"], msg });
        }
      } else if (lowerThan === 10) {
        for (let i = 0; i < exportedGuests.length; i++) {
          ids.push(exportedGuests[i].id);
          let sent = exportedGuests[i][`נשלחו`] + 1;
          msg =
            sent === 1
              ? patterns.first
              : sent === 2
              ? patterns.second
              : patterns.third;
          msg = msg
            .replace(
              `[הקדמה${exportedGuests[i][`נשלחו`] + 1}]`,
              exportedGuests[i][`הקדמה${exportedGuests[i][`נשלחו`] + 1}`]
            )
            .replace(`[הזמנה]`, exportedGuests[i]["קישור"]);
          // msg = `לכבוד ${exportedGuests[i]["שם"]},\n` + msg
          arr.push({
            phone: exportedGuests[i]["טלפון"],
            msg,
            sent: exportedGuests[i][`נשלחו`] + 1,
          });
        }
      } else if (lowerThan === 9) {
        for (let i = 0; i < exportedGuests.length; i++) {
          ids.push(exportedGuests[i].id);
          let sent = 9;
          // msg = `${
          //   dic[new Date(user.date).getDay()]
          // } הקרוב זה קורה - החתונה של ${user.brideFN + " " + user.brideLN} ו${
          //   user.groomFN + " " + user.groomLN
          // } 🎊🎉🍻\nמתנה באשראי - ${user.paybox}\nניווט לאירוע - ${
          //   user.waze
          // }\nמתרגשים ומצפים לראותכם 🙏``כככ הקרוב זה קורה!`;
          arr.push({
            phone: exportedGuests[i]["טלפון"],
            msg: customMsg,
            sent: exportedGuests[i][`נשלחו`] + 8,
          });
        }
      } else
        for (let i = 0; i < exportedGuests.length; i++) {
          ids.push(exportedGuests[i].id);
          pre1 =
            exportedGuests[i][`הקדמה${lowerThan}`] !== undefined &&
            exportedGuests[i][`הקדמה${lowerThan}`] !== null &&
            exportedGuests[i][`הקדמה${lowerThan}`] !== ``
              ? `${exportedGuests[i][`הקדמה${lowerThan}`]}`
              : localPre;
          msg = customMsg
            .replace(`[הקדמה${lowerThan}]`, pre1)
            .replace(`[הזמנה]`, exportedGuests[i]["קישור"]);
          // msg = `לכבוד ${exportedGuests[i]["שם"]},\n` + msg
          arr.push({ phone: exportedGuests[i]["טלפון"], msg });
        }
    var today = new Date();
    var tomorrow = new Date();

    tomorrow.setDate(today.getDate() - 1);
    if (
      lowerThan === 4 &&
      dateFormat(today.toISOString()) !== dateFormat(user.date)
    )
      dateFlag1 = false;
    if (
      lowerThan === 5 &&
      dateFormat(tomorrow.toISOString()) !== dateFormat(user.date)
    )
      dateFlag2 = false;
    if (dateFlag1 && dateFlag2)
      dispatch(
        sms({
          arr,
          title,
          password: sha512(password),
          ids,
          lowerThan,
          userPhone: user.phone,
          smsSender: user.smsSender,
        })
      ).then((res) => {
        if (!res.payload?.error) {
          setAllowSMS(false);
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
        } else GetToast(addToast, "error", res.payload.error);
      });
    else alert("נראה שיש ניסיון לשלוח הודעת תזכורת/תודה שלא בזמן...");
  };

  let msgOptions = [
    <MenuItem
      value={"חופשי"}
      onClick={() => {
        setAllowSMS(false);
        setLowerThan(0);
      }}
    >
      חופשי
    </MenuItem>,
    <MenuItem
      value={"הודעה ראשונה"}
      onClick={() => {
        setAllowSMS(false);
        prepareFirstMsg();
      }}
    >
      הודעה ראשונה
    </MenuItem>,
    <MenuItem
      value={"הודעה שניה"}
      onClick={() => {
        setAllowSMS(false);
        prepareSecondMsg();
      }}
    >
      הודעה שניה
    </MenuItem>,
    <MenuItem
      value={"הודעה שלישית"}
      onClick={() => {
        setAllowSMS(false);
        prepareThirdMsg();
      }}
    >
      הודעה שלישית
    </MenuItem>,
    <MenuItem
      value={"אוטומט"}
      onClick={() => {
        setAllowSMS(false);
        prepareAutoMsg();
      }}
    >
      אוטומט
    </MenuItem>,
    <MenuItem
      value={"קדם תזכורת"}
      onClick={() => {
        setAllowSMS(false);
        prepareKdamMsg();
      }}
    >
      קדם תזכורת
    </MenuItem>,
    <MenuItem
      value={"תזכורת בוקר האירוע"}
      onClick={() => {
        setAllowSMS(false);
        prepareReminderMsg();
      }}
    >
      תזכורת בוקר האירוע
    </MenuItem>,
    <MenuItem
      value={"הודעת תודה למחורת"}
      onClick={() => {
        setAllowSMS(false);
        prepareThankMsg();
      }}
    >
      הודעת תודה למחורת
    </MenuItem>,
  ];
  const [pattern, setPattern] = useState("חופשי");

  return (
    <OptionsFirst className="animate__animated animate__fadeIn">
      {/* <RespFont cSize={"50px"} pSize={"30px"}>בקרוב...</RespFont> */}
      {/* <div style={{ flex: 1, border: "1px solid red" }}><DropDownG label={"תבנית"} data={msgOptions} value={pattern} onChange={setPattern} /></div> */}

      <div style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <FlexRowBetween style={{ justifyContent: "space-evenly" }}>
            <div>
              <DropDownG
                key={"מענה"}
                label={"מענה"}
                data={acceptOptions}
                value={accept}
                onChange={setAccept}
              />
            </div>
            <div>
              <DropDownG
                key={"הערות"}
                label={"הערות"}
                data={notesOptions}
                value={notes}
                onChange={setNotes}
              />
            </div>
          </FlexRowBetween>

          <FlexRowBetween style={{ justifyContent: "space-evenly" }}>
            <div>
              <DropDownG
                key={"פלאפון"}
                label={"פלאפון"}
                data={phoneOptions}
                value={phone}
                onChange={setPhone}
              />
            </div>
            <div>
              <DropDownG
                key={"מיון לפי"}
                label={"מיון לפי"}
                data={sortOptions}
                value={sort}
                onChange={setSort}
              />
            </div>
          </FlexRowBetween>
          {/* <InputBox labelColor={"gray"} color={"#3A3C3F"}>
                    <FlexRowColumn style={{ width: "90%", margin: "10px auto" }}>
                        <div className='relativeBox' style={{ width: "100px", margin: "0px" }}>
                            <input value={sender} type="text" onChange={(e) => {
                                if (/^[a-zA-Z]{1,11}$/.test(e.target.value))
                                    setSender(e.target.value)
                                else alert("1-11 אותיות באנגלית בלבד")
                            }} />
                            <label>כותרת</label>
                        </div>
                        <OverlayTrigger placement={'top'} overlay={<Tooltip>
                            {user.smsPhone ? !title ? "ההודעה תישלח מהמספר שלך ובכך יוכל האורח להשיב בהודעה" : "כותרת ההודעה - האורח לא יוכל להשיב להודעה" : "על מנת שהאורח יוכל להשיב להודעה יש לפנות למנהל המערכת כדי להגדיר מספר פלאפון, אחרת בחר כותרת אשר תופיע כשולח ההודעה"}
                        </Tooltip>}>
                            <Rotate90 style={{ margin: "0 10px", marginTop: "15px" }}>
                                <Switcher disable={!user.smsPhone} checked={title} onChange={setTitle} color={user.color} />
                            </Rotate90>
                        </OverlayTrigger>
                        <RespFont style={{ marginTop: "15px", marginRight: "5px" }}>מספר לחזרה</RespFont>

                    </FlexRowColumn>
                </InputBox> */}
          <FlexRowBetween
            style={{
              margin: "10px auto",
              textAlign: "center",
              justifyContent: "space-evenly",
            }}
          >
            {/* <div>
                        <RespFont>שולחן</RespFont>
                        <Switcher checked={table} onChange={setTable} color={user.color} />
                    </div> */}

            <div>
              <DropDownG
                label={"תבנית"}
                data={msgOptions}
                value={pattern}
                onChange={setPattern}
              />
            </div>
          </FlexRowBetween>
          {/* <div style={{ position: "relative", width: "80%", margin: "0px auto" }}>
                    <RTextArea value={msg1} onChange={(e) => setMsg1(e.target.value)} color={user.color} placeholder="תוכן ההודעה" rows="2" />
                </div>

                <div style={{ textAlign: "center" }}>
                    <RespFont>קישור</RespFont>
                    <Switcher checked={link} onChange={setLink} color={user.color} />
                </div> */}

          <div
            style={{ position: "relative", width: "80%", margin: "0px auto" }}
          >
            <RTextArea
              value={customMsg}
              onChange={(e) => setCustomMsg(e.target.value)}
              color={user.color}
              placeholder="תוכן ההודעה"
              rows="5"
            />
          </div>
          {/* <div style={{ position: "relative", width: "80%", margin: "0px auto" }}>
                    <RTextArea value={msg2} onChange={(e) => setMsg2(e.target.value)} color={user.color} placeholder="תוכן ההודעה" rows="2" />
                </div>
                <div style={{ margin: "0 10px", textAlign: "center" }}>
                    <div>מספר שולחן</div>
                    <Switcher checked={table} onChange={setTable} color={user.color} style={{ margin: "0 10px" }} />
                </div> */}
          {/* <FlexRowBetween style={{ margin: "10px auto", justifyContent: "space-evenly" }}>
                    {exportedGuests.length > 0 &&
                        <OverlayTrigger placement={'top'} overlay={<Tooltip>{exportedGuests?.length < user.sms ?
                            `יש ברשותך ${user.sms} הודעות, האם לשלוח הודעה זו ל${exportedGuests?.length} אורחים?`
                            :
                            `בחרת לשלוח הודעה זו ל ${exportedGuests?.length} אורחים אבל יש ברשותך רק ${user.sms} הודעות בחבילה, צמצם רשימה או רכוש הודעות נוספות`}</Tooltip>}>
                            <GenericButton background={user.color} onClick={() => {
                                if (exportedGuests?.length > user.sms)
                                    alert("אין ברשותך מספיק הודעות כדי להמשיך פעולה זו")
                                else sendSMS()
                            }} >SMS</GenericButton>
                        </OverlayTrigger>
                    }
                    {dataError?.kind === "sms" &&
                        <ExcelDownloder
                            data={{ "שגיאות": dataError.error }}
                            filename={'שגיאות sms'}
                            type={Type.Button} // or type={'button'}
                            style={{ border: "none" }}
                        >
                            <GenericButton className="animate__animated animate__fadeIn" background={user.color}>שגיאות sms</GenericButton>
                        </ExcelDownloder>

                    }
                </FlexRowBetween> */}

          <FlexRowBetween
            style={{ margin: "10px auto", justifyContent: "space-evenly" }}
          >
            <RInput
              color={user.color}
              onChange={(e) => setPassword(e.target.value)}
              style={{ padding: "7px 5px", width: "100px" }}
              placeholder="סיסמא"
            />
            <GenericDiv
              background={color}
              onClick={() => {
                setAllowDownload(true);
                setAllowSMS(true);
                getData();
              }}
            >
              ייבוא
            </GenericDiv>
            {exportedGuests.length > 0 && allowSMS && lowerThan !== 0 && (
              <GenericDiv background={color} onClick={() => sendSMS()}>
                SMS
              </GenericDiv>
            )}
          </FlexRowBetween>
          {
            // false
            allowDownload && exportedGuests.length && (
              <GenericModal
                init={setAllowDownload}
                div={
                  <AskForDownload
                    color={user.color}
                    title={`נתונים`}
                    filename={`נתונים`}
                    body={`האם ברצונך להוריד אקסל נתונים?`}
                    no={setAllowDownload}
                    data={exportedGuests}
                    init={setAllowDownload}
                  />
                }
              />
            )
          }
        </div>
      </div>
    </OptionsFirst>
  );
};

export default Messages;
