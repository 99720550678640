import React from 'react'
import Calendar from 'react-calendar';
import { GenericDiv, CenteredForm } from '../Style';
import 'react-calendar/dist/Calendar.css';
import './style.css'
const GCalendar = ({ setDate, date, init, background = "#fda14b" }) => {
    return (<CenteredForm onClick={(e) => e.stopPropagation()}>
        <Calendar locale={"he"} onChange={setDate} value={date} style={{ paddingBottom: "20px" }} />
        <GenericDiv hoverBackground={"rgb(223, 223, 223)"} background={background} style={{ borderRadius: "0px", width: "100%", fontSize: "16px", padding: "10px" }} onClick={() => { init(null); }}>שמירה</GenericDiv>
    </CenteredForm>
    )
}
export default GCalendar