import React from 'react';
import { CenteredForm, GenericButton, GenericDiv, PlaceHolderImage, RespFont } from '../../common/Style';
import exitModalLogo from '../../../assets/common/cancel1.svg';

const PaymentAccessModal = (props) => {

    return (
        <CenteredForm onClick={(e) => e.stopPropagation()}>
            <PlaceHolderImage src={exitModalLogo} alt="exitModalLogo" style={{ top: "8px", left: "8px", transform: "translate(0,0)", width: "32px", cursor: "pointer" }} onClick={() => props.init(null)} />
            <div style={{ padding: "0 20px" }}>
                <RespFont pSize={"20px"} cSize={"22px"} style={{ fontFamily: "logo", fontWeight: "700", margin: "20px" }}>IBS - מתנות לאירועים</RespFont>
                <RespFont style={{ marginBottom: "30px" }}>
                    <div style={{ fontWeight: "bold" }}>אחד השירותים החשובים באירוע!</div>
                    <div>תחשבו על אנשים שלא יכולים להגיע מסיבות כאלו ואחרות או על אנשים שהייתם באירועים שלהם והקשר ביניכם ירד ולא יגיעו,</div>
                    <div>עד הפעם הבאה שתיפגשו או שיפגשו את המשפחות ואם בכלל יזכרו לתת מעטפה.</div>
                    <div>בעזרת שירות זה יוכלו בלחיצת כפתור להעניק לכם מתנה בלי שתשלמו עליהם מנות</div>
                    <div style={{ fontWeight: "bold" }}>הסבר:</div>
                    <div>הענקת מתנה באשראי ללקוחות כל הבנקים.</div>
                    <div>כמו בכל חברת אישורי הגעה קיימת עמלת סליקה - על האורח בלבד, משמע אתם תקבלו את המתנה במלואה!</div>
                    <div>עד 2000₪ (לא כולל) יחויב בעמלה של 2%, לדוגמא על מתנה של 300₪ יחויב ב- 6₪,</div>
                    <div>על 2000₪ ויותר יחויב בעמלה של 1.5%.</div>
                    <div>ניתן לעקוב אחר כל ההעברות במערכת.</div>
                    <div>הכסף (ללא העמלות) יועבר לבעל האירוע עד יומיים למחורת האירוע.</div>
                    <div>יש ללחוץ על כפתור האישור למטה ולאשר בהודעת ווצאפ.</div>
                </RespFont>
            </div>
            <a style={{ textDecoration: "none", color: "inherit", width: "100%" }} target="_blank" rel="noreferrer" href={
                `https://wa.me/972547439322/?text=קראתי את התקנון ואני מאשר/ת`}>
                <GenericDiv hoverBackground={"rgb(223, 223, 223)"} background={props.color}
                    style={{ borderRadius: "0px", width: "100%", padding: "10px 0" }}>אישור</GenericDiv>
            </a>
        </CenteredForm>
    )
}

export default PaymentAccessModal;