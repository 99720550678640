import React from 'react';
import { CenteredForm, PlaceHolderImage, RespFont } from '../Style';
import exitModalLogo from '../../../assets/common/cancel1.svg';
const TitleAndContent = ({ title, content, init }) => {
    let height = window.screen.availHeight;

    return (<CenteredForm onClick={(e) => e.stopPropagation()} style={{ padding: "10px 20px", borderRadius: "5px", maxHeight: `${height * 0.8}px` }}>
        <PlaceHolderImage src={exitModalLogo} alt="exitModalLogo" style={{ top: "8px", left: "8px", transform: "translate(0,0)", width: "25px", cursor: "pointer" }} onClick={() => init(null)} />
        <RespFont pSize={"20px"} cSize={"22px"} style={{ textAlign: "center", letterSpacing: "-0.5px", color: "#3A3C3F", fontWeight: "700", padding: `30px 0`, paddingBottom: `10px`, fontFamily: "openSansMedium" }}>{title}</RespFont>
        <RespFont pSize={"14px"} cSize={"16px"} style={{ fontFamily: "openSansRegular", paddingBottom: "30px" }}>{content}</RespFont>

    </CenteredForm>)
}
export default TitleAndContent;