import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ClientRoutes from "./Routes";
import Itay from "../components/unAuth/Itay";
import ChooseProvider from "../components/unAuth/ChooseProvider";
import Login from "../components/unAuth/Login";
import Register from "../components/unAuth/Register";
import Aviv from "../components/unAuth/producers/aviv/Aviv";
import Ohad from "../components/unAuth/producers/aviv/Ohad";
import GuestInvitation2 from "../components/common/invitation/GuestInvitation2";
import SelfInvitationNavigator from "../components/common/invitation/SelfInvitationNavigator";
import SuccessPage from "../components/common/payments/SuccessPage";
import PaymentLInk from "../components/common/payments/PaymentLInk";

const UnAuthSwitch = () => {
  return (
    <Switch>
      <Route
        exact={true}
        path={ClientRoutes.Producers.aviv}
        component={(props) => <Aviv {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.Producers.ohad}
        component={(props) => <Ohad {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.Producers.itay}
        component={(props) => <Itay {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.chooseProvider}
        component={(props) => <ChooseProvider {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.login}
        component={(props) => <Login {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.register}
        component={(props) => <Register {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.n}
        component={(props) => <GuestInvitation2 {...props} />}
      />
      {/* <Route exact={true} path={ClientRoutes.UnAuth.c} component={props => <GuestInvitation2 {...props} />} /> */}
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.p}
        component={(props) => <PaymentLInk {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.paymentSuccess}
        component={(props) => <SuccessPage {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.s}
        component={(props) => <SelfInvitationNavigator {...props} />}
      />
      <Redirect
        from="/"
        to={
          localStorage["logout"]
            ? localStorage["logout"]
            : ClientRoutes.Producers.itay
        }
      />
    </Switch>
  );
};

export default UnAuthSwitch;
