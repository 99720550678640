import { createSlice } from "@reduxjs/toolkit";
import { post2, get2 } from "../api/http";
import ServerRoutes from "../api/routes";

const initialState = {
  sms: {},
  smsError: null,
  smsLoading: false,
};

const _setSms = (state, action) => {
  state.sms = action.payload;
};

const _deleteSms = (state, action) => {
  let editedSms = { ...state.sms };
  delete editedSms[action.payload];
  state.sms = editedSms;
};

const _actionStart = (state) => {
  state.smsLoading = true;
  state.smsError = null;
};

const _actionSuccess = (state) => {
  state.smsLoading = false;
  state.smsError = null;
};

const _actionFailed = (state, action) => {
  state.smsLoading = false;
  state.smsError = action.payload.error;
};

const _cleanSmsState = (state) => {
  state.sms = {};
  state.smsError = null;
  state.smsLoading = false;
};

const sms = createSlice({
  name: "sms",
  initialState,
  reducers: {
    cleanSmsState: _cleanSmsState,
    deleteSms1: _deleteSms,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    setSms: _setSms,
    actionSuccess: _actionSuccess,
  },
});

const { reducer, actions } = sms;

const {
  actionStart,
  actionFailed,
  actionSuccess,
  deleteSms1,
  setSms,
  cleanSmsState,
} = actions;

export default reducer;

export const getAll = () => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(ServerRoutes.Sms.getAll);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setSms(data));
    }
  };
};

export const cleanAll = () => {
  return async (dispatch) => {
    return dispatch(cleanSmsState());
  };
};

export const deleteSms = (id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Sms.delete, { id });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(deleteSms1(id));
    }
  };
};
