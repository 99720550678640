import React from "react";
import { useSelector } from 'react-redux';
import { getImageUrl } from "../../common/modals/Toast";

const Output = () => {
    const { user } = useSelector(state => state.authReducer);
    let pic = user?.pic !== "" ? `${getImageUrl().replace('"', '')}${user._id}/${user.pic}` : `${getImageUrl()}sys/invBack3.jpeg`;

    const scale = 100 / user.picPosition.width;
    const transform = {
        x: `${-user.picPosition.x * scale}%`,
        y: `${-user.picPosition.y * scale}%`,
        scale,
        width: "calc(100% + 0.5px)",
        height: "auto"
    };

    const imageStyle = {
        transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
        width: transform.width,
        height: transform.height
    };

    return (
        <div
            className="output"
            style={{ paddingBottom: `${100 / (user.cropAreaAspect.x/user.cropAreaAspect.y)}%` }}
        >
            <img src={pic} alt="pic" style={imageStyle} />
        </div>
    );
};

export default Output;