import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import {
  editDetails,
  editDetailsRudexOnly,
  editUserPic,
} from "../../store/reducers/authReducer";
import {
  CenteredForm,
  GenericButton,
  FlexRowBetween,
  InputBox,
  RespFont,
  MainDiv,
  GradientText,
  GenericDiv,
  FlexColumnBetween,
  RTextArea,
} from "../common/Style";
import gallery from "../../assets/client/galleryWhite.svg";
import { getImageUrl, GetToast } from "../common/modals/Toast";
import SketchColor from "../common/SketchColor";
import { slide as Menu } from "react-burger-menu";
import ClientRoutes from "../../navigation/Routes";
import { useHistory } from "react-router-dom";
import GenericModal from "../common/modals/GenericModal";
import LogOutAll from "../common/LogOut/LogOutAll";
import EditPics from "./EditPics";
import { deletePhoto } from "../../store/reducers/photosReducer";
import OpacityLoading from "../common/loader/OpacityLoading";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import InvitationDemo from "../common/invitation/InvitationDemo";
import Switcher from "../common/switcher/Switcher";
import brain from "../../assets/client/brain.svg";
import pdf from "../../assets/client/pdf1.svg";
import logout1 from "../../assets/client/logout.svg";
import accessPayment from "../../assets/client/accessPayment.svg";
import video from "../../assets/client/video2.svg";
import tables from "../../assets/client/editTables.svg";
import creditCard from "../../assets/client/creditCard.svg";
import todoList from "../../assets/client/todoList.svg";
import guests1 from "../../assets/client/guests.svg";
import expenses from "../../assets/client/expenses.svg";
import { MenuCss } from "../common/modals/Toast";
import SketchColorShape from "../common/SketchColorShape";
import TopInv from "../../assets/invitation/TopInv";
import BottomInv from "../../assets/invitation/BottomInv";
import Image from "./image/Image";
import PaymentAccessModal from "./payments/PaymentAccessModal";
import ColorCheckbox from "../common/others/ColorCheckbox";
import GiftsInfo from "./GiftsInfo";
const EditDetails = () => {
  const { user, authLoading, authError } = useSelector(
    (state) => state.authReducer
  );
  let history = useHistory();
  const [view, setView] = useState(false); // 1 for details, 2 for pic
  const [loading, setLoading] = useState(false);
  const [demoMode, setDemoMode] = useState(true);
  const [sharePics, setSharePics] = useState(user.sharePics);
  const [specialDishes, setSpecialDishes] = useState(user.specialDishes);
  const [inv, setInv] = useState(user.inv);
  const [invK, setInvK] = useState(user.invK);
  const [centeredPic, setCenteredPic] = useState(user.centeredPic);
  const [family, setFamily] = useState(user.family);
  const [editPic, setEditPic] = useState(false);
  const [giftsInfo, setGiftsInfo] = useState(false);
  const [showAccessPayment, setShowAccessPayment] = useState(false);
  const [details, setDetails] = useState(user);
  const [cropAreaAspect, setCropAreaAspect] = useState(user.cropAreaAspect);
  const [picPosition, setPicPosition] = useState(user.picPosition);
  const [transportation, setTransportation] = useState(user.transportation);
  const [blessing, setBlessing] = useState(user.blessing);
  const [logout, setLogout] = useState(false);
  let height = window.screen.availHeight;
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { photosError } = useSelector((state) => state.photosReducer);
  const [alpha, setAlpha] = useState(user.color);
  const [displayHeartPicker, setDisplayHeartPicker] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [displayTopColorPicker, setDisplayTopColorPicker] = useState(false);
  const [displayBottomColorPicker, setDisplayBottomColorPicker] =
    useState(false);
  const [topColor, setTopColor] = useState(user.gradient[0]);
  const [bottomColor, setBottomColor] = useState(user.gradient[1]);
  const [heartColor, setHeartColor] = useState(user.heartColor);
  const [hex, setHex] = useState(user.color);
  const { addToast } = useToasts();
  const { register, handleSubmit } = useForm();

  const handleSetSpecialDishes = () => {
    setSpecialDishes(!specialDishes);
  };

  const handleSetSharePics = () => {
    setSharePics(!sharePics);
  };

  const handleSetBlessing = () => {
    setBlessing(!blessing);
  };

  const handleSetTransportation = () => {
    setTransportation(!transportation);
  };

  const handleSetFamily = () => {
    setFamily(!family);
  };

  const handleSetInvK = () => {
    if (invK === 3) setInvK(1);
    else setInvK(invK + 1);
  };

  const handleSetInv = () => {
    if (inv === 1) setInv(2);
    else setInv(1);
  };

  useEffect(() => {
    if (authError) GetToast(addToast, "error", authError);
  }, [authError]);

  useEffect(() => {
    if (photosError) GetToast(addToast, "error", photosError);
  }, [photosError]);

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  const onSubmit = async (data) => {
    const body = {
      brideFN: user.eventType ? data.brideFN : "אחר",
      groomFN: user.eventType ? data.groomFN : "אחר",
      brideLN: user.eventType ? data.brideLN : "אחר",
      groomLN: user.eventType ? data.groomLN : "אחר",
      brideParents: user.eventType ? data.brideParents : "אחר",
      transportation,
      blessing,
      sharePics,
      specialDishes,
      groomParents: user.eventType ? data.groomParents : "אחר",
      transportationDetails: data.transportationDetails,
      blessingPlaceholder: data.blessingPlaceholder,
      sharePicsPlaceholder: data.sharePicsPlaceholder,
      sharePicsLink: data.sharePicsLink,
      calendarName: user.eventType ? "" : data.calendarName,
      hall: data.hall,
      city: data.city,
      hour1: data.hour1,
      hour2: data.hour2 ? data.hour2 : data.hour1,
      address: data.address,
      inv,
      invK,
      waze: data.waze,
      gradient: [topColor, bottomColor],
      family,
      heartColor,
      picPosition: data.picPosition ? data.picPosition : picPosition,
      cropAreaAspect: data.cropAreaAspect
        ? data.cropAreaAspect
        : cropAreaAspect,
    };
    // if (alpha !== user.color || fillColor !== user.color || hex !== user.color)
    // body["color"] = addAlpha(hex, alpha)
    if (hex !== user.color) body["color"] = hex;
    if (demoMode) dispatch(editDetailsRudexOnly(body));
    // setDetails({ ...body, date: user.date, gradient: user.gradient, pic: user.pic, _id: user._id, gradient: [topColor, bottomColor] })
    else {
      dispatch(editDetails(body)).then(
        (res) => {
          if (!res.payload?.error)
            GetToast(addToast, "success", "הפעולה הצליחה!!");
          setDetails({
            ...body,
            date: user.date,
            gradient: user.gradient,
            pic: user.pic,
            _id: user._id,
            gradient: [topColor, bottomColor],
          });
        },
        (error) => {
          GetToast(addToast, "error", error);
        }
      );
    }
  };

  const deletePhoto1 = async (id) => dispatch(deletePhoto(id));
  const deletePhotoOnly = async () => {
    setLoading(true);
    await deletePhoto1(user.pic);
    dispatch(editUserPic({ pic: "" })).then(
      (res) => {
        if (!res.payload?.error)
          GetToast(addToast, "success", "הפעולה הצליחה!!");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
    setLoading(false);
  };
  // let pic = `${getImageUrl().replace('"', '')}${user._id}/${user.pic}`;
  let pic =
    user.pic !== ""
      ? `${getImageUrl().replace('"', "")}${user._id}/${user.pic}`
      : `${getImageUrl()}sys/invBack3.jpeg`;

  return (
    <MainDiv
      onClick={() => setIsOpen(false)}
      className="animate__animated animate__fadeIn"
      style={{
        background: `radial-gradient(circle, ${addAlpha(
          user.color,
          0.75
        )} 0%, #f2f2f2 100%)`,
      }}
    >
      <Menu
        noOverlay
        isOpen={isOpen}
        onStateChange={handleStateChange}
        right
        width={"max-content"}
        styles={MenuCss(user.color)}
      >
        <div
          className="menu-item"
          style={{ marginTop: "0px" }}
          onClick={() => history.push(ClientRoutes.User.guests)}
        >
          <div>מוזמנים</div>
          <img src={guests1} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.tables)}
        >
          <div>מערך שולחנות</div>
          <img src={tables} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.instructions)}
        >
          <div>הדרכות</div>
          <img src={video} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.todos)}
        >
          <div>יומן משימות</div>
          <img src={todoList} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.expenses)}
        >
          <div>יומן הוצאות</div>
          <img src={expenses} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.print)}
        >
          <div>מוח המערכת</div>
          <img src={brain} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          style={{
            position: "absolute",
            bottom: "20px",
            fontWeight: "bold",
            fontSize: "20px",
          }}
          onClick={() => setLogout(true)}
        >
          <div>התנתקות</div>
          <img src={logout1} style={{ width: "25px" }} />
        </div>
      </Menu>

      <FlexColumnBetween
        style={{
          justifyContent: "space-evenly",
          height: "100%",
          position: "absolute",
        }}
      >
        <InvitationDemo />

        <CenteredForm
          onClick={(e) => e.stopPropagation()}
          onSubmit={handleSubmit(onSubmit)}
          style={{
            maxHeight: `90vh`,
            paddingTop: "30px",
            paddingBottom: "10px",
            boxShadow: "5px 20px 50px rgba(0,0,0,0.3)",
            background:
              "linear-gradient(to bottom right,rgba(255,255,255,0.7),rgba(255,255,255,0.8),rgba(255,255,255,0.7))",
            margin: "50px auto",
            boxShadow: "none",
          }}
        >
          {view ? (
            <div className="animate__animated animate__fadeIn">
              <Image
                pic={pic}
                color={details.color}
                save={onSubmit}
                setView={setView}
                setPicPosition={setPicPosition}
                setCropAreaAspect={setCropAreaAspect}
              />
            </div>
          ) : (
            <div>
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip>{demoMode ? "הדמיה בלבד" : "עריכת הזמנה"}</Tooltip>
                }
              >
                <div
                  style={{ position: "absolute", right: "15px", top: "15px" }}
                >
                  <Switcher
                    checked={demoMode}
                    onChange={setDemoMode}
                    color={user.color}
                  />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement={"bottom"}
                overlay={<Tooltip>סגנון הזמנה</Tooltip>}
              >
                <GenericDiv
                  onClick={handleSetInvK}
                  background={user.color}
                  style={{ position: "absolute", left: "15px", top: "15px" }}
                >
                  {invK}
                </GenericDiv>
              </OverlayTrigger>
              <RespFont
                pSize={"20px"}
                cSize={"22px"}
                style={{
                  textAlign: "center",
                  color: "#3A3C3F",
                  fontWeight: "bold",
                  padding: `10px 0`,
                  paddingBottom: `10px`,
                }}
              >
                עריכת פרטים
              </RespFont>
              <InputBox labelColor={"gray"} color={"#3A3C3F"}>
                {user.eventType ? (
                  <div>
                    <FlexRowBetween>
                      <div
                        style={{
                          position: "relative",
                          width: "40%",
                          margin: "4px 5%",
                        }}
                      >
                        <input
                          defaultValue={user.brideFN}
                          type="text"
                          name="brideFN"
                          required={true}
                          minLength={2}
                          maxLength={20}
                          ref={register()}
                        />
                        <label>שם פרטי - כלה</label>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          width: "40%",
                          margin: "4px 5%",
                        }}
                      >
                        <input
                          defaultValue={user.groomFN}
                          type="text"
                          name="groomFN"
                          required={true}
                          minLength={2}
                          maxLength={20}
                          ref={register()}
                        />
                        <label>שם פרטי - חתן</label>
                      </div>
                    </FlexRowBetween>
                    <FlexRowBetween>
                      <div
                        style={{
                          position: "relative",
                          width: "40%",
                          margin: "4px 5%",
                        }}
                      >
                        <input
                          defaultValue={user.brideLN}
                          type="text"
                          name="brideLN"
                          required={true}
                          minLength={2}
                          maxLength={20}
                          ref={register()}
                        />
                        <label>שם משפחה - כלה</label>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          width: "40%",
                          margin: "4px 5%",
                        }}
                      >
                        <input
                          defaultValue={user.groomLN}
                          type="text"
                          name="groomLN"
                          required={true}
                          minLength={2}
                          maxLength={20}
                          ref={register()}
                        />
                        <label>שם משפחה - חתן</label>
                      </div>
                    </FlexRowBetween>

                    <FlexRowBetween>
                      <OverlayTrigger
                        placement={"top"}
                        overlay={<Tooltip>{"שמות מלאים"}</Tooltip>}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "40%",
                            margin: "4px 5%",
                          }}
                        >
                          <input
                            defaultValue={user.brideParents}
                            type="text"
                            name="brideParents"
                            required={true}
                            minLength={2}
                            maxLength={50}
                            ref={register()}
                          />
                          <label>הורי כלה</label>
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement={"top"}
                        overlay={<Tooltip>{"שמות מלאים"}</Tooltip>}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "40%",
                            margin: "4px 5%",
                          }}
                        >
                          <input
                            defaultValue={user.groomParents}
                            type="text"
                            name="groomParents"
                            required={true}
                            minLength={2}
                            maxLength={50}
                            ref={register()}
                          />
                          <label>הורי חתן</label>
                        </div>
                      </OverlayTrigger>
                    </FlexRowBetween>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "60%",
                        margin: "20px auto",
                      }}
                    >
                      <div
                        className="relativeBox"
                        style={{ width: "45%", margin: "0 0" }}
                      >
                        <input
                          type="time"
                          defaultValue={user.hour1}
                          name="hour1"
                          required={true}
                          ref={register()}
                        />
                        <label>קבלת פנים</label>
                      </div>
                      <div
                        className="relativeBox"
                        style={{ width: "45%", margin: "0 0" }}
                      >
                        <input
                          type="time"
                          defaultValue={user.hour2}
                          name="hour2"
                          required={true}
                          ref={register()}
                        />
                        <label>חופה וקידושין</label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <FlexRowBetween>
                    <div
                      style={{
                        position: "relative",
                        width: "25%",
                        margin: "4px 5%",
                      }}
                    >
                      <input
                        type="time"
                        defaultValue={user.hour1}
                        name="hour1"
                        required={true}
                        ref={register()}
                      />
                      <label>קבלת פנים</label>
                    </div>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip>{"כותרת האירוע אשר תופיע ביומן"}</Tooltip>
                      }
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "55%",
                          margin: "4px 5%",
                        }}
                      >
                        <input
                          defaultValue={user.calendarName}
                          type="text"
                          name="calendarName"
                          required={true}
                          minLength={2}
                          maxLength={50}
                          ref={register()}
                        />
                        <label>תיאור האירוע</label>
                      </div>
                    </OverlayTrigger>
                  </FlexRowBetween>
                )}
                <FlexRowBetween style={{ width: "90%", marginRight: "5%" }}>
                  <div style={{ position: "relative", maxWidth: "30%" }}>
                    <input
                      defaultValue={user.hall}
                      type="text"
                      name="hall"
                      required={true}
                      minLength={2}
                      maxLength={20}
                      ref={register()}
                    />
                    <label>שם אולם</label>
                  </div>
                  <div style={{ position: "relative", maxWidth: "30%" }}>
                    <input
                      defaultValue={user.city}
                      type="text"
                      name="city"
                      required={true}
                      minLength={2}
                      maxLength={20}
                      ref={register()}
                    />
                    <label>עיר</label>
                  </div>
                  <div style={{ position: "relative", maxWidth: "30%" }}>
                    <input
                      defaultValue={user.address}
                      type="text"
                      name="address"
                      required={true}
                      minLength={2}
                      maxLength={50}
                      ref={register()}
                    />
                    <label>כתובת</label>
                  </div>
                </FlexRowBetween>
                <FlexRowBetween style={{ alignItems: "baseline" }}>
                  <div
                    className="relativeBox"
                    style={{
                      marginTop: "12px",
                      width: "70%",
                      marginRight: "5%",
                    }}
                  >
                    <input
                      placeholder="לחצו על התמונה משמאל  👈"
                      defaultValue={user.waze}
                      type="text"
                      name="waze"
                      minLength={2}
                      maxLength={50}
                      ref={register()}
                    />
                    <label>ווייז</label>
                  </div>
                  <OverlayTrigger
                    placement={"top"}
                    overlay={
                      <Tooltip>{"מדריך יצירת קישור לניווט לאולם"}</Tooltip>
                    }
                  >
                    <a
                      target={"_blank"}
                      style={{ marginLeft: "5%" }}
                      rel="noopener noreferrer"
                      href={`${getImageUrl()}sys/waze.pdf`}
                    >
                      <img src={pdf} alt="pdf" style={{ width: "30px" }} />
                    </a>
                  </OverlayTrigger>
                </FlexRowBetween>

                <FlexRowBetween style={{ alignItems: "baseline" }}>
                  <div
                    className="relativeBox"
                    style={{ width: "70%", marginRight: "5%" }}
                  >
                    <input
                      placeholder="לחצו על התמונה משמאל  👈"
                      defaultValue={user.paybox}
                      disabled={true}
                      type="text"
                      name="paybox"
                      minLength={2}
                      maxLength={50}
                      ref={register()}
                    />
                    <label
                      style={{ top: "-22px", fontSize: "12px", color: "gray" }}
                    >
                      מתנה באשראי
                    </label>
                  </div>
                  <OverlayTrigger
                    placement={"top"}
                    overlay={<Tooltip>{"לחצו כאן"}</Tooltip>}
                  >
                    {/* <a
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        marginLeft: "5%",
                      }}
                      target="_blank"
                      rel="noreferrer"
                      href={`https://wa.me/972547439322/?text=אשמח לקבל שירות של מתנות באשראי לאירוע שלי`}
                    > */}
                    <img
                      onClick={() => setGiftsInfo(!giftsInfo)}
                      src={accessPayment}
                      alt="accessPayment"
                      style={{
                        width: "30px",
                        marginLeft: "5%",
                        cursor: "pointer",
                      }}
                    />
                    {/* </a> */}
                  </OverlayTrigger>
                </FlexRowBetween>
                {transportation && (
                  <div
                    style={{
                      position: "relative",
                      width: "90%",
                      margin: "10px auto",
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    <RTextArea
                      defaultValue={user.transportationDetails}
                      color={user.color}
                      placeholder="פרטי הסעה"
                      name="transportationDetails"
                      type="text"
                      rows="2"
                      minLength={0}
                      maxLength={300}
                      ref={register()}
                      style={{ margin: "0 auto" }}
                    />
                  </div>
                )}
                {blessing && (
                  <div
                    style={{
                      position: "relative",
                      width: "90%",
                      margin: "10px auto",
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    <RTextArea
                      defaultValue={user.blessingPlaceholder}
                      color={user.color}
                      placeholder={user.blessingPlaceholder}
                      name="blessingPlaceholder"
                      type="text"
                      rows="2"
                      minLength={0}
                      maxLength={300}
                      ref={register()}
                      style={{ margin: "0 auto" }}
                    />
                  </div>
                )}
                {sharePics && (
                  <div
                    style={{
                      position: "relative",
                      width: "90%",
                      margin: "10px auto",
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    <RTextArea
                      defaultValue={user.sharePicsLink}
                      color={user.color}
                      placeholder={"קישור"}
                      name="sharePicsLink"
                      type="text"
                      rows="2"
                      minLength={0}
                      maxLength={300}
                      ref={register()}
                      style={{ margin: "0 auto" }}
                    />
                  </div>
                )}
                {sharePics && (
                  <div
                    style={{
                      position: "relative",
                      width: "90%",
                      margin: "10px auto",
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    <RTextArea
                      defaultValue={user.sharePicsPlaceholder}
                      color={user.color}
                      placeholder={user.sharePicsPlaceholder}
                      name="sharePicsPlaceholder"
                      type="text"
                      rows="2"
                      minLength={0}
                      maxLength={300}
                      ref={register()}
                      style={{ margin: "0 auto" }}
                    />
                  </div>
                )}

                <FlexRowBetween style={{ width: "90%", margin: "10px auto" }}>
                  <div
                    style={{
                      background:
                        "radial-gradient(circle, silver 50%, transparent 50%)",
                    }}
                  >
                    <SketchColorShape
                      shape={<TopInv fill={topColor} />}
                      display={displayTopColorPicker}
                      setDisplay={setDisplayTopColorPicker}
                      setHex={setTopColor}
                      color={topColor}
                      direction={"right"}
                      percent={"0%"}
                      tooltip={"צבע רקע חלק עליון"}
                    />
                  </div>

                  <SketchColor
                    title={"צבע לב + שליחה"}
                    display={displayHeartPicker}
                    setDisplay={setDisplayHeartPicker}
                    setHex={setHeartColor}
                    color={heartColor}
                    direction={"left"}
                    percent={"0%"}
                    tooltip={"צבע לב + כפתור השליחה"}
                  />

                  <SketchColorShape
                    shape={<BottomInv fill={bottomColor} />}
                    display={displayBottomColorPicker}
                    setDisplay={setDisplayBottomColorPicker}
                    setHex={setBottomColor}
                    color={bottomColor}
                    direction={"left"}
                    percent={"0%"}
                    tooltip={"צבע רקע חלק תחתון"}
                  />
                </FlexRowBetween>

                <FlexRowBetween
                  style={{
                    justifyContent: "space-between",
                    marginTop: "10px",
                    zIndex: 2,
                    width: "90%",
                    margin: "10px auto",
                  }}
                >
                  <div>
                    {inv === 1 && (
                      <FlexRowBetween style={{ justifyContent: "flex-start" }}>
                        <ColorCheckbox
                          checked={!family}
                          color={user.color}
                          handleCHange={handleSetFamily}
                        />
                        <RespFont>שמות הורים</RespFont>
                      </FlexRowBetween>
                    )}
                    <FlexRowBetween style={{ justifyContent: "flex-start" }}>
                      <ColorCheckbox
                        checked={specialDishes}
                        color={user.color}
                        handleCHange={handleSetSpecialDishes}
                      />
                      <RespFont>מנות מיוחדות</RespFont>
                    </FlexRowBetween>
                    <FlexRowBetween style={{ justifyContent: "flex-start" }}>
                      <ColorCheckbox
                        checked={sharePics}
                        color={user.color}
                        handleCHange={handleSetSharePics}
                      />
                      <RespFont>שיתוף תמונות</RespFont>
                    </FlexRowBetween>
                  </div>
                  <div>
                    {user.eventType && (
                      <FlexRowBetween style={{ justifyContent: "flex-start" }}>
                        <ColorCheckbox
                          checked={inv === 1}
                          color={user.color}
                          handleCHange={handleSetInv}
                        />
                        <RespFont>פרטים</RespFont>
                      </FlexRowBetween>
                    )}
                    <FlexRowBetween style={{ justifyContent: "flex-start" }}>
                      <ColorCheckbox
                        checked={transportation}
                        color={user.color}
                        handleCHange={handleSetTransportation}
                      />
                      <RespFont>הסעה</RespFont>
                    </FlexRowBetween>
                    <FlexRowBetween style={{ justifyContent: "flex-start" }}>
                      <ColorCheckbox
                        checked={blessing}
                        color={user.color}
                        handleCHange={handleSetBlessing}
                      />
                      <RespFont>ברכות</RespFont>
                    </FlexRowBetween>
                  </div>
                </FlexRowBetween>

                <FlexRowBetween
                  style={{ width: "90%", margin: "0 auto", marginTop: "15px" }}
                >
                  <GenericDiv
                    background={user.color}
                    onClick={() => setView(!view)}
                  >
                    <img
                      src={gallery}
                      style={{ width: "20px", marginLeft: "10px" }}
                    />
                    <div style={{ fontSize: "15px" }}>תמונה</div>
                  </GenericDiv>
                  <GenericButton
                    background={user.color}
                    hoverBackground={"#5c3e94"}
                    hoverColor={"#fff"}
                    type="submit"
                    style={{ padding: "5px 40px", fontSize: "15px" }}
                  >
                    {demoMode ? "הדמיה" : "שמירה סופית"}
                  </GenericButton>
                </FlexRowBetween>
              </InputBox>
            </div>
          )}

          {editPic && (
            <GenericModal
              init={setEditPic}
              div={<EditPics init={setEditPic} />}
            />
          )}

          {giftsInfo && (
            <GenericModal
              init={setGiftsInfo}
              div={<GiftsInfo init={setGiftsInfo} />}
            />
          )}

          {showAccessPayment && (
            <GenericModal
              init={setShowAccessPayment}
              div={
                <PaymentAccessModal
                  color={user.color}
                  init={setShowAccessPayment}
                />
              }
            />
          )}

          {authLoading && <OpacityLoading />}

          {logout && (
            <GenericModal
              init={setLogout}
              div={<LogOutAll init={setLogout} />}
            />
          )}
        </CenteredForm>
      </FlexColumnBetween>
    </MainDiv>
  );
};

export default EditDetails;
