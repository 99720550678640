import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  GuestOptions,
  CenteredForm,
  RespFont,
  InputBox,
  FlexRowBetween,
  GenericDiv,
} from "../common/Style";
import "./style.css";
import ExitModal from "../common/modals/ExitModal";
import { getImageUrl } from "../common/modals/Toast";

const GiftsInfo = (props) => {
  const { register, handleSubmit } = useForm();
  const { user } = useSelector((state) => state.authReducer);
  let height = window.screen.availHeight;
  const [animate, setAnimate] = useState(
    "animate__animated animate__wobble animate__repeat-1 animate__slow animate__delay-2s"
  );
  useEffect(() => {
    setTimeout(() => {
      setAnimate("animate__animated animate__heartBeat");
    }, 6000);
  }, []);
  let widthS = window.innerWidth; // 1728

  const onSubmit = async (data) => props.setGuestsCloseness11(data.closeness);

  return (
    <CenteredForm
      onClick={(e) => {
        e.stopPropagation();
      }}
      onSubmit={handleSubmit(onSubmit)}
      style={{ maxHeight: `${height * 0.7}px` }}
      id="form1"
    >
      {/* <ExitModal init={props.init} color={user.color} /> */}
      <img src={`${getImageUrl() + "sys/giftsDes.png"}`} />
      <a
        style={{
          textDecoration: "none",
          color: "inherit",
          position: "absolute",
          bottom: "20px",
          left: "20px",
          width: "min-content",
        }}
        target="_blank"
        rel="noreferrer"
        href={`https://wa.me/972547439322/?text=אשמח לקבל שירות של מתנות באשראי לאירוע שלי, מספר נייד: ${user.phone}`}
      >
        <GenericDiv
          className={animate}
          style={{
            background: "linear-gradient(135deg, #6f669a, #a99ecf)",
            padding: widthS > 768 ? "10px 20px" : "7px 14px",
          }}
        >
          קדימה!
        </GenericDiv>
      </a>
    </CenteredForm>
  );
};

export default GiftsInfo;
