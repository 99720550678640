import React from "react";
import ICalendarLink from "react-icalendar-link";

const GCal = ({ mission, date, div, description = "" }) => {
    let date1 = date.split('T')[0]
    const event = {
        title: mission,
        description: description,
        startTime: date1 + `T06:30:00+00:00`,
        endTime: date1 + "T07:00:00+00:00",
    }
    return (
        <ICalendarLink id="todo" event={event}>
            {div}
        </ICalendarLink>
    )
}
export default GCal;