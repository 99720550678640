import React from 'react';
import { CenteredForm, FlexRowBetween, GenericButton, MyHr } from '../Style';

const YesNoModal = props => {
    return (<CenteredForm onClick={(e) => e.stopPropagation()}>
        <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "space-around" }}>
            <div style={{ width: "100%", marginTop: "30px" }}>
                <div style={{ textAlign: "center", fontSize: "1.2rem", marginBottom: "10px" }}>{props.title}</div>
                <MyHr />
            </div>
            <div style={{ width: "100%", marginTop: "30px" }}>
                <div style={{ textAlign: "center", marginBottom: "10px" }}>{props.body}</div>
                <MyHr />
            </div>
            <div style={{ width: "100%" }}>
                <FlexRowBetween style={{ width: "90%", margin: "20px auto" }}>
                    <GenericButton style={{ backgroundColor: props.color ? props.color : "#DEDEE0", padding: "9px 25px" }} onClick={() => { props.no(null); props.yes() }}>כן</GenericButton>
                    <GenericButton style={{ backgroundColor: "rgb(223, 223, 223)", color: "#3A3C3F", padding: "9px 25px" }} onClick={() => { props.no(null) }}>לא</GenericButton>
                </FlexRowBetween>
            </div>
        </div>
    </CenteredForm>)
}
export default YesNoModal;