import React, { useEffect, useState } from "react";
import wazeIcon from "../../../../assets/invitation/wazeIcon.svg";
import calendar from "../../../../assets/invitation/calendar.svg";
import gift from "../../../../assets/invitation/gift.svg";
import bus from "../../../../assets/invitation/bus3.svg";
import Cal from "../../../user/Cal";
import { addAlpha } from "../../modals/Toast";
const Links = (props) => {
  const [animate, setAnimate] = useState(
    "animate__animated animate__bounceInLeft animate__slow"
  );
  useEffect(() => {
    setTimeout(() => {
      setAnimate(
        "animate__animated animate__wobble animate__repeat-1 animate__slow"
      );
    }, 3000);
  }, []);
  return (
    <div
      className="sss"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        fontSize: "12px",
        width: "100%",
        marginTop: "28px",
      }}
    >
      <Cal
        data={props.data}
        div={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#3A3C3F",
              textDecoration: "none",
            }}
          >
            <img alt="calendar" src={calendar} style={{ width: "25px" }} />
            {props.data.isEnglish ? (
              <div>Calendar</div>
            ) : (
              <div>הוספה ליומן</div>
            )}
          </div>
        }
      />
      {props.data.waze !== "" && (
        <div>
          <a
            target={"_blank"}
            rel="noopener noreferrer"
            href={props.data.waze}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#3A3C3F",
              textDecoration: "none",
            }}
          >
            <img alt="wazeIcon" src={wazeIcon} style={{ width: "25px" }} />
            {props.data.isEnglish ? (
              <div>Navigation</div>
            ) : (
              <div>ניווט לאירוע</div>
            )}
          </a>
        </div>
      )}
      {props.data.paybox !== "" && props.data.paybox !== undefined && (
        <a
          target={"_blank"}
          rel="noopener noreferrer"
          href={props.data.paybox}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#3A3C3F",
            textDecoration: "none",
          }}
        >
          <img alt="gift" src={gift} style={{ width: "25px" }} />
          <div>מתנה באשראי</div>
        </a>
      )}
      {props.data.transportation && (
        <div
          className={animate}
          onClick={() => props.setTransportation(true)}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#3A3C3F",
            textDecoration: "none",
            cursor: "pointer",
            boxShadow: `0 1px 10px ${addAlpha(props.data.heartColor, 0.5)}`,
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <img
            alt="bus"
            src={bus}
            style={{ width: "29px", marginTop: "-4px" }}
          />
          <div>הרשמה להסעה</div>
        </div>
      )}
    </div>
  );
};

export default Links;
