import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import {
  CenteredForm,
  PlaceHolderImage,
  GenericButton,
  RespFont,
  InputBox,
  MainDiv,
} from "../Style";
import exitModalLogo from "../../../assets/common/cancel1.svg";
import sum from "../../../assets/client/amount1.svg";
import { GetToast } from "../modals/Toast";
import OpacityLoading from "../loader/OpacityLoading";
import { createLink } from "../../../store/reducers/paymentsReducer";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
const PaymentLInk = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { register, handleSubmit } = useForm();
  const { guestsLoading } = useSelector((state) => state.guestsReducer);
  const [fee, setFee] = useState(0);
  let params = queryString.parse(props.location.search);
  const onSubmit = async (data) => {
    let newWindow = window.open();
    dispatch(
      createLink({
        amount: parseFloat(data.amount),
        fee: parseFloat(fee),
        name: params.name,
        uid: params.uid,
      })
    ).then((res) => {
      if (res.payload.error) {
        GetToast(addToast, "error", res.payload.error, 10000);
        newWindow.close();
      } else {
        const link = `${res.payload}`;
        newWindow.location = link;
      }
    });
  };
  return (
    <MainDiv
      style={{
        justifyContent: "center",
        background: `radial-gradient(circle, #948ddf71 0%, #f2f2f2 100%)`,
      }}
    >
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          fontFamily: "logo",
          fontWeight: "700",
          position: "absolute",
          top: "50px",
        }}
      >
        IBS - מתנות לאירועים
      </RespFont>
      <RespFont pSize={"27px"} cSize={"29px"}>
        זמנית לא ניתן להשתמש בשירות
      </RespFont>
      <CenteredForm
        onSubmit={handleSubmit(onSubmit)}
        style={{ borderRadius: "10px", boxShadow: "none" }}
      >
        {/* <RespFont
          pSize={"20px"}
          cSize={"22px"}
          style={{
            textAlign: "center",
            color: "#3A3C3F",
            fontWeight: "bold",
            padding: `30px 0`,
            paddingBottom: `10px`,
          }}
        >
          מתנה באשראי
        </RespFont>
        <RespFont
          pSize={"17px"}
          cSize={"19px"}
          style={{
            textAlign: "center",
            color: "#3A3C3F",
            paddingBottom: `10px`,
          }}
        >
          {params.name}
        </RespFont> */}

        {/* <InputBox labelColor={"gray"} color={"#3A3C3F"}>
          <div className="relativeBox">
            <input
              onChange={(e) => {
                if (e.target.value >= 2000) setFee(e.target.value * 0.015);
                else setFee(e.target.value * 0.02);
              }}
              type="number"
              name="amount"
              min={0}
              max={100000}
              required={true}
              ref={register()}
            />
            <label>סכום המתנה (₪)</label>
          </div>
          <div className="relativeBox">
            <input
              value={fee}
              disabled={true}
              type="number"
              name="fee"
              min={0}
              max={1000}
              required={true}
              ref={register()}
            />
            <label style={{ top: "-22px", fontSize: "12px", color: "gray" }}>
              עמלה (₪)
            </label>
          </div>
          <GenericButton
            hoverBackground={"rgb(223, 223, 223)"}
            background={"#948DDF"}
            type="submit"
            style={{ width: "60%", margin: "30px auto", padding: "15px 0" }}
          >
            מעבר לתשלום
          </GenericButton>
        </InputBox> */}
        {guestsLoading && <OpacityLoading />}
      </CenteredForm>
    </MainDiv>
  );
};

export default PaymentLInk;
