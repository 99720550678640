import { createSlice } from "@reduxjs/toolkit";
import { post2, get2 } from "../api/http";
import ServerRoutes from "../api/routes";

const initialState = {
  payments: {},
  paymentLink: null,
  paymentsError: null,
  paymentsLoading: false,
};

const _setPaymentLink = (state, action) => {
  state.paymentLink = action.payload;
};

const _actionStart = (state) => {
  state.paymentsLoading = true;
  state.paymentsError = null;
};

const _actionSuccess = (state) => {
  state.paymentsLoading = false;
  state.paymentsError = null;
};

const _actionFailed = (state, action) => {
  state.paymentsLoading = false;
  state.paymentsError = action.payload.error;
};

const _setPayments = (state, action) => {
  state.payments = action.payload;
};

const _cleanPaymentsState = (state) => {
  state.payments = {};
  state.paymentsError = null;
  state.paymentsLoading = false;
};

const payments = createSlice({
  name: "payments",
  initialState,
  reducers: {
    cleanPaymentsState: _cleanPaymentsState,
    setPayments: _setPayments,
    setPaymentLink: _setPaymentLink,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    actionSuccess: _actionSuccess,
  },
});

const { reducer, actions } = payments;

const {
  actionStart,
  actionFailed,
  actionSuccess,
  setPaymentLink,
  setPayments,
  cleanPaymentsState,
} = actions;

export default reducer;

export const cleanAll = () => {
  return async (dispatch) => {
    return dispatch(cleanPaymentsState());
  };
};

export const getAll = () => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(ServerRoutes.Payments.getAll);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setPayments(data));
    }
  };
};

export const createLink = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Payments.createlink,
      body
    );
    if (status !== 200) {
      //   dispatch(actionFailed({ error }));
      if (status === 404) return dispatch(actionFailed({ error }));
      else
        return dispatch(
          setPaymentLink(
            `https://wa.me/972547439322/?text=יצירת קישור לתשלום עבור ${body.name} לא עובד`
          )
        );
    } else {
      dispatch(actionSuccess());
      return dispatch(setPaymentLink(data));
    }
  };
};

export const setRecord = (body) => {
  console.log(body);
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Payments.setRecord,
      body
    );
    if (status !== 200)
      return dispatch(
        actionFailed({
          error: `https://wa.me/972547439322/?text=שילמתי אך קיימת שגיאה, אשמח לבדיקה`,
        })
      );
    else return dispatch(actionSuccess(data));
  };
};

export const editBlessing = (id, body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Payments.editBlessing + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      return dispatch(actionSuccess());
    }
  };
};
