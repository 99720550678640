import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { TableLine } from "../common/Style";
import saveLogo from "../../assets/client/save1.svg";
import table1 from "../../assets/client/table2.svg";
import msg5 from "../../assets/client/msg5.svg";

import {
  editGuestById,
  setGuestsTable,
} from "../../store/reducers/tablesReducer";
import { chair } from "../../store/reducers/dataReducer";
import { GetToast } from "../common/modals/Toast";
import OpacityLoading from "../common/loader/OpacityLoading";

const TrTables = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const [checked, setChecked] = useState(props.checked);
  const [showEditTable, setShowEditTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState();

  const isNumber = (value) => (parseInt(value) >= 0 ? parseInt(value) : null);

  const sendTable = async (e = null) => {
    {
      e && e.stopPropagation();
    }
    setLoading(true);
    let msg;
    if (user.array[props.data.table - 1].side.split(" ")[0] === "או") {
      const searchRegExp = /[a-zA-Zא-ת]/gi;
      const tables = user.array[props.data.table - 1].side
        .substring(3)
        .replaceAll(searchRegExp, "");
      msg = `מספרי שולחנות הישיבה שלך לחתונה של ${user.brideFN} ו${user.groomFN} הם: ${props.data.table}, ${tables}`;
    } else
      msg = `מספר השולחן שלך לחתונה של ${user.brideFN} ו${user.groomFN} הוא: ${props.data.table}`;
    dispatch(
      chair({ title: props.title, arr: [{ msg, phone: props.data.phone }] })
    ).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      } else
        GetToast(
          addToast,
          "warning",
          "הסימון נשמר אך חלה שגיאה בשליחת ההודעה לאורח, יש לכתוב על פתק",
          8000
        );

      setLoading(false);
    });
  };
  const editGuestById1 = async (id, arrived) => {
    console.log(arrived);
    if (arrived !== "e") {
      dispatch(editGuestById(isNumber(arrived), id, props.data.table)).then(
        async (res) => {
          console.log(res);
          if (!res?.payload.error) {
            setTable();
            setShowEditTable(false);
            if (
              props.sendTableMsg &&
              props.data.phone !== "" &&
              props.data.phone !== null
            )
              await sendTable();
            else if (props.data.phone === "" || props.data.phone === null)
              GetToast(
                addToast,
                "warning",
                "סימון הגעה נשמר אך לא נשלחה הודעה לאורח מכיוון שאין לו מספר במערכת",
                8000
              );
            else
              GetToast(
                addToast,
                "warning",
                "סימון הגעה נשמר אך לא נשלחה הודעה לאורח מכיוון שלא הפעלת יכולת זו",
                8000
              );
          } else GetToast(addToast, "error", "הפעולה נכשלה...");
        }
      );
    } else alert("כמות חייב להיות מספר חיובי או שווה ל 0");
  };

  const setGuestsTable1 = async (table1) => {
    if (user.array[parseInt(table) - 1] !== undefined || parseInt(table) === 0)
      dispatch(
        setGuestsTable(
          { [props.data._id]: props.data },
          parseInt(table1) === 0 ? null : table1
        )
      ).then((data) => {
        console.log(data);
        if (!data.payload.error) {
          GetToast(addToast, "success", "הפעולה הצליחה");
          setTable();
          setShowEditTable(false);
        } else GetToast(addToast, "success", "הפעולה נכשלה");
      });
    else alert("שולחן לא תקין או לא קיים");
  };

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  useEffect(() => {
    setChecked(false);
  }, [props.multy]);

  return (
    <TableLine
      key={props.index}
      onClick={() => {
        if (props.multy) props.addOrDelete({ ...props.data });
        else setShowEditTable(!showEditTable);
      }}
      //  onClick={() => setShowEditTable(!showEditTable)}
      // background={props.checked ? addAlpha(user.color, 0.35) : (props.data.arrived ? ((props.data.arrived < props.data.accept) ?
      //     "#f0d4ae7c" : null) : null)}
      background={
        props.checked
          ? addAlpha(user.color, 0.35)
          : props.data.arrived
          ? props.data.arrived
          : null
      }
    >
      <div>
        {showEditTable ? (
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip>{"העברת שולחן"}</Tooltip>}
            >
              <img
                className="animate__animated animate__fadeIn"
                src={table1}
                alt="table1"
                style={{ cursor: "pointer", width: "22px", marginLeft: "7px" }}
                onClick={() => setGuestsTable1(table)}
              />
            </OverlayTrigger>
            <input
              type="number"
              min="1"
              max="100"
              onChange={(e) => setTable(e.target.value)}
              className="animate__animated animate__fadeIn"
              style={{ padding: "2px 3px" }}
            />
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip>{"סימון הגעה"}</Tooltip>}
            >
              <img
                className="animate__animated animate__fadeIn"
                src={saveLogo}
                alt="saveLogo"
                style={{ cursor: "pointer", width: "22px", marginRight: "7px" }}
                onClick={() => editGuestById1(props.data._id, table)}
              />
            </OverlayTrigger>
          </div>
        ) : (
          <div>{props.data.name}</div>
        )}
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {/* {(!showEditTable && props.sendTableMsg && props.data.phone !== "" && props.data.phone !== null)
                && <OverlayTrigger placement={'bottom'}
                    overlay={<Tooltip>{"שליחת מספר שולחן בהודעה"}</Tooltip>}>
                    <img src={msg5} style={{ width: "25px" }} onClick={sendTable} /></OverlayTrigger>} */}
        <div style={{ cursor: "pointer", marginRight: "20px" }}>
          {parseInt(props.data.arrived) >= 0
            ? parseInt(props.data.arrived) + "/" + props.data.accept
            : props.data.accept}
        </div>
      </div>
      {loading && <OpacityLoading />}
    </TableLine>
  );
};

export default TrTables;
