import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const LogoStyle = styled.div`
    border-radius: 15px;
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    user-select: none;
    font-size:18px;
    padding: 15px 13px;
    box-shadow: 0 2px 20px silver, 0 -2px 20px silver;
    font-family: logo;
    :hover{
        color: white ;
    }
`;

const Logo = (props) => {
    const { user } = useSelector(state => state.authReducer);
    return (
        <LogoStyle style={{ background: user !== null ? user.color : props.back ? props.back : "#3A3C3F" }}>B.S</LogoStyle>
    )
}
export default Logo;