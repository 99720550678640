import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  cleanAll,
  deleteCouple,
  getAll,
  getBetweenDates,
} from "../../../store/reducers/couplesReducer";
import { useDispatch, useSelector } from "react-redux";
import GenericModal from "../../common/modals/GenericModal";
import { GetToast } from "../../common/modals/Toast";
import YesNoModal from "../../common/modals/YesNoModal";
import { useToasts } from "react-toast-notifications";
import EditCouple from "./EditCouple";
import AddCouple from "./AddCouple";
import { CoupleRowStyle, FlexRowBetween } from "../../common/Style";
import GenericSvg from "../../common/coloredSvgs/GenericSvg";
import { svgs } from "../../common/coloredSvgs/svgs";
import palette from "../../../assets/client/palette.svg";
import SketchColorPalette from "../../common/sketchColor/SketchColorPalette";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import OpacityLoading from "../../common/loader/OpacityLoading";
import HallCalendar from "../Calendar";

const Couples = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState(null);
  const [deleteCouple, setDeleteCouple] = useState(false);
  const [editCouple, setEditCouple] = useState(false);
  const [addCouple, setAddCouple] = useState(false);
  const { user } = useSelector((state) => state.authReducer);
  const { couples, couplesLoading } = useSelector(
    (state) => state.couplesReducer
  );
  const getTodos = () => {
    dispatch(getAll());
  };
  const deleteCoupleFunc = async () => {
    dispatch(deleteCouple(deleteCouple)).then((res) => {
      if (res.payload.error)
        GetToast(addToast, "success", "הפעולה נכשלה, יש לפנות למנהל המערכת");
      else GetToast(addToast, "success", "הפעולה בוצעה");
    });
  };

  const handleSetDate = (e) => {
    let date1 = e;
    date1.setHours(19);
    setDate(date1);
  };

  const handleSearch = () => {
    setShowCalendar(null);
    // searchBetweenDates(date);
  };

  const searchBetweenDates = (date1) => {
    let date = date1;
    date.setHours(19);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    date.setDate(date.getDate() - 1);
    let start = date.toISOString();
    date.setDate(date.getDate() + 7);
    // dispatch(getBetweenDates({ start, end: date.toISOString() })).then(
    dispatch(getAll()).then((res) => {
      if (res.payload.error)
        GetToast(addToast, "success", "הפעולה נכשלה, יש לפנות למנהל המערכת");
    });
  };

  useEffect(() => {
    searchBetweenDates(new Date());
    return () => {
      dispatch(cleanAll());
    };
  }, []);

  let couplesArray = useMemo(() => {
    let arr = [];
    let dates = {};
    let date;

    let sortedArray = [];
    if (Object.keys(couples).length > 0) {
      for (const [key, value] of Object.entries(couples)) {
        sortedArray.push(value);
        date = new Date(value.weddingDate);
        date = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
        if (date in dates) dates[date] = dates[date] + " + " + value.fullname;
        else dates[date] = value.fullname;
      }
      sortedArray.sort(
        (a, b) => new Date(a["weddingDate"]) - new Date(b["weddingDate"])
      );
      for (let i = 0; i < sortedArray.length; i++) {
        let date = new Date();
        date.setHours(20);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        date = date.toISOString();
        let today = date === sortedArray[i].weddingDate;
        console.log(sortedArray[i].weddingDate);
        let yesterday = new Date(date);
        yesterday.setHours(19);
        let next7days = new Date(date);
        next7days.setDate(next7days.getDate() + 7);

        console.log("yesterday: " + yesterday.toISOString());
        console.log("next7days: " + next7days.toISOString());
        arr.push(
          <CoupleRowStyle
            color={addAlpha(user.color, 0.2)}
            today={today}
            key={i}
            onClick={() => setEditCouple(sortedArray[i])}
          >
            <div>{sortedArray[i].fullname}</div>
            <div>
              {sortedArray[i]?.weddingDate
                .replace(/T.*/, "")
                .split("-")
                .reverse()
                .join("/")}
            </div>
          </CoupleRowStyle>
        );
      }
    }
    return [arr, dates];
  }, [couples, flag]);

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100%",
        maxWidth: "480px",
        margin: "0 auto",
        background: `radial-gradient(circle, ${addAlpha(
          user.color,
          0.25
        )} 0%, #f2f2f2 100%)`,
        maxHeight: "100vh",
      }}
    >
      <FlexRowBetween style={{ width: "90%", margin: "20px auto" }}>
        <div
          style={{
            cursor: "pointer",
            background: "#f2f2f2",
            borderRadius: "50%",
            boxShadow: "0 2px 4px gray",
            padding: "13px 13px 12px 13px",
            width: "min-content",
          }}
          onClick={() => setShowCalendar(true)}
        >
          <div style={{ filter: "brightness(95%)" }}>
            <GenericSvg
              fill={user.color}
              size={"28px"}
              svg={svgs.calendar}
              viewBox={"0 0 64 64"}
            />
          </div>
        </div>
        <div
          onClick={() => setDisplayColorPicker(!displayColorPicker)}
          style={{
            cursor: "pointer",
            background: "#f2f2f2",
            borderRadius: "50%",
            boxShadow: "0 2px 4px gray",
            padding: "13px 10px 10px 14px",
            width: "min-content",
          }}
        >
          <SketchColorPalette
            change={"color"}
            display={displayColorPicker}
            onClose={setDisplayColorPicker}
            setFlag={setFlag}
            flag={flag}
            top={"150%"}
            div={
              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip>{"צבע המערכת"}</Tooltip>}
              >
                <img src={palette} alt="palette" style={{ width: "30px" }} />
              </OverlayTrigger>
            }
          />
        </div>
        <div
          style={{
            cursor: "pointer",
            background: "#f2f2f2",
            borderRadius: "50%",
            boxShadow: "0 2px 4px gray",
            padding: "13px 14px",
            width: "min-content",
          }}
          onClick={() => setAddCouple(true)}
        >
          <GenericSvg
            fill={user.color}
            size={"25px"}
            svg={svgs.plus}
            viewBox={"0 0 45.402 45.402"}
          />
        </div>
      </FlexRowBetween>
      <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
        {couplesArray[0]}
      </div>
      {addCouple && (
        <GenericModal
          init={setAddCouple}
          div={<AddCouple init={setAddCouple} />}
        />
      )}
      {editCouple && (
        <GenericModal
          init={setEditCouple}
          div={
            <EditCouple
              setDeleteCouple={setDeleteCouple}
              data={editCouple}
              init={setEditCouple}
            />
          }
        />
      )}
      {deleteCouple && (
        <GenericModal
          init={setDeleteCouple}
          div={
            <YesNoModal
              color={useReducer.color}
              title={"מחיקת משימה"}
              body={"האם את/ה בטוח/ה?"}
              no={setDeleteCouple}
              yes={deleteCoupleFunc}
            />
          }
        />
      )}
      {showCalendar && (
        <GenericModal
          init={setShowCalendar}
          div={
            <HallCalendar
              background={user.color}
              setDate={handleSetDate}
              date={date}
              color={user.color}
              init={handleSearch}
              dates={couplesArray[1]}
            />
          }
        />
      )}
      {couplesLoading && <OpacityLoading />}
    </div>
  );
};

export default Couples;
