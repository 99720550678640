import authReducer from "./authReducer";
import userReducer from "./userReducer";
import guestsReducer from "./guestsReducer";
import tablesReducer from "./tablesReducer";
import messagesReducer from "./messagesReducer";
import invitationReducer from "./invitationReducer";
import photosReducer from "./photosReducer";
import dataReducer from "./dataReducer";
import todosReducer from "./todosReducer";
import smsReducer from "./smsReducer";
import couplesReducer from "./couplesReducer";
import expensesReducer from "./expensesReducer";
import cartReducer from "./cartReducer";
import potentialsReducer from "./potentialsReducer";
import paymentsReducer from "./paymentsReducer";

export const reducers = {
  authReducer,
  userReducer,
  paymentsReducer,
  potentialsReducer,
  cartReducer,
  expensesReducer,
  couplesReducer,
  smsReducer,
  todosReducer,
  dataReducer,
  photosReducer,
  guestsReducer,
  messagesReducer,
  tablesReducer,
  invitationReducer,
};
