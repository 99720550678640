import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import { editExpense } from '../../../store/reducers/expensesReducer';
import { CenteredForm, PlaceHolderImage, GenericButton, InputBox, Footer } from '../../common/Style'
import delete1 from '../../../assets/client/delete1.svg';
import exitModalLogo from '../../../assets/common/cancel1.svg';
import { GetToast } from '../../common/modals/Toast';
import { RTextArea } from '../../common/Style';
import ExitModal from '../../common/modals/ExitModal';

const EditExpense = (props) => {
    const dispatch = useDispatch();
    const { expensesError } = useSelector(state => state.expensesReducer);
    const { user } = useSelector(state => state.authReducer);
    const { addToast } = useToasts();
    const { register, handleSubmit } = useForm();


    const onSubmit = async data => {
        dispatch(editExpense(props.data._id, { name: data.name, amount: parseInt(data.amount), price: parseInt(data.price) })).then(() => {
            if (expensesError === null)
                GetToast(addToast, "success", "הפעולה בוצעה בהצלחה")
        })
        props.init(false)
    }
    return (<CenteredForm onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit(onSubmit)}>
        <ExitModal init={props.init} color={user.color} />
        <div style={{ textAlign: "center", fontWeight: "bold", padding: "20px", fontSize: "22px" }}>עריכת הוצאה</div>
        <InputBox labelColor={"gray"} color={"#3A3C3F"} borderBottomColor={user.color}>
            <div className='relativeBox'>
                <input defaultValue={props.data.name} type="text" name="name" minLength={1} maxLength={50} required={true} ref={register()} />
                <label>שם ההוצאה</label>
            </div>
            <div className='relativeBox'>
                <input defaultValue={props.data.amount} type="number" name="amount" min={1} max={10000} required={true} ref={register()} />
                <label>כמות</label>
            </div>
            <div className='relativeBox'>
                <input defaultValue={props.data.price} type="number" name="price" min={0} max={1000000} required={true} ref={register()} />
                <label>עלות ליחידה</label>
            </div>

            <GenericButton background={user.color} type="submit" style={{ width: "50%", margin: "30px auto", padding: "15px" }}>אישור</GenericButton>
        </InputBox>

        <Footer background={user.color}>
            <img src={delete1} alt="delete" onClick={() => { props.init(false); props.setDeleteExpense(props.data._id) }} />
        </Footer>
    </CenteredForm >)
}

export default EditExpense;