import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ClientRoutes from "./Routes";
import Print from "../components/user/Print";
// import Print from '../components/user/brain/Main';
import Guests from "../components/user/Guests";
import Expenses from "../components/user/expenses/Expenses";
import Payments from "../components/user/payments/Payments";
import Todos from "../components/user/todos/Todos";
import Tables from "../components/user/Tables";
import EditDetails from "../components/user/EditDetails";
import Attractions from "../components/user/attractions/Attractions";
import Instruction from "../components/user/instructions/Instruction";
import GuestInvitation2 from "../components/common/invitation/GuestInvitation2";
import SelfInvitationNavigator from "../components/common/invitation/SelfInvitationNavigator";
import SuccessPage from "../components/common/payments/SuccessPage";
import PaymentLInk from "../components/common/payments/PaymentLInk";
import Tools from "../components/user/tools/Tools";

const UserSwitch = () => {
  return (
    <Switch>
      <Route
        path={ClientRoutes.User.print}
        component={(props) => <Print {...props} />}
      />
      <Route
        path={ClientRoutes.User.attractions}
        component={(props) => <Attractions {...props} />}
      />
      <Route
        path={ClientRoutes.User.tools}
        component={(props) => <Tools {...props} />}
      />
      <Route
        path={ClientRoutes.User.guests}
        component={(props) => <Guests {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.paymentSuccess}
        component={(props) => <SuccessPage {...props} />}
      />
      <Route
        path={ClientRoutes.User.expenses}
        component={(props) => <Expenses {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.p}
        component={(props) => <PaymentLInk {...props} />}
      />
      <Route
        path={ClientRoutes.User.todos}
        component={(props) => <Todos {...props} />}
      />
      <Route
        path={ClientRoutes.User.payments}
        component={(props) => <Payments {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.n}
        component={(props) => <GuestInvitation2 {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.s}
        component={(props) => <SelfInvitationNavigator {...props} />}
      />

      <Route
        path={ClientRoutes.User.instructions}
        component={(props) => <Instruction {...props} />}
      />
      <Route
        path={ClientRoutes.User.tables}
        component={(props) => <Tables {...props} />}
      />
      <Route
        path={ClientRoutes.User.editDetails}
        component={(props) => <EditDetails {...props} />}
      />
      <Redirect from="/" to={ClientRoutes.User.guests} />
    </Switch>
  );
};

export default UserSwitch;
