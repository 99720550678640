import React from 'react';
import { CenteredForm, RespFont } from '../Style';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { GetToast } from './Toast';
import { useToasts } from 'react-toast-notifications';
import copy from '../../../assets/client/copy.svg';
import share from '../../../assets/client/share.svg';
import {
    WhatsappShareButton,
} from "react-share";

import './style.css';
const SelfInvitationLink = props => {
    const { addToast } = useToasts();

    return (<CenteredForm onClick={(e) => e.stopPropagation()}>
        <RespFont pSize={"20px"} cSize={"22px"} style={{ textAlign: "center", color: "#3A3C3F", fontWeight: "bold", padding: `30px 0` }}>קישור להפצה עצמית</RespFont>

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", width: "100%", padding: "40px 10px", paddingTop: "10px" }}>
            <CopyToClipboard text={encodeURI(props.link)}
                onCopy={() => GetToast(addToast, "success", "הקישור הועתק בהצלחה!")}>
                <div style={{ padding: "10px 20px", boxShadow: "0 2px 4px silver", borderRadius: "5px", cursor: "pointer" }}>
                    <div>העתקה</div>
                    <img alt={"copy"} src={copy} style={{ width: "40px", marginTop: "10px" }} />
                </div>
            </CopyToClipboard>
            <div className="Demo__some-network">
                <WhatsappShareButton
                    url={encodeURI(props.link)}
                    // title={"title"}
                    // separator=":: "
                    className="Demo__some-network__share-button"
                >
                    <div style={{ padding: "10px 20px", boxShadow: "0 2px 4px silver", borderRadius: "5px", cursor: "pointer" }}>
                        <div>שיתוף</div>
                        <img alt={"share"} src={share} style={{ width: "40px", marginTop: "10px" }} />
                    </div>
                </WhatsappShareButton>
            </div>
        </div>
    </CenteredForm>)
}
export default SelfInvitationLink;