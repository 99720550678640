const ClientRoutes = {
  Admin: {
    main: "/admin/main",
    potentials: "/admin/potentials",
    guests: "/admin/guests",
    todos: "/admin/todos",
    aviv: "/admin/aviv",
    sms: "/admin/sms",
  },
  Hall: {
    couples: "/hall/couples",
  },
  Daniel: {
    daniel: "/ttt",
  },
  User: {
    print: "/print",
    payments: "/payments",
    todos: "/todos",
    expenses: "/expenses",
    guests: "/guests",
    editDetails: "/editDetails",
    attractions: "/attractions",
    tools: "/tools",
    addC: "/addC",
    instructions: "/instructions",
    tables: "/tables",
  },
  UnAuth: {
    chooseProvider: "/main",
    login: "/login",
    register: "/register",
    paymentSuccess: "/paymentsuccess",
    p: "/p", // payment
    n: "/n", // regular invitation
    s: "/s",
  },
  Producers: {
    itay: "/itay",
    event4u: "/event4u",
    ohad: "/ohad",
    aviv: "/aviv",
  },
};

export default ClientRoutes;
