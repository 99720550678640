import React from 'react';
const BottomInv = props => {
    return (
        <svg style={{ width: "42px", height: "40px", cursor: "pointer" }}>
            <svg width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.1134 21.6907C20.4536 21.5876 21.5876 20.5567 21.6907 19.1134C21.8969 17.0515 22 14.1649 22 12C22 9.83505 21.8969 6.94845 21.6907 4.8866C21.6907 4.16495 21.3814 3.54639 20.866 3.13402C20.3505 2.72165 19.8351 2.41237 19.1134 2.30928C17.0515 2.10309 14.1649 2 12 2C9.83505 2 6.94845 2.20618 4.8866 2.30928C3.54639 2.41237 2.41237 3.4433 2.30928 4.8866C2.10309 6.94845 2 9.83505 2 12C2 14.1649 2.20619 17.0515 2.30928 19.1134C2.41237 20.4536 3.4433 21.5876 4.8866 21.6907C6.94845 21.8969 9.83505 22 12 22C14.1649 22 17.0515 21.8969 19.1134 21.6907ZM12.0347 16.9485L7.36083 13.2371C7.15464 13.134 7.05155 12.9278 7.05155 12.6186C7.05155 12.4124 7.15464 12.3093 7.25773 12.1031C7.56701 11.7938 7.97938 11.6907 8.28866 12L11.1753 14.4742L11.1753 7.87629C11.1753 7.46392 11.4845 7.05155 12 7.05155C12.5155 7.05155 12.8247 7.36082 12.8247 7.87629L12.8247 14.3711L15.7113 11.8969C16.0206 11.5876 16.5361 11.6907 16.7423 12C17.0515 12.3093 16.9485 12.8247 16.6392 13.0309L12.0347 16.9485Z" fill={props.fill} />
            </svg>
        </svg>
    )
}


export default BottomInv