import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CenteredForm, GenericButton, PlaceHolderImage } from "../common/Style";
import exitModalLogo from "../../assets/common/cancel1.svg";

import { uploadPhoto } from "../../store/reducers/photosReducer";
import { editUserPic } from "../../store/reducers/authReducer";
import { GetToast } from "../common/modals/Toast";
import "./style.css";
import OpacityLoading from "../common/loader/OpacityLoading";
import imageCompression from "browser-image-compression";
import ExitModal from "../common/modals/ExitModal";

const EditPics = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.authReducer);

  async function postImage(image) {
    const rnd = Math.floor(Math.random() * 899999) + 100000;
    image = new File([image], `pic${rnd}`, {
      type: image.type,
    });
    const formData = new FormData();
    formData.append("image", image);
    setLoading(true);
    dispatch(uploadPhoto(formData)).then((data) => {
      setFile(null);
      if (image.name === data)
        dispatch(editUserPic({ pic: data })).then((data) => {
          if (!data.payload.error) {
            GetToast(addToast, "success", "הפעולה הצליחה!");
            props.init(null);
          }
          setLoading(false);
        });
      else
        GetToast(addToast, "error", "הפעולה כשלה, נסה/י שנית או פנה/י לתמיכה");
    });
  }

  const submit = async (event) => {
    event.preventDefault();
    await postImage(file);
  };

  const fileSelected = async (event) => {
    let imageFile = event.target.files[0];
    if (imageFile.size > 1500000) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        setLoading(true);
        const compressedFile = await imageCompression(imageFile, options);
        setLoading(false);
        imageFile = new File([compressedFile], "image.jpeg", {
          type: compressedFile.type,
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    setFile(imageFile);
  };

  return (
    <CenteredForm onClick={(e) => e.stopPropagation()}>
      <ExitModal init={props.init} color={user.color} />
      <div style={{ marginTop: "5vh", fontSize: "1.1rem" }}>העלאת תמונה</div>
      <label
        htmlFor="file-upload"
        className="custom-file-upload"
        style={{ marginBottom: "5vh" }}
      >
        בחירת תמונה
      </label>
      <input
        id="file-upload"
        type="file"
        onChange={fileSelected}
        accept="image/*"
      />
      {file !== null && (
        <GenericButton
          hoverBackground={"rgb(223, 223, 223)"}
          background={user.color}
          style={{ borderRadius: "0px" }}
          onClick={(e) => submit(e)}
        >
          שמירה
        </GenericButton>
      )}
      {loading && <OpacityLoading />}
    </CenteredForm>
  );
};

export default EditPics;
