import React from "react";
import { ToastMsg } from "../Style";
import vi from "./vi.svg";
const CROP_AREA_ASPECT = 4.2 / 3;
export const GetToast = (addToast, type, msg, duration = 4000) => {
  addToast(<ToastMsg>{typeof msg === "object" ? msg.error : msg}</ToastMsg>, {
    appearance: type,
    autoDismiss: true,
    autoDismissTimeout: duration,
  });
};

export const copyToClipBoard = async (copyMe, addToast) => {
  try {
    await navigator.clipboard.writeText(copyMe);
    GetToast(addToast, "info", "הקישור הועתק בהצלחה!");
  } catch (err) {
    GetToast(addToast, "error", "העתקה נכשלה, יש לפנות למנהל המערכת");
  }
};

// check input validation
export const CFV = (key, msg, errors) => (
  <p style={{ color: "red" }}>
    {errors && errors[key] ? (
      msg
    ) : Object.keys(errors).length > 0 ? (
      <img src={vi} style={{ width: "20px" }} />
    ) : (
      <div style={{ height: "20px" }} />
    )}
  </p>
);

// export const GenericLogo = ({src}) => <img style={{ width:"300px" }} src={src} />

export const Output = ({ croppedArea, pic, cropAreaAspect }) => {
  console.log("cropAreaAspect: " + cropAreaAspect);
  const scale = 100 / croppedArea.width;
  const transform = {
    x: `${-croppedArea.x * scale}%`,
    y: `${-croppedArea.y * scale}%`,
    scale,
    width: "calc(100% + 0.5px)",
    height: "auto",
  };

  const imageStyle = {
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height,
  };

  return (
    <div
      className="output"
      style={{ paddingBottom: `${100 / CROP_AREA_ASPECT}%` }}
    >
      <img src={pic} alt="pic" style={imageStyle} />
    </div>
  );
};

export const addAlpha = (color, opacity) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const dateFormat = (date) =>
  date.replace(/T.*/, "").split("-").reverse().join("/");
export const getImageUrl = () =>
  "https://secret-mqr-upload.s3.eu-central-1.amazonaws.com/wedding/";
export const MenuCss = (color) => ({
  bmBurgerBars: {
    background: color,
  },
  bmMenu: {
    background: color,
    paddingTop: "50px",
    width: "100%",
    padding: "50px 30px",
    fontSize: "16px",
  },
  bmItem: {
    padding: "2px 0",
    marginTop: "10px",
    borderBottom: "1px solid #f2f2f2",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

// export const parseIsoDate = date => date.toISOString().replace(/T.*/, '').split('-').reverse().join('/');
// export const parseDate = date => date.replace(/T.*/, '').split('-').reverse().join('/');
