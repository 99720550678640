import React from 'react';
import { Sticky2 } from './contactUsStyle';
import whatsapp from '../../../assets/common/whatsapp.svg';
import phone from '../../../assets/common/phone.svg';
import email from '../../../assets/common/email.svg';


const ContactUs2 = (props) => {
    return (
        <Sticky2 background={props.background}
            className={props?.animate}
        >
            <a target="_blank" href={`https://api.whatsapp.com/send?phone=972${props.phone}`} rel="noopener noreferrer"><img src={whatsapp} alt="whatsapp" /></a>
            <a href={`tel:${props.phone}`}><img src={phone} alt="phone" style={{ width: "25px" }} /></a>
            {props.facebook && props.facebook}
            <a target="_blank" href={`mailto:be.simple.service@gmail.com`} rel="noopener noreferrer"><img src={email} alt="email" /></a>
        </Sticky2>
    )
}
export default ContactUs2;
