import styled from "styled-components";

const media = {
  inv: "@media only screen and (max-width:500px)",
  phone: "@media only screen and (max-width:480px)",
  phoneAndTablet: "@media only screen and (max-width:768px)",
  tablet: "@media only screen and (min-width:481px) and (max-width:768px)",
  bigTablet: "@media only screen and (min-width:769px) and (max-width:1024px)",
  comp: "@media only screen and (min-width:1025px)",
};

const constants = {
  backgroundColor: "#f1ae6e",
  fontColor: "#3A3C3F",
};

export const RespLogo = styled.img`
  width: 100px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  ${media.phoneAndTablet} {
    width: 50px;
  }
`;
export const Option = styled.div`
  box-shadow: 0px 2px 4px white;
  border-radius: 15px;
  text-align: center;
  padding: 20px 10px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  img {
    width: 250px;
    ${media.phoneAndTablet} {
      width: 13vh;
    }
  }
  transition: all 0.2s;
  &:hover {
    transition: all 0.2s;
    transform: scale(1.1, 1.1);
  }
`;

export const AddGuestsOption = styled(Option)`
  padding: 20px 30px;
  img {
    margin-top: 15px;
    width: ${(props) => (props.cSize ? props.cSize : "150px")} !important;
    ${media.phoneAndTablet} {
      width: ${(props) => (props.pSize ? props.pSize : "50px")} !important;
    }
  }
`;

export const ReportsOption = styled(Option)`
  padding: 20px 30px;
  box-shadow: ${(props) =>
    props.chosen
      ? "10px 20px 60px white, -10px -20px 60px white"
      : "0px 2px 4px white"};
  ${media.phoneAndTablet} {
    padding: 10px 15px !important;
  }
  img {
    margin-top: 15px;
    width: 100px;
    ${media.phoneAndTablet} {
      width: 50px;
    }
  }
`;
