import React from "react";
import {
  GenericButton,
  RespFont,
  Responsive,
  HomeInput,
  FlexRowBetween,
} from "../../common/Style";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { GetToast } from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import { addPotential } from "../../../store/reducers/potentialsReducer";
import { CenteredFormPage1, MainRegister } from "../Style";
import l1 from "../../../assets/logo/l3.png";
import { LogoImg } from "./Style";

const Page1 = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { potentialsLoading } = useSelector((state) => state.potentialsReducer);
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    const body = {
      phone: data.phone,
      name: data.name,
    };
    dispatch(addPotential(body)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      } else GetToast(addToast, "error", res.payload.error);
    });
  };
  return (
    <div
      id={"home"}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        background:
          "linear-gradient(to bottom, #0f0c29, #302b63, #24243e, transparent)",
      }}
    >
      <LogoImg src={l1} />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Responsive padding={true} padC={"0 16%"} padP={"0 2%"}>
          <RespFont
            style={{
              color: "white",
              textShadow: "2px 2px rgba(0,0,0,0.4)",
              fontWeight: "700",
              fontFamily: "openSansMedium",
              letterSpacing: "2px",
              wordSpacing: "10px",
              textAlign: "center",
            }}
            pSize={"20px"}
            cSize={"35px"}
          >
            המערכת שתלווה אותך בתהליך מסודר, נוח ופשוט!
          </RespFont>
        </Responsive>
        <Responsive
          id={"contactUs"}
          margin={true}
          marC={"0 20%"}
          marP={"0 5%"}
          width={true}
          widC={"60%"}
          widP={"90%"}
          padding={true}
          padC={"20px 0"}
          padP={"10px 0"}
          fontSize={true}
          fontSizeC={"20px"}
          fontSizeP={"16px"}
          style={{
            color: "white",
            borderTop: "2px solid white",
            borderBottom: "3px solid white",
            fontFamily: "openSansRegular",
            letterSpacing: "1px",
            textAlign: "center",
            textShadow: "2px 2px rgba(0,0,0,0.2)",
            marginTop: "30px",
            background:
              "linear-gradient(to right,transparent,rgba(255,255,255,0.1),rgba(255,255,255,0.1),rgba(255,255,255,0.1),rgba(255,255,255,0.1),rgba(255,255,255,0.1),rgba(255,255,255,0.1),rgba(255,255,255,0.1),rgba(255,255,255,0.1),rgba(255,255,255,0.1),rgba(255,255,255,0.1),rgba(255,255,255,0.1),transparent)",
          }}
        >
          מערכת רספונסיבית, נוחה לתפעול, חוסכת בזמנים, כיפית עד כדי כך שתמליצו
          עליה לכולם!
        </Responsive>
        {/* <CenteredFormPage1
          onSubmit={handleSubmit(onSubmit)}
          style={{ margin: "50px auto" }}
        >
          <MainRegister>
            <FlexRowBetween style={{ width: "90%", margin: "12px auto" }}>
              <HomeInput
                fontFamily={"openSansRegular"}
                color={"silver"}
                required={true}
                minLength={2}
                maxLength={20}
                ref={register()}
                placeholder="שם מלא"
                name="name"
                style={{ marginRight: "5.5%" }}
              />
              <HomeInput
                fontFamily={"openSansRegular"}
                color={"silver"}
                required={true}
                pattern="[0]{1}[5]{1}[0-9]{8}"
                ref={register()}
                placeholder="מס׳ פלאפון"
                name="phone"
                style={{ marginLeft: "7.3%" }}
              />
            </FlexRowBetween>
            <div>
              <GenericButton
                hoverBackground={"#5c3e94"}
                style={{
                  padding: "10px",
                  fontWeight: "700",
                  background: "linear-gradient(135deg, #302b63, #302b6382)",
                }}
                type="submit"
              >
                השארת פרטים
              </GenericButton>
            </div>
          </MainRegister>
        </CenteredFormPage1> */}
      </div>
      {potentialsLoading && <OpacityLoading />}
    </div>
  );
};

export default Page1;
