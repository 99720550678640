import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import Switch from "react-switch";

import {
  GuestOptions,
  CenteredForm,
  PlaceHolderImage,
  GenericButton,
  RespFont,
  InputBox,
  RTextArea,
  FlexRowBetween,
} from "../../common/Style";
import callLogo from "../../../assets/client/call1.svg";
import whatsappLogo from "../../../assets/client/whatsapp1.svg";
import exitModalLogo from "../../../assets/common/cancel1.svg";
import { editUserById } from "../../../store/reducers/userReducer";
import { GetToast } from "../../common/modals/Toast";
import delete1 from "../../../assets/client/delete1.svg";

const EditUser = (props) => {
  let height = window.screen.availHeight;
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { register, handleSubmit } = useForm();
  const [isPaid, setIsPaid] = useState(props.data.isPaid);
  const [isDanielReserved, setIsDanielReserved] = useState(
    props.data.isDanielReserved
  );

  // addBasicTodos

  const onSubmit = async (data) => {
    dispatch(
      editUserById(
        {
          paybox: data.paybox,
          vPassword: data.vPassword,
          waze: data.waze,
          itayDescription: data.itayDescription,
          itayPrice: data.itayPrice,
          records: parseInt(data.records),
          startRecords: parseInt(data.startRecords),
          deal: parseFloat(data.deal),
          danielAmount: parseInt(data.danielAmount),
          isPaid,
          isDanielReserved,
        },
        props.data._id
      )
    ).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
        props.init();
      }
    });
  };

  return (
    <CenteredForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      style={{ maxHeight: `${height * 0.8}px` }}
    >
      <PlaceHolderImage
        src={exitModalLogo}
        alt="exitModalLogo"
        style={{
          top: "8px",
          left: "8px",
          transform: "translate(0,0)",
          width: "32px",
          cursor: "pointer",
        }}
        onClick={() => props.init(null)}
      />
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          textAlign: "center",
          color: "#3A3C3F",
          fontWeight: "bold",
          padding: `30px 0`,
          paddingBottom: `10px`,
        }}
      >
        עריכת משתמש
      </RespFont>
      <InputBox
        labelColor={"gray"}
        color={"#3A3C3F"}
        style={{ paddingBottom: "30px" }}
      >
        <div className="relativeBox">
          <input
            defaultValue={props.data?.paybox}
            type="text"
            placeholder="לא חובה"
            name="paybox"
            minLength={2}
            maxLength={50}
            ref={register()}
          />
          <label>פייבוקס</label>
        </div>
        <div className="relativeBox">
          <input
            defaultValue={props.data?.waze}
            type="text"
            placeholder="לא חובה"
            name="waze"
            minLength={2}
            maxLength={50}
            ref={register()}
          />
          <label>ווייז</label>
        </div>
        <div className="relativeBox">
          <input
            defaultValue={props.data?.vPassword}
            type="text"
            placeholder="לא חובה"
            name="vPassword"
            minLength={2}
            maxLength={20}
            ref={register()}
          />
          <label>סיס</label>
        </div>
        <div
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px auto",
          }}
        >
          <div style={{ position: "relative", width: "40%" }}>
            <input
              defaultValue={props.data?.itayPrice}
              type="text"
              placeholder="לא חובה"
              name="itayPrice"
              minLength={0}
              maxLength={50}
              ref={register()}
            />
            <label>מחיר</label>
          </div>
          <div style={{ position: "relative", width: "20%" }}>
            <input
              defaultValue={props.data?.records}
              type="number"
              min={0}
              max={3000}
              name="records"
              ref={register()}
            />
            <label>רשומות</label>
          </div>
          <div style={{ position: "relative", width: "20%" }}>
            <input
              defaultValue={props.data?.deal}
              type="number"
              min={0}
              max={2}
              step={0.05}
              name="deal"
              ref={register()}
            />
            <label>עלות</label>
          </div>
        </div>
        <FlexRowBetween style={{ width: "80%", margin: "10px auto" }}>
          <div style={{}}>
            <div style={{ fontSize: "14px" }}>שולם?</div>
            <Switch
              width={34}
              height={14}
              onColor={"#ffc48c"}
              offColor={"#c9c6c6"}
              onHandleColor={"#fda14b"}
              offHandleColor={"#929292"}
              checkedIcon={false}
              uncheckedIcon={false}
              checked={isPaid}
              onChange={() => setIsPaid(!isPaid)}
              handleDiameter={20}
            />
          </div>
          <div style={{ position: "relative", width: "20%" }}>
            <input
              defaultValue={props.data.danielAmount}
              type="number"
              min={0}
              max={10}
              name="danielAmount"
              ref={register()}
            />
            <label>דיילות</label>
          </div>

          <div style={{}}>
            <div style={{ fontSize: "14px" }}>שוריין?</div>
            <Switch
              width={34}
              height={14}
              onColor={"#ffc48c"}
              offColor={"#c9c6c6"}
              onHandleColor={"#fda14b"}
              offHandleColor={"#929292"}
              checkedIcon={false}
              uncheckedIcon={false}
              checked={isDanielReserved}
              onChange={() => setIsDanielReserved(!isDanielReserved)}
              handleDiameter={20}
            />
          </div>
        </FlexRowBetween>

        <div
          style={{ position: "relative", width: "80%", margin: "20px auto" }}
        >
          <RTextArea
            defaultValue={props.data?.itayDescription}
            placeholder="הערות"
            name="itayDescription"
            type="text"
            rows="3"
            minLength={0}
            maxLength={500}
            ref={register()}
          />
        </div>

        <GenericButton
          hoverBackground={"rgb(223, 223, 223)"}
          type="submit"
          style={{ width: "60%", margin: "20px auto" }}
        >
          אישור
        </GenericButton>
      </InputBox>
      <GuestOptions
        width={`25px`}
        padding={`10px 0`}
        style={{ backgroundColor: "#f1ae6e" }}
      >
        <a
          target="_blank"
          rel="noreferrer"
          href={
            "https://api.whatsapp.com/send?phone=972" +
            props.data.phone +
            "&text="
          }
        >
          <img alt="whatsapp" src={whatsappLogo} />
        </a>
        <a href={"tel:" + props.data.phone}>
          <img alt="call" src={callLogo} />
        </a>
        <img
          alt="delete1"
          src={delete1}
          style={{ width: "25px" }}
          onClick={() => props.setDeleteUser(props.data)}
        />
      </GuestOptions>
    </CenteredForm>
  );
};

export default EditUser;
