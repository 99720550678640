import React, { useEffect, useState } from "react";
import {
  FlexRowBetween,
  GenericButton,
  GenericDiv,
  HomeInput,
} from "../../Style";
import { useSelector } from "react-redux";
import { addAlpha } from "../../modals/Toast";
import minus from "../../../../assets/invitation/minus2.svg";
import plus from "../../../../assets/invitation/plus2.svg";
import minusB from "../../../../assets/invitation/minusB.svg";
import plusB from "../../../../assets/invitation/plusB.svg";

const GenericArivalConfirmation = ({
  setCount,
  count,
  onSubmit,
  done,
  phone,
  setPhone,
  name,
  setName,
  self = false,
}) => {
  const { user } = useSelector((state) => state.authReducer);

  const plusButtonStyleInv3 = {
    boxShadow: `1px 1px 3px ${addAlpha(user.heartColor, 0.3)}`,
    color: "#3A3C3F",
    background: "rgba(255,255,255,0.1)",
  };
  const countStyleInv3 = {
    boxShadow: `1px 2px 4px ${addAlpha(user.heartColor, 0.3)}`,
    color: "#3A3C3F",
    fontSize: "15px",
    background: addAlpha(user.heartColor, 0.15),
  };
  const minusButtonStyleInv3 = {
    boxShadow: `-1px 1px 3px ${addAlpha("#3A3C3F", 0.2)}`,
    color: "#3A3C3F",
    fontSize: "15px",
    background: "rgba(255,255,255,0.1)",
  };
  const acceptButtonStyleInv3 = {
    borderRadius: "2px",
    boxShadow: `0 1px 3px ${addAlpha(user.heartColor, 0.6)}`,
    color: user.heartColor,
    background: addAlpha(user.heartColor, 0.1),
  };
  const negativeButtonStyleInv3 = {
    borderRadius: "2px",
    color: "#3A3C3F",
    boxShadow: `0 1px 3px ${addAlpha("#3A3C3F", 0.6)}`,
    background: addAlpha("#3A3C3F", 0.1),
  };

  const plusButtonStyle = user.invK === 3 ? plusButtonStyleInv3 : {};
  const countStyle = user.invK === 3 ? countStyleInv3 : {};
  const acceptButtonStyle = user.invK === 3 ? acceptButtonStyleInv3 : {};
  const negativeButtonStyle = user.invK === 3 ? negativeButtonStyleInv3 : {};
  const minusButtonStyle = user.invK === 3 ? minusButtonStyleInv3 : {};
  const [animate, setAnimate] = useState(
    "animate__animated animate__wobble animate__repeat-1 animate__slow"
  );
  useEffect(() => {
    setTimeout(() => {
      setAnimate("animate__animated animate__heartBeat");
    }, 6000);
  }, []);

  return (
    <>
      <FlexRowBetween
        style={{ width: "100%", marginTop: "20px", flexDirection: "column" }}
      >
        <FlexRowBetween style={{ justifyContent: "center" }}>
          <div style={{ fontSize: "14px", marginLeft: "1px" }}>כמה אתם</div>
          <div style={{ fontSize: "14px" }}>?</div>
        </FlexRowBetween>
        <FlexRowBetween
          style={{
            justifyContent: "center",
            width: "max-content",
            marginTop: "10px",
          }}
        >
          <GenericDiv
            onClick={() => {
              parseInt(count) < user.acceptLimit &&
                setCount(parseInt(count) + 1);
            }}
            background={user.heartColor}
            style={{
              borderRadius: "0 10px 10px 0",
              width: "min-content",
              ...plusButtonStyle,
            }}
          >
            <img
              src={user.invK === 3 ? plusB : plus}
              alt="plus"
              style={{ width: "16px", padding: "3.5px 0" }}
            />
          </GenericDiv>
          <GenericButton
            className="animate__animated animate__jello animate__repeat-2 animate__slow"
            style={{
              margin: "0 5px",
              background: user.heartColor,
              padding: "7px 18px",
              ...countStyle,
            }}
          >
            {parseInt(count)}
          </GenericButton>
          <GenericDiv
            onClick={() => {
              parseInt(count) > 1 && setCount(parseInt(count) - 1);
            }}
            style={{
              borderRadius: "10px 0 0 10px",
              background: "#3A3C3F",
              width: "min-content",
              ...minusButtonStyle,
            }}
          >
            <img
              src={user.invK === 3 ? minusB : minus}
              alt="minus"
              style={{ width: "16px", padding: "3px 0" }}
            />
          </GenericDiv>
        </FlexRowBetween>
      </FlexRowBetween>
      {self && (
        <div
          style={{
            display: "flex",
            width: "90%",
            marginRight: "5%",
            marginTop: "20px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <HomeInput
            pHcolor={"rgba(56, 52, 61, 0.7)"}
            color={"silver"}
            onChange={(e) => setName(e.target.value)}
            placeholder="שם מלא"
            value={name}
            style={{
              backgroundColor: "#f2f2f2",
              margin: "0px",
              width: "55%",
              paddingRight: "10px",
            }}
          />
          <HomeInput
            pHcolor={"rgba(56, 52, 61, 0.7)"}
            color={"silver"}
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            placeholder="מס׳ פלאפון"
            name="phone"
            style={{
              backgroundColor: "#f2f2f2",
              margin: "0px",
              width: "40%",
              paddingRight: "10px",
            }}
          />
        </div>
      )}
      <FlexRowBetween
        className={animate}
        style={{ justifyContent: "space-evenly", margin: "20px auto" }}
        id={"bla"}
      >
        <GenericButton
          onClick={() => onSubmit(parseInt(count))}
          style={{
            background: user.heartColor,
            color: "white",
            fontSize: "15px",
            padding: "7px 15px",
            ...acceptButtonStyle,
          }}
        >
          <div style={{ filter: user.invK === 3 ? "brightness(75%)" : "none" }}>
            {done ? "שליחה נוספת" : "אישור הגעה"}
          </div>
        </GenericButton>
        <GenericButton
          onClick={() => onSubmit(0)}
          style={{
            background: "#3A3C3F",
            color: "white",
            fontSize: "15px",
            padding: "7px 10px",
            ...negativeButtonStyle,
          }}
        >
          {done ? "שליחה נוספת" : "לא נוכל להגיע"}
        </GenericButton>
      </FlexRowBetween>
    </>
  );
};

export default GenericArivalConfirmation;
