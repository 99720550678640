import React from "react";
import ring5 from '../../../../assets/invitation/ring5.svg';
const Extra = props => {
    return(<>
        <div style={{
            display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", fontSize: "12px", textAlign: "center"
            , color: "#3A3C3F", textDecoration: "none", marginTop: "20px"
        }}>
            <div>
                <div>קבלת פנים</div>
                <div>{props.data.hour1}</div>
            </div>
            <div>
                <div><img alt="ring5" src={ring5} style={{ width: "35px" }} /></div>
            </div>
            <div>
                <div>חופה וקידושין</div>
                <div>{props.data.hour2}</div>
            </div>
        </div>

        <div style={{ display: "flex", width: "100%", marginTop: "15px", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", textAlign: "center" }}>
            <div>
                <div style={{ fontSize: "12px" }}>{props.data.family ? "משפחת" : "הורי"} הכלה</div>
                <div style={{ fontSize: "14px" }}>{props.data.brideParents.split('??').map(x => <div>{x}</div>)}</div>

            </div>
            <div>
                <div style={{ fontSize: "12px" }}>{props.data.family ? "משפחת" : "הורי"} החתן</div>
                <div style={{ fontSize: "14px" }}>{props.data.groomParents.split('??').map(x => <div>{x}</div>)}</div>
            </div>
        </div>
   </>)
}

export default Extra;