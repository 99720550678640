import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ClientRoutes from './Routes';
import Aviv from '../components/admin/aviv/Aviv';

const AvivSwitch = () => {
  return (
    <Switch>
      <Route path={ClientRoutes.Admin.aviv} component={props => <Aviv {...props} />} />
      <Redirect from="/" to={ClientRoutes.Admin.aviv} />
    </Switch>
  )
}

export default AvivSwitch;