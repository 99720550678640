import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import { editGuestById } from "../../store/reducers/guestsReducer";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  GuestOptions,
  CenteredForm,
  RTextArea,
  DropOption,
  RespFont,
  InputBox,
  GenericDiv,
  FlexRowBetween,
} from "../common/Style";
import callLogo from "../../assets/client/call1.svg";
import dropdown1 from "../../assets/client/dropdown1.svg";
import delete1 from "../../assets/client/delete1.svg";
import whatsappLogo from "../../assets/client/whatsapp1.svg";
import { GetToast } from "../common/modals/Toast";
import Switcher from "../common/switcher/Switcher";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import GenericSvg from "../common/coloredSvgs/GenericSvg";
import { svgs } from "../common/coloredSvgs/svgs.js";
import "./style.css";
import ExitModal from "../common/modals/ExitModal";

const EditGuest = (props) => {
  const [ans, setAns] = useState(
    props.data.ans !== "" && props.data.ans !== null ? props.data.ans : ""
  );
  const [owc, setOwc] = useState(
    props.data.owc !== "" && props.data.owc !== null ? props.data.owc : "קשר"
  );
  const [limit, setLimit] = useState(true);
  const { encode } = require("url-encode-decode");
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { register, handleSubmit } = useForm();
  const [show, setShow] = useState(true);
  const [tempAccept, setAccept] = useState(false);
  const [tempNotes, setNotes] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [options, setOptions] = useState(false);
  const [showDropOwc, setShowDropOwc] = useState(false);
  const [closeness, setCloseness] = useState(props.data.closeness);
  const [dishes, setDishes] = useState([...props.data.dishes]);
  const { user } = useSelector((state) => state.authReducer);
  let height = window.screen.availHeight;

  const handleOnSelect = (item) => {
    setCloseness(item.id);
  };
  const handleOnSearch = (string, results) => {
    setCloseness(string);
  };

  const handleAnsClick = (num, pos = "") => {
    if (ans !== "M") {
      setAns(`C${num} - ${pos} נענתה`);
      setShowDrop(false);
    } else alert("לא ניתן לסמן מענה מכיוון שכבר התקבל דרך הודעה");
  };

  // const handleResetSent = () => {
  //     dispatch(resetSent({ sent: 0 }, props.data._id))
  //         .then(res => {
  //             if (!res.payload?.error)
  //                 GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!")
  //         })
  // }
  const onSubmit = async (data) => {
    let newOwc = "";
    if (
      user.eventType &&
      ((data?.inviting.length && owc === "קשר") ||
        (data?.inviting.length === 0 && owc !== "קשר"))
    )
      alert("שדה המזמין והקשר באים ביחד - הכל או כלום");
    else if (closeness.length > 1) {
      newOwc = owc;
      let accept = data.accept === undefined ? null : parseInt(data.accept);
      if (tempAccept !== false) accept = tempAccept;
      let notes = tempNotes !== false ? tempNotes : data.notes;
      if (tempNotes !== false) notes = tempNotes;
      let phone = data.phone;
      if (phone[0] === "0") phone = phone.substring(1);
      phone = phone.replaceAll("-", "");
      phone = phone.replaceAll(/[^\d]/g, "");

      let reset = false;
      if (props.data.phone?.length > 0 && props.data.phone !== data.phone)
        reset = true;

      let update = {
        name: data.name,
        phone,
        closeness,
        sum: parseInt(data.sum),
        ans: ans,
        inviting: data.inviting,
        owc: newOwc,
        dishes: [data.glt, data.tsim, data.tiv, data.glat],
        accept: reset ? null : accept,
        arrived: parseInt(data.arrived),
        transportation: parseInt(data.transportation),
        notes: reset ? "" : notes,
        table: props.data.table,
        gift: props.data.gift,
      };

      dispatch(
        editGuestById(update, props.data._id, props.data.closeness, reset)
      ).then((res) => {
        if (!res.payload?.error) {
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
          props.setEdit(null);
        }
      });
    } else alert("שדה קירבה לא יכול להיות פחות מ 2 תווים");
  };

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  let msg = encode(
    `https://b-s.fun/n?n=` +
      `${window.btoa(user._id + " " + props.data._id + " " + props.data.sum)}`
  );
  return (
    <CenteredForm
      onClick={(e) => {
        e.stopPropagation();
        setShowDrop(false);
        setShowDropOwc(false);
      }}
      onSubmit={handleSubmit(onSubmit)}
      style={{ maxHeight: `${height * 0.7}px` }}
      id="form1"
    >
      <ExitModal init={props.init} color={user.color} />
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          textAlign: "center",
          color: "#3A3C3F",
          fontWeight: "bold",
          padding: `30px 0`,
          paddingBottom: `10px`,
        }}
      >
        עריכת אורח
      </RespFont>
      <InputBox
        labelColor={"gray"}
        color={"#3A3C3F"}
        style={{ paddingBottom: "30px" }}
      >
        <FlexRowBetween>
          <div style={{ position: "relative", width: "40%", margin: "4px 5%" }}>
            <input
              defaultValue={props.data.name}
              type="text"
              name="name"
              minLength={2}
              maxLength={50}
              required={true}
              ref={register()}
            />
            <label>שם מלא</label>
          </div>
          <div
            style={{
              width: "40%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "4px 5%",
              alignItems: "baseline",
            }}
          >
            <div
              className="relativeBox"
              style={{ width: "70%", marginRight: "0px" }}
            >
              <input
                defaultValue={props.data.phone}
                type="text"
                name="phone"
                pattern={limit && "[0]{0,1}[5]{1}[0-9]{8}"}
                ref={register()}
              />
              <label>מס' פלאפון</label>
            </div>

            {props.data.phone && props.data.call && (
              <a
                href={
                  "tel:" +
                  (props.data.phone[0] === "0"
                    ? props.data.phone
                    : "0" + props.data.phone + "")
                }
              >
                <OverlayTrigger
                  placement={"top"}
                  overlay={<Tooltip>{"חיוג"}</Tooltip>}
                >
                  <GenericDiv
                    hoverBackground={"rgb(223, 223, 223)"}
                    background={user.color}
                    style={{ padding: "7px 7px" }}
                  >
                    <img alt="call" style={{ width: `15px` }} src={callLogo} />
                  </GenericDiv>
                </OverlayTrigger>
              </a>
            )}
          </div>
        </FlexRowBetween>
        <FlexRowBetween>
          <div
            className="relativeBox"
            style={{
              marginTop: "20px",
              zIndex: "1",
              marginRight: "5%",
              // , display: "flex", flexDirection: "row", width: "100%"
            }}
          >
            <ReactSearchAutocomplete
              items={props.categories}
              onSelect={handleOnSelect}
              onSearch={handleOnSearch}
              fuseOptions={{ minMatchCharLength: 1 }}
              maxResults={show ? 10 : 0}
              inputSearchString={closeness}
              showNoResults={false}
              placeholder="קירבה"
              styling={{
                searchIconMargin: "0 5px 0 10px",
                clearIconMargin: "4px 0 0 5px",
                borderRadius: "5px",
                width: "100%",
                height: "30px",
              }}
            />
          </div>
          <OverlayTrigger
            placement={"bottom"}
            overlay={
              <Tooltip>
                {show ? "הסתרת השלמות לחיפוש" : "הצגת השלמות לחיפוש"}
              </Tooltip>
            }
          >
            <GenericDiv
              style={{ marginLeft: "5%", height: "30px", marginTop: "11px" }}
              background={user.color}
              onClick={() => setShow(!show)}
            >
              {show ? "הסתר" : "הצג"}
            </GenericDiv>
          </OverlayTrigger>
        </FlexRowBetween>

        <FlexRowBetween
          className="relativeBox"
          style={{ width: "90%", margin: "10px auto" }}
        >
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{"מענה"}</Tooltip>}
          >
            <GenericDiv
              background={user.color}
              style={{ position: "relative" }}
              onClick={(e) => {
                e.stopPropagation();
                setShowDropOwc(false);
                setShowDrop(!showDrop);
              }}
            >
              <div>{ans === "" ? "מענה" : ans}</div>
              <img
                src={dropdown1}
                style={{ width: "25px", marginLeft: "-7px" }}
              />
            </GenericDiv>
          </OverlayTrigger>

          {showDrop && props.mode === 0 && (
            <div
              style={{
                position: "absolute",
                right: "0",
                top: "85%",
                zIndex: 1,
                color: user.color,
                borderRadius: "5px",
                backgroundColor: "white",
                padding: "10px",
                fontSize: "14px",
                boxShadow: "0 2px 4px gray",
              }}
            >
              <FlexRowBetween>
                <FlexRowBetween>
                  <DropOption
                    shadow={user.color}
                    onClick={() => handleAnsClick(1)}
                    style={{ padding: "3px 8px" }}
                  >
                    כן
                  </DropOption>
                  <div style={{ marginLeft: "7px", marginRight: "9px" }}>
                    C1
                  </div>
                  <DropOption
                    shadow={user.color}
                    onClick={() => handleAnsClick(1, "לא ")}
                    style={{ padding: "3px 7px" }}
                  >
                    לא
                  </DropOption>
                </FlexRowBetween>
              </FlexRowBetween>
              <FlexRowBetween style={{ marginTop: "15px" }}>
                <FlexRowBetween>
                  <DropOption
                    shadow={user.color}
                    onClick={() => handleAnsClick(2)}
                    style={{ padding: "3px 8px" }}
                  >
                    כן
                  </DropOption>
                  <div style={{ marginLeft: "7px", marginRight: "9px" }}>
                    C2
                  </div>
                  <DropOption
                    shadow={user.color}
                    onClick={() => handleAnsClick(2, "לא ")}
                    style={{ padding: "3px 7px" }}
                  >
                    לא
                  </DropOption>
                </FlexRowBetween>
              </FlexRowBetween>
              <FlexRowBetween style={{ marginTop: "15px" }}>
                <FlexRowBetween>
                  <DropOption
                    shadow={user.color}
                    onClick={() => handleAnsClick(3)}
                    style={{ padding: "3px 8px" }}
                  >
                    כן
                  </DropOption>
                  <div style={{ marginLeft: "7px", marginRight: "9px" }}>
                    C3
                  </div>
                  <DropOption
                    shadow={user.color}
                    onClick={() => handleAnsClick(3, "לא ")}
                    style={{ padding: "3px 7px" }}
                  >
                    לא
                  </DropOption>
                </FlexRowBetween>
              </FlexRowBetween>
              <FlexRowBetween style={{ marginTop: "15px" }}>
                <FlexRowBetween>
                  <DropOption
                    shadow={user.color}
                    onClick={() => handleAnsClick(4)}
                    style={{ padding: "3px 8px" }}
                  >
                    כן
                  </DropOption>
                  <div style={{ marginLeft: "7px", marginRight: "9px" }}>
                    C4
                  </div>
                  <DropOption
                    shadow={user.color}
                    onClick={() => handleAnsClick(4, "לא ")}
                    style={{ padding: "3px 7px" }}
                  >
                    לא
                  </DropOption>
                </FlexRowBetween>
              </FlexRowBetween>
            </div>
          )}

          <div style={{ position: "relative", width: "70%" }}>
            <RTextArea
              defaultValue={props.data.notes}
              color={user.color}
              placeholder="הערות"
              name="notes"
              type="text"
              rows="2"
              minLength={0}
              maxLength={100}
              ref={register()}
              style={{ margin: "0 auto" }}
            />
          </div>
        </FlexRowBetween>
        <div
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0px auto",
          }}
        >
          <div style={{ position: "relative", width: "20%" }}>
            <input
              defaultValue={props.data.sum}
              type="number"
              name="sum"
              required={true}
              min={1}
              max={limit ? 30 : 1000}
              ref={register()}
            />
            <label>מוזמנים</label>
          </div>
          <div style={{ position: "relative", width: "20%" }}>
            <input
              style={{
                borderBottom: `1px solid ${user.color}`,
                color: `${user.color}`,
              }}
              defaultValue={props.data.accept}
              type="number"
              name="accept"
              min={0}
              max={limit ? 30 : 1000}
              ref={register()}
            />
            <label style={{ color: `${user.color}` }}>אישרו</label>
          </div>
          <div style={{ position: "relative", width: "20%" }}>
            <input
              defaultValue={props.data.arrived}
              type="number"
              name="arrived"
              min={0}
              max={30}
              ref={register()}
            />
            <label>הגיעו</label>
          </div>
          {user.transportation && (
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{user.transportationDetails}</Tooltip>}
            >
              <div style={{ position: "relative", width: "20%" }}>
                <input
                  defaultValue={props.data.transportation}
                  type="number"
                  name="transportation"
                  min={0}
                  max={30}
                  ref={register()}
                />
                <label>הסעה</label>
              </div>
            </OverlayTrigger>
          )}
        </div>
        <div
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0px auto",
          }}
        >
          {user.specialDishes && (
            <>
              <div style={{ position: "relative", width: "22%" }}>
                <input
                  defaultValue={dishes[3]}
                  type="number"
                  name="glat"
                  min={0}
                  max={30}
                  ref={register()}
                />
                <label>גלאט</label>
              </div>
              <div style={{ position: "relative", width: "22%" }}>
                <input
                  defaultValue={dishes[2]}
                  type="number"
                  name="tiv"
                  min={0}
                  max={30}
                  ref={register()}
                />
                <label>טבעוני</label>
              </div>
              <div style={{ position: "relative", width: "22%" }}>
                <input
                  defaultValue={dishes[1]}
                  type="number"
                  name="tsim"
                  min={0}
                  max={30}
                  ref={register()}
                />
                <label>צמחוני</label>
              </div>
              <div style={{ position: "relative", width: "22%" }}>
                <input
                  defaultValue={dishes[0]}
                  type="number"
                  name="glt"
                  min={0}
                  max={30}
                  ref={register()}
                />
                <label>ללא גלוטן</label>
              </div>
            </>
          )}
        </div>
        {user.eventType && (
          <FlexRowBetween style={{ width: "90%", margin: "0 auto" }}>
            <div
              style={{ position: "relative", width: "70%", margin: "4px 5%" }}
            >
              <input
                defaultValue={props.data?.inviting}
                type="text"
                name="inviting"
                minLength={2}
                maxLength={50}
                ref={register()}
              />
              <label>המזמין</label>
            </div>
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip>{"הקשר של בעל/ת השמחה למזמין"}</Tooltip>}
            >
              <GenericDiv
                background={user.color}
                style={{
                  position: "relative",
                  width: "20%",
                  marginBottom: "-10px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDrop(false);
                  setShowDropOwc(!showDropOwc);
                }}
              >
                <div>{owc}</div>
                <img
                  src={dropdown1}
                  style={{ width: "25px", marginLeft: "-7px" }}
                />
                {showDropOwc && (
                  <div
                    style={{
                      position: "absolute",
                      left: "0",
                      bottom: "110%",
                      zIndex: 2,
                      color: user.color,
                      borderRadius: "5px",
                      backgroundColor: "white",
                      padding: "10px",
                      fontSize: "12px",
                      boxShadow: "0 2px 4px gray",
                    }}
                  >
                    <FlexRowBetween>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בנם");
                          setShowDropOwc(false);
                        }}
                      >
                        בנם
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בנה");
                          setShowDropOwc(false);
                        }}
                      >
                        בנה
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בתו");
                          setShowDropOwc(false);
                        }}
                      >
                        בתו
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בנו");
                          setShowDropOwc(false);
                        }}
                      >
                        בנו
                      </DropOption>
                      <DropOption
                        background={"black"}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("קשר");
                          setShowDropOwc(false);
                        }}
                        style={{ boxShadow: "0 2px 4px black" }}
                      >
                        <img src={delete1} style={{ width: "15px" }} />
                      </DropOption>
                    </FlexRowBetween>
                    <FlexRowBetween style={{ marginTop: "8px" }}>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בתם");
                          setShowDropOwc(false);
                        }}
                      >
                        בתם
                      </DropOption>
                      <DropOption
                        background={addAlpha(user.color, 0.2)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("בתה");
                          setShowDropOwc(false);
                        }}
                      >
                        בתה
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדה");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדה
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדתם");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדתם
                      </DropOption>
                    </FlexRowBetween>
                    <FlexRowBetween style={{ marginTop: "8px" }}>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדם");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדם
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדתה");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדתה
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדתו");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדתו
                      </DropOption>
                      <DropOption
                        shadow={user.color}
                        onClick={() => {
                          setOwc("נכדו");
                          setShowDropOwc(false);
                        }}
                      >
                        נכדו
                      </DropOption>
                    </FlexRowBetween>
                    <FlexRowBetween style={{ marginTop: "8px" }}>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחותה");
                          setShowDropOwc(false);
                        }}
                      >
                        אחותה
                      </DropOption>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחותו");
                          setShowDropOwc(false);
                        }}
                      >
                        אחותו
                      </DropOption>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחיו");
                          setShowDropOwc(false);
                        }}
                      >
                        אחיו
                      </DropOption>
                      <DropOption
                        style={{ color: "white" }}
                        background={addAlpha(user.color, 0.7)}
                        shadow={user.color}
                        onClick={() => {
                          setOwc("אחיה");
                          setShowDropOwc(false);
                        }}
                      >
                        אחיה
                      </DropOption>
                    </FlexRowBetween>
                  </div>
                )}
              </GenericDiv>
            </OverlayTrigger>
          </FlexRowBetween>
        )}

        {/* <GenericButton hoverBackground={"rgb(223, 223, 223)"} background={user.color} type="submit" style={{ width: "60%", margin: "10px auto", marginTop: "30px" }}>שמירה</GenericButton> */}
      </InputBox>
      {props.mode === 0 && (
        <FlexRowBetween
          style={{
            width: "90%",
            margin: "0 auto",
            marginBottom: "30px",
            fontSize: "12px",
          }}
        >
          <button
            type="submit"
            form="form1"
            style={{
              fontSize: "12px",
              color: "black",
              border: "2px solid silver",
              padding: "4px 9px",
              borderRadius: "3px",
            }}
            onClick={() => setAccept(0)}
          >
            0
          </button>
          <button
            type="submit"
            form="form1"
            style={{
              fontSize: "12px",
              color: "black",
              border: "2px solid silver",
              padding: "4px 9px",
              borderRadius: "3px",
            }}
            onClick={() => setAccept(1)}
          >
            1
          </button>
          <button
            type="submit"
            form="form1"
            style={{
              fontSize: "12px",
              color: "black",
              border: "2px solid silver",
              padding: "4px 9px",
              borderRadius: "3px",
            }}
            onClick={() => setAccept(2)}
          >
            2
          </button>
          <button
            type="submit"
            form="form1"
            style={{
              fontSize: "12px",
              color: "black",
              border: "2px solid silver",
              padding: "4px 7px",
              borderRadius: "3px",
            }}
            onClick={() =>
              setNotes(
                "לא יודעים כרגע, לא להתקשר יותר - יעדכנו בהודעה במידה ויהיה שינוי"
              )
            }
          >
            לא להתקשר + יעדכנו
          </button>
        </FlexRowBetween>
      )}
      {props.mode === 0 && (
        <FlexRowBetween
          style={{
            width: "90%",
            margin: "0 auto",
            marginBottom: "30px",
            fontSize: "12px",
          }}
        >
          <button
            type="submit"
            form="form1"
            style={{
              fontSize: "12px",
              color: "black",
              border: "2px solid silver",
              padding: "4px 7px",
              borderRadius: "3px",
            }}
            onClick={() => {
              setNotes("לא מגיעים כרגע, יעדכנו במידה ויהיה שינוי");
              setAccept(0);
            }}
          >
            0 + יעדכנו
          </button>
          <button
            type="submit"
            form="form1"
            style={{
              fontSize: "12px",
              color: "black",
              border: "2px solid silver",
              padding: "4px 7px",
              borderRadius: "3px",
            }}
            onClick={() => setNotes("תא קולי/ לא מחובר/ לא זמין")}
          >
            תא קולי/ לא מחובר/ לא זמין
          </button>
        </FlexRowBetween>
      )}
      <FlexRowBetween
        style={{
          width: "90%",
          margin: "0 auto",
          marginBottom: "30px",
          fontSize: "12px",
        }}
      ></FlexRowBetween>
      <GuestOptions
        width={"21px"}
        color={user.color}
        padding={`10px 0`}
        style={{ justifyContent: "space-between" }}
      >
        <FlexRowBetween
          style={{
            justifyContent: "space-around",
            boxShadow: `0 -1px 4px ${addAlpha(user.color, 0.44)}`,
          }}
        >
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{"שמירה"}</Tooltip>}
          >
            <button
              type="submit"
              form="form1"
              style={{
                outline: "none",
                width: "100%",
                background: "inherit",
                border: "none",
              }}
            >
              <FlexRowBetween style={{ justifyContent: "space-evenly" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: user.color,
                  }}
                >
                  שמירה
                </div>
                <div style={{ padding: "8px 0" }}>
                  <GenericSvg
                    fill={user.color}
                    size={"20px"}
                    svg={svgs.save}
                    viewBox={"0 0 49 49"}
                  />
                </div>
              </FlexRowBetween>
            </button>
          </OverlayTrigger>
        </FlexRowBetween>
        <FlexRowBetween
          style={{
            justifyContent: "space-around",
            background: `linear-gradient(to right, ${user.color}, ${addAlpha(
              user.color,
              0.95
            )})`,
          }}
        >
          {/* {props.data.phone &&
                    <OverlayTrigger placement={'top'} overlay={<Tooltip>{"איפוס ההודעות עבור אורח זה (לאחר תיקון)"}</Tooltip>}>
                        <img onClick={handleResetSent} alt="resetSent" src={resetSent1} />
                    </OverlayTrigger>} */}
          {msg && props.data.phone && (
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip>
                  {"שליחת ווצאפ לאורח עם קישור לאישור הגעה דיגיטלי ספציפי שלו"}
                </Tooltip>
              }
            >
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://wa.me/972${props.data.phone}/?text=${msg}`}
              >
                <img alt="whatsapp" src={whatsappLogo} />
              </a>
            </OverlayTrigger>
          )}
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{"מחיקת אורח"}</Tooltip>}
          >
            <div>
              <img
                src={delete1}
                alt="edit"
                onClick={() => {
                  props.init();
                  props.setDeleteGuest([
                    { closeness: props.data.closeness, _id: props.data._id },
                  ]);
                }}
              />
            </div>
          </OverlayTrigger>
        </FlexRowBetween>
      </GuestOptions>
      <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip>{limit ? "כולל מגבלות" : "ללא מגבלות"}</Tooltip>}
      >
        <div
          style={{
            position: "absolute",
            right: "15px",
            top: "15px",
            // , transform: "rotate(90deg)"
          }}
        >
          <Switcher checked={limit} onChange={setLimit} color={user.color} />
        </div>
      </OverlayTrigger>
    </CenteredForm>
  );
};

export default EditGuest;
