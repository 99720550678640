import React from 'react';
const TopInv = props => {
    return (
        <svg style={{ width: "42px", height: "40px", cursor: "pointer" }}>
            <svg width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.8866 2.30928C3.54639 2.41237 2.41237 3.4433 2.30928 4.8866C2.10309 6.94845 2 9.83505 2 12C2 14.1649 2.10309 17.0515 2.30928 19.1134C2.30928 19.8351 2.61856 20.4536 3.13402 20.866C3.64948 21.2784 4.16495 21.5876 4.8866 21.6907C6.94845 21.8969 9.83505 22 12 22C14.1649 22 17.0515 21.7938 19.1134 21.6907C20.4536 21.5876 21.5876 20.5567 21.6907 19.1134C21.8969 17.0515 22 14.1649 22 12C22 9.83505 21.7938 6.94845 21.6907 4.8866C21.5876 3.54639 20.5567 2.41237 19.1134 2.30928C17.0515 2.10309 14.1649 2 12 2C9.83505 2 6.94845 2.10309 4.8866 2.30928ZM11.9653 7.05155L16.6392 10.7629C16.8454 10.866 16.9485 11.0722 16.9485 11.3814C16.9485 11.5876 16.8454 11.6907 16.7423 11.8969C16.433 12.2062 16.0206 12.3093 15.7113 12L12.8247 9.52577L12.8247 16.1237C12.8247 16.5361 12.5155 16.9485 12 16.9485C11.4845 16.9485 11.1753 16.6392 11.1753 16.1237L11.1753 9.62887L8.28866 12.1031C7.97938 12.4124 7.46392 12.3093 7.25773 12C6.94845 11.6907 7.05155 11.1753 7.36082 10.9691L11.9653 7.05155Z" fill={props.fill} />
            </svg>
        </svg>
    )
}
export default TopInv