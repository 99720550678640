import React from 'react';
import { CenteredForm, MyHr } from '../Style';

const DuplicatesModal = props => {
    const regex = /}|{|"/ig;
    let duplicates = props.data.map((item, index) => {
        console.log(JSON.stringify(item._id));
        if (item._id !== null && item._id !== "")
            return <div key={index} style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", alignItems: "center", marginBottom: "10px" }}>
                <div>{JSON.stringify(item._id).replaceAll(regex, " ")}</div>
                <div>{item.count}</div>
            </div>
    })

    return (<CenteredForm onClick={(e) => e.stopPropagation()}>
        <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "space-around" }}>
            <div style={{ width: "100%", marginTop: "30px" }}>
                <div style={{ textAlign: "center", fontSize: "1.2rem", marginBottom: "10px" }}>כפולים</div>
                <MyHr />
            </div>
            <div style={{ width: "100%", margin: "20px auto" }}>
                {duplicates}
            </div>
        </div>
    </CenteredForm>)
}
export default DuplicatesModal;