import React, { useState, useEffect } from "react";
import { HomeTextArea, InvDiv } from "../Style";
import { useToasts } from "react-toast-notifications";
import { GetToast } from "../modals/Toast";
import { setAccept, setDishes } from "../../../store/reducers/guestsReducer";
import { useDispatch, useSelector } from "react-redux";
import OpacityLoading from "../loader/OpacityLoading";
import TransportationConfirmation from "./TransportationConfirmation";
import GenericModal from "../modals/GenericModal";
import Extra from "./parts/Extra";
import pic222 from "../../../assets/invitation/pic222.svg";
import pic111 from "../../../assets/invitation/pic111.svg";
import Footer from "./parts/Footer";
import Names from "./parts/Names";
import Links from "./parts/Links";
import SpecialDishes from "./parts/SpecialDishes";
import Output from "../../user/image/Output";
import GenericArivalConfirmation from "./parts/GenericArivalConfirmation";
import Company from "./parts/Company";

const Invitation = (props) => {
  const { user } = useSelector((state) => state.authReducer);
  const { guestsLoading } = useSelector((state) => state.guestsReducer);
  const [transportation, setTransportation] = useState(false);
  const [done, setDone] = useState(false);
  const [notes, setNotes] = useState("");
  const [tiv, setTiv] = useState(0);
  const [tsim, setTsim] = useState(0);
  const [glt, setGlt] = useState(0);
  const [glat, setGlat] = useState(0);
  // console.log(user);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const onSubmit = async (data) => {
    let body = { accept: data, ans: "M" };
    if (notes !== "") body["notes"] = notes;
    dispatch(setAccept(body, props.id)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה הצליחה", 8000);
        setDone(true);
        setTimeout(function () {
          setDone(false);
        }, 8000);
      } else GetToast(addToast, "error", res.payload.error);
    });
  };

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  useEffect(() => {
    setTimeout(function () {
      handleClickScroll("bla");
    }, 2000);
  }, []);

  const handleSetDishes = () => {
    dispatch(setDishes({ dishes: [glt, tsim, tiv, glat] }, props.id)).then(
      (res) => {
        if (!res.payload?.error) {
          GetToast(addToast, "success", "הפעולה הצליחה", 8000);
        } else GetToast(addToast, "error", res.payload.error);
      }
    );
  };

  const [count, setCount] = useState(
    parseInt(parseInt(props.amount) > 0 ? props.amount : 1)
  );
  const borderedInvsStyle = {
    width: "100%",
    backgroundColor: "rgba(255,255,255,0.7)",
    boxShadow: "0 1px 2px silver",
    padding: "5px",
    boxShadow: "0 2px 4px silver",
    borderRadius: "3px",
    paddingBottom: "20px",
  };
  const basicDivStyle = user.invK === 1 ? { width: "100%" } : borderedInvsStyle;
  return (
    <InvDiv
      gradient={user.gradient}
      style={{ padding: user.invK === 1 ? "0" : "15px" }}
    >
      <div style={{ ...basicDivStyle }}>
        <Output />
        {user.inv === 1 && <Names data={user} />}
        {user.isCompany ? (
          <Company
            count={count}
            setCount={setCount}
            onSubmit={onSubmit}
            done={done}
            id={props.id}
          />
        ) : (
          <GenericArivalConfirmation
            count={count}
            setCount={setCount}
            onSubmit={onSubmit}
            done={done}
          />
        )}
        <Links setTransportation={setTransportation} data={user} />
        {user.inv === 1 && <Extra data={user} />}
        {user.specialDishes && (
          <SpecialDishes
            setTiv={setTiv}
            setTsim={setTsim}
            setGlt={setGlt}
            setGlat={setGlat}
            tiv={tiv}
            tsim={tsim}
            glt={glt}
            glat={glat}
            handleSetDishes={handleSetDishes}
          />
        )}
        {user.sharePics && (
          <a
            target={"_blank"}
            rel="noopener noreferrer"
            href={user.sharePicsLink}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              color: "#3A3C3F",
              textDecoration: "none",
              fontSize: "14px",
              marginTop: "20px",
            }}
          >
            <img alt="wazeIcon" src={pic222} style={{ width: "25px" }} />
            <div>{user.sharePicsPlaceholder}</div>
            <img alt="wazeIcon" src={pic111} style={{ width: "25px" }} />
          </a>
        )}
        {user.blessing && (
          <div style={{ width: "90%", margin: "0px auto", marginTop: "20px" }}>
            <HomeTextArea
              type="text"
              rows="3"
              placeholder={user.blessingPlaceholder}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
        )}
      </div>

      <Footer producer={user.producer} />
      {guestsLoading && <OpacityLoading />}
      {transportation && (
        <GenericModal
          init={setTransportation}
          div={
            <TransportationConfirmation
              setDone={setDone}
              uid={user._id}
              color={user.heartColor}
              init={setTransportation}
              details={user.transportationDetails}
              setCount={setCount}
              id={props.id}
              count={count}
            />
          }
        />
      )}
    </InvDiv>
  );
};

export default Invitation;
