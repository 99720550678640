import React from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import delete1 from '../../../../assets/client/delete1.svg';
import exitModalLogo from '../../../../assets/common/cancel1.svg';
import { GetToast } from '../../../common/modals/Toast';
import { RTextArea, CenteredForm, PlaceHolderImage, GenericButton, InputBox, Footer } from '../../../common/Style';
import { editPotentialDetails } from '../../../../store/reducers/potentialsReducer';

const EditPotential = (props) => {
    console.log(props);
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { register, handleSubmit } = useForm();

    const onSubmit = async data => {
        dispatch(editPotentialDetails(props.data._id, { details: data.details })).then((res) => {
            if (!res.payload?.error)
                GetToast(addToast, "success", "success!!")
        })
        props.init(false)
    }
    return (<CenteredForm onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit(onSubmit)}>
        <PlaceHolderImage src={exitModalLogo} alt="exitModalLogo" style={{ top: "8px", left: "8px", transform: "translate(0,0)", width: "32px", cursor: "pointer" }} onClick={() => props.init(null)} />
        <div style={{ textAlign: "center", fontWeight: "bold", padding: "20px", fontSize: "22px" }}>עריכת פניה</div>
        <InputBox labelColor={"gray"} color={"#3A3C3F"} borderBottomColor={"#fda14b"}>
            <div style={{ position: "relative", width: "70%", margin: "20px auto" }}>
                <RTextArea defaultValue={props.data.details} color={"#fda14b"} placeholder="פרטים" name="details" type="text" rows="2"
                    minLength={0} maxLength={500} ref={register()} />
            </div>
            <GenericButton background={"#fda14b"} type="submit" style={{ width: "50%", margin: "30px auto", padding: "15px" }}>אישור</GenericButton>
        </InputBox>
        <Footer background={"#fda14b"}>
            <img src={delete1} alt="delete" onClick={() => { props.init(false); props.setDeleteTodo(props.data._id) }} />
        </Footer>
    </CenteredForm >)
}

export default EditPotential;