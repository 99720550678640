import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ClientRoutes from "../../navigation/Routes";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import GCalendar from "../common/calendar/Calendar";
import { sha512 } from "js-sha512";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { register1 } from "../../store/reducers/authReducer";
import eye from "./eye.svg";
import {
  MainDiv,
  CenteredForm,
  M1,
  M11R,
  M12R,
  Yet,
  GenericButton,
  GenericDiv,
  InputBox,
  GradientText,
  RespFont,
  PlaceHolderImage,
  FlexRowBetween,
} from "../common/Style";
import invBack from "../../assets/invitation/proposal2.jpeg";
// import invBack from './Background.jpeg';
import { GetToast } from "../common/modals/Toast";
import GenericModal from "../common/modals/GenericModal";
import Switcher from "../common/switcher/Switcher";

const Register = (props) => {
  const example = (
    <div>
      <div>:דוגמאות</div>
      <div style={{ textAlign: "right" }}>ענבר ואיתי - החתונה</div>
      <div style={{ textAlign: "right" }}>רועי חוגג בר מצווה</div>
      <div style={{ textAlign: "right" }}>כנס עובדי ים המלח</div>
      <div style={{ textAlign: "right" }}>ליהיא - בת מצווה</div>
    </div>
  );
  const [date, setDate] = useState(null);
  const [showPass, setShowPass] = useState(false);
  const [eventType, setEventType] = useState(true);
  let height = window.screen.availHeight;
  const [showCalendar, setShowCalendar] = useState(null);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    if (date) {
      const body = {
        brideFN: eventType ? data.brideFN : "אחר",
        groomFN: eventType ? data.groomFN : "אחר",
        brideLN: eventType ? data.brideLN : "אחר",
        groomLN: eventType ? data.groomLN : "אחר",
        hall: `״${data.hall}״,`,
        city: data.city,
        inv: eventType ? 1 : 2,
        brideParents: eventType ? data.brideParents : "אחר",
        groomParents: eventType ? data.groomParents : "אחר",
        date: date1.toISOString(),
        phone: data.phone,
        password: sha512(data.password),
        eventType,
        calendarName: eventType
          ? `החתונה של ${data.brideFN} ${data.brideLN} ו${data.groomFN} ${data.groomLN}`
          : data.calendarName,
        hour1: data.hour1,
        hour2: data.hour2 ? data.hour2 : data.hour1,
        address: data.address,
        producer: localStorage["logout"] || "/itay",
      };
      dispatch(register1(body)).then((res) => {
        if (!res.payload?.error) {
          GetToast(addToast, "success", "נרשמת בהצלחה!!");
          props.history.push(ClientRoutes.UnAuth.login);
        } else GetToast(addToast, "error", res.payload.error);
      });
    } else alert("לא נבחר תאריך לאירוע");
  };
  let date1;
  if (date) {
    date1 = date;
    date1.setHours(20);
  }
  return (
    <MainDiv className="animate__animated animate__fadeIn">
      <M1
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          height: "100vh",
        }}
      >
        <M12R>
          <CenteredForm
            onSubmit={handleSubmit(onSubmit)}
            style={{
              maxHeight: `${height * 0.9}px`,
              paddingTop: "20px",
              paddingBottom: "10px",
              width: "80%",
              background: "#f2f2f2cc",
              margin: "10px auto",
            }}
          >
            <RespFont
              pSize={"20px"}
              cSize={"22px"}
              style={{
                textAlign: "center",
                color: "#3A3C3F",
                fontWeight: "bold",
                paddingBottom: `10px`,
              }}
            >
              הרשמה
            </RespFont>
            <InputBox labelColor={"gray"} color={"#3A3C3F"}>
              <FlexRowBetween>
                <div
                  style={{
                    position: "relative",
                    width: "40%",
                    margin: "4px 5%",
                  }}
                >
                  <input
                    type="text"
                    name="phone"
                    required={true}
                    pattern="[0]{1}[5]{1}[0-9]{8}"
                    ref={register()}
                  />
                  <label>מס׳ פלאפון</label>
                </div>

                <div
                  style={{
                    position: "relative",
                    width: "40%",
                    margin: "4px 5%",
                  }}
                >
                  <PlaceHolderImage
                    onClick={() => setShowPass(!showPass)}
                    fontSize={`20px`}
                    top={`34px`}
                    src={eye}
                    alt="accept"
                  />
                  <input
                    name="password"
                    type={showPass ? "text" : "password"}
                    required={true}
                    minLength={6}
                    maxLength={20}
                    ref={register()}
                  />
                  <label>סיסמא</label>
                </div>
              </FlexRowBetween>
              <FlexRowBetween style={{ width: "90%", margin: "20px auto" }}>
                <FlexRowBetween
                  style={{ width: "50%", justifyContent: "center" }}
                >
                  <div>חתונה</div>
                  <div
                    style={{
                      margin: "0 7px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Switcher
                      checked={eventType}
                      onChange={setEventType}
                      color={"#f1ae6e"}
                    />
                  </div>

                  <div>אחר</div>
                </FlexRowBetween>
                {/* <GenericDiv onClick={() => setEventType(!eventType)} style={{ width: "30%", margin: "20px auto", marginBottom: "0" }} background={"#f1ae6e"}>{eventType ? "חתונה" : "אחר"}</GenericDiv> */}
                <div
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <GenericDiv
                    hoverBackground={"rgb(223, 223, 223)"}
                    background={"#f1ae6e"}
                    style={{
                      width: "max-content",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={() => setShowCalendar(true)}
                  >
                    <div>תאריך האירוע</div>
                    <div>
                      {date &&
                        date1
                          .toISOString()
                          .replace(/T.*/, "")
                          .split("-")
                          .reverse()
                          .join("/")}
                    </div>
                  </GenericDiv>
                </div>
              </FlexRowBetween>
              {eventType ? (
                <div className="animate__animated animate__fadeIn">
                  <FlexRowBetween>
                    <div
                      style={{
                        position: "relative",
                        width: "40%",
                        margin: "4px 5%",
                      }}
                    >
                      <input
                        type="text"
                        name="brideFN"
                        required={true}
                        minLength={2}
                        maxLength={20}
                        ref={register()}
                      />
                      <label>שם פרטי - כלה</label>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        width: "40%",
                        margin: "4px 5%",
                      }}
                    >
                      <input
                        type="text"
                        name="groomFN"
                        required={true}
                        minLength={2}
                        maxLength={20}
                        ref={register()}
                      />
                      <label>שם פרטי - חתן</label>
                    </div>
                  </FlexRowBetween>
                  <FlexRowBetween>
                    <div
                      style={{
                        position: "relative",
                        width: "40%",
                        margin: "4px 5%",
                      }}
                    >
                      <input
                        type="text"
                        name="brideLN"
                        required={true}
                        minLength={2}
                        maxLength={20}
                        ref={register()}
                      />
                      <label>שם משפחה - כלה</label>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        width: "40%",
                        margin: "4px 5%",
                      }}
                    >
                      <input
                        type="text"
                        name="groomLN"
                        required={true}
                        minLength={2}
                        maxLength={20}
                        ref={register()}
                      />
                      <label>שם משפחה - חתן</label>
                    </div>
                  </FlexRowBetween>

                  <FlexRowBetween>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={<Tooltip>{"שמות מלאים"}</Tooltip>}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "40%",
                          margin: "4px 5%",
                        }}
                      >
                        <input
                          type="text"
                          name="brideParents"
                          required={true}
                          minLength={2}
                          maxLength={50}
                          ref={register()}
                        />
                        <label>הורי כלה</label>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={<Tooltip>{"שמות מלאים"}</Tooltip>}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "40%",
                          margin: "4px 5%",
                        }}
                      >
                        <input
                          type="text"
                          name="groomParents"
                          required={true}
                          minLength={2}
                          maxLength={50}
                          ref={register()}
                        />
                        <label>הורי חתן</label>
                      </div>
                    </OverlayTrigger>
                  </FlexRowBetween>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "60%",
                      margin: "20px auto",
                    }}
                  >
                    <div
                      className="relativeBox"
                      style={{ width: "45%", margin: "0 0" }}
                    >
                      <input
                        type="time"
                        defaultValue={"19:30"}
                        name="hour1"
                        required={true}
                        ref={register()}
                      />
                      <label>קבלת פנים</label>
                    </div>
                    <div
                      className="relativeBox"
                      style={{ width: "45%", margin: "0 0" }}
                    >
                      <input
                        type="time"
                        defaultValue={"20:30"}
                        name="hour2"
                        required={true}
                        ref={register()}
                      />
                      <label>חופה וקידושין</label>
                    </div>
                  </div>
                </div>
              ) : (
                <FlexRowBetween>
                  <div
                    style={{
                      position: "relative",
                      width: "25%",
                      margin: "4px 5%",
                    }}
                  >
                    <input
                      type="time"
                      defaultValue={"19:30"}
                      name="hour1"
                      required={true}
                      ref={register()}
                    />
                    <label>קבלת פנים</label>
                  </div>
                  <OverlayTrigger
                    placement={"top"}
                    overlay={<Tooltip>{example}</Tooltip>}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "55%",
                        margin: "4px 5%",
                      }}
                    >
                      <input
                        defaultValue={""}
                        type="text"
                        name="calendarName"
                        required={true}
                        minLength={2}
                        maxLength={50}
                        ref={register()}
                      />
                      <label>תיאור האירוע</label>
                    </div>
                  </OverlayTrigger>
                </FlexRowBetween>
              )}
              <FlexRowBetween style={{ width: "90%", marginRight: "5%" }}>
                <div style={{ position: "relative", maxWidth: "30%" }}>
                  <input
                    type="text"
                    name="hall"
                    required={true}
                    minLength={2}
                    maxLength={20}
                    ref={register()}
                  />
                  <label>שם אולם</label>
                </div>
                <div style={{ position: "relative", maxWidth: "30%" }}>
                  <input
                    type="text"
                    name="city"
                    required={true}
                    minLength={2}
                    maxLength={20}
                    ref={register()}
                  />
                  <label>עיר</label>
                </div>
                <div style={{ position: "relative", maxWidth: "30%" }}>
                  <input
                    type="text"
                    name="address"
                    required={true}
                    minLength={2}
                    maxLength={50}
                    ref={register()}
                  />
                  <label>כתובת</label>
                </div>
              </FlexRowBetween>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Yet as={Link} to={ClientRoutes.UnAuth.login}>
                  כבר רשומים?
                </Yet>
              </div>
              <div style={{ paddingBottom: "10px" }}>
                <GenericButton
                  hoverBackground={"#5c3e94"}
                  hoverColor={"#fff"}
                  type="submit"
                  style={{ width: "60%", margin: "20px auto" }}
                >
                  שליחה
                </GenericButton>
              </div>
            </InputBox>
          </CenteredForm>
        </M12R>
        <M11R style={{ backgroundImage: `url(${invBack})` }} />
      </M1>
      {showCalendar && (
        <GenericModal
          init={setShowCalendar}
          div={
            <GCalendar setDate={setDate} date={date} init={setShowCalendar} />
          }
        />
      )}
    </MainDiv>
  );
};

export default Register;
