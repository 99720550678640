import React, { useState } from 'react';

import { CenteredForm, PlaceHolderImage, BaseInput } from '../common/Style'

import exitModalLogo from '../../assets/common/cancel1.svg';

const ShareUs = (props) => {

    const [phone, setPhone] = useState()

    return (<CenteredForm onClick={(e) => e.stopPropagation()}>
        <PlaceHolderImage src={exitModalLogo} alt="exitModalLogo" style={{ top: "8px", left: "8px", transform: "translate(0,0)", width: "32px" }} onClick={() => props.init(null)} />

        <div style={{ marginTop: "40px", marginBottom: "30px" }}>הקלד מספר פלאפון</div>
        <div style={{ width: "60%", marginRight: "20%", marginBottom: "35px" }}>
            <BaseInput onChange={(e) => setPhone(e.target.value)} style={{ padding: "7px 5px" }} />
        </div>
        {/* <GenericButton style={{ background: "#00aaff", borderRadius: "0px", padding: "11px", fontSize: "20px" }}> */}
        <a target="_blank" rel="noreferrer" href={`https://wa.me/972${phone}/?text=https://b-s.fun${localStorage["logout"]}`}
            style={{ textDecoration: "none", color: "white", background: props.background, borderRadius: "0px", padding: "11px", fontSize: "20px" }}
        >שליחה</a>
        {/* </GenericButton> */}

    </CenteredForm >
    )
}

export default ShareUs;