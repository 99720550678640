import React from "react";
import errors from "../../../assets/print/errors1.svg";
import link from "../../../assets/print/link.svg";
import import1 from "../../../assets/print/import1.svg";
import excel from "../../../assets/print/excel1.svg";
import { AddGuestsOption, RespLogo } from "./Style";
import { FlexColumnBetween, OptionsFirst, RespFont } from "../../common/Style";
import l1 from "../../../assets/logo/l4.png";
import { postCsv } from "../../../store/reducers/dataReducer";
import readXlsxFile from "read-excel-file";
import { useSelector, useDispatch } from "react-redux";
import { useExcelDownloder } from "react-xls";

const AddGuests = ({ setSelfInv }) => {
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const { ExcelDownloder, Type } = useExcelDownloder();
  const { dataError } = useSelector((state) => state.dataReducer);
  const fileSelected = (event) => {
    readXlsxFile(event.target.files[0]).then((rows) => {
      // console.log(rows);
      dispatch(postCsv({ rows }));
    });
  };

  return (
    <OptionsFirst className="animate__animated animate__fadeIn">
      <a
        href="https://secret-mqr-upload.s3.eu-central-1.amazonaws.com/wedding/sys/%D7%AA%D7%91%D7%A0%D7%99%D7%AA.xlsx"
        style={{ textDecoration: "none" }}
        target="_blank"
        download
      >
        <AddGuestsOption>
          <RespFont style={{ color: "#3A3C3F" }}>תבנית</RespFont>
          <img src={excel} />
        </AddGuestsOption>
      </a>
      <label htmlFor="file-upload">
        <AddGuestsOption>
          <RespFont>טעינה</RespFont>
          <img src={import1} />
        </AddGuestsOption>
      </label>
      <input
        id="file-upload"
        type="file"
        onChange={fileSelected}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      {/* {user.selfInvitation && ( */}
      <AddGuestsOption onClick={() => setSelfInv(true)}>
        <RespFont>הפקת קישור עצמי</RespFont>
        <img src={link} />
      </AddGuestsOption>
      {/* )} */}

      {dataError?.kind === "csv" && (
        <ExcelDownloder
          data={{ שגיאות: dataError.error }}
          filename={"שגיאות אקסל"}
          type={Type.Button} // or type={'button'}
          style={{ border: "none", background: "transparent" }}
        >
          <AddGuestsOption className="animate__animated animate__fadeIn">
            <RespFont>שגיאות</RespFont>
            <img src={errors} />
          </AddGuestsOption>
          {/* <GenericButton className="animate__animated animate__fadeIn" background={"#f2f2f2"}>
                            <img src={errors} style={{ width: "25px", marginLeft: "10px" }} />
                            <RespFont style={{ color: "#3A3C3F" }}>
                                שגיאות
                            </RespFont>
                        </GenericButton> */}
        </ExcelDownloder>
      )}
    </OptionsFirst>
  );
};

export default AddGuests;
