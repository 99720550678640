import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CenteredForm, GenericDiv } from "../../../common/Style";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import mark from "../../../../assets/client/mark.svg";
import "./style.css";
const ACalendar = (props) => {
  return (
    <CenteredForm
      onClick={(e) => e.stopPropagation()}
      style={{ width: "90%", maxWidth: "500px" }}
    >
      <Calendar
        locale={"he"}
        onChange={props.setDate}
        value={props.date}
        style={{ paddingBottom: "50px" }}
        tileContent={({ date }) =>
          `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}` in
          props.dates ? (
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip>
                  {
                    props.dates[
                      `${date.getFullYear()}/${
                        date.getMonth() + 1
                      }/${date.getDate()}`
                    ]
                  }
                </Tooltip>
              }
            >
              <div
                style={{
                  position: "absolute",
                  bottom: "0px",
                  right: "0px",
                  width: "100%",
                  height: "100%",
                  border: "1px solid silver",
                  backgroundColor: "rgba(192, 192, 192, 0.363)",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: "2px",
                    right: "1px",
                  }}
                >
                  <img src={mark} alt="mark" style={{ width: "12px" }} />
                </div>
              </div>
            </OverlayTrigger>
          ) : null
        }
      />
      <GenericDiv
        hoverBackground={"rgb(223, 223, 223)"}
        background={"radial-gradient(circle, #3A3C3F 0%, #9a9a9c 100%)"}
        style={{
          borderRadius: "0px",
          width: "100%",
          fontSize: "16px",
          padding: "10px",
        }}
        onClick={() => {
          props.init(null);
        }}
      >
        אישור
      </GenericDiv>
    </CenteredForm>
  );
};
export default ACalendar;
