import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ClientRoutes from "./Routes";
import Daniel from "../components/daniel/Daniel";

const DanielSwitch = () => {
  return (
    <Switch>
      <Route
        path={ClientRoutes.Daniel.daniel}
        component={(props) => <Daniel {...props} />}
      />
      <Redirect from="/" to={ClientRoutes.Daniel.daniel} />
    </Switch>
  );
};

export default DanielSwitch;
