import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  details: null,
  detailsError: null,
  detailsLoading: false,
};

const _setDetails = (state, action) => {
  state.details = action.payload;
};

const _actionStart = (state) => {
  state.detailsLoading = true;
  state.detailsError = null;
};

const _actionSuccess = (state) => {
  state.detailsLoading = false;
  state.detailsError = null;
};

const _actionFailed = (state, action) => {
  state.detailsLoading = false;
  state.detailsError = action.payload.error;
};

const _clean = (state) => {
  state.details = null;
  state.detailsError = null;
  state.detailsLoading = false;
};

const invitation = createSlice({
  name: "invitation",
  initialState,
  reducers: {
    setDetails: _setDetails,
    actionStart: _actionStart,
    actionSuccess: _actionSuccess,
    actionFailed: _actionFailed,
    clean: _clean,
  },
});

const { reducer, actions } = invitation;

export const { setDetails, actionStart, actionSuccess, actionFailed, clean } =
  actions;

export default reducer;

// export const getDetails = (id) => {
//     return async (dispatch) => {
//         dispatch(actionStart())
//         const { data, error, status } = await get2(ServerRoutes.Client.getInvitationDetails + id);
//         if (status !== 200)
//             return dispatch(actionFailed({ error }));
//         else {
//             // setTimeout(() => {
//             dispatch(actionSuccess())
//             return dispatch(setDetails(data));
//             // }, 2000);
//         }

//     }
// };

export const cleanDetails = () => {
  return async (dispatch) => {
    return dispatch(clean());
  };
};
