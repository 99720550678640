import React from 'react'
import Switch from "react-switch";

const Switcher = ({ checked, onChange, color, disable = false }) =>
(<Switch
    width={34}
    height={14}
    onColor={'#b1b1b1'}
    offColor={'#b1b1b1'}
    onHandleColor={color}
    boxShadow={'0 2px 4px silver, 0 -1px 4px silver'}
    checkedIcon={false}
    uncheckedIcon={false}
    disabled={disable}
    checked={checked}
    onChange={() => onChange(!checked)}
    handleDiameter={20}
/>)

export default Switcher