import React from "react";
import minusB from "../../../../assets/invitation/minusB.svg";
import plusB from "../../../../assets/invitation/plusB.svg";
import { FlexRowBetween, GenericDiv } from "../../Style";
const SpecialDishes = ({
  setTiv,
  setTsim,
  setGlt,
  setGlat,
  tiv,
  tsim,
  glt,
  glat,
  handleSetDishes,
  showSubmit = true,
}) => {
  return (
    <FlexRowBetween
      style={{
        marginTop: "20px",
        justifyContent: "space-evenly",
        alignItems: "initial",
        fontSize: "14px",
      }}
    >
      <div>
        <FlexRowBetween>
          <div style={{ width: "max-content" }}>מספר מנות טבעוניות: </div>
          <FlexRowBetween style={{ width: "min-content", marginRight: "10px" }}>
            <img
              onClick={() => {
                if (tiv < 50) setTiv(tiv + 1);
              }}
              src={plusB}
              style={{ width: "20px" }}
            />
            <div style={{ margin: "0 10px" }}>{tiv}</div>
            <img
              onClick={() => {
                if (tiv > 0) setTiv(tiv - 1);
              }}
              src={minusB}
              style={{ width: "20px" }}
            />
          </FlexRowBetween>
        </FlexRowBetween>

        <FlexRowBetween style={{ marginTop: "5px" }}>
          <div style={{ width: "max-content" }}>מספר מנות צמחוניות: </div>
          <FlexRowBetween style={{ width: "min-content", marginRight: "10px" }}>
            <img
              onClick={() => {
                if (tsim < 50) setTsim(tsim + 1);
              }}
              src={plusB}
              style={{ width: "20px" }}
            />
            <div style={{ margin: "0 10px" }}>{tsim}</div>
            <img
              onClick={() => {
                if (tsim > 0) setTsim(tsim - 1);
              }}
              src={minusB}
              style={{ width: "20px" }}
            />
          </FlexRowBetween>
        </FlexRowBetween>

        <FlexRowBetween style={{ marginTop: "5px" }}>
          <div style={{ width: "max-content" }}>מספר מנות ללא גלוטן: </div>
          <FlexRowBetween style={{ width: "min-content", marginRight: "10px" }}>
            <img
              onClick={() => {
                if (glt < 50) setGlt(glt + 1);
              }}
              src={plusB}
              style={{ width: "20px" }}
            />
            <div style={{ margin: "0 10px" }}>{glt}</div>
            <img
              onClick={() => {
                if (glt > 0) setGlt(glt - 1);
              }}
              src={minusB}
              style={{ width: "20px" }}
            />
          </FlexRowBetween>
        </FlexRowBetween>

        <FlexRowBetween style={{ marginTop: "5px" }}>
          <div style={{ width: "max-content" }}>מספר מנות גלאט: </div>
          <FlexRowBetween style={{ width: "min-content", marginRight: "10px" }}>
            <img
              onClick={() => {
                if (glat < 50) setGlat(glat + 1);
              }}
              src={plusB}
              style={{ width: "20px" }}
            />
            <div style={{ margin: "0 10px" }}>{glat}</div>
            <img
              onClick={() => {
                if (glat > 0) setGlat(glat - 1);
              }}
              src={minusB}
              style={{ width: "20px" }}
            />
          </FlexRowBetween>
        </FlexRowBetween>
      </div>
      {showSubmit && (
        <GenericDiv onClick={handleSetDishes} style={{ padding: "2px 15px" }}>
          שליחה
        </GenericDiv>
      )}
    </FlexRowBetween>
  );
};

export default SpecialDishes;
