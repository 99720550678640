import React, { useState, useEffect, useMemo } from "react";
import { editTodoAdmin, getDaniel } from "../../store/reducers/todosReducer";
import { UseInterval } from "../common/others/UseInterval";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { GetToast } from "../common/modals/Toast";
import {
  ClosenessTitle2,
  FlexRowBetween,
  SomeRowStyle,
  ListHolder,
  RespFont,
  TextWithPic,
} from "../common/Style";
import GenericModal from "../common/modals/GenericModal";
import LogOutAll from "../common/LogOut/LogOutAll";
import OpacityLoading from "../common/loader/OpacityLoading";
import logout1 from "../../assets/client/logoutB.svg";
import Switch from "react-switch";
import CopyText from "../common/others/CopyText";
import refresh from "../../assets/client/refreshB.svg";

const Daniel = () => {
  const { todos, todosLoading } = useSelector((state) => state.todosReducer);
  const [logout, setLogout] = useState(false);

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    searchGA();
  }, []);

  //   UseInterval(async () => {
  //     searchGA();
  //   }, 60000);

  const searchGA = () => {
    let date = new Date();
    date.setHours(21);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    date.setDate(date.getDate() - 2);
    let start = date.toISOString();
    date.setDate(date.getDate() + 7);
    dispatch(getDaniel({ start, end: date.toISOString() })).then((res) => {
      if (!res.payload?.error)
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      else GetToast(addToast, "error", res.payload.error);
    });
  };

  const setDone = async (id, done, place, date) => {
    dispatch(editTodoAdmin(id, { done }, place, date)).then((res) => {
      if (!res.payload?.error)
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      else GetToast(addToast, "error", res.payload.error);
    });
  };

  let todosArray = useMemo(() => {
    let arr = [];
    if (Object.keys(todos).length > 0) {
      for (const [key, value] of Object.entries(todos)) {
        arr.push(
          <div style={{ width: "100%" }}>
            <ClosenessTitle2 color={"#fda14b"} key={key}>
              {value[0].executionDate
                .replace(/T.*/, "")
                .split("-")
                .reverse()
                .join("/")}
            </ClosenessTitle2>
          </div>
        );
        for (let i = 0; i < value.length; i++)
          arr.push(
            <SomeRowStyle
              key={value[i]._id}
              style={{ cursor: "auto", padding: "10px" }}
            >
              <FlexRowBetween>
                <div>{value[i].name}</div>
                <div>{value[i].title + " (" + value[i].count + ")"}</div>
              </FlexRowBetween>
              <FlexRowBetween style={{ marginTop: "10px" }}>
                <CopyText dataToCopy={value[i].pass} text={"סיסמא"} />
                <CopyText dataToCopy={value[i].phone} text={"נייד"} />
                <Switch
                  width={34}
                  height={14}
                  onColor={"#fda14b"}
                  offColor={"#b1b1b1"}
                  // onHandleColor={user.color}
                  boxShadow={"0 2px 4px silver, 0 -1px 4px silver"}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  checked={value[i].done}
                  onChange={() => setDone(value[i]._id, !value[i].done, i, key)}
                  handleDiameter={20}
                />
              </FlexRowBetween>
            </SomeRowStyle>
          );
      }
    }
    return arr;
  }, [todos]);

  return (
    <div>
      <FlexRowBetween>
        <TextWithPic onClick={() => setLogout(true)}>
          <RespFont style={{ width: "max-content" }}>התנתקות</RespFont>
          <img
            src={logout1}
            alt={"logout1"}
            style={{ width: "20px", marginRight: "20px" }}
          />
        </TextWithPic>

        <TextWithPic onClick={searchGA}>
          <RespFont style={{ width: "max-content" }}>סנכרון</RespFont>
          <img
            src={refresh}
            alt={"refresh"}
            style={{ width: "20px", marginRight: "20px" }}
          />
        </TextWithPic>
      </FlexRowBetween>
      <ListHolder>{todosArray}</ListHolder>

      {logout && (
        <GenericModal init={setLogout} div={<LogOutAll init={setLogout} />} />
      )}

      {todosLoading && <OpacityLoading />}
    </div>
  );
};

export default Daniel;
