import React from "react";
import { copyToClipBoard } from "../modals/Toast";
import copy from "../../../assets/common/copy.svg";
import { useToasts } from "react-toast-notifications";
import { RespFont } from "../Style";

const CopyText = ({ text, dataToCopy, width = "max-content" }) => {
  const { addToast } = useToasts();
  let widthS = window.innerWidth; // 1728

  return (
    <div
      style={{ width: width }}
      onClick={() => copyToClipBoard(dataToCopy, addToast)}
    >
      <div
        style={{
          padding: "7px 10px",
          borderRadius: "5px",
          border: "1px solid rgba(0, 0, 0, 0.11)",
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img
          alt={"copy"}
          src={copy}
          style={{
            width: widthS > 768 ? "20px" : "17px",
            marginLeft: "15px",
          }}
        />
        <RespFont cSize={"14px"} pSize={"12px"}>
          {text}
        </RespFont>
      </div>
    </div>
  );
};

export default CopyText;
