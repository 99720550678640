import React, { useState } from "react";
import { ServiceOption } from "../../Style";
import TitleAndContent from "../../../common/modals/TitleAndContent";
import GenericModal from "../../../common/modals/GenericModal";
import { FlexRowBetween } from "../../../common/Style";
import fire from "../fire.svg";

const s1 = (
  <div>
    <div>
      מערך כל המוזמנים מתעדכן על פי תשובות האורחים מחולקים לקבוצות על פי קירבה
    </div>
    <div>כמה אישרו מכל קבוצה וכמה הזמנתם</div>
    <div>את מי הזמנתם, כמה אישרו, כמה לא מגיעים, כמה לא ענו</div>
    <div>עריכה/הוספה/מחיקה</div>
  </div>
);
let sit = (
  <FlexRowBetween style={{ justifyContent: "center" }}>
    <img src={fire} style={{ width: "20px" }} />
    <div style={{ margin: " 0 7px" }}>הושבה דיגיטלית</div>
    <img src={fire} style={{ width: "20px" }} />
  </FlexRowBetween>
);

const services = [
  { title: "2 הודעות SMS", content: null }, // 1
  { title: "3 הודעות WHATSAPP", content: null }, // 1
  { title: "4 סבבי שיחות", content: null }, // 1
  { title: "הושבה דיגיטלית", content: null }, // 1
  {
    title: "תזכורת כמה ימים לפני החתונה לרענן לכולם את הזיכרון",
    content: null,
  }, // 1
  { title: "תזכורת בוקר האירוע", content: null }, // 1
  { title: "תזכורת נוספת ערב האירוע", content: null }, // 1
  { title: "הודעת תודה למחורת", content: null }, // 1
];
const services1 = [
  { title: "ניהול רשימת מוזמנים", content: s1 }, // 1
  {
    title: "הזמנה דיגיטלית",
    content:
      "אישור הגעה דיגיטלי מעוצב שישלח לאורחים ובו יוכלו להשיב את מספר המגיעים, לנווט לאולם, להוסיף ליומן ולהעניק מתנה באשראי",
  }, // 2
  {
    title: "הוספה ליומן",
    content:
      "כחלק מההזמנה הדיגיטלית יוכלו האורחים בלחיצת כפתור להוסיף את האירוע שלכם ליומן במכשיר שלהם",
  }, // 3
  {
    title: "ניווט לאירוע",
    content:
      "כחלק מההזמנה הדיגיטלית וגם בהודעת התזכורת בוקר האירוע יוכלו האורחים בלחיצת כפתור לנווט לאולם באמצעות אפליקציית waze",
  }, // 4
  {
    title: "מתנה באשראי",
    content:
      "כחלק מההזמנה הדיגיטלית וגם בהודעת התזכורת בוקר האירוע יוכלו האורחים להעניק מתנה באשראי",
  }, // 5
  {
    title: "ניהול משימות",
    content:
      "עמוד פשוט בו תוכלו להוסיף משימות, לשייך, לתת דרגת חשיבות ואף ליצור תזכורות",
  }, // 6
  {
    title: "ניהול הוצאות",
    content:
      "עמוד פשוט בו תוכלו להוסיף/לערוך את כל הוצאות האירוע, למיין ולחשב עלות מנה משוכללת",
  }, // 7
  { title: "ניהול הסעות", content: "ייצוא אקסל עם האורחים שנרשמו להסעה" }, // 8
  {
    title: "מילוי מתנות",
    content:
      "מצבת האורחים כבר במערכת, אין טעם לרשום את המתנות על דפים או ליצור אקסל חדש - בפשטות ובמהירות תוכלו למלא במערכת ולייצא אקסל להמשך החיים שתדעו איך להחזיר",
  }, // 9
  {
    title: "הפקת דוחות",
    content:
      "בכל רגע נתון תוכלו להפיק דוח אקסל על מצבת המוזמנים, מענה השיחות, הערות למיניהם",
  }, // 10
  {
    title: "3 סבבי הודעות",
    content:
      "3 סבבי הודעות כאשר כל הודעה תכלול את פרטי אירוע וקישור להזמנה הדיגיטלית שתוארה קודם, האורח יוכל גם להשיב להודעה זו והודעתו תיקלט במערכת",
  }, // 11
  {
    title: sit,
    content:
      "לאחר שימוש במערכת הנפלאה שלנו שתחסוך לכם זמן יקר בסידור השולחנות תוכלו לשלוח את מספר השולחן לאורחים יחד עם הודעת התזכורת וגם בערב האירוע לתזכר בשנית על מנת לשמור על הסדר",
  }, // 12
  {
    title: "פיצ׳ר לזיהוי קרחות LIVE",
    content:
      "במידה ותיקחו את שירות הדיילות, כל אורח שייכנס יסומן במערכת ולכן בכל רגע נתון נדע אם יש לנו מקומות פנויים בעקבות אורח שהגיע פחות ממה שאישר או אורח שלא הגיע בכלל ובכך נוכל לקחת אורחים משולחנות הרזרבה לאותם חורים בצורה מדוייקת ולמנוע פתיחת רזרבות שלא לצורך",
  }, // 13
  {
    title: "תזכורת בוקר האירוע",
    content:
      "שליחת הודעת תזכורת לכלל האורחים בוקר יום האירוע וצירוף קישורי הניווט והענקת המתנה באשראי",
  }, // 14
  {
    title: "הודעת תודה למחורת",
    content: "שליחת הודעת תודה לאורחים בוקר למחורת האירוע",
  }, // 15
  { title: "2 סבבי שיחות", content: "2 סבבי שיחות לאורחים שלא ענו להודעות" }, // 16
  {
    title: "שיחות ללא הגבלה",
    content: "שיחות ללא הגבלה לאורחים שלא ענו להודעות",
  }, // 17
  {
    title: "ווצאפ",
    content:
      "הביטחון שלכם שהגענו לכולם! אורח שלא עונה ל 3 הודעות, למספר שיחות טלפוניות, קיבל ווצאפ (מי לא רואה היום ווצאפ?) ועדיין לא השיב? אין לכם מה לקחת אותו בחשבון!",
  }, // 18
];
const Generic = ({ include, title, price }) => {
  const [recoredExplnation, setrecoredExplnation] = useState(false);

  const [service, setService] = useState(null);
  const servicesToShow = services.map((item, index) => {
    return (
      <ServiceOption
        include={include[index]}
        key={index}
        onClick={() => console.log("")}
      >
        {item.title}
      </ServiceOption>
    );
  });
  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{
        width: "350px",
        // height: "580px",
        borderRadius: "10px",
        border: "2px solid rgba(255,255,255,0.3)",
        boxShadow:
          "1px 3px 4px rgba(255,255,255,0.3), -1px -3px 4px rgba(255,255,255,0.3)",
      }}
    >
      <div
        style={{
          fontFamily: "openSansMedium",
          fontSize: "30px",
          textAlign: "center",
          padding: "10px 0",
          color: "rgb(67, 66, 66)",
          textShadow: "1px 1px silver",
        }}
      >
        {/* <div style={{ padding: "0" }}>{title}</div> */}
        <div style={{ fontSize: "20px", padding: "0" }}>{price}</div>
        <ServiceOption
          include={true}
          onClick={() => setrecoredExplnation(true)}
          style={{
            margin: "10px 5px",
            cursor: "pointer",
            color: "white",
            background:
              "linear-gradient(135deg, #302b63, rgba(68, 59, 149, 0.549))",
            boxShadow: "0 2px 12px 0 rgba(41, 99, 191, 0.5)",
            fontSize: "20px",
            width: "max-content",
            margin: "10px auto",
            padding: "10px 20px",
          }}
        >
          מה זה רשומה?
        </ServiceOption>
      </div>
      {/* <div style={{ height: "480px", overflow: "auto", overflowY: "scroll" }}> */}
      {servicesToShow}
      <div
        style={{
          width: "80%",
          margin: "10px auto",
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "center",
          fontFamily: "openSansRegular",
        }}
      >
        כל הודעות התזכורת כוללות ניווט לאולם ומתנה באשראי
      </div>
      {/* </div> */}
      {/* {service && (
        <GenericModal
          init={setService}
          div={
            <TitleAndContent
              title={service.title}
              content={service.content}
              init={setService}
            />
          }
        />
      )} */}
      {recoredExplnation && (
        <GenericModal
          init={setrecoredExplnation}
          div={
            <TitleAndContent
              title={"רשומה לעומת אורח"}
              content={
                <div>
                  <div>נניח שיש לנו דוד נשוי פלוס 2 ילדים בני 10,</div>
                  <div>
                    לכאורה מדובר ב 4 אורחים אך רק רשומה אחת מכיוון שהדוד עונה
                    עבור כולם.
                  </div>
                  <div>לכן, כמות הרשומות נמוכה בהרבה מכמות המוזמנים</div>
                  <div>
                    נקודה חשובה נוספת - התשלום הינו רק עבור רשומות שיש לוודא
                    איתן הגעה משמע רשומות כמו סבים, סבתות, משפחה גרעינית, מלווים
                    ובכלל אנשים שמבחינתכם מגיעים בוודאות ואין לשלוח להם הודעה
                    ולהתקשר תוכלו להוסיף למערכת ולא לשלם עליהם, עדיין יקבלו
                    הודעה תזכורת, מספר שולחן והודעת תודה.
                  </div>
                  <div>
                    רק אחרי שתטענו למערכת את כלל המוזמנים, המערכת תסכום את מספר
                    הרשומות יש לוודא לגביהן הגעה ועל פי מספר זה ייגבה תשלום
                  </div>
                </div>
              }
              init={setrecoredExplnation}
            />
          }
        />
      )}
    </div>
  );
};

export default Generic;
