import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { CenteredForm, GenericDiv } from "../common/Style";
import "../admin/aviv/calendar/style.css";
import GenericSvg from "../common/coloredSvgs/GenericSvg";
import { svgs } from "../common/coloredSvgs/svgs";
import { addAlpha } from "../common/modals/Toast";
const HallCalendar = ({
  setDate,
  date,
  init,
  background = "#fda14b",
  dates = [],
  color = "silver",
}) => {
  return (
    <CenteredForm onClick={(e) => e.stopPropagation()}>
      <Calendar
        locale={"he"}
        onChange={setDate}
        value={date}
        style={{ paddingBottom: "20px" }}
        tileContent={({ date }) =>
          `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}` in
          dates ? (
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip>
                  {
                    dates[
                      `${date.getFullYear()}/${
                        date.getMonth() + 1
                      }/${date.getDate()}`
                    ]
                  }
                </Tooltip>
              }
            >
              <div
                style={{
                  position: "absolute",
                  bottom: "0px",
                  right: "0px",
                  width: "100%",
                  height: "100%",
                  background: addAlpha(color, 0.1),
                  border: `1px solid ${color}`,
                  padding: "2px 4px",
                  borderRadius: "5px",
                }}
              ></div>
            </OverlayTrigger>
          ) : null
        }
      />
      <GenericDiv
        hoverBackground={"rgb(223, 223, 223)"}
        background={background}
        style={{
          borderRadius: "0px",
          width: "100%",
          fontSize: "16px",
          padding: "10px",
        }}
        onClick={() => {
          init(null);
        }}
      >
        סגירה
      </GenericDiv>
    </CenteredForm>
  );
};
{
  /* <GenericSvg fill={color} size={"27px"} svg={svgs.cancel} viewBox={"0 0 512 512"} /> */
}
export default HallCalendar;
