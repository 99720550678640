import { createSlice } from "@reduxjs/toolkit";
import ServerRoutes from "../api/routes";
import { post2 } from "../api/http";

const initialState = {
  messages: [],
  messagesError: null,
  messagesLoading: false,
};

const _actionStart = (state) => {
  state.messagesLoading = true;
  state.messagesError = null;
};
const _actionSuccess = (state) => {
  state.messagesLoading = false;
  state.messagesError = null;
};
const _actionFailed = (state, action) => {
  state.messagesLoading = false;
  state.messagesError = action.payload;
};

const _setMessages = (state, action) => {
  let tempMessages = action.payload;
  state.messages = [...tempMessages];
  state.changeMessages = !state.changeMessages;
};

const _cleanMessages = (state, action) => {
  state.messages = [];
  state.messagesError = null;
  state.messagesLoading = false;
};

const messages = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessages: _setMessages,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    actionSuccess: _actionSuccess,
    cleanMessages: _cleanMessages,
  },
});

const { reducer, actions } = messages;

export const {
  setMessages,
  actionStart,
  actionFailed,
  actionSuccess,
  cleanMessages,
} = actions;

export default reducer;

export const cleanMessages1 = () => {
  return async (dispatch) => {
    dispatch(cleanMessages());
  };
};

export const getMessages = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Messages.getMessages,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setMessages(data));
    }
  };
};
