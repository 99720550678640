import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import MenuItem from "@mui/material/MenuItem";
import { useToasts } from "react-toast-notifications";
import {
  getGuestsWithFilter,
  cleanAll,
  setGuestsTable,
  deleteGuest,
  getGuest,
  editGuestByIdReduxOnly,
  setGuestsInvitingAndOwc,
  setGuestsCloseness,
  setGuestsWasInvited,
} from "../../store/reducers/guestsReducer";
import Loading from "../common/modals/Loading";
import { GetToast } from "../common/modals/Toast";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { slide as Menu } from "react-burger-menu";
import {
  ClosenessTitle2,
  GuestsHolder,
  FlexRowBetween,
  BaseInput,
  OverlayDiv,
  MainDiv,
  InputBox,
  PlaceHolderImage,
  GenericDiv,
  FlexColumnBetween,
} from "../common/Style";
import GenericModal from "../common/modals/GenericModal";
import EditGuest from "./EditGuest";
import palette from "../../assets/client/palette.svg";
import checklist2 from "../../assets/client/checklist2.svg";
import callLogo from "../../assets/client/call1.svg";

import ix from "../../assets/client/ix.svg";
import vi from "../../assets/client/vi.svg";
import extension from "../../assets/client/extension2.svg";
import moveTable from "../../assets/client/table2.svg";
import weddingInvitation from "../../assets/client/weddingInvitation.svg";
import weddingInvitationWhite from "../../assets/client/weddingInvitationWhite.svg";
import default1 from "../../assets/client/default.svg";
import guests11 from "../../assets/client/guests1.svg";
import noPhone from "../../assets/client/noPhoneWhite.svg";
import expenses from "../../assets/client/expenses.svg";
import brain from "../../assets/client/brain.svg";
import duplicate from "../../assets/client/duplicate.svg";
import creditCard from "../../assets/client/creditCard.svg";
import attraction1 from "../../assets/client/attraction2.svg";
import logout1 from "../../assets/client/logout.svg";
import present from "../../assets/client/presentWhite.svg";
import editWhite from "../../assets/client/editWhite.svg";
import editTables from "../../assets/client/tableWhite.svg";
import addUser from "../../assets/client/addUser.svg";
import contacts from "../../assets/client/contacts.svg";
import tables from "../../assets/client/editTables.svg";
import clear from "../../assets/client/clear.svg";
import ClientRoutes from "../../navigation/Routes";
import YesNoModal from "../common/modals/YesNoModal";
import DropDown from "../common/others/DropDown";
import { useHistory } from "react-router-dom";
import "./style.css";
import LogOutAll from "../common/LogOut/LogOutAll";
import EditDetails from "./EditDetails";
import { getUpdatedTables } from "../../store/reducers/authReducer";
import delete1 from "../../assets/client/deleteBlack.svg";
import { cleanAllData, findDuplicates } from "../../store/reducers/dataReducer";
import DuplicatesModal from "../common/modals/DuplicatesModal";
import { MenuCss } from "../common/modals/Toast";
import EditGuestsSms from "./EditGuestsSms";
import AddGuests from "./guests/AddGuests";
import { svgs } from "../common/coloredSvgs/svgs";
import GenericSvg from "../common/coloredSvgs/GenericSvg";
import EditGuestsCloseness from "./EditGuestsCloseness";
import SketchColorPalette from "../common/sketchColor/SketchColorPalette";
import GuestRowNew from "./GuestRowNew";
import AddGuestsFromC from "./guests/AddGuestsFromC";

let phones = {};
let multyChoise = {};
let multyChoiseCounter = 0;
const Guests = () => {
  let history = useHistory();
  const [closenessChosenFlag, setClosenessChosenFlag] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState(0);
  const [showDuplicate, setShowDuplicate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [giftMode, setGiftMode] = useState(false);
  const [mode, setMode] = useState(1); // 0 - calls, 1 - default, 2 - gifts, 3 - tables, 4 - wasInvited?
  const [editTablesMode, setEditTablesMode] = useState(false);
  const [flag, setFlag] = useState(false);
  const [edit, setEdit] = useState(null);
  const [editInvitingAndOwc, setEditInvitingAndOwc] = useState(null);
  const [editCloseness, setEditCloseness] = useState(null);
  const [logout, setLogout] = useState(false);
  const [askForDelete, setAskForDelete] = useState(false);
  const [guestToDelete, setDeleteGuest] = useState(null);
  const [table, setTable] = useState();
  const [multiSelect, setMultiSelect] = useState(false);
  const { guest, guestsError, guestsLoading } = useSelector(
    (state) => state.guestsReducer
  );
  const { guests } = useSelector((state) => state.guestsReducer);
  const { user } = useSelector((state) => state.authReducer);
  const [showAddGuestNew, setShowAddGuestNew] = useState(false);
  const [showAddGuestOptions, setShowAddGuestOptions] = useState(false);
  const [showAddGuestOptionsTooltip, setShowAddGuestOptionsTooltip] =
    useState(false);
  const [showAddGuest, setShowAddGuest] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [editDetails, setEditDetails] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const width = window.screen.availWidth;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  const getAll = () => dispatch(getGuestsWithFilter("getAll"));

  const selfInit = () => {
    setShowAddGuest(false);
    setShowAddGuestNew(false);
    getAll();
  };

  useEffect(() => {
    dispatch(getUpdatedTables());
  }, []);

  useEffect(() => {
    getAll();
    return () => {
      dispatch(cleanAll());
      multyChoise = {};
      multyChoiseCounter = 0;
      setSelected(0);
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(cleanAllData());
    };
  }, []);

  useEffect(() => {
    if (guestsError) GetToast(addToast, "error", guestsError);
  }, [guestsError]);

  const deleteGuest1 = (arr = null) => {
    dispatch(deleteGuest(arr !== null ? arr : guestToDelete)).then(
      (data) => {
        // setDeleteMode(false)
        setMultiSelect(false);
        if (!data.payload.error)
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
        setDeleteGuest(null);
        multyChoise = {};
        multyChoiseCounter = 0;
        setSelected(0);
        setEdit(null);
        setFlag(!flag);
      },
      (error) => {
        GetToast(addToast, "error", error);
        // setDeleteMode(false)
        setMultiSelect(false);
      }
    );
    phones = {};
  };

  const getOne = (id) => {
    dispatch(getGuest(id)).then(
      () => {
        setEdit(true);
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };

  const getGuestsWithFilter1 = (filter) =>
    dispatch(getGuestsWithFilter(filter));

  const editGuestReduxOnly = () => {
    dispatch(
      editGuestByIdReduxOnly(
        {
          name: guest.name,
          preSMS: guest.preSMS,
          closeness: guest.closeness,
          sum: parseInt(guest.sum),
          accept: parseInt(guest.accept) >= 0 ? parseInt(guest.accept) : null,
          table: parseInt(guest.table) >= 0 ? parseInt(guest.table) : null,
          gift: parseInt(guest.gift) >= 0 ? parseInt(guest.gift) : null,
          phone: guest.phone,
          notes: guest.notes,
          sent: guest.sent,
        },
        guest._id
      )
    ).then(
      () => {
        setEdit(false);
      },
      (error) => {
        GetToast(addToast, "error", error);
        setEdit(false);
      }
    );
  };

  const setGuestsInvitingAndOwc11 = (update) => {
    dispatch(
      setGuestsInvitingAndOwc(multyChoise, update.inviting, update.owc)
    ).then(
      (data) => {
        GetToast(addToast, "success", "הפעולה הצליחה");
        setMultiSelect(false);
        multyChoise = {};
        multyChoiseCounter = 0;
        setSelected(0);
        setEditInvitingAndOwc(false);
        setFlag(!flag);
      },
      (error) => GetToast(addToast, "error", error)
    );
  };

  const setGuestsCloseness11 = (closeness) => {
    dispatch(setGuestsCloseness(multyChoise, closeness)).then(
      (data) => {
        GetToast(addToast, "success", "הפעולה הצליחה");
        setMultiSelect(false);
        multyChoise = {};
        multyChoiseCounter = 0;
        setSelected(0);
        setEditCloseness(false);
        setFlag(!flag);
        getAll();
      },
      (error) => GetToast(addToast, "error", error)
    );
  };

  const setWasInvited = (data) => {
    dispatch(setGuestsWasInvited(multyChoise, data)).then(
      (data) => {
        GetToast(addToast, "success", "הפעולה הצליחה");
        setMultiSelect(false);
        multyChoise = {};
        multyChoiseCounter = 0;
        setSelected(0);
        // setEditCloseness(false);
        // setFlag(!flag);
        // getAll();
      },
      (error) => GetToast(addToast, "error", error)
    );
  };

  const updateGuest = async (items, table1, empty = false) => {
    dispatch(setGuestsTable(items, empty ? null : table1)).then(
      (data) => {
        GetToast(addToast, "success", "הפעולה הצליחה");
        setMultiSelect(false);
        setTable();
        multyChoise = {};
        multyChoiseCounter = 0;
        setSelected(0);
        setFlag(!flag);
      },
      (error) => GetToast(addToast, "error", error)
    );
  };
  const deleteGuests11 = async () => {
    if (Object.keys(multyChoise).length > 0) {
      let arr = [];
      for (const [key, value] of Object.entries(multyChoise)) arr.push(value);
      deleteGuest1(arr);
    } else alert("לא נבחרו אורחים");
  };

  const setGuestsTable11 = async (items, table) => {
    if (Object.keys(items).length > 0) {
      if (user.array[parseInt(table) - 1] !== undefined)
        await updateGuest(items, parseInt(table));
      else if (parseInt(table) === 0) await updateGuest(items, null, true);
      else alert("שולחן לא תקין או לא קיים");
    } else alert("לא נבחרו אורחים");
  };
  const addOrDeleteAllCloseness = (closeness) => {
    let guestsT = guests[closeness] !== undefined ? guests[closeness] : {};
    for (const [key, value] of Object.entries(guestsT))
      add_del({
        _id: value._id,
        closeness: value.closeness,
        amount: parseInt(value.accept) >= 0 ? value.accept : value.sum,
      });
  };

  const add_del = (item) => {
    if (multyChoise[item._id] !== undefined) {
      delete multyChoise[item._id];
      multyChoiseCounter -= item.amount;
    } else {
      multyChoise[item._id] = { _id: item._id, closeness: item.closeness };
      multyChoiseCounter += item.amount;
    }
    setSelected(multyChoiseCounter);
    setClosenessChosenFlag(!closenessChosenFlag);
  };

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  let guestsArray = useMemo(() => {
    let status = {
      1: [0, "עוד לא הגיבו", "#f2f2f2", "getNotAnsweredUser"],
      2: [0, "מגיעים ועוד לא הושבת", "#f0d4ae", "getAcceptedAndNotSitUser"],
      3: [0, "מגיעים והושבת", "#d7f0ae", "getAcceptedAndSitUser"],
      4: [0, "לא מגיעים", "#f0aeae", "getDontArriveUser"],
    };
    const getBackground = (accept, table, sum) => {
      if (accept > 0 && table > 0) {
        status[3][0] += accept;
        if (accept < sum) status[4][0] += sum - accept;
        return status[3][2];
      } else if (accept === 0) {
        status[4][0] += sum;
        return status[4][2];
      } else if (accept > 0 && !(table > 0)) {
        status[2][0] += accept;
        if (accept < sum) status[4][0] += sum - accept;
        return status[2][2];
      } else {
        status[1][0] += parseInt(sum);
        return status[1][2];
      }
    };
    let checked = false;
    let totalCount = 0;
    let categoryCount = 0;
    let categoryAccept = 0;
    let categoryCountArr = [];
    let categoryAcceptArr = [];
    let array = [];
    let tags = [];

    const insert = (side, data) => {
      tags.push(side);
      array.push(data);
    };

    if (Object.keys(guests).length > 0) {
      // אם קיימים אורחים
      for (const [category_key, category_value] of Object.entries(guests)) {
        // עבור כל קטגוריה
        if (Object.keys(category_key).length > 0) {
          // אם קיימים אורחים בקטגוריה זו
          // if (category_key.toLowerCase().includes(searchText)) {
          if (searchText.length === 0) {
            categoryCountArr.push(categoryCount); // דחיפת סך כל מוזמני הקטגוריה למערך
            categoryAcceptArr.push(categoryAccept); // דחיפת סך כל מאשרי הקטגוריה למערך
            categoryCount = 0; // איפוס מונה מוזמנים מאותה קטגוריה
            categoryAccept = 0; // איפוס מונה מאשרים מאותה קטגוריה
            // insert(category_key, <ClosenessTitle key={category_key} marginTop={"30px"} color={user.color} id={category_key} ><div style={{ zIndex: "100" }} onClick={() => console.log("ggg")}><div>{category_key}</div></div></ClosenessTitle>) // הכנסת קטגוריה למערך
            insert(
              category_key,
              <div style={{ width: "100%" }}>
                <ClosenessTitle2
                  pointer={multiSelect}
                  color={user.color}
                  id={category_key}
                  key={category_key}
                  onClick={() => {
                    if (multiSelect) addOrDeleteAllCloseness(category_key);
                  }}
                >
                  {category_key}
                </ClosenessTitle2>
              </div>
            ); // הכנסת קטגוריה למערך
          }
          for (const [guest_key, guest_value] of Object.entries(
            category_value
          )) {
            if (phones[guest_value.phone] === undefined)
              phones[guest_value.phone] = true;
            // if (guest_value.name.toLowerCase().includes(searchText) || guest_value.phone?.includes(searchText)) {
            if (
              searchText
                .toLowerCase()
                .split(" ")
                .filter((i) =>
                  guest_value.name?.toLowerCase().includes(i.toLowerCase())
                ).length === searchText.toLowerCase().split(" ").length ||
              guest_value.phone?.includes(searchText)
            ) {
              totalCount += parseInt(guest_value.sum);
              categoryCount += parseInt(guest_value.sum);
              guest_value.accept
                ? (categoryAccept += parseInt(guest_value.accept))
                : (categoryAccept += 0);
              array.push(
                <GuestRowNew
                  checked={multyChoise[guest_key] !== undefined}
                  key={guest_key}
                  wasInvitedMode={mode === 4}
                  callMode={mode === 0}
                  giftMode={mode === 2}
                  editTablesMode={mode === 3}
                  background={getBackground(
                    guest_value.accept,
                    guest_value.table,
                    guest_value.sum
                  )}
                  multiSelect={multiSelect}
                  add_del={add_del}
                  setEdit={getOne}
                  data={guest_value}
                />
              );
            }
          }
        }
      }
      categoryCountArr.push(categoryCount); // דחיפת סך כל מוזמני הקטגוריה האחרונה למערך
      categoryAcceptArr.push(categoryAccept); // דחיפת סך כל מאשרי הקטגוריה האחרונה למערך
    }
    let categoriesKeys = [];
    Object.keys(guests).map((item, index) => {
      categoriesKeys.push({ id: item, name: item });
    });
    let choose = tags.map((item, index) => {
      return (
        <MenuItem key={index} style={{ textAlign: "right", direction: "rtl" }}>
          <HashLink
            style={{ color: "#3A3C3F", textDecoration: "none" }}
            smooth
            to={"/guests#" + item}
          >
            {item +
              " " +
              categoryAcceptArr[index + 1] +
              "/" +
              categoryCountArr[index + 1]}
          </HashLink>
        </MenuItem>
      );
    });

    setCategories(categoriesKeys);
    // let choose = tags.map((item, index) => {
    //     return <Dropdown.Item key={index} style={{ textAlign: "right", direction: "rtl" }}><HashLink style={{ color: "#3A3C3F", textDecoration: "none" }} smooth to={"/guests#" + item}>
    //         {item + " " + categoryAcceptArr[index + 1] + "/" + categoryCountArr[index + 1]}</HashLink></Dropdown.Item>
    // })

    let arr = [
      <OverlayTrigger
        key={0}
        placement={"bottom"}
        overlay={<Tooltip>{"סך הכל"}</Tooltip>}
      >
        <OverlayDiv
          onClick={() => getGuestsWithFilter1("getAll")}
          style={{ color: "white", background: user.color }}
        >
          {totalCount}
        </OverlayDiv>
      </OverlayTrigger>,
    ];
    for (const [key, value] of Object.entries(status)) {
      arr.push(
        <OverlayTrigger
          key={(key + 1) * 1000}
          placement={"bottom"}
          overlay={<Tooltip>{value[1]}</Tooltip>}
        >
          <OverlayDiv
            onClick={() => getGuestsWithFilter1(value[3])}
            style={{
              background: value[2],
              boxShadow:
                parseInt(key) === 1
                  ? `2px 2px 7px silver, -2px 0 7px silver`
                  : null,
            }}
          >
            {value[0]}
          </OverlayDiv>
        </OverlayTrigger>
      );
    }

    return [array, choose, arr];
  }, [
    guests,
    multiSelect,
    searchText,
    mode,
    closenessChosenFlag,
    flag,
    // editTablesMode,
    user.color,
  ]);
  let widthS = window.innerWidth; // 1728

  const findDuplicates1 = () => {
    dispatch(findDuplicates({ uid: user._id })).then((data) => {
      if (data.payload.length > 0) setShowDuplicate(data.payload);
    });
  };

  const handleOverTooltip = () => {
    setShowAddGuestOptionsTooltip(true);
    setTimeout(function () {
      setShowAddGuestOptionsTooltip(false);
    }, 1000);
  };

  return (
    <MainDiv
      onClick={() => setIsOpen(false)}
      className="animate__animated animate__fadeIn"
      style={{
        overflow: "hidden",
        background: `radial-gradient(circle, ${addAlpha(
          user.color,
          0.25
        )} 0%, #f2f2f2 100%)`,
        maxHeight: "100vh",
        // , backgroundImage: `url(${backSvg})`
      }}
    >
      {/* <UserMenu isOpen={isOpen} setIsOpen={setIsOpen} include={[true, false]} color={user.color} /> */}
      <Menu
        noOverlay
        isOpen={isOpen}
        onStateChange={handleStateChange}
        right
        width={"max-content"}
        styles={MenuCss(user.color)}
      >
        {/* <GradientText className="menu-item" style={{
                    display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "logo",
                    background: "#fff", borderRadius: "10px", cursor: "auto", width: "max-content", padding: "13px 13px",
                    position: "absolute", right: "20px", top: "10px"
                }}>B.S</GradientText> */}

        <div
          className="menu-item"
          style={{ marginTop: "0px" }}
          onClick={() => history.push(ClientRoutes.User.tables)}
        >
          <div>מערך שולחנות</div>
          <img src={tables} style={{ width: "25px" }} />
        </div>
        {/* <div className="menu-item" onClick={() => setShowAddGuest(true)}>
          <div>הוספת אורחים ידני</div>
          <img src={addUser} style={{ width: "25px" }} />
        </div>
        <div className="menu-item" onClick={() => setShowAddGuestNew(true)}>
          <div>הוספת אורחים מהנייד</div>
          <img src={contacts} style={{ width: "30px" }} />
        </div> */}
        <FlexRowBetween style={{ flexDirection: "column" }}>
          <FlexRowBetween
            style={{ justifyContent: "center" }}
            onClick={(e) => {
              e.stopPropagation();
              setMultiSelect(false);
              multyChoise = {};
              multyChoiseCounter = 0;
              setSelected(0);
            }}
          >
            מצב:{" "}
            {mode === 0
              ? "שיחות"
              : mode === 1
              ? "רגיל"
              : mode === 2
              ? "מתנות"
              : mode === 3
              ? "שולחנות"
              : "הזמנתי?"}
          </FlexRowBetween>
          <FlexRowBetween
            // className="menu-item"
            style={{ width: "100%", marginTop: "10px" }}
            onClick={() => {
              setMultiSelect(false);
              multyChoise = {};
              multyChoiseCounter = 0;
              setSelected(0);
              // if (mode < 4) setMode(mode + 1);
              // else setMode(1);
              // setGiftMode(!giftMode);
              // setEditTablesMode(false);
            }}
          >
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip>{"רגיל"}</Tooltip>}
            >
              <img
                onClick={() => setMode(1)}
                src={default1}
                alt={"default1"}
                style={{
                  width: "30px",
                  marginLeft: "7px",
                  marginBottom: "-5px",
                  cursor: "pointer",
                }}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip>{"שיחות"}</Tooltip>}
            >
              <img
                onClick={() => setMode(0)}
                src={callLogo}
                alt={"callLogo"}
                style={{
                  width: "25px",
                  marginLeft: "15px",
                  marginBottom: "-5px",
                  cursor: "pointer",
                }}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip>{"עריכת מתנות"}</Tooltip>}
            >
              <img
                onClick={() => setMode(2)}
                src={present}
                alt={"present"}
                style={{
                  width: "30px",
                  marginLeft: "15px",
                  marginRight: "-8px",
                  cursor: "pointer",
                }}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip>{"עריכת שולחנות"}</Tooltip>}
            >
              <img
                onClick={() => setMode(3)}
                src={editTables}
                alt={"editTables"}
                style={{
                  width: "25px",
                  marginLeft: "13px",
                  marginRight: "-5px",
                  marginBottom: "-5px",
                  cursor: "pointer",
                }}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip>{"״הזמנתי?״"}</Tooltip>}
            >
              <img
                onClick={() => setMode(4)}
                src={weddingInvitationWhite}
                alt={"weddingInvitationWhite"}
                style={{ width: "25px", cursor: "pointer" }}
              />
            </OverlayTrigger>
          </FlexRowBetween>
        </FlexRowBetween>
        {/* <div
          className="menu-item"
          onClick={() => {
            setEditTablesMode(!editTablesMode);
            setGiftMode(false);
          }}
        >
          <div>עריכת שולחנות</div>
          <img src={editTables} style={{ width: "24px" }} />
        </div> */}
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.tools)}
        >
          <div>כלים נוספים</div>
          <img src={extension} style={{ width: "28px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.print)}
        >
          <div>מוח המערכת</div>
          <img src={brain} style={{ width: "25px" }} />
        </div>
        <div className="menu-item" onClick={() => findDuplicates1()}>
          <div>כפולים</div>
          <img src={duplicate} style={{ width: "28px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => getGuestsWithFilter1("noNumber")}
        >
          <div>ללא מספר</div>
          <img src={noPhone} style={{ width: "24px" }} />
        </div>
        {/* <div
          className="menu-item-black"
          style={{
            backgroundColor: "#f2f2f2",
            backgroundColor: "rgba(255,255,255,0.9)",
            color: "#3A3C3F",
            padding: "5px 5px",
            marginRight: "-5px",
            borderRadius: "5px",
          }}
          onClick={() => history.push(ClientRoutes.User.tools)}
        >
          <div>אטרקציות</div>
          <img src={attraction1} style={{ width: "45px" }} />
        </div> */}
        <div
          className="menu-item-black"
          style={{
            backgroundColor: "#f2f2f2",
            backgroundColor: "rgba(255,255,255,0.9)",
            color: "#3A3C3F",
            padding: "5px 5px",
            marginRight: "-5px",
            borderRadius: "5px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            // setDisplayColorPicker(!displayColorPicker);
          }}
        >
          {/* <div>צבע המערכת</div> */}
          <SketchColorPalette
            change={"color"}
            display={displayColorPicker}
            onClose={setDisplayColorPicker}
            setFlag={setFlag}
            flag={flag}
            div={
              <FlexRowBetween
                style={{ width: "100%" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setDisplayColorPicker(!displayColorPicker);
                }}
              >
                <div>צבע המערכת</div>
                <img
                  src={palette}
                  alt="palette"
                  style={{
                    width: "33px",
                    cursor: "pointer",
                    marginBottom: "-3px",
                  }}
                />
              </FlexRowBetween>
            }
          />
          {/* <img src={attraction1} style={{ width: "45px" }} /> */}
        </div>
        <div
          className="menu-item"
          style={{
            position: "absolute",
            bottom: "20px",
            fontWeight: "bold",
            fontSize: "20px",
          }}
          onClick={() => setLogout(true)}
        >
          <div>התנתקות</div>
          <img src={logout1} style={{ width: "25px" }} />
        </div>
      </Menu>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "flex-start",
          marginTop: "20px",
          direction: "ltr",
          marginLeft: "33px",
          alignItems: "center",
        }}
      >
        {multiSelect ? (
          <>
            {mode === 4 && (
              <>
                <GenericDiv
                  onClick={() => setWasInvited(false)}
                  background={"rgba(255,255,255,0.7)"}
                  style={{
                    color: "black",
                    marginRight: "10px",
                    padding: "3px 7px",
                  }}
                >
                  <img
                    src={ix}
                    alt={"ix"}
                    style={{
                      marginRight: "10px",
                      width: "18px",
                      padding: "4.7px 0",
                    }}
                  />
                  לא הזמנתי
                </GenericDiv>
                <GenericDiv
                  onClick={() => setWasInvited(true)}
                  background={"rgba(255,255,255,0.7)"}
                  style={{ color: "black", padding: "3px 7px" }}
                >
                  <img
                    src={vi}
                    alt={"vi"}
                    style={{ marginRight: "10px", width: "28px" }}
                  />
                  הזמנתי
                </GenericDiv>
              </>
            )}
            {(mode === 1 || mode === 3) && (
              <>
                <BaseInput
                  color={user.color}
                  style={{
                    height: "max-content",
                    width: "max-content",
                    textAlign: "left",
                    paddingRight: "0px",
                    padding: "2px 1px",
                  }}
                  type="number"
                  min="1"
                  max="100"
                  onChange={(e) => setTable(e.target.value)}
                  className="animate__animated animate__backInDown"
                />
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={<Tooltip>{"הושבה"}</Tooltip>}
                >
                  <img
                    className="animate__animated animate__backInDown"
                    src={moveTable}
                    alt="moveTable"
                    style={{
                      cursor: "pointer",
                      width: "25px",
                      marginLeft: "10px",
                    }}
                    onClick={() => setGuestsTable11(multyChoise, table)}
                  />
                </OverlayTrigger>
              </>
            )}
            {mode === 1 && (
              <>
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={<Tooltip>{"מחיקה"}</Tooltip>}
                >
                  <img
                    className="animate__animated animate__backInDown"
                    src={delete1}
                    alt="delete1"
                    style={{
                      cursor: "pointer",
                      width: "25px",
                      marginLeft: "12px",
                    }}
                    onClick={() => {
                      if (Object.keys(multyChoise).length > 0)
                        setAskForDelete(!askForDelete);
                      else alert("לא נבחרו אורחים");
                    }}
                  />
                </OverlayTrigger>
                {user.eventType && (
                  <OverlayTrigger
                    placement={"bottom"}
                    overlay={<Tooltip>{"עריכת מזמין + קשר"}</Tooltip>}
                  >
                    <img
                      className="animate__animated animate__backInDown"
                      src={weddingInvitation}
                      alt="weddingInvitation"
                      style={{
                        cursor: "pointer",
                        width: "25px",
                        marginLeft: "10px",
                      }}
                      onClick={() => {
                        if (Object.keys(multyChoise).length > 0)
                          setEditInvitingAndOwc(!editInvitingAndOwc);
                        else alert("לא נבחרו אורחים");
                      }}
                    />
                  </OverlayTrigger>
                )}
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={<Tooltip>{"עריכת קירבה (קטגוריה)"}</Tooltip>}
                >
                  <img
                    className="animate__animated animate__backInDown"
                    src={guests11}
                    alt="guests11"
                    style={{
                      cursor: "pointer",
                      width: "38px",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      if (Object.keys(multyChoise).length > 0)
                        setEditCloseness(!editCloseness);
                      else alert("לא נבחרו אורחים");
                    }}
                  />
                </OverlayTrigger>
              </>
            )}
          </>
        ) : (
          guestsArray[2]
        )}
        {mode !== 2 && (
          <OverlayTrigger
            placement={"bottom"}
            overlay={
              <Tooltip>
                {multiSelect ? "ביטול בחירה מרובה" : "בחירה מרובה"}
              </Tooltip>
            }
          >
            <img
              onClick={() => {
                setMultiSelect(!multiSelect);
                multyChoise = {};
                multyChoiseCounter = 0;
                setSelected(0);
              }}
              src={checklist2}
              alt="checklist2"
              style={{ width: "30px", marginLeft: "15px", cursor: "pointer" }}
            />
          </OverlayTrigger>
        )}
        <OverlayTrigger
          key={0}
          placement={"bottom"}
          overlay={<Tooltip>{"נבחרו"}</Tooltip>}
        >
          <div
            style={{ marginLeft: "15px" }}
            className="animate__animated animate__fadeIn"
          >
            {selected > 0 && selected}
          </div>
        </OverlayTrigger>
      </div>

      <FlexRowBetween
        style={{ width: "90%", margin: "10px auto", alignItems: "flex-end" }}
      >
        <InputBox
          labelColor={"gray"}
          color={"rgba(0,0,0,0.6)"}
          style={{ width: "100px", margin: "0px" }}
        >
          <div style={{ position: "relative", width: "100px" }}>
            <PlaceHolderImage
              onClick={() => setSearchText("")}
              fontSize={`22px`}
              top={`34.5px`}
              src={clear}
              alt="clear"
              style={{
                cursor: "pointer",
                border: "1px solid silver",
                padding: "4px",
                borderRadius: "2px",
              }}
            />
            <input
              value={searchText}
              type="text"
              required={true}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <label>חיפוש</label>
          </div>
        </InputBox>
        <div
          style={{
            width: "min-content",
            position: "relative",
            cursor: "pointer",
          }}
          onMouseEnter={handleOverTooltip}
          onClick={() => setShowAddGuestOptions(!showAddGuestOptions)}
        >
          {showAddGuestOptionsTooltip && (
            <GenericDiv
              style={{ position: "absolute", bottom: "120%", right: "-90%" }}
            >
              הוספת אורחים
            </GenericDiv>
          )}
          <div
            style={{
              padding: "5px 6px",
              // padding: widthS > 768 ? "6px 6px" : "0px 4px",

              backgroundColor: "inherit",
              transition: "all 0.2s",
              borderRadius: "50px",
              width: "max-content",
              boxShadow: "0 1px 2px silver",
              filter: "brightness(75%)",
            }}
          >
            <GenericSvg
              fill={user.color}
              size={"23px"}
              svg={svgs.plus1}
              viewBox={"0 0 16 16"}
            />
          </div>
          {showAddGuestOptions && (
            <div
              style={{
                position: "absolute",
                top: "130%",
                right: "-140%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background:
                  "radial-gradient(circle, #f2f2f2 0%, rgba(255,255,255,0.2) 100%)",
                backdropFilter: "blur(10px)",
                padding: "20px",
                zIndex: 1,
              }}
            >
              <GenericDiv
                onClick={() => setShowAddGuest(true)}
                style={{ width: "45%", padding: "7px 10px" }}
              >
                ידני
                <img
                  src={addUser}
                  style={{ width: "25px", marginRight: "10px" }}
                />
              </GenericDiv>
              <GenericDiv
                onClick={() => setShowAddGuestNew(true)}
                background={user.color}
                style={{
                  width: "45%",
                  padding: "7px 10px",
                  marginTop: "10px",
                }}
              >
                מהנייד
                <img
                  src={contacts}
                  style={{ width: "30px", marginRight: "10px" }}
                />
              </GenericDiv>
            </div>
          )}
        </div>

        {/* <SketchColorPalette
          change={"color"}
          display={displayColorPicker}
          onClose={setDisplayColorPicker}
          setFlag={setFlag}
          flag={flag}
          div={
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"צבע המערכת"}</Tooltip>}
            >
              <img
                onClick={() => setDisplayColorPicker(!displayColorPicker)}
                src={palette}
                alt="palette"
                style={{ width: "33px", cursor: "pointer" }}
              />
            </OverlayTrigger>
          }
        /> */}
        {/* <Responsive padding={true} padC={"20px"} padP={"11px 13px"} style={{
                    cursor: "pointer", background: "#f2f2f2", borderRadius: "50%", boxShadow: "0 2px 4px gray"
                }}
                    onClick={() => setEdit(!edit)}
                >
                    <GenericSvg fill={user.color} size={width > 500 ? "38px" : "30px"} svg={svgs.category} viewBox={"0 0 40 40"} />
                </Responsive>
                <Responsive padding={true} padC={"20px"} padP={"11px 13px"} style={{
                    cursor: "pointer", background: "#f2f2f2", borderRadius: "50%", boxShadow: "0 2px 4px gray"
                }}
                    onClick={() => setEdit(!edit)}
                >
                    <GenericSvg fill={user.color} size={width > 500 ? "28px" : "20px"} svg={svgs.plus} viewBox={"0 0 45.402 45.402"} />
                </Responsive> */}
        <div>
          <DropDown data={guestsArray[1]} label={"קטגוריה"} />
        </div>
      </FlexRowBetween>
      <GuestsHolder>
        {guestsArray[0]}
        {/* <FlexRowBetween style={{borderRadius:"5px", boxShadow:"0 1px 2px silver", backgroundColor:"rgba(255,255,255,0.8)"
            , width:"45%", padding:"5px", marginRight:"3%"}}>
                    <div style={{width:"5px", boxShadow:"0 1px 2px silver", height:"20px", borderRadius:"5px", backgroundColor:"#f2f2f2"}}>
                    </div>
                    <div>איתי שטרית </div>
                    <div>1/2</div>
                </FlexRowBetween>
                <FlexRowBetween style={{borderRadius:"5px", boxShadow:"0 1px 2px silver", backgroundColor:"rgba(255,255,255,0.8)"
            , width:"45%", padding:"5px", marginRight:"3%"}}>
                    <div style={{width:"5px", boxShadow:"0 1px 2px silver", height:"20px", borderRadius:"5px", backgroundColor:"#f0d4ae"}}>
                    </div>
                    <div>איתי שטרית </div>
                    <div>1/2</div>
                </FlexRowBetween>
                <FlexRowBetween style={{borderRadius:"5px", boxShadow:"0 1px 2px silver", backgroundColor:"rgba(255,255,255,0.8)"
            , width:"45%", padding:"5px", marginRight:"3%", marginTop:"10px"}}>
                    <div style={{width:"5px", boxShadow:"0 1px 2px silver", height:"20px", borderRadius:"5px", backgroundColor:"#d7f0ae"}}>
                    </div>
                    <div>איתי שטרית </div>
                    <div>1/2</div>
                </FlexRowBetween>
                <FlexRowBetween style={{borderRadius:"5px", boxShadow:"0 1px 2px silver", backgroundColor:"rgba(255,255,255,0.8)"
            , width:"45%", padding:"5px", marginRight:"3%", marginTop:"10px"}}>
                    <div style={{width:"5px", boxShadow:"0 1px 2px silver", height:"20px", borderRadius:"5px", backgroundColor:"#f0aeae"}}>
                    </div>
                    <div>איתי שטרית </div>
                    <div>1/2</div>
                </FlexRowBetween> */}
      </GuestsHolder>

      {guestsLoading && <Loading />}

      {showAddGuest && (
        <GenericModal
          init={selfInit}
          div={<AddGuests categories={categories} init={selfInit} />}
        />
      )}

      {showAddGuestNew && (
        <GenericModal
          init={selfInit}
          div={
            <AddGuestsFromC
              categories={categories}
              phones={phones}
              init={selfInit}
            />
          }
        />
      )}

      {editInvitingAndOwc && (
        <GenericModal
          init={setEditInvitingAndOwc}
          div={
            <EditGuestsSms
              setGuestsInvitingAndOwc11={setGuestsInvitingAndOwc11}
              init={setEditInvitingAndOwc}
            />
          }
        />
      )}

      {editCloseness && (
        <GenericModal
          init={setEditCloseness}
          div={
            <EditGuestsCloseness
              setGuestsCloseness11={setGuestsCloseness11}
              init={setEditCloseness}
            />
          }
        />
      )}

      {edit && (
        <GenericModal
          init={editGuestReduxOnly}
          div={
            <EditGuest
              categories={categories}
              setDeleteGuest={setDeleteGuest}
              data={guest}
              mode={mode}
              setEdit={setEdit}
              // update={editGuestReduxOnlyWithParams}
              init={editGuestReduxOnly}
            />
          }
        />
      )}

      {logout && (
        <GenericModal init={setLogout} div={<LogOutAll init={setLogout} />} />
      )}

      {editDetails && (
        <GenericModal
          init={setEditDetails}
          div={<EditDetails init={setEditDetails} />}
        />
      )}

      {askForDelete && (
        <GenericModal
          init={setDeleteGuest}
          div={
            <YesNoModal
              color={user.color}
              title={"מחיקת אורחים"}
              body={"האם את/ה בטוח/ה?"}
              no={setAskForDelete}
              yes={deleteGuests11}
            />
          }
        />
      )}

      {guestToDelete && (
        <GenericModal
          init={setDeleteGuest}
          div={
            <YesNoModal
              color={user.color}
              title={"מחיקת אורח"}
              body={"האם את/ה בטוח/ה?"}
              no={setDeleteGuest}
              yes={deleteGuest1}
            />
          }
        />
      )}
      {showDuplicate && (
        <GenericModal
          init={setShowDuplicate}
          div={<DuplicatesModal data={showDuplicate} />}
        />
      )}
    </MainDiv>
  );
};
export default Guests;
