import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ClientRoutes from '../../navigation/Routes';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import eye from './eye.svg'
import { sha512 } from 'js-sha512';

import { login } from '../../store/reducers/authReducer';

import { MainDiv, CenteredForm, Yet, GenericButton, M1, M11, M12, GradientText, InputBox, RespFont, PlaceHolderImage } from '../common/Style'
import invBack from '../../assets/invitation/invBack3.jpeg';
import { GetToast } from '../common/modals/Toast';
import OpacityLoading from '../common/loader/OpacityLoading';

const Login = () => {
    const { authError, authLoading } = useSelector(state => state.authReducer);
    const [showPass, setShowPass] = useState(false);
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { register, handleSubmit } = useForm();

    useEffect(() => {
        if (authError)
            GetToast(addToast, "error", authError)
    }, [authError]);

    const onSubmit = async data => {
        const body = {
            phone: data.phone, password: sha512(data.password)
        }
        dispatch(login(body))
    }

    return (<MainDiv className="App animate__animated animate__fadeIn">
        <M1 style={{ width: "100%", display: "flex", flexDirection: "row", height: "100vh" }}>
            <M11 style={{ backgroundImage: `url(${invBack})` }} />
            <M12>
                {/* <GradientText background={"#f1ae6e"} as={Link} to={localStorage["logout"]} style={{
                    display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", right: "2vh", top: "2vh", fontFamily: "logo",
                    borderRadius: "15px", cursor: "auto", zIndex: 1
                }}>B.S</GradientText> */}
                <CenteredForm onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: "10px", paddingBottom: "10px", width: "80%", background: "#f2f2f2cc" }}>
                    <RespFont pSize={"20px"} cSize={"22px"} style={{ textAlign: "center", color: "#3A3C3F", fontWeight: "bold", padding: `30px 0`, paddingBottom: `10px` }}>התחברות</RespFont>

                    {/* <div style={{ textAlign: "center", margin: "20px auto", fontSize: "20px", paddingTop: "10px" }}>התחברות</div> */}
                    <InputBox labelColor={"gray"} color={"#3A3C3F"}>
                        <div className='relativeBox'>
                            <input type="text" name="phone" required={true} pattern="[0]{1}[5]{1}[0-9]{8}" ref={register()} />
                            <label>מס׳ פלאפון</label>
                        </div>
                        <div className='relativeBox'>
                            <PlaceHolderImage onClick={() => setShowPass(!showPass)} fontSize={`20px`} top={`34px`} src={eye} alt="accept" />
                            <input name="password" type={showPass ? "text" : "password"} required={true} minLength={2} maxLength={20} ref={register()} />
                            <label>סיסמא</label>
                        </div>

                        <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <Yet as={Link} to={ClientRoutes.UnAuth.register}>עדיין לא רשומים?</Yet>
                        </div>
                        <div style={{ paddingBottom: "10px" }}>

                            <GenericButton hoverBackground={"#5c3e94"} hoverColor={"#fff"} type="submit" style={{ width: "60%", margin: "30px auto" }}>שליחה</GenericButton>
                        </div>
                    </InputBox>
                </CenteredForm>
            </M12>
        </M1>
        {authLoading && <OpacityLoading />}
    </MainDiv>)
}

export default Login;