import { createSlice } from "@reduxjs/toolkit";
import { post2, get2 } from "../api/http";
import ServerRoutes from "../api/routes";

const initialState = {
  todos: {},
  todosError: null,
  todosLoading: false,
};

const _addTodo = (state, action) => {
  let editedTodos = { ...state.todos };
  editedTodos[action.payload.id] = {
    ...action.payload.body,
    done: false,
    _id: action.payload.id,
    dateAdded: action.payload.dateAdded,
  };
  state.todos = editedTodos;
};

const _setTodos = (state, action) => {
  state.todos = action.payload;
};

const _editTodo = (state, action) => {
  let editedTodos = { ...state.todos };
  for (const [key, value] of Object.entries(action.payload.body))
    editedTodos[action.payload.id][key] = value;
  state.todos = editedTodos;
};

const _editTodoAdmin = (state, action) => {
  let editedTodos = { ...state.todos };
  const { done, date, place } = action.payload;
  editedTodos[date][place].done = done;
  state.todos = { ...editedTodos };
};

const _deleteTodo = (state, action) => {
  let editedTodos = { ...state.todos };
  delete editedTodos[action.payload];
  state.todos = editedTodos;
};

const _actionStart = (state) => {
  state.todosLoading = true;
  state.todosError = null;
};

const _actionSuccess = (state) => {
  state.todosLoading = false;
  state.todosError = null;
};

const _actionFailed = (state, action) => {
  state.todosLoading = false;
  state.todosError = action.payload.error;
};

const _cleanTodosState = (state) => {
  state.todos = {};
  state.todosError = null;
  state.todosLoading = false;
};

const todos = createSlice({
  name: "todos",
  initialState,
  reducers: {
    addTodo1: _addTodo,
    cleanTodosState: _cleanTodosState,
    editTodo1: _editTodo,
    deleteTodo1: _deleteTodo,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    setTodos: _setTodos,
    actionSuccess: _actionSuccess,
    editTodoAdmin1: _editTodoAdmin,
  },
});

const { reducer, actions } = todos;

const {
  actionStart,
  actionFailed,
  actionSuccess,
  addTodo1,
  editTodo1,
  deleteTodo1,
  setTodos,
  cleanTodosState,
  editTodoAdmin1,
} = actions;

export default reducer;

export const addTodo = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(ServerRoutes.Todos.add, body);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(
        addTodo1({ id: data.id, dateAdded: data.dateAdded, body })
      );
    }
  };
};

export const getDaniel = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Todos.getDaniel,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setTodos(data));
    }
  };
};

export const getBetweenDates = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Todos.getBetweenDates,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setTodos(data));
    }
  };
};

export const getTodayTodos = (today) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Todos.getTodayTodos,
      { today }
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setTodos(data));
    }
  };
};

export const getAll = () => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(ServerRoutes.Todos.getAll);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setTodos(data));
    }
  };
};

export const cleanAll = () => {
  return async (dispatch) => {
    return dispatch(cleanTodosState());
  };
};

export const editTodo = (id, body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Todos.edit + id, body);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editTodo1({ id, body }));
    }
  };
};

export const editStatus = (id, body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Todos.editStatus + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editTodo1({ id, body }));
    }
  };
};

export const editTodoAdmin = (id, body, place, date) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Todos.editStatus + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editTodoAdmin1({ done: body.done, date, place }));
    }
  };
};

export const deleteTodo = (id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Todos.delete, { id });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(deleteTodo1(id));
    }
  };
};
