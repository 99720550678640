import React, { useMemo, useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import OpacityLoading from "../../common/loader/OpacityLoading";
import GenericModal from "../../common/modals/GenericModal";
import AddTodo from "./AddTodo";
import EditTodo from "./EditTodo";
import plus from "../../../assets/client/plus.svg";
import direction from "../../../assets/client/direction.svg";
import {
  editStatus,
  deleteTodo,
  getAll,
  cleanAll,
} from "../../../store/reducers/todosReducer";
import YesNoModal from "../../common/modals/YesNoModal";
import {
  FlexRowBetween,
  GradientText,
  TodoCard,
  TodosHolder,
  MainDiv,
} from "../../common/Style";
import { GetToast } from "../../common/modals/Toast";
import { slide as Menu } from "react-burger-menu";
import ClientRoutes from "../../../navigation/Routes";
import { useHistory } from "react-router-dom";
import LogOutAll from "../../common/LogOut/LogOutAll";
import "../style.css";
import Dropdown from "react-bootstrap/Dropdown";
import expenses from "../../../assets/client/expenses.svg";
import brain from "../../../assets/client/brain.svg";
import creditCard from "../../../assets/client/creditCard.svg";
import logout1 from "../../../assets/client/logout.svg";
import editInv from "../../../assets/client/editInv.svg";
import tables from "../../../assets/client/editTables.svg";
import video from "../../../assets/client/video2.svg";
import guests1 from "../../../assets/client/guests.svg";
import { MenuCss } from "../../common/modals/Toast";
import GCal from "./GCal";
import calendar from "../../../assets/invitation/calendar.svg";

const Todos = () => {
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState(false);
  const [sortBy, setSortBy] = useState("executionDate");
  const [addTodo, setAddTodo] = useState(false);
  const [editTodoS, setEditTodo] = useState(false);
  const [deleteTodoS, setDeleteTodo] = useState(false);
  const [logout, setLogout] = useState(false);
  const dispatch = useDispatch();
  const { todos, todosError, todosLoading } = useSelector(
    (state) => state.todosReducer
  );
  const { user } = useSelector((state) => state.authReducer);
  const { addToast } = useToasts();

  const getTodos = () => {
    dispatch(getAll());
  };

  useEffect(() => {
    getTodos();
    return () => {
      dispatch(cleanAll());
    };
  }, []);

  useEffect(() => {
    if (todosError) GetToast(addToast, "error", todosError);
  }, [todosError]);

  const setDone = async (id, done) => {
    dispatch(editStatus(id, { done })).then(() => {
      if (todosError === null) GetToast(addToast, "success", "הפעולה בוצעה");
    });
  };

  const deleteTodoFunc = async () => {
    dispatch(deleteTodo(deleteTodoS)).then(() => {
      if (todosError === null) GetToast(addToast, "success", "הפעולה בוצעה");
    });
  };

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  let todosArray = useMemo(() => {
    let arr = [];
    let nullArr = [];
    let sortedArray = [];
    if (Object.keys(todos).length > 0) {
      for (const [key, value] of Object.entries(todos)) {
        if (sortBy === "executionDate") {
          if (value["executionDate"] !== null) sortedArray.push(value);
          else nullArr.push(value);
        } else sortedArray.push(value);
      }

      switch (sortBy) {
        case "dateAdded":
          if (sortDirection)
            sortedArray.sort(
              (a, b) => new Date(b[sortBy]) - new Date(a[sortBy])
            );
          else
            sortedArray.sort(
              (a, b) => new Date(a[sortBy]) - new Date(b[sortBy])
            );
          break;
        case "executionDate":
          if (sortDirection)
            sortedArray.sort(
              (a, b) => new Date(b[sortBy]) - new Date(a[sortBy])
            );
          else
            sortedArray.sort(
              (a, b) => new Date(a[sortBy]) - new Date(b[sortBy])
            );
          break;
        case "belongTo":
          if (sortDirection)
            sortedArray.sort((a, b) => b[sortBy].localeCompare(a[sortBy]));
          else sortedArray.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
          break;
        default:
          if (sortDirection) sortedArray.sort((a, b) => b[sortBy] - a[sortBy]);
          else sortedArray.sort((a, b) => a[sortBy] - b[sortBy]);
      }
      for (let i = 0; i < nullArr.length; i++) sortedArray.push(nullArr[i]);
      for (let i = 0; i < sortedArray.length; i++) {
        let date = new Date();
        date.setHours(20);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        date = date.toISOString();
        let today = date === sortedArray[i].executionDate;
        arr.push(
          <TodoCard
            color={user.color}
            today={today}
            key={i}
            onClick={() => {
              if (sortedArray[i].belongTo === "Be Simple")
                GetToast(
                  addToast,
                  "warning",
                  "משימה של החברה, יש לדבר עם מנהל המערכת לפני שינוי",
                  8000
                );
              setEditTodo(sortedArray[i]);
            }}
          >
            <div className="info">
              <div>{sortedArray[i].title}</div>
              {sortedArray[i]?.executionDate && (
                <div style={{ fontSize: "13px" }}>
                  {sortedArray[i]?.executionDate
                    .replace(/T.*/, "")
                    .split("-")
                    .reverse()
                    .join("/")}
                </div>
              )}
              {sortedArray[i]?.executionDate && (
                <GCal
                  mission={sortedArray[i].title}
                  date={sortedArray[i].executionDate}
                  div={
                    <OverlayTrigger
                      key={i + 1 * 1000}
                      placement={"bottom"}
                      overlay={<Tooltip>{"הוספה ליומן"}</Tooltip>}
                    >
                      <img
                        src={calendar}
                        style={{ width: "25px", marginRight: "10px" }}
                      />
                    </OverlayTrigger>
                  }
                />
              )}

              <OverlayTrigger
                key={i + 1 * 1000}
                placement={"bottom"}
                overlay={<Tooltip>{"סטטוס"}</Tooltip>}
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  style={{ marginRight: "10px", marginBottom: "-5px" }}
                >
                  <Switch
                    width={34}
                    height={14}
                    onColor={"#b1b1b1"}
                    offColor={"#b1b1b1"}
                    onHandleColor={user.color}
                    boxShadow={"0 2px 4px silver, 0 -1px 4px silver"}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={sortedArray[i].done}
                    onChange={() => {
                      if (sortedArray[i].belongTo === "Be Simple")
                        GetToast(
                          addToast,
                          "warning",
                          "משימה של החברה, לשינויים פנו למנהל המערכת"
                        );
                      else setDone(sortedArray[i]._id, !sortedArray[i].done);
                    }}
                    handleDiameter={20}
                  />
                </div>
              </OverlayTrigger>
            </div>
          </TodoCard>
        );
      }
    }
    return arr;
  }, [todos, sortBy, sortDirection]);

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  return (
    <MainDiv
      onClick={() => setIsOpen(false)}
      className="animate__animated animate__fadeIn"
      style={{
        overflow: "hidden",
        background: `radial-gradient(circle, ${addAlpha(
          user.color,
          0.25
        )} 0%, #f2f2f2 100%)`,
        maxHeight: "100vh",
      }}
    >
      <Menu
        noOverlay
        isOpen={isOpen}
        onStateChange={handleStateChange}
        right
        width={"max-content"}
        styles={MenuCss(user.color)}
      >
        <div
          className="menu-item"
          style={{ marginTop: "0px" }}
          onClick={() => history.push(ClientRoutes.User.guests)}
        >
          <div>מוזמנים</div>
          <img src={guests1} alt={"guests1"} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.tables)}
        >
          <div>מערך שולחנות</div>
          <img src={tables} alt={"tables"} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.instructions)}
        >
          <div>הדרכות</div>
          <img src={video} alt={"video"} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.editDetails)}
        >
          <div>עריכת הזמנה</div>
          <img src={editInv} alt={"editInv"} style={{ width: "23px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.expenses)}
        >
          <div>יומן הוצאות</div>
          <img src={expenses} alt={"expenses"} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.print)}
        >
          <div>מוח המערכת</div>
          <img src={brain} alt={"brain"} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          style={{
            position: "absolute",
            bottom: "20px",
            fontWeight: "bold",
            fontSize: "20px",
          }}
          onClick={() => setLogout(true)}
        >
          <div>התנתקות</div>
          <img src={logout1} alt={"logout1"} style={{ width: "25px" }} />
        </div>
      </Menu>

      <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip>{"משימה חדשה"}</Tooltip>}
      >
        <div
          onClick={() => setAddTodo(true)}
          style={{
            position: "absolute",
            left: "11px",
            top: "22px",
            zIndex: 9,
            background: user.color,
            padding: "1px 5px",
            borderRadius: "20%",
            cursor: "pointer",
          }}
        >
          <img
            src={plus}
            alt="plus"
            style={{ width: "16px", paddingBottom: "1.8px" }}
          />
        </div>
      </OverlayTrigger>

      <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip>{"מיון"}</Tooltip>}
      >
        <Dropdown
          style={{
            position: "absolute",
            left: "43px",
            top: "22px",
            zIndex: 9,
          }}
        >
          <Dropdown.Toggle
            style={{
              background: user.color,
              border: "none",
              height: "26px",
              width: "20px",
              borderRadius: "20%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            id="dropdown-basic"
          ></Dropdown.Toggle>
          <Dropdown.Menu style={{ textAlign: "right" }}>
            <Dropdown.Item onClick={() => setSortBy("rank")}>
              דרגת חשיבות
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSortBy("done")}>
              סטטוס
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSortBy("belongTo")}>
              שיוך
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSortBy("dateAdded")}>
              תאריך הוספה
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSortBy("executionDate")}>
              תאריך לביצוע
            </Dropdown.Item>
            {/* <Dropdown.Item onClick={() => setSortBy("executionDate")}>תאריך לביצוע</Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </OverlayTrigger>

      <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip>{"כיוון"}</Tooltip>}
      >
        <div
          onClick={() => setSortDirection(!sortDirection)}
          style={{
            position: "absolute",
            left: "73.5px",
            top: "22px",
            zIndex: 9,
            background: user.color,
            padding: "1px 3px",
            borderRadius: "20%",
            cursor: "pointer",
          }}
        >
          <img
            src={direction}
            alt="direction"
            style={{ width: "16px", paddingBottom: "1.8px" }}
          />
        </div>
      </OverlayTrigger>

      <FlexRowBetween
        style={{
          width: "100%",
          padding: "20px 5%",
          alignItems: "center",
          position: "fixed",
          zIndex: 1,
          justifyContent: "center",
          background: `#f2f2f2`,
          paddingRight: "5%",
          boxShadow: "0 2px 20px silver",
          paddingBottom: "15px",
        }}
      >
        <div
          style={{
            fontSize: "22px",
            fontWeight: "bold",
            textAlign: "center",
            color: "black",
          }}
        >
          יומן משימות
        </div>
      </FlexRowBetween>

      {/* <div style={{ marginTop: "100px" }}>
                <DropDownG label={"מיון"} data={sortOptions} value={sortBy} onChange={setSortBy} />
            </div> */}

      <TodosHolder style={{ marginTop: "70px" }}>{todosArray}</TodosHolder>

      {addTodo && (
        <GenericModal init={setAddTodo} div={<AddTodo init={setAddTodo} />} />
      )}
      {editTodoS && (
        <GenericModal
          init={setEditTodo}
          div={
            <EditTodo
              setDeleteTodo={setDeleteTodo}
              data={editTodoS}
              init={setEditTodo}
            />
          }
        />
      )}

      {deleteTodoS && (
        <GenericModal
          init={setDeleteTodo}
          div={
            <YesNoModal
              color={user.color}
              title={"מחיקת משימה"}
              body={"האם את/ה בטוח/ה?"}
              no={setDeleteTodo}
              yes={deleteTodoFunc}
            />
          }
        />
      )}

      {logout && (
        <GenericModal init={setLogout} div={<LogOutAll init={setLogout} />} />
      )}

      {todosLoading && <OpacityLoading />}
    </MainDiv>
  );
};

export default Todos;
