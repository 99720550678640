import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import { addTodo } from "../../../store/reducers/todosReducer";
import {
  CenteredForm,
  PlaceHolderImage,
  GenericButton,
  InputBox,
  RTextArea,
  GenericDiv,
} from "../../common/Style";
import exitModalLogo from "../../../assets/common/cancel1.svg";
import { GetToast } from "../../common/modals/Toast";
import GenericModal from "../../common/modals/GenericModal";
import GCalendar from "../../common/calendar/Calendar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Dropdown from "react-bootstrap/Dropdown";
import ExitModal from "../../common/modals/ExitModal";
const options = [
  "הודעה ראשונה",
  "הודעה שניה",
  "הודעה שלישית",
  "ווצאפ ראשון",
  "ווצאפ שני",
  "שיחה ראשונה",
  "שיחה שניה",
  "שיחה שלישית",
  "שיחה רביעית",
  "תזכורת",
  "תודה",
];

const AddTodo = (props) => {
  const dispatch = useDispatch();
  const { todosError } = useSelector((state) => state.todosReducer);
  const { user } = useSelector((state) => state.authReducer);
  const { addToast } = useToasts();
  const { register, handleSubmit } = useForm();
  const [showCalendar, setShowCalendar] = useState(null);
  const [title, setTitle] = useState("");
  const ops = options.map((item, index) => (
    <Dropdown.Item key={index} onClick={() => setTitle(item)}>
      {item}
    </Dropdown.Item>
  ));

  const [date, setDate] = useState(null);
  const onSubmit = async (data) => {
    const dis = () => {
      dispatch(
        addTodo({
          title: data.title,
          belongTo: data.belongTo,
          rank: data.rank,
          description: data.description,
          executionDate: date1 ? date1.toISOString() : null,
        })
      ).then(() => {
        if (todosError === null)
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        props.init(false);
      });
    };
    if (options.includes(title))
      if (date) dis();
      else alert("לא נבחר תאריך לביצוע");
    else dis();
  };

  let date1;
  if (date) {
    date1 = date;
    date1.setHours(20);
  }
  return (
    <CenteredForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ExitModal init={props.init} color={user.color} />
      <div
        style={{
          textAlign: "center",
          fontWeight: "bold",
          padding: "20px",
          fontSize: "22px",
        }}
      >
        משימה חדשה
      </div>
      <InputBox
        labelColor={"gray"}
        color={"#3A3C3F"}
        borderBottomColor={user.color}
      >
        <div className="relativeBox">
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            name="title"
            minLength={1}
            maxLength={50}
            required={true}
            ref={register()}
          />
          <label>כותרת</label>
          <Dropdown
            style={{
              position: "absolute",
              left: "0px",
              top: "16px",
              zIndex: 1,
            }}
          >
            <Dropdown.Toggle
              style={{
                background: user.color,
                border: "none",
                height: "26px",
                width: "20px",
                borderRadius: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              id="dropdown-basic"
            ></Dropdown.Toggle>
            <Dropdown.Menu style={{ textAlign: "right" }}>{ops}</Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="relativeBox">
          <input
            type="text"
            defaultValue={"משותף"}
            name="belongTo"
            minLength={1}
            maxLength={50}
            required={true}
            ref={register()}
          />
          <label>שיוך</label>
        </div>
        <div className="relativeBox">
          <input
            type="number"
            defaultValue={1}
            name="rank"
            min={1}
            max={100}
            required={true}
            ref={register()}
          />
          <label>דרגת חשיבות</label>
        </div>

        <GenericDiv
          hoverBackground={"rgb(223, 223, 223)"}
          background={user.color}
          style={{
            width: "max-content",
            margin: "20px auto",
            display: "flex",
            flexDirection: "column",
          }}
          onClick={() => setShowCalendar(true)}
        >
          <div>תאריך לביצוע</div>
          <div>
            {date &&
              date1
                .toISOString()
                .replace(/T.*/, "")
                .split("-")
                .reverse()
                .join("/")}
          </div>
        </GenericDiv>

        <div
          style={{ position: "relative", width: "70%", margin: "20px auto" }}
        >
          <RTextArea
            color={user.color}
            placeholder="תוכן"
            name="description"
            type="text"
            rows="2"
            minLength={0}
            maxLength={500}
            ref={register()}
          />
        </div>

        <GenericButton
          background={user.color}
          type="submit"
          style={{ width: "50%", margin: "30px auto", padding: "10px" }}
        >
          הוספה
        </GenericButton>
      </InputBox>
      {showCalendar && (
        <GenericModal
          init={setShowCalendar}
          div={
            <GCalendar
              background={user.color}
              setDate={setDate}
              date={date}
              init={setShowCalendar}
            />
          }
        />
      )}
    </CenteredForm>
  );
};

export default AddTodo;
