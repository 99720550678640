import React from "react";
import Heart from "../../../../assets/invitation/Heart";
const Names = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          fontSize: "25px",
          width: "100%",
          textAlign: "center",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <div>{props.data.brideFN}</div>
        <div
          className="animate__animated animate__pulse animate__infinite"
          style={{ zIndex: 0, margin: "0 13px" }}
        >
          <Heart fill={props.data.heartColor} style={{ width: "30px" }} />
        </div>
        <div>{props.data.groomFN}</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          margin: "7px auto",
          fontSize: "16px",
          width: "100%",
        }}
      >
        <div>{props.data.hall + " " + props.data.city}</div>
        <div>
          {props.data.date.replace(/T.*/, "").split("-").reverse().join("/")}
        </div>
      </div>
    </>
  );
};

export default Names;
