import { createSlice } from "@reduxjs/toolkit";
import ServerRoutes from "../api/routes";
import { uploadImage, post, post2 } from "../api/http";

const initialState = {
  photosError: null,
  photosLoading: false,
};

const _actionStart = (state) => {
  state.photosLoading = true;
  state.photosError = null;
};

const _actionSuccess = (state) => {
  state.photosLoading = false;
  state.photosError = null;
};

const _actionFailed = (state, action) => {
  state.photosLoading = false;
  state.photosError = action.payload.error;
};

const photos = createSlice({
  name: "photos",
  initialState,
  reducers: {
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    actionSuccess: _actionSuccess,
  },
});

const { reducer, actions } = photos;

export const { actionStart, actionFailed, actionSuccess } = actions;

export default reducer;

export const uploadPhoto = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await uploadImage(
      ServerRoutes.Photos.upload,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return data.data;
    }
  };
};

export const deletePhoto = (key) => {
  return async () => {
    await post(ServerRoutes.Photos.delete, { key });
  };
};
