import React, { useState } from "react";
import PriceListPhoneMenu from "./PriceListPhoneMenu";
import Package2 from "./Package2";
import Package3 from "./Package3";
import Package4 from "./Package4";
import Package5 from "./Package5";
import { FlexRowBetween, OptionsFirst, RespImg } from "../../../common/Style";
import { ServiceOption } from "../../Style";
import GenericModal from "../../../common/modals/GenericModal";
import TitleAndContent from "../../../common/modals/TitleAndContent";
import i1 from "../../../../assets/logo/i1.svg";
import i2 from "../../../../assets/logo/i2.svg";
const PricePage = (props) => {
  const [package1, setPackage] = useState(4);
  const [recoredExplnation, setrecoredExplnation] = useState(false);
  return (
    <OptionsFirst
      style={{
        background: "radial-gradient(circle, #6055d643 0%, #f2f2f2 100%)",
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "space-around",
      }}
    >
      <RespImg src={i1} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginBottom: "10px",
            paddingTop: "20px",
            fontFamily: "openSansRegular",
            fontWeight: "600",
            fontSize: "30px",
            textShadow: "2px 2px 2px silver",
          }}
        >
          החבילה שלנו:
        </div>
        {/* <FlexRowBetween>
          <ServiceOption
            include={true}
            style={{ margin: "10px 5px", cursor: "auto" }}
          >
            כולל
          </ServiceOption>
          <ServiceOption style={{ margin: "10px 5px", cursor: "auto" }}>
            לא כולל
          </ServiceOption>
          <ServiceOption
            include={true}
            onClick={() => setrecoredExplnation(true)}
            style={{
              margin: "10px 5px",
              cursor: "pointer",
              color: "white",
              background:
                "linear-gradient(135deg, #302b63, rgba(68, 59, 149, 0.549))",
              boxShadow: "0 2px 12px 0 rgba(41, 99, 191, 0.5)",
            }}
          >
            מה זה רשומה?
          </ServiceOption>
        </FlexRowBetween> */}
        <Package5 />
        <div style={{ height: "50px" }} />
        {/* {package1 === 2 ? (
          <Package2 />
        ) : package1 === 3 ? (
          <Package3 />
        ) : package1 === 4 ? (
          <Package4 />
        ) : package1 === 5 ? (
          <Package5 />
        ) : null} */}
        {/* <PriceListPhoneMenu setPackage={setPackage} /> */}
        {recoredExplnation && (
          <GenericModal
            init={setrecoredExplnation}
            div={
              <TitleAndContent
                title={"רשומה לעומת אורח"}
                content={
                  <div>
                    <div>נניח שיש לנו דוד נשוי פלוס 2 ילדים בני 10,</div>
                    <div>
                      לכאורה מדובר ב 4 אורחים אך רק רשומה אחת מכיוון שהדוד עונה
                      עבור כולם.
                    </div>
                    <div>
                      לכן, במידה ויש לנו משפחות וזוגות אזי בהערכה גסה, ניתן לומר
                      כי מספר הרשומות הינו בערך כחצי מכמות המוזמנים.
                    </div>
                    <div>
                      נקודה חשובה נוספת - התשלום הינו רק עבור רשומות שיש לוודא
                      איתן הגעה משמע רשומות כמו סבים, סבתות, משפחה גרעינית,
                      מלווים ובכלל אנשים שמבחינתכם מגיעים בוודאות ואין לשלוח להם
                      הודעה ולהתקשר תוכלו להוסיף למערכת ולא לשלם עליהם, עדיין
                      יקבלו הודעה תזכורת, מספר שולחן והודעת תודה.
                    </div>
                    <div>
                      רק אחרי שתטענו למערכת את כלל המוזמנים, המערכת תסכום את
                      מספר הרשומות יש לוודא לגביהן הגעה ועל פי מספר זה ייגבה
                      תשלום
                    </div>
                  </div>
                }
                init={setrecoredExplnation}
              />
            }
          />
        )}
      </div>
      <RespImg src={i2} />
    </OptionsFirst>
  );
};

export default PricePage;
