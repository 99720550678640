import styled from 'styled-components';

const media = {
    inv: '@media only screen and (max-width:500px)',
    phone: '@media only screen and (max-width:480px)',
    phoneAndTablet: '@media only screen and (max-width:768px)',
    tablet: '@media only screen and (min-width:481px) and (max-width:768px)',
    bigTablet: '@media only screen and (min-width:769px) and (max-width:1024px)',
    comp: '@media only screen and (min-width:1025px)',
}

const constants = {
    backgroundColor: '#f1ae6e',
    fontColor: '#3A3C3F',
}

export const LogoImg = styled.img`
    width: 350px;
    margin-top: 17vh;
    margin-bottom: 50px;
    ${media.phoneAndTablet}{
        width: 200px;
        margin-top: 4vh;
        margin-bottom: 30px;
    }
`;