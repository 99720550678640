import React, { useEffect, useState } from "react";
import {
  CenteredForm,
  FlexRowBetween,
  GenericDiv,
  HomeTextArea,
  MainDiv,
  RespFont,
} from "../Style";
import queryString from "query-string";
import {
  editBlessing,
  setRecord,
} from "../../../store/reducers/paymentsReducer";
import { useDispatch } from "react-redux";
import { GetToast } from "../modals/Toast";
import { useToasts } from "react-toast-notifications";

const SuccessPage = (props) => {
  const [bracha, setBracha] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [brachaContent, setBrachaContent] = useState("");
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  let params = queryString.parse(props.location.search);
  const { CCode, Fild1, Fild2, Amount, Id, ACode, Info, cell, Order, UserId } =
    params;
  useEffect(() => {
    if (parseInt(CCode) === 0)
      dispatch(
        setRecord({
          Fild1,
          Fild2,
          Amount,
          Id,
          ACode,
          Info,
          cell,
          Order,
          UserId,
        })
      ).then((res) => {
        if (res.payload?.error) {
          window.open(`${res.payload.error}`, "_blank");
          console.log(res.payload.error);
          GetToast(addToast, "error", "התשלום בוצע אך לא נרשם");
        } else {
          GetToast(addToast, "success", "התשלום בוצע בהצלחה!!!", 8000);
          console.log(res.payload);
          //   setPaymentId(res.payload);
          //   setBracha("");
        }
      });
  }, []);

  const handleSetBracha = () => {
    dispatch(editBlessing(paymentId, { blessing: brachaContent })).then(
      (res) => {
        if (res.payload?.error) {
          GetToast(
            addToast,
            "error",
            "חלה שגיאה בשמירת הברכה, במידה ותרצה להעביר ברכה לזוג פנה לתמיכה",
            8000
          );
        } else {
          GetToast(addToast, "success", "הברכה נרשמה בהצלחה!!!", 8000);
          setBracha(false);
        }
      }
    );
  };
  return (
    <MainDiv
      style={{
        justifyContent: "center",
        background: `radial-gradient(circle, #948ddf71 0%, #f2f2f2 100%)`,
      }}
    >
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          fontFamily: "logo",
          fontWeight: "700",
          position: "absolute",
          top: "50px",
        }}
      >
        IBS - מתנות לאירועים
      </RespFont>
      <CenteredForm
        style={{ borderRadius: "10px", boxShadow: "none", textAlign: "center" }}
      >
        <RespFont
          pSize={"17px"}
          cSize={"19px"}
          style={{ fontWeight: "700", marginBottom: "20px", marginTop: "20px" }}
        >
          אישור תשלום
        </RespFont>
        <RespFont>
          <FlexRowBetween
            style={{ justifyContent: "flex-start", padding: "2px 10px" }}
          >
            <div style={{ fontWeight: "bold", marginLeft: "10px" }}>
              פרטי בית העסק:
            </div>
            <div>IBS - מתנות לאירועים</div>
          </FlexRowBetween>
          <FlexRowBetween
            style={{ justifyContent: "flex-start", padding: "2px 10px" }}
          >
            <div style={{ fontWeight: "700", marginLeft: "10px" }}>
              שם לקוח:
            </div>
            <div>{Fild1}</div>
          </FlexRowBetween>
          <FlexRowBetween
            style={{ justifyContent: "flex-start", padding: "2px 10px" }}
          >
            <div style={{ fontWeight: "700", marginLeft: "10px" }}>
              מספר עסקה:
            </div>
            <div>{Id}</div>
          </FlexRowBetween>
          <FlexRowBetween
            style={{ justifyContent: "flex-start", padding: "2px 10px" }}
          >
            <div style={{ fontWeight: "700", marginLeft: "10px" }}>
              תיאור עסקה:
            </div>
            <div>{Info}</div>
          </FlexRowBetween>
          <FlexRowBetween
            style={{ justifyContent: "flex-start", padding: "2px 10px" }}
          >
            <div style={{ fontWeight: "700", marginLeft: "10px" }}>סכום:</div>
            <div>{Amount}</div>
          </FlexRowBetween>
          <FlexRowBetween
            style={{
              justifyContent: "flex-start",
              padding: "2px 10px",
              paddingBottom: "20px",
            }}
          >
            <div style={{ fontWeight: "700", marginLeft: "10px" }}>
              מספר אישור:
            </div>
            <div>{ACode}</div>
          </FlexRowBetween>
        </RespFont>
      </CenteredForm>
      {/* {bracha !== false && (
        <div style={{ width: "300px", margin: "10px auto", marginTop: "30px" }}>
          <HomeTextArea
            onChange={(e) => setBrachaContent(e.target.value)}
            pHcolor={"silver"}
            placeholder={"כאן תוכלו לכתוב ברכות 🙂"}
            type="text"
            rows="5"
          />
          <GenericDiv
            background={"white"}
            style={{ width: "300px", color: "black" }}
          >
            שליחה
          </GenericDiv>
        </div>
      )} */}
      <RespFont
        pSize={"17px"}
        cSize={"19px"}
        style={{ fontWeight: "700", marginBottom: "20px", marginTop: "20px" }}
      >
        נתקלתם בבעיה? פנו לתמיכה - 0547439322
      </RespFont>
    </MainDiv>
  );
};

export default SuccessPage;
