import { createSlice } from '@reduxjs/toolkit';
import { post2, get2 } from '../api/http';
import ServerRoutes from '../api/routes';

const initialState = {
    expenses: {},
    expensesError: null,
    expensesLoading: false
}

const _addExpense = (state, action) => {
    let editedExpenses = { ...state.expenses }
    editedExpenses[action.payload.id] = { ...action.payload.body, calculate: true, _id: action.payload.id, dateAdded: action.payload.dateAdded }
    state.expenses = editedExpenses;
};

const _setExpenses = (state, action) => {
    state.expenses = action.payload;
};

const _editExpense = (state, action) => {
    let editedExpenses = { ...state.expenses }
    for (const [key, value] of Object.entries(action.payload.body))
        editedExpenses[action.payload.id][key] = value;
    state.expenses = editedExpenses;
};

const _deleteExpense = (state, action) => {
    let editedExpenses = { ...state.expenses }
    delete editedExpenses[action.payload]
    state.expenses = editedExpenses;
};

const _actionStart = (state,) => {
    state.expensesLoading = true;
    state.expensesError = null;
};

const _actionSuccess = (state) => {
    state.expensesLoading = false;
    state.expensesError = null;
};

const _actionFailed = (state, action) => {
    state.expensesLoading = false;
    state.expensesError = action.payload.error;
};

const _cleanExpensesState = (state) => {
    state.expenses = {};
    state.expensesError = null;
    state.expensesLoading = false
};

const expenses = createSlice({
    name: 'expenses',
    initialState,
    reducers: {
        addExpense1: _addExpense,
        cleanExpensesState: _cleanExpensesState,
        editExpense1: _editExpense,
        deleteExpense1: _deleteExpense,
        actionStart: _actionStart,
        actionFailed: _actionFailed,
        setExpenses: _setExpenses,
        actionSuccess: _actionSuccess
    },
});

const { reducer, actions } = expenses;

export const { actionStart, actionFailed, actionSuccess, addExpense1, editExpense1, deleteExpense1, setExpenses, cleanExpensesState } = actions;

export default reducer;

export const addExpense = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { data, error, status } = await post2(ServerRoutes.Expenses.add, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(addExpense1({ id: data.id, dateAdded: data.dateAdded, body }));
        }
    }
};

export const getAll = () => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { data, error, status } = await get2(ServerRoutes.Expenses.getAll);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(setExpenses(data));
        }
    }
};

export const cleanAll = () => {
    return async (dispatch) => {
        return dispatch(cleanExpensesState());
    }
};


export const editExpense = (id, body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Expenses.edit + id, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editExpense1({ id, body }));
        }
    }
};

export const editCalculate = (id, body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Expenses.editCalculate + id, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editExpense1({ id, body }));
        }
    }
};

export const deleteExpense = (id) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Expenses.delete, { id });
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(deleteExpense1(id));
        }
    }
};