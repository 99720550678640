import React, { useMemo, useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import OpacityLoading from "../../common/loader/OpacityLoading";
import GenericModal from "../../common/modals/GenericModal";
import AddExpense from "./AddExpense";
import EditExpense from "./EditExpense";
import plus from "../../../assets/client/plus.svg";
import download from "../../../assets/client/download.svg";
import {
  editCalculate,
  deleteExpense,
  getAll,
  cleanAll,
} from "../../../store/reducers/expensesReducer";
import YesNoModal from "../../common/modals/YesNoModal";
import {
  FlexRowBetween,
  GradientText,
  TodoCard,
  TodosHolder,
  MainDiv,
  InputBox,
  GenericDiv,
  RespFont,
} from "../../common/Style";
import { GetToast } from "../../common/modals/Toast";
import { slide as Menu } from "react-burger-menu";
import ClientRoutes from "../../../navigation/Routes";
import { useHistory } from "react-router-dom";
import LogOutAll from "../../common/LogOut/LogOutAll";
import "../style.css";
import { useExcelDownloder } from "react-xls";
import Dropdown from "react-bootstrap/Dropdown";
import direction from "../../../assets/client/direction.svg";
import brain from "../../../assets/client/brain.svg";
import logout1 from "../../../assets/client/logout.svg";
import editInv from "../../../assets/client/editInv.svg";
import tables from "../../../assets/client/editTables.svg";
import creditCard from "../../../assets/client/creditCard.svg";
import video from "../../../assets/client/video2.svg";

import todoList from "../../../assets/client/todoList.svg";
import guests1 from "../../../assets/client/guests.svg";
import { MenuCss } from "../../common/modals/Toast";
import AskForDownload from "../../common/modals/AskForDownload.js";

const Expenses = () => {
  const { ExcelDownloder, Type } = useExcelDownloder();
  let history = useHistory();
  const [elaborateDish, setElaborateDish] = useState(0);
  const [change, setChange] = useState(false);
  const [dataToDownload, setDataToDownload] = useState(null);
  const [price, setPrice] = useState(null);
  const [sortDirection, setSortDirection] = useState(false);
  const [amount, setAmount] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [addExpense, setAddExpense] = useState(false);
  const [editExpenseS, setEditExpense] = useState(false);
  const [deleteExpenseS, setDeleteExpense] = useState(false);
  const [logout, setLogout] = useState(false);
  const dispatch = useDispatch();
  const { expenses, expensesError, expensesLoading } = useSelector(
    (state) => state.expensesReducer
  );
  const { user } = useSelector((state) => state.authReducer);
  const { addToast } = useToasts();

  const getExpenses = () => {
    dispatch(getAll());
  };

  useEffect(() => {
    getExpenses();
    return () => {
      dispatch(cleanAll());
    };
  }, []);

  useEffect(() => {
    if (expensesError) GetToast(addToast, "error", expensesError);
  }, [expensesError]);

  const setCalculate = async (id, calculate) => {
    dispatch(editCalculate(id, { calculate })).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
        setChange(!change);
      }
    });
  };

  const deleteExpenseFunc = async () => {
    dispatch(deleteExpense(deleteExpenseS)).then(() => {
      if (expensesError === null) GetToast(addToast, "success", "success!!");
    });
  };

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  const prepareDataToDownload = () => {
    setDataToDownload(expensesArray[2]);
  };

  let expensesArray = useMemo(() => {
    let arr = [];
    let exel = [];
    let sortedArray = [];
    let totalPrice = 0;
    if (Object.keys(expenses).length > 0) {
      for (const [key, value] of Object.entries(expenses))
        sortedArray.push(value);
      switch (sortBy) {
        case "dateAdded":
          if (sortDirection)
            sortedArray.sort(
              (a, b) => new Date(b[sortBy]) - new Date(a[sortBy])
            );
          else
            sortedArray.sort(
              (a, b) => new Date(a[sortBy]) - new Date(b[sortBy])
            );
          break;
        case "price":
          if (sortDirection)
            sortedArray.sort(
              (a, b) =>
                parseInt(b.price) * parseInt(b.amount) -
                parseInt(a.price) * parseInt(a.amount)
            );
          else
            sortedArray.sort(
              (a, b) =>
                parseInt(a.price) * parseInt(a.amount) -
                parseInt(b.price) * parseInt(b.amount)
            );
          break;
        default:
          if (sortDirection) sortedArray.sort((a, b) => b[sortBy] - a[sortBy]);
          else sortedArray.sort((a, b) => a[sortBy] - b[sortBy]);
      }
      for (let i = 0; i < sortedArray.length; i++) {
        exel.push({
          "שם ההוצאה": sortedArray[i].name,
          כמות: sortedArray[i].amount,
          "עלות ליחידה": sortedArray[i].price,
          "עלות כוללת": sortedArray[i].price * sortedArray[i].amount,
          "האם לכלול בחישוב": sortedArray[i].calculate ? "כן" : "לא",
        });
        if (sortedArray[i].calculate)
          totalPrice += sortedArray[i].amount * sortedArray[i].price;
        arr.push(
          <TodoCard key={i} onClick={() => setEditExpense(sortedArray[i])}>
            <div className="info">
              <div>{sortedArray[i].name}</div>
              <div>{sortedArray[i].amount * sortedArray[i].price}₪</div>

              <OverlayTrigger
                key={i + 1 * 1000}
                placement={"bottom"}
                overlay={<Tooltip>{"לכלול בחישוב?"}</Tooltip>}
              >
                <div onClick={(e) => e.stopPropagation()}>
                  <Switch
                    width={34}
                    height={14}
                    onColor={"#b1b1b1"}
                    offColor={"#b1b1b1"}
                    onHandleColor={user.color}
                    boxShadow={"0 2px 4px silver, 0 -1px 4px silver"}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={sortedArray[i].calculate}
                    onChange={() =>
                      setCalculate(
                        sortedArray[i]._id,
                        !sortedArray[i].calculate
                      )
                    }
                    handleDiameter={20}
                  />
                </div>
              </OverlayTrigger>
            </div>
          </TodoCard>
        );
      }

      exel.push({
        "שם ההוצאה": "אישורי הגעה",
        כמות: user.records,
        "עלות ליחידה": user.deal,
        "עלות כוללת": user.deal * user.records,
        "האם לכלול בחישוב": "כן",
      });

      exel.push({
        "שם ההוצאה": "",
        כמות: "",
        "עלות ליחידה": "",
        "עלות כוללת": "",
        "האם לכלול בחישוב": "",
      });

      exel.push({
        "שם ההוצאה": "מחיר מנה",
        כמות: "כמות מנות",
        "עלות ליחידה": "עלות מנות",
        "עלות כוללת": "הוצאות נוספות",
        "האם לכלול בחישוב": "סך הוצאות",
        "": "עלות לאורח",
      });

      exel.push({
        "שם ההוצאה": price,
        כמות: amount,
        "עלות ליחידה": price * amount,
        "עלות כוללת": totalPrice,
        "האם לכלול בחישוב":
          price * amount + totalPrice + user.deal * user.records,
        "":
          parseInt(amount) > 0
            ? (
                parseInt(
                  totalPrice + parseInt(price) * parseInt(amount)
                ).toFixed() / amount
              ).toFixed()
            : "",
      });

      // exel.push({
      //     "שם ההוצאה": "",
      //     "כמות": "",
      //     "עלות ליחידה": "",
      //     "עלות כוללת": "",
      //     "האם לכלול בחישוב": "",
      // })

      // exel.push({
      //     "שם ההוצאה": "",
      //     "כמות": "",
      //     "עלות ליחידה": "",
      //     "עלות כוללת": "",
      //     "האם לכלול בחישוב": "",
      //     "": "עלות לאורח",
      // })

      // exel.push({
      //     "שם ההוצאה": "",
      //     "כמות": "",
      //     "עלות ליחידה": "",
      //     "עלות כוללת": "",
      //     "האם לכלול בחישוב": "",
      // })
    }
    setElaborateDish(
      (
        parseInt(totalPrice + parseInt(price) * parseInt(amount)).toFixed() /
        amount
      ).toFixed() + "₪"
    );
    return [arr, totalPrice, exel];
  }, [expenses, sortBy, sortDirection, change, price, amount]);

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  return (
    <MainDiv
      onClick={() => setIsOpen(false)}
      className="animate__animated animate__fadeIn"
      style={{
        overflow: "hidden",
        background: `radial-gradient(circle, ${addAlpha(
          user.color,
          0.25
        )} 0%, #f2f2f2 100%)`,
        maxHeight: "100vh",
      }}
    >
      <Menu
        noOverlay
        isOpen={isOpen}
        onStateChange={handleStateChange}
        right
        width={"max-content"}
        styles={MenuCss(user.color)}
      >
        <div
          className="menu-item"
          style={{ marginTop: "0px" }}
          onClick={() => history.push(ClientRoutes.User.guests)}
        >
          <div>מוזמנים</div>
          <img src={guests1} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.tables)}
        >
          <div>מערך שולחנות</div>
          <img src={tables} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.instructions)}
        >
          <div>הדרכות</div>
          <img src={video} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.editDetails)}
        >
          <div>עריכת הזמנה</div>
          <img src={editInv} style={{ width: "23px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.todos)}
        >
          <div>יומן משימות</div>
          <img src={todoList} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          onClick={() => history.push(ClientRoutes.User.print)}
        >
          <div>מוח המערכת</div>
          <img src={brain} style={{ width: "25px" }} />
        </div>
        <div
          className="menu-item"
          style={{
            position: "absolute",
            bottom: "20px",
            fontWeight: "bold",
            fontSize: "20px",
          }}
          onClick={() => setLogout(true)}
        >
          <div>התנתקות</div>
          <img src={logout1} style={{ width: "25px" }} />
        </div>
      </Menu>
      {/* <OverlayTrigger placement={'bottom'} overlay={<Tooltip>{"ייצוא לאקסל"}</Tooltip>}>
                <div onClick={() => print()} style={{
                    position: "absolute", right: "75px", top: "18px", zIndex: 9, border: "none",
                    background: user.color, padding: "4px 6px", borderRadius: "20%", cursor: "pointer"
                }}>
                    ff
                </div>
            </OverlayTrigger> */}
      {/* {
                expensesArray[2].length && <ExcelDownloder
                    data={{ "נתונים": dataToDownload }}
                    filename={`הוצאות`}
                    type={Type.Button} // or type={'button'}
                    style={{
                        position: "absolute", right: "75px", top: "18px", zIndex: 9, border: "none",
                        background: user.color, padding: "4px 6px", borderRadius: "20%", cursor: "pointer"
                    }}
                >
                    <img src={download} alt="download" style={{ width: "20px", paddingBottom: "1.8px" }} />
                </ExcelDownloder>
            } */}
      <div
        background={user.color}
        onClick={prepareDataToDownload}
        style={{
          position: "absolute",
          right: "75px",
          top: "22px",
          zIndex: 2,
          border: "none",
          background: user.color,
          padding: "1px 4px",
          borderRadius: "20%",
          cursor: "pointer",
        }}
      >
        <img
          src={download}
          alt="download"
          style={{ width: "16px", paddingBottom: "1.8px" }}
        />
      </div>
      {/* {data &&
                <ExcelDownloder
                    data={{ "נתונים": data }}
                    filename={`הוצאות`}
                    type={Type.Button} // or type={'button'}
                    style={{
                        position: "absolute", right: "110px", top: "18px", zIndex: 9, border: "none",
                        background: user.color, padding: "4px 6px", borderRadius: "20%", cursor: "pointer"
                    }}
                >
                    <img src={download} alt="download" style={{ width: "20px", paddingBottom: "1.8px" }} />
                </ExcelDownloder>
            } */}

      <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip>{"הוצאה חדשה"}</Tooltip>}
      >
        <div
          onClick={() => setAddExpense(true)}
          style={{
            position: "absolute",
            left: "11px",
            top: "22px",
            zIndex: 9,
            background: user.color,
            padding: "1px 5px",
            borderRadius: "20%",
            cursor: "pointer",
          }}
        >
          <img
            src={plus}
            alt="plus"
            style={{ width: "16px", paddingBottom: "1.8px" }}
          />
        </div>
      </OverlayTrigger>

      <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip>{"מיון"}</Tooltip>}
      >
        <Dropdown
          style={{
            position: "absolute",
            left: "43px",
            top: "22px",
            zIndex: 9,
          }}
        >
          <Dropdown.Toggle
            style={{
              background: user.color,
              border: "none",
              height: "26px",
              width: "20px",
              borderRadius: "20%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            id="dropdown-basic"
          ></Dropdown.Toggle>
          <Dropdown.Menu style={{ textAlign: "right" }}>
            <Dropdown.Item onClick={() => setSortBy("price")}>
              עלות כוללת
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSortBy("calculate")}>
              סטטוס
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSortBy("dateAdded")}>
              תאריך הוספה
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </OverlayTrigger>

      <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip>{"כיוון"}</Tooltip>}
      >
        <div
          onClick={() => setSortDirection(!sortDirection)}
          style={{
            position: "absolute",
            left: "73.5px",
            top: "22px",
            zIndex: 9,
            background: user.color,
            padding: "1px 3px",
            borderRadius: "20%",
            cursor: "pointer",
          }}
        >
          <img
            src={direction}
            alt="direction"
            style={{ width: "16px", paddingBottom: "1.8px" }}
          />
        </div>
      </OverlayTrigger>

      <FlexRowBetween
        style={{
          width: "100%",
          padding: "20px 5%",
          alignItems: "center",
          position: "fixed",
          zIndex: 1,
          justifyContent: "center",
          background: `#f2f2f2`,
          paddingRight: "5%",
          boxShadow: "0 2px 20px silver",
          paddingBottom: "15px",
        }}
      >
        <div
          style={{
            fontSize: "22px",
            fontWeight: "bold",
            textAlign: "center",
            color: "black",
          }}
        >
          יומן הוצאות
        </div>
      </FlexRowBetween>

      <InputBox
        labelColor={"gray"}
        color={"#3A3C3F"}
        borderBottomColor={user.color}
        style={{
          width: "95%",
          marginTop: "80px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className="relativeBox"
          style={{
            marginRight: "0",
            minWidth: "20%",
            maxWidth: "40%",
            width: "min-content",
          }}
        >
          <input
            type="number"
            min={1}
            max={1000}
            required={true}
            onChange={(e) => setPrice(e.target.value)}
          />
          <label>מחיר מנה (₪)</label>
        </div>
        <div className="relativeBox" style={{ marginRight: "0", width: "20%" }}>
          <input
            type="number"
            min={1}
            max={10000}
            required={true}
            onChange={(e) => setAmount(e.target.value)}
          />
          <label>כמות מנות</label>
        </div>
        {parseInt(price) > 0 && parseInt(amount) > 0 && (
          <>
            <div style={{ fontSize: "14px", textAlign: "center" }}>
              <div style={{ fontSize: "12px", color: "gray" }}>סה״כ</div>
              <div>{parseInt(price) * parseInt(amount) + "₪"}</div>
            </div>
            <div style={{ fontSize: "14px", textAlign: "center" }}>
              <div style={{ fontSize: "12px", color: "gray" }}>סך ההוצאות</div>
              <div>
                {parseInt(
                  expensesArray[1] + parseInt(price) * parseInt(amount)
                ) + "₪"}
              </div>
            </div>
            <div style={{ fontSize: "14px", textAlign: "center" }}>
              <div style={{ fontSize: "12px", color: "gray" }}>עלות לאורח</div>
              <div>{elaborateDish}</div>
            </div>
            <div></div>
          </>
        )}
      </InputBox>
      <div
        style={{
          width: "100%",
          marginTop: "20px",
          textAlign: "center",
          paddingBottom: "20px",
        }}
      >
        <TodoCard color={user.color} today={true} style={{ padding: "13px 0" }}>
          <RespFont className="info">
            <div>
              <div>רשומות</div>
              <div>{user.records}</div>
            </div>
            <div>
              <div>חבילה</div>
              <div>{user.deal}₪</div>
            </div>
            <div>
              <div>סה״כ</div>

              <div>{user.deal * user.records}₪</div>
            </div>
          </RespFont>
        </TodoCard>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "20px",
          textAlign: "center",
          paddingBottom: "20px",
        }}
      >
        <TodoCard color={user.color} today={true} style={{ padding: "13px 0" }}>
          <RespFont className="info">
            <div>
              <div>רשומות בהפצה עצמית</div>
              <div>{user.selfRecords}</div>
            </div>
            <div>
              <div>חבילה</div>
              <div>{user.deal / 2}₪</div>
            </div>
            <div>
              <div>סה״כ</div>

              <div>{(user.deal / 2) * user.selfRecords}₪</div>
            </div>
          </RespFont>
        </TodoCard>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "20px",
          textAlign: "center",
          paddingBottom: "20px",
        }}
      >
        <TodoCard color={user.color} today={true} style={{ padding: "13px 0" }}>
          <RespFont className="info">
            <div>
              <div>מתנות באשראי</div>
              <div>1</div>
            </div>
            <div>
              <div>חבילה</div>
              <div>{user.giftCost}₪</div>
            </div>
            <div>
              <div>סה״כ</div>

              <div>{user.giftCost}₪</div>
            </div>
          </RespFont>
        </TodoCard>
      </div>
      <TodosHolder style={{ paddingTop: "0px", marginTop: "30px" }}>
        {expensesArray[0]}
      </TodosHolder>

      {addExpense && (
        <GenericModal
          init={setAddExpense}
          div={<AddExpense init={setAddExpense} />}
        />
      )}
      {editExpenseS && (
        <GenericModal
          init={setEditExpense}
          div={
            <EditExpense
              setDeleteExpense={setDeleteExpense}
              data={editExpenseS}
              init={setEditExpense}
            />
          }
        />
      )}

      {deleteExpenseS && (
        <GenericModal
          init={setDeleteExpense}
          div={
            <YesNoModal
              color={user.color}
              title={"מחיקת משימה"}
              body={"האם את/ה בטוח/ה?"}
              no={setDeleteExpense}
              yes={deleteExpenseFunc}
            />
          }
        />
      )}

      {logout && (
        <GenericModal init={setLogout} div={<LogOutAll init={setLogout} />} />
      )}

      {dataToDownload && (
        <GenericModal
          init={setDataToDownload}
          div={
            <AskForDownload
              color={user.color}
              title={"אקסל הוצאות"}
              filename={"הוצאות"}
              body={"האם ברצונך להוריד אקסל הוצאות?"}
              no={setDataToDownload}
              data={dataToDownload}
              init={setDataToDownload}
            />
          }
        />
      )}
      {/* {dataToDownload && <AskForDownload
                color={user.color}
                yes={prepareDataToDownload}
                no={setDataToDownload}
                data={dataToDownload}
            />} */}

      {expensesLoading && <OpacityLoading />}
    </MainDiv>
  );
};

export default Expenses;
