import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import facebook from '../../../../assets/common/facebook.svg';
import pic from '../../../../assets/producers/aviv/partyOpacity.png'
import avivLogo from '../../../../assets/producers/aviv/avivLogo.png';
import ClientRoutes from '../../../../navigation/Routes';
import { FlexRowBetween, GenericButton, MainWithPhoto } from '../../../common/Style';
import Logo from '../../../common/logo/Logo';
import ContactUs2 from '../../../common/contactUs/ContactUs2';
import ShareUs from '../../ShareUs';
import GenericModal from '../../../common/modals/GenericModal';
import { AvivItems } from '../Style';
import { getImageUrl } from '../../../common/modals/Toast';

const Ohad = () => {
    const [shareUs, setShareUs] = useState(false)
    useEffect(() => {
        localStorage["logout"] = ClientRoutes.Producers.ohad
    }, []);

    return (
        <MainWithPhoto className="App animate__animated animate__fadeIn"
            pic={pic}
            opacity={1}
        >
            <div style={{ width: "94%", height: "100%", position: "absolute" }}>
                <FlexRowBetween style={{ alignItems: "flex-end", marginTop: "3%" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <GenericButton background={"#3A3C3F"} as={Link} to={ClientRoutes.UnAuth.login}>כניסה</GenericButton>
                        <GenericButton background={"#3A3C3F"} as={Link} to={ClientRoutes.UnAuth.register} style={{ marginRight: "10px" }}>הרשמה</GenericButton>
                    </div>
                    <Logo back={"#3A3C3F"} />
                </FlexRowBetween>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={avivLogo} alt="avivLogo" style={{ width: "240px" }} />
                </div>
                <AvivItems style={{ alignItems: "center", marginTop: `3vh` }}>
                    <div style={{
                        background: "#3A3C3F", padding: `13px 22px`, borderRadius: "5px", color: "white",
                        fontSize: `16px`, fontWeight: "bold", cursor: "pointer", userSelect: "none", marginTop: `8px`
                    }} onClick={() => window.open(`${getImageUrl() + "sys/h2.png"}`)}>קצת עלינו</div>

                    <div style={{
                        background: "#3A3C3F", padding: `13px 22px`, borderRadius: "5px", color: "white",
                        fontSize: `16px`, fontWeight: "bold", cursor: "pointer", userSelect: "none", marginTop: `8px`
                    }} onClick={() => window.open(`${getImageUrl() + "sys/avivPriceList.png"}`)}>מחירון ושירותים</div>
                    <div style={{
                        background: "#3A3C3F", padding: `13px 22px`, borderRadius: "5px", color: "white",
                        fontSize: `16px`, fontWeight: "bold", cursor: "pointer", userSelect: "none", marginTop: `8px`
                    }} onClick={() => setShareUs(true)}>שתף אותנו</div>
                </AvivItems>
            </div>
            <ContactUs2
                phone={"0527734788"}
                facebook={<a target="_blank" href={`https://www.facebook.com/aviv.ohad/`} rel="noopener noreferrer"><img src={facebook} alt="facebook" /></a>}
                background={"#3A3C3F"}
                animate={"animate__animated animate__fadeInLeftBig"} />

            {
                shareUs && <GenericModal
                    init={setShareUs}
                    div={<ShareUs background="#3A3C3F" init={setShareUs} />}
                />
            }

        </MainWithPhoto>
    )
}

export default Ohad;