import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ClientRoutes from './Routes';
import Couples from '../components/hall/sofia/Couples';

const HallSwitch = () => {
  return (
    <Switch>
      <Route path={ClientRoutes.Hall.couples} component={props => <Couples {...props} />} />
      <Redirect from="/" to={ClientRoutes.Hall.couples} />
    </Switch>
  )
}

export default HallSwitch;