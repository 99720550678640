import { createSlice } from "@reduxjs/toolkit";
import ServerRoutes from "../api/routes";
import { post, post2, get2 } from "../api/http";

const initialState = {
  guests: {},
  guest: null,
  guestsError: null,
  guestsLoading: false,
  getWhHadC: false,
};

const _actionStart = (state) => {
  state.guestsLoading = true;
  state.guestsError = null;
};

const _getWhHadCA = (state) => {
  state.getWhHadC = !state.getWhHadC;
};
const _actionSuccess = (state) => {
  state.guestsLoading = false;
  state.guestsError = null;
};
const _actionFailed = (state, action) => {
  state.guestsLoading = false;
  state.guestsError = action.payload.error;
};

const _setGuests = (state, action) => {
  state.guests = action.payload;
};

const _editGuestAdmin = (state, action) => {
  let editedGuests = { ...state.guests };
  for (const [key, value] of Object.entries(action.payload.body))
    editedGuests[action.payload._id][key] = value;
  state.guests = editedGuests;
};

const _deleteGuests = (state, action) => {
  let editedGuests = { ...state.guests };
  for (let i = 0; i < action.payload.length; i++) {
    delete editedGuests[action.payload[i].closeness][action.payload[i]._id];
    if (Object.keys(editedGuests[action.payload[i].closeness]).length === 0)
      delete editedGuests[action.payload[i].closeness];
  }
  state.guests = { ...editedGuests };
};

const _editGuest = (state, action) => {
  let editedGuests = { ...state.guests };
  const { _id, body, prevCloseness } = action.payload;
  let newGuest = { ...body, _id };
  if (prevCloseness !== body.closeness) {
    // במידה ושינו קטגוריה
    delete editedGuests[prevCloseness][_id];
    if (Object.keys(editedGuests[prevCloseness]).length === 0)
      delete editedGuests[prevCloseness];
  }
  if (editedGuests[body.closeness] === undefined)
    editedGuests = { ...editedGuests, [body.closeness]: { [_id]: newGuest } };
  else
    editedGuests = {
      ...editedGuests,
      [body.closeness]: { ...editedGuests[body.closeness], [_id]: newGuest },
    };
  state.guests = { ...editedGuests };
};

const _clean = (state) => {
  state.guests = {};
  state.guest = null;
  state.guestsError = null;
  state.guestsLoading = false;
};

const _editGuestGift = (state, action) => {
  const { item, gift } = action.payload;
  let editedGuests = { ...state.guests };
  editedGuests = {
    ...editedGuests,
    [item.closeness]: {
      ...editedGuests[item.closeness],
      [item._id]: { ...editedGuests[item.closeness][item._id], gift },
    },
  };
  state.guests = { ...editedGuests };
};

const _editGuestTable = (state, action) => {
  const { items, table } = action.payload;
  let editedGuests = { ...state.guests };
  for (const [key, value] of Object.entries(items))
    editedGuests = {
      ...editedGuests,
      [value.closeness]: {
        ...editedGuests[value.closeness],
        [value._id]: { ...editedGuests[value.closeness][value._id], table },
      },
    };
  state.guests = { ...editedGuests };
};

const _editGuestInvitingAndOwc = (state, action) => {
  const { items, inviting, owc } = action.payload;
  let editedGuests = { ...state.guests };
  for (const [key, value] of Object.entries(items))
    editedGuests = {
      ...editedGuests,
      [value.closeness]: {
        ...editedGuests[value.closeness],
        [value._id]: {
          ...editedGuests[value.closeness][value._id],
          inviting,
          owc,
        },
      },
    };
  state.guests = { ...editedGuests };
};

const _editGuestWasInvited = (state, action) => {
  const { items, wasInvited } = action.payload;
  let editedGuests = { ...state.guests };
  for (const [key, value] of Object.entries(items))
    editedGuests = {
      ...editedGuests,
      [value.closeness]: {
        ...editedGuests[value.closeness],
        [value._id]: {
          ...editedGuests[value.closeness][value._id],
          wasInvited,
        },
      },
    };
  state.guests = { ...editedGuests };
};

const _setGuest = (state, action) => {
  state.guest = action.payload;
};

const _addGuest = (state, action) => {
  let editedGuests = { ...state.guests };
  const { _id, body } = action.payload;
  let newGuest = { ...body, _id };
  if (editedGuests[body.closeness] === undefined) {
    editedGuests = { ...editedGuests, [body.closeness]: { [_id]: newGuest } };
  } else {
    editedGuests = {
      ...editedGuests,
      [body.closeness]: { ...editedGuests[body.closeness], [_id]: newGuest },
    };
  }
  state.guests = { ...editedGuests };
};

const guest = createSlice({
  name: "guest",
  initialState,
  reducers: {
    getWhHadCA: _getWhHadCA,
    setGuests: _setGuests,
    setGuest: _setGuest,
    addGuest1: _addGuest,
    editGuest: _editGuest,
    editGuestTable: _editGuestTable,
    editGuestWasInvited: _editGuestWasInvited,
    editGuestInvitingAndOwc: _editGuestInvitingAndOwc,
    deleteGuests1: _deleteGuests,
    editGuestGift: _editGuestGift,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    actionSuccess: _actionSuccess,
    cleanGuestsState: _clean,
    editGuestAdmin1: _editGuestAdmin,
  },
});

const { reducer, actions } = guest;

const {
  setGuests,
  setGuest,
  editGuestAdmin1,
  addGuest1,
  editGuest,
  editGuestTable,
  editGuestWasInvited,
  getWhHadCA,
  editGuestInvitingAndOwc,
  deleteGuests1,
  editGuestGift,
  actionStart,
  actionFailed,
  actionSuccess,
  cleanGuestsState,
} = actions;

export default reducer;

export const getGuestsWithFilter = (filter) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(ServerRoutes.Guests[filter], {});
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setGuests(data));
    }
  };
};

export const getGuest = (id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(ServerRoutes.Guests.getGuest, {
      id,
    });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setGuest(data));
    }
  };
};

export const getGuestAdmin = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Guests.getGuestAdmin,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setGuests(data));
    }
  };
};

export const cleanAll = () => {
  return async (dispatch) => {
    dispatch(cleanGuestsState());
  };
};

export const editGuestByIdReduxOnly = (body, _id) => {
  return async (dispatch) => {
    dispatch(editGuest({ _id, body, prevCloseness: body.closeness }));
  };
};

export const editGuestById = (body, _id, prevCloseness, reset) => {
  return async (dispatch) => {
    let body1 = { ...body };
    if (reset) body1.sent = 0;
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Guests.editById + _id,
      body1
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editGuest({ _id, body, prevCloseness }));
    }
  };
};

export const editGuestAdmin = (body, _id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Guests.editGuestAdmin + _id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editGuestAdmin1({ _id, body }));
    }
  };
};

export const resetSent = (body, _id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Guests.resetSent + _id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      return dispatch(actionSuccess());
      // return dispatch(editGuestAdmin1({ _id, body }));
    }
  };
};

export const setGuestsTable = (items, table) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Guests.setGuestsTable, {
      items,
      table,
    });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editGuestTable({ items, table }));
    }
  };
};

export const setGuestsInvitingAndOwc = (items, inviting, owc) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Guests.setGuestsInvitingAndOwc,
      { items, inviting, owc }
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editGuestInvitingAndOwc({ items, inviting, owc }));
    }
  };
};

export const setGuestsCloseness = (items, closeness) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Guests.setGuestsCloseness,
      { items, closeness }
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      return dispatch(actionSuccess());
    }
  };
};

export const setGuestsWasInvited = (items, wasInvited) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Guests.setGuestsWasInvited,
      { items, wasInvited }
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editGuestWasInvited({ items, wasInvited }));
    }
  };
};

export const setGuestGift = (item, gift) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Guests.setGift + item._id,
      { gift }
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editGuestGift({ item, gift }));
    }
  };
};

export const deleteGuest = (guestsToDelete) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Guests.deleteGuest, {
      guestsToDelete,
    });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(deleteGuests1(guestsToDelete));
    }
  };
};

export const addGuest = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Guests.addGuest,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(addGuest1({ _id: data.id, body }));
    }
  };
};

export const AddGuestHimself = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Guests.AddGuestHimself,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(addGuest1({ _id: data.id, body }));
    }
  };
};

export const setAcceptAndTransportation = (body, id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Guests.setAcceptAndTransportation + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      return dispatch(actionSuccess());
    }
  };
};

export const setAcceptAndTransportationHimself = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Guests.setAcceptAndTransportationHimself,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      return dispatch(actionSuccess());
    }
  };
};

export const setAccept = (body, id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Guests.setAccept + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      return dispatch(actionSuccess());
    }
  };
};

export const setDishes = (body, id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Guests.setDishes + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      return dispatch(actionSuccess());
    }
  };
};

export const addMany = () => {
  return async () => {
    await post(ServerRoutes.Guests.addMany, {});
  };
};

export const getMoney = (body) => {
  return async (dispatch) => {
    const result = await post(ServerRoutes.Guests.getMoney, body);
    dispatch(setGuests(result));
  };
};

export const getTablesGuests = (uid, sort) => {
  return async (dispatch) => {
    const result = await post(ServerRoutes.Guests.getTablesGuests, {
      uid,
      sort,
    });
    dispatch(setGuests(result));
  };
};

export const getwh = (uid) => {
  return async (dispatch) => {
    const result = await post(ServerRoutes.Guests.getNotAnswered, { uid });
    return dispatch(setGuests(result));
  };
};

export const getwhW = (uid) => {
  return async (dispatch) => {
    const result = await post(ServerRoutes.Guests.getNotAnsweredW, { uid });
    return dispatch(setGuests(result));
  };
};

export const getGuestsAdmin = (uid) => {
  return async (dispatch) => {
    const result = await post(ServerRoutes.Guests.getGuestsAdmin, { uid });
    return dispatch(setGuests(result));
  };
};

export const getAcceptedAdmin = (uid) => {
  return async (dispatch) => {
    const result = await post(ServerRoutes.Guests.getAcceptedAdmin, { uid });
    return dispatch(setGuests(result));
  };
};

export const getAccepted1 = (uid) => {
  return async (dispatch) => {
    const result = await post(ServerRoutes.Guests.getAccepted, { uid });
    dispatch(setGuests(result));
  };
};

export const getAllExcludeAccepted1 = (uid) => {
  return async (dispatch) => {
    const result = await post(ServerRoutes.Guests.getAllExcludeAccepted, {
      uid,
    });
    dispatch(setGuests(result));
  };
};

export const deleteMany = (uid) => {
  return async (dispatch) => {
    await post(ServerRoutes.Guests.deleteMany, { uid });
  };
};
