import { createSlice } from "@reduxjs/toolkit";
import ServerRoutes from "../api/routes";
import { get, get2, post, post2 } from "../api/http";

const initialState = {
  users: [],
  user: null,
  usersError: null,
  usersLoading: false,
};

const _setUsers = (state, action) => {
  state.users = action.payload;
  state.usersLoading = false;
};

const _setUser = (state, action) => {
  state.user = action.payload;
  state.usersLoading = false;
};

const _addUser = (state, action) => {
  state.users = state.users.concat(action.payload);
  state.usersLoading = false;
};

const _editUser = (state, action) => {
  for (let i = 0; i < state.users.length; i++) {
    if (state.users[i]._id === action.payload._id) {
      let temp = { ...state.users[i] };
      for (const [key, value] of Object.entries(action.payload.body))
        temp[key] = value;
      state.users[i] = { ...temp };
      break;
    }
  }
  state.usersLoading = false;
};

const _actionStart = (state, action) => {
  state.usersLoading = true;
  state.usersError = null;
};

const _actionSuccess = (state, action) => {
  state.usersLoading = false;
  state.usersError = null;
};

const _actionFailed = (state, action) => {
  state.usersLoading = false;
  state.usersError = action.payload.error;
};

const _deleteUser = (state, action) => {
  state.users = state.users.filter((x) => x._id !== action.payload);
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: _setUsers,
    setUser: _setUser,
    addUser: _addUser,
    editUser: _editUser,
    deleteUser1: _deleteUser,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    actionSuccess: _actionSuccess,
  },
});

const { reducer, actions } = user;

export const {
  setUsers,
  setUser,
  addUser,
  editUser,
  deleteUser1,
  actionStart,
  actionFailed,
  actionSuccess,
} = actions;

export default reducer;

export const getOne = (data) => {
  return async (dispatch) => {
    const result = await get(ServerRoutes.Client.getOne, data);
    dispatch(setUser(result));
  };
};

export const getAll = () => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(ServerRoutes.Client.getAll, {});
    if (status !== 200) return dispatch(actionFailed({ error }));
    else return dispatch(setUsers(data));
  };
};

export const getAvivClients = () => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(
      ServerRoutes.Client.getAvivClients,
      {}
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else return dispatch(setUsers(data));
  };
};

export const cleanAll = () => {
  return async (dispatch) => {
    dispatch(setUsers([]));
  };
};

export const editUserPD = (body, _id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Client.editPD + _id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else return dispatch(editUser({ _id, body }));
  };
};

export const destroyUser = (_id, body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Client.destroyUser + _id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(deleteUser1(_id));
    }
  };
};

export const editUserById = (body, _id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Client.edit + _id, body);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else return dispatch(editUser({ _id, body }));
  };
};

export const addBasicTodos = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Todos.addBasic,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    // else {
    //   return dispatch(actionSuccess());
    else
      return dispatch(
        editUser({ _id: body._id, body: { isTodosRecorded: true } })
      );
    // }
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    await post(ServerRoutes.Client.delete, { id });
    dispatch(deleteUser1(id));
  };
};

export const sendWhatsapp = (id, msg) => {
  return async () => {
    await post(ServerRoutes.Client.whatsapp, { id, msg });
  };
};
