import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import { addExpense } from '../../../store/reducers/expensesReducer';
import { CenteredForm, PlaceHolderImage, GenericButton, InputBox, RTextArea } from '../../common/Style'
import exitModalLogo from '../../../assets/common/cancel1.svg';
import { GetToast } from '../../common/modals/Toast';
import ExitModal from '../../common/modals/ExitModal';

const AddExpense = (props) => {
    const dispatch = useDispatch();
    const { expensesError } = useSelector(state => state.expensesReducer);
    const { user } = useSelector(state => state.authReducer);
    const { addToast } = useToasts();
    const { register, handleSubmit } = useForm();


    const onSubmit = async data => {
        dispatch(addExpense({ name: data.name, amount: parseInt(data.amount), price: parseInt(data.price) })).then(() => {
            if (expensesError === null)
                GetToast(addToast, "success", "הפעולה בוצעה בהצלחה")
        })
        props.init(false)
    }
    return (<CenteredForm onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit(onSubmit)}>
        <ExitModal init={props.init} color={user.color} />
        <div style={{ textAlign: "center", fontWeight: "bold", padding: "20px", fontSize: "22px" }}>הוצאה חדשה</div>
        <InputBox labelColor={"gray"} color={"#3A3C3F"} borderBottomColor={user.color}>
            <div className='relativeBox'>
                <input type="text" name="name" minLength={1} maxLength={50} required={true} ref={register()} />
                <label>שם ההוצאה</label>
            </div>
            <div className='relativeBox'>
                <input type="number" defaultValue={1} name="amount" min={1} max={10000} ref={register()} />
                <label>כמות</label>
            </div>
            <div className='relativeBox'>
                <input type="number" defaultValue={0} name="price" min={0} max={1000000} ref={register()} />
                <label>עלות ליחידה</label>
            </div>

            <GenericButton background={user.color} type="submit" style={{ width: "50%", margin: "30px auto", padding: "10px" }}>הוספה</GenericButton>
        </InputBox>
    </CenteredForm >)
}

export default AddExpense;